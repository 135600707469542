import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import {Card} from '@material-ui/core';
import Utils from '../../utils/utils';
import {connect} from "react-redux";
import {addStudent} from "../../redux/actions/studentActions";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import LearningCenterService from "../../services/learningCenterService";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
});

class LearningCenter extends React.Component {
    state = {
        learningCenter: {
            name: '',
            address: '',
            website: '',
            phone: '',
            email: '',
            pobox: '',
            lga: '',
            district: '',
            number_of_staff: '',
            classification: '',
            lecture_room_count: '',
            lecture_room_capacity: '',
            enrollment_capacity: '',
            ownership: '',
            financial_source: '',
            region: '',
            school_code: '',
        }
    };

    // Method to update values of the text fields
    handleChange = e => {
        let val = e.target.value;
        let name = e.target.name;
        if ((name === 'number_of_staff' || name === 'phone' || name === 'enrollment_capacity'
            || name === 'lecture_room_count' ||  name === 'lecture_room_capacity')
            && Utils.isValid(val)) {
            Utils.displayMessage("warning", "", "Only numbers allowed").then(r => r);
            return;
        }
        this.props.updateData(e.target.name, val);
    };

    // Method to clear values of the text fields
    clearForm = () => {
        let _orgData = this.state.learningCenter;
        _orgData.name = '';
        _orgData.address = '';
        _orgData.website = '';
        _orgData.number_of_staff = '';
        _orgData.ownership = '';
        _orgData.lga = '';
        _orgData.email = '';
        _orgData.school_code = '';
        this.setState({
            learningCenter: _orgData
        });
        if (localStorage.getItem('stakeholderId')) {
            this.goBack();
        }
    };

    // Method to validate values of the text fields
    validateFields = () => {
        const {name, email, address, school_code} = this.state.learningCenter;

        if (name === '' || !Utils.validateText(name)) return 'name';
        if (email !== '' && !Utils.validateEmail(email)) return 'email';
        if (address === '' || !Utils.validateText(address)) return 'address';
        if (school_code === '') return 'school_code';
    };

    /*
    * Method to set focus on the text fields with invalid data
    * Parameters:
    * + field_id: The id of the field to set the focus on
    * */
    setFocus = (field_id) => {
        Utils.displayMessage('error', 'Error', 'Please specify ' + field_id).then(r => r);
        document.getElementById(field_id).focus();
    };

    // Method to retrieve return organization id of a learning center
    getReturnedOrgId = (data) => {
        this.props.getOrgId(data);
        this.clearForm();
    };

    // Method to send and save data to the server
    saveData = () => {
        let data = this.state.learningCenter;

        let result = this.validateFields();
        if (result) {
            this.setFocus(result);
            return;
        }
        LearningCenterService.createLearningCenter(data, this.getReturnedOrgId);

        this.props.enbaleButton();
        this.setState({
            // isEnabled: true
            isEnabled: false
        });
    };

    // Initializes the select field values of the form
    initialiseSelectFields = () => {
        let _lc = {...this.state.learningCenter};
        _lc.ownership = Utils.sector[0].value;
        _lc.financial_source = Utils.financial_source[0].value;
        _lc.lga = Utils.lga[0].value;
        _lc.region = Utils.regions[0].value;
        _lc.classification = Utils.classifications[0].value;
        this.setState({
            learningCenter: _lc
        })
    };

    // Method to navigate back to the previous component/view
    goBack = () => {
        this.props.history.goBack();
    };

    componentDidMount() {
        this.initialiseSelectFields();
    }

    render() {
        const {classes} = this.props;
        let lc = this.props.lcData;

        return (
            <div>
                <div>
                    <Card>
                        <div style={{textAlign: "center"}}>
                            <form className={classes.root}>
                                <h4>Enter LearningCenter Information</h4>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <TextField
                                            required
                                            id="name"
                                            name="name"
                                            label="Learning Center Name"
                                            variant="outlined"
                                            value={lc.name}
                                            onChange={e => this.handleChange(e)}
                                        />
                                        <TextField
                                            required
                                            id="email"
                                            name="email"
                                            label="Learning Center Email"
                                            variant="outlined"
                                            value={lc.email}
                                            onChange={e => this.handleChange(e)}
                                        />
                                        <TextField
                                            required
                                            id="phone"
                                            name="phone"
                                            label="Phone"
                                            variant="outlined"
                                            value={lc.phone}
                                            onChange={e => this.handleChange(e)}
                                        />
                                        <TextField
                                            id="website"
                                            name="website"
                                            label="Website"
                                            variant="outlined"
                                            value={lc.website}
                                            onChange={e => this.handleChange(e)}
                                        />


                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <TextField
                                            required
                                            id="region"
                                            name="region"
                                            select
                                            label="Region"
                                            value={lc.region}
                                            onChange={(e) => this.handleChange(e)}
                                            variant="outlined"
                                            style={{
                                                border: 0,
                                                outline: 0
                                            }}
                                        >
                                            {Utils.regions.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <TextField
                                            required
                                            id="lga"
                                            name="lga"
                                            select
                                            label="Local Government Area"
                                            value={lc.lga}
                                            onChange={(e) => this.handleChange(e)}
                                            variant="outlined"
                                            style={{
                                                border: 0,
                                                outline: 0
                                            }}
                                        >
                                            {Utils.lga.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <TextField
                                            required
                                            id="address"
                                            name="address"
                                            label="Address"
                                            variant="outlined"
                                            value={lc.address}
                                            onChange={e => this.handleChange(e)}
                                        />

                                        <TextField
                                            required
                                            id="ownership"
                                            name="ownership"
                                            select
                                            label="Sector"
                                            value={lc.ownership}
                                            onChange={(e) => this.handleChange(e)}
                                            variant="outlined"
                                            style={{
                                                border: 0,
                                                outline: 0
                                            }}
                                        >
                                            {Utils.sector.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>

                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <TextField
                                            required
                                            id="school_code"
                                            name="school_code"
                                            label="School Code"
                                            variant="outlined"
                                            value={lc.school_code}
                                            onChange={e => this.handleChange(e)}
                                        />
                                        <TextField
                                            id="pobox"
                                            name="pobox"
                                            label="P.O.Box"
                                            variant="outlined"
                                            value={lc.pobox}
                                            onChange={e => this.handleChange(e)}
                                        />
                                        <TextField
                                            id="financial_source"
                                            name="financial_source"
                                            label="Financial Source"
                                            variant="outlined"
                                            select
                                            value={lc.financial_source}
                                            onChange={e => this.handleChange(e)}
                                            style={{
                                                border: 0,
                                                outline: 0
                                            }}
                                        >
                                            {Utils.financial_source.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <TextField
                                            id="classification"
                                            name="classification"
                                            select
                                            label="Classification"
                                            value={lc.classification}
                                            onChange={(e) => this.handleChange(e)}
                                            variant="outlined"
                                            style={{
                                                border: 0,
                                                outline: 0
                                            }}
                                        >
                                            {Utils.classifications.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                    </Card>
                </div>
            </div>
        );
    }
}

export default connect(null, {addStudent})(withStyles(styles)(LearningCenter));


