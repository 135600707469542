import $ from "jquery";
import Utils from "../utils/utils";

import {Api} from "../config/api_config";
import * as cookies from "react-cookies";

export default class LearningCenterService {

    /*
    * Method to create learning center during registration
    * Parameters:
    * + data: The learning center details to be saved
    * + callback: The method to handle the response coming from the server
    * */
    static createLearningCenter(data, callback) {
        let r = Math.random().toString(36);
        $.ajax({
            method: "POST",
            url: Api.DEV_BASE_URL + "/v1/learning_center",
            headers: {"UserKey": r},
            dataType: 'json',
            data: {data: data, influence: 'emis', r: r},
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                callback(e.responseText);
            }
        })
    };

    /*
    * Method to update learning center
    * Parameters:
    * + data: The learning center details to be updated
    * + callback: The method to handle the response coming from the server
    * */
    static updateLC(data, callback) {
        const id = data._id.$oid;
        data.updating = true;
        $.ajax({
            method: "PUT",
            url: Api.DEV_BASE_URL + "/v1/learning_center/" + id,
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: data,
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Learning center not be saved').then(r => r);
            }
        })
    };

    static updatePicture(data, callback) {
        $.ajax({
            method: "PUT",
            url: Api.DEV_BASE_URL + "/v1/learning_center/upload_picture",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {lc_id: data.lc_id, picture: data.picture},
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Learning center not be saved').then(r => r);
            }
        })
    };

    /*
    * Method to load the logo/picture of a learning center
    * Parameters:
    * + id: The id of the learning center to get image for
    * + callback: The method to handle the response coming from the server
    * */
    static LoadImage(id, callback) {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/v1/learning_center/get_image",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {lc_id: id.$oid},
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Learning center not be saved').then(r => r);
            }
        })
    };

    /*
    * Method to load the settings/details of a learning center
    * Parameters:
    * + id: The id of the learning center to load details for
    * + callback: The method to handle the response coming from the server
    * */
    static loadSettings = (id, callback) => {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/v1/learning_center/" + id.$oid,
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            success: function (result) {
                callback(result.learning_center);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Settings could not be load').then(r => r)
            }
        });
    };

    /*
    * Method to load the grading system of a learning center
    * Parameters:
    * + id: The id of the learning center to load grades for
    * + callback: The method to handle the response coming from the server
    * */
    static loadGrades = (id, callback) => {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/v1/learning_center/get_placements",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {lcId: id.$oid},
            success: function (result) {
                callback(result.data);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Settings could not be load').then(r => r)
            }
        });
    };

    /*
    * Method to load the school terms definitions of a learning center
    * Parameters:
    * + id: The id of the learning center to load school terms definitions for
    * + callback: The method to handle the response coming from the server
    * */
    static getTerms = (id, callback) => {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/v1/school_terms",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {lcId: id.$oid},
            success: function (result) {
                callback(result.data);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'School terms could not be load').then(r => r)
            }
        });
    };

    /*
    * Method to load the current school term of a learning center
    * Parameters:
    * + id: The id of the learning center to load the current term for
    * + callback: The method to handle the response coming from the server
    * */
    static getCurrentTerm = (id, callback) => {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/v1/open_closed_terms/get_current_term",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {lcId: id.$oid},
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Current term could not be load').then(r => r)
            }
        });
    };

    /*
    * Method to suspend/activate programmes, courses, departments etc of a learning center
    * Parameters:
    * + id: The id of the learning center to make suspension for
    * + type: The name of the model to execute the suspension for, example Program
    * + suspend: suspension value, either suspended for suspension or activated for activating
    * + callback: The method to handle the response coming from the server
    * */
    static suspendItem = (id, type, suspend, callback) =>{
        $.ajax({
            method: "PUT",
            url: Api.DEV_BASE_URL+"/v1/learning_center/update_suspend",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"), "UserKey":cookies.load('User-Key')},
            dataType: "json",
            data: {id: id, type: type, suspend: suspend},
            success: function(result) {
                callback(result)
            },
            error: function(e){
                Utils.displayMessage('error', 'Failed', 'Course could not be suspended').then(r => r);
            }
        });
    };
}
