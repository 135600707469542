import $ from "jquery";
import Utils from "../utils/utils";

import {Api} from "../config/api_config";
import * as cookies from "react-cookies";

export default class DashboardService {

    /*
    * Method to get teachers' details for the dashboard
    * Parameters:
    * + callback: Method to handle returned data from the server
    * */
    static teachers = (callback) => {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/users/staff_chart_data",
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"), "UserKey":cookies.load('User-Key')},
            data:{org_id: cookies.load('orgId').$oid},
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Teachers chart data could not be loaded').then(r => r)
            }
        });
    };

    /*
    * Method to get students' details for the dashboard
    * Parameters:
    * + callback: Method to handle returned data from the server
    * */
    static students = (callback) => {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/v1/students/students_chart_data",
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"), "UserKey":cookies.load('User-Key')},
            data:{org_id: cookies.load('orgId').$oid},
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Student chart data could not be loaded').then(r => r)
            }
        });
    };
}
