import $ from "jquery";
import Utils from "../utils/utils";

import {Api} from "../config/api_config";
import * as cookies from "react-cookies";

export default class ProgramService {

    /*
    * Method to create/update a programme
    * Parameters:
    * + data: The data of the programme to created or updated
    * + callback: Method to handle the data returned from the server
    * */
    static createProgram(data, callback) {
        const id = (data._id) ? data._id.$oid : '';
        const _method = (id) ? "PUT" : "POST";
        const text = (id) ? "Program successfully updated" : "Program successfully added";

        let _data = {...data};
        _data.national_nameId = (_data.national_nameId.value) ? _data.national_nameId.value : _data.national_nameId;
        _data.departmentId = (_data.departmentId.value) ? _data.departmentId.value : _data.departmentId;
        _data.education_fieldId = (_data.education_fieldId.value) ? _data.education_fieldId.value : _data.education_fieldId;
        $.ajax({
            method: _method,
            url: Api.DEV_BASE_URL + "/v1/programs/" + id,
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            dataType: "json",
            data: _data,
            success: function (result) {
                Utils.displayMessage('success', 'Successful', text).then(r => r);
                callback();
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Program not be saved').then(r => r);
            }
        })
    }

    /*
    * Method to load all programmes of a learning center into a data table
    * Parameters:
    * + query: The query sent by the data table
    * + resolve: callback method to process the received list of courses
    * + callback: The method to handle the response coming from the server
    * */
    static loadData = (query, resolve, reject, callback) => {
        let url = Utils.compileQuery(query, (Api.DEV_BASE_URL + "/v1/programs"),
            ["id", "name", "national_name", "isced_level", "duration", "tuition", "entry_requirement", "award",
                "department_name", "accreditation_status", "accreditation_number", "education_field_name", "suspended", "can_be_deleted", "dirty", "global_id"], [{"by": "desc", "attr": "name"}],
            "Academics::Program", null, null);
        fetch(url, {
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            }
        })
            .then(response => {
                return response.json()
            })
            .then(result => {
                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total
                });
                callback();
            });
    };

    /*
    * Method to get the details of a programme
    * Parameters:
    * + id: The id of the programme to be loaded
    * + callback: Method to handle the data returned from the server
    * */
    static loadProgramId = (id, callback) => {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/v1/programs/" + id,
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            dataType: "json",
            success: function (result) {
                callback(result.program);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Program could not be loaded')
            }
        });
    };

    /*
    * Method to load programmes for the select fields
    * Parameters:
    * + callback: Method to handle response from the server
    *
    * */
    static loadPrograms = (callback) => {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/v1/programs/get_programs",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            success: function (result) {
                callback(result.programs);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Programs could not be loaded')
            }
        });
    };

    /*
    * Method to delete a programme
    * Parameters:
    * + id: The id of the programme to be deleted
    * + callback: Method to handle the data returned from the server
    * */
    static deleteProgram = (id, callback) => {
        $.ajax({
            method: "DELETE",
            url: Api.DEV_BASE_URL + "/v1/programs/" + id,
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            dataType: "json",
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Program not be deleted').then(r => r);
            }
        });
    };

    /*
    * Method to load departments and national field names
    * Parameters:
    * + callback: Method to handle response from the server
    *
    *  */
    static loadDeptsNationalNames = (callback) => {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/v1/programs/load_depts_national_names",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            dataType: "json",
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Departments/National names could not be loaded').then(r => r)
            }
        });
    };

    /*
    * Method to load the courses of a programme
    * Parameters:
    * + id: The id of the programme to load courses for
    * + callback: Method to handle the data returned from the server
    * */
    static loadCourses(id, callback) {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/v1/courses/program_courses",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            dataType: "json",
            data: {prog_id: id},
            success: function (result) {
                callback(result.courses);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'TeacherCourses could not be loaded').then(r => r);
            }
        })
    };

    /*
    * Method to get the list of courses for the select field of courses when adding a programme
    * Parameters:
    * + callback: Method to handle the data returned from the server
    * */
    static loadAllCourses(callback) {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/v1/courses/load_all",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            success: function (result) {
                callback(result.courses);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Courses could not be loaded').then(r => r);
            }
        })
    };

    /*
    * Method to get the list of departments for the select field of courses when adding a programme
    * Parameters:
    * + callback: Method to handle the data returned from the server
    * */
    static loadDepartments = (callback) => {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/v1/departments/get_departments",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            success: function (result) {
                callback(result.departments);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Departments could not be loaded').then(r => r);
            }
        })
    };

    /*
    * Method to fetch the list of students doing a course
    * Parameters:
    * + id: The id of the course to get the students for
    * + setName: The set name for the course
    * callback: THe method to handle data returned from the server
    *
    * */
    static getStudents = (id, setName, callback) => {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/v1/courses/get_students",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {course_id: id, set_name: setName},
            success: function (result) {
                callback(result.students);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Students could not be loaded').then(r => r);
            }
        });
    };


}
