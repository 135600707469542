import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Card, CardContent, CardHeader, Grid, Typography} from '@material-ui/core';

import StaffService from "../../services/staffService";
import MyButton from "../Buttons/Button";
import Table from "material-table";
import Button from "@material-ui/core/Button";
import Utils from "../../utils/utils";
import ActionButton from "../Buttons/ActionButton";
import ProgressBar from "../ProgressBar/ProgressBar";
import ResetStaffPassword from "./ResetStaffPassword";
import StudentService from "../../services/studentService";
import UploadStudentPicture from "../UploadPicture/UploadStudentPicture";
import UploadStaffPicture from "../UploadPicture/UploadStaffPicture";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    btn: {
        margin: 8
    }
});

const StaffDetails = (props) => {
    const _match = props.match;
    const [staffData, setStaffData] = useState({assign: null});
    const [staffProfile, setStaffProfile] = useState(null);
    const [courses, setCourses] = useState([]);
    const [employmentDetails, setEmploymentDetails] = useState([]);
    const [empHistoryData, setEmpHistoryData] = useState({});
    const [displayLoader, setDisplayLoader] = useState(false);
    const [displayDialog, setDisplayDialog] = useState(false);
    const [credentials, setCredentials] = useState({email: '', password: '', confirmPassword: ''});

    const header = [
        {title: 'Course', field: 'course_name'},
        {title: 'Set', field: 'class_set'},
        {title: 'Academic Year', field: 'academic_year', render: rowData => Utils.dateFormat(rowData.academic_year)},
        {title: 'Term', field: 'term'}
    ];

    const employmentHeader = [
        {title: 'Start Date', field: 'employment_date', render: rowData => Utils.dateFormat(rowData.employment_date)},
        {title: 'Type', field: 'employment_type'},
        {title: 'Qualification', field: 'highest_qualification'},
        {title: 'Qualification In', field: 'certificated_in'},
        {title: 'Department', field: 'department'},
        {title: 'Role', field: 'role'},
        {title: 'Teaching Level', field: 'teaching_level'},
        {title: 'Status', field: 'status'}
    ];

    useEffect(() => {
        setDisplayLoader(true);
    }, []);

    useEffect(() => {
        StaffService.loadStaffId(_match.params.staffId, setData);
        StaffService.getEmpHistory(_match.params.staffId, updateHistoryTable);
    }, [_match.params.staffId]);

    useEffect(() => {
        localStorage.removeItem('teacherId');
    }, []);

    const classes = useStyles();

    // Method to update field values to display the user details
    const setData = (result) => {
        setStaffData(result.user);
        setStaffProfile(result.profile);
        setCourses(result.courses);
        setStaffPicture(result.user.image)

        let creds = {...credentials};
        creds.email = result.user.email;
        setCredentials(creds);

        setDisplayLoader(false);
    };

    // Method to update the employment history table of a staff
    const updateHistoryTable = (data) => {
        // let _data = data.filter(d => d.status === "active")[0];
        setEmploymentDetails(data);
        setEmpHistoryData(data[0]);
    };

    // Method to terminate a staff's current employment
    const terminateEmployment = () => {
        let text = "Do you want to terminate staff's employment?";
        Utils.confirmDeleteMessage(text)
            .then((willDelete) => {
                if (willDelete) {
                    StaffService.terminateEmployment(_match.params.staffId);
                }
            });
    };

    // Method to activate a staff's terminated employment
    const activateEmployment = () => {
        let text = "Do you want to re-activate staff's employment?";
        Utils.confirmDeleteMessage(text)
            .then((willDelete) => {
                if (willDelete) {
                    StaffService.activateEmployment(_match.params.staffId);
                }
            });
    };

    const openResetPassword = () => {
        setDisplayDialog(true);
    };

    // Method to return to the previous view
    const goBack = () => {
        props.history.goBack();
        if (localStorage.getItem("staff_id")) {
            localStorage.removeItem("staff_id");
        }
    };

    // Method to remove a staff as the hod of a department
    const removeHOD = () => {
        let text = 'Remove staff as HOD, do you want to continue?';
        Utils.confirmDeleteMessage(text)
            .then((willDelete) => {
                if (willDelete) {
                    StaffService.unAssignHOD(_match.params.staffId, result => {
                        if (result.error) {
                            Utils.displayMessage('error', 'Error', result.errors[0]).then(r => r);
                        } else {
                            Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                            StaffService.loadStaffId(_match.params.staffId, setData)
                        }
                    });
                }
            });
    };

    const displayField = (data) => (
        <Typography className={classes.title} gutterBottom variant="h5">
            {data}
        </Typography>
    );

    /*
    * Method to unassign a course from a teacher
    * Parameters:
    * + rowData: The course to unassign from the teacher
    *
    * */
    const unAssignCourse = (event, rowData) => {
        let response = window.confirm('Are you sure you want to unassign this course from this teacher?');
        if (response) {
            StaffService.unAssignCourse(rowData._id.$oid, result => {
                if (result.error) {
                    Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
                } else {
                    Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                    StaffService.loadStaffId(_match.params.staffId, setData);
                }
            });
        }
    };

    const handleChange = (e) => {
        let creds = {...credentials};
        creds[e.target.name] = e.target.value;
        setCredentials(creds);
    };

    const onClose = () => {
        setDisplayDialog(false);
    };

    // Method for admin to reset a staff's password when they forget it.
    const resetStaffPassword = () => {
        let cred = {...credentials};
        cred.email = staffData.email;
        if (cred.password !== cred.confirmPassword) {
            Utils.displayMessage('error', 'Password mismatch', 'Passwords do not match').then(r => r);
        } else {
            StaffService.adminResetPassword(cred, result => {
                if (result.error) {
                    Utils.displayMessage('error', 'Error', result.errors[0]).then(r => r);
                } else {
                    Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                    onClose();
                }
            });
        }
    };

    const [openPicture, setOpenPicture] = useState(false)
    const [staffPicture, setStaffPicture] = useState('')

    const openUploadPicture = () => setOpenPicture(true);

    const handleClosePicture = () => setOpenPicture(false);


    const loadStaffImage = () => {
        StaffService.LoadImage(staffData._id.$oid, result => {
            if (result && result.image) {
                const preview = document.getElementById("test");
                setStaffPicture(result.image)
            }
        });
    };

    return staffData && staffProfile && (
        <div>
            {
                displayLoader ? <div style={{marginBottom: 15}}><ProgressBar/></div> : null
            }
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                    {
                        (staffData.status == 'inactive') ?
                            <></>
                            :
                            <MyButton to={{
                                pathname: `${_match.url}/edit-staff`,
                                staffData: staffData,
                                empHistoryData: empHistoryData,
                                staffProfile: staffProfile
                            }}
                                      direction={'left'} color={"primary"}
                                      title={"Edit"}
                            />
                    }

                    {
                        (staffData.hod_for !== null) ?
                            <Button variant="contained" color="default" onClick={removeHOD}>
                                Remove As HOD
                            </Button> : null
                    }
                    <Button variant="contained" color="secondary" onClick={goBack} style={{float: 'right'}}>
                        Back
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={7}>
                    <Card className={classes.root}>
                        <CardHeader color="primary" title="Staff Details"/>
                        <CardContent style={{textAlign: 'left'}}>
                            <Grid container>
                                <Grid item xs={4} sm={4} md={3}>
                                    <img width="90%" height="90%" id="test" src={staffPicture}/>
                                </Grid>
                                <Grid item xs={4} sm={4} md={3}>
                                    <Button variant="contained" color="default" onClick={openUploadPicture}
                                            style={{margin: 5}}>
                                        UPLOAD
                                    </Button>
                                </Grid>

                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Staff ID")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(staffData.user_id)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("First Name")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(staffData.first_name)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Middle Name")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(staffData.middle_name)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Last Name")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(staffData.last_name)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Department")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(staffData.department_name)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("DOB")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(Utils.dateFormat(staffData.dob))}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Phone Number")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(staffData.phone_number)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Email")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(staffData.email)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Role")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(staffProfile.user_type.charAt(0).toUpperCase() + staffProfile.user_type.slice(1))}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Rank")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(staffData.rank)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("License")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(staffData.license)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Highest Qualification")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(empHistoryData.highest_qualification)}
                                </Grid>
                            </Grid>
                            {
                                (staffData.hod_for !== null) ?
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={4}>
                                            {displayField("HOD For")}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            {displayField(staffData.hod_for)}
                                        </Grid>
                                    </Grid> : null
                            }
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                    {
                        (staffData.status == 'inactive') ?
                            <Card style={{textAlign: 'center', padding: 15}}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <ActionButton color="default" fullWidth={true} onClick={activateEmployment}
                                                  title={"Activate Old Employment"} color="primary"/>
                                </Grid>

                            </Card> :

                            <Card style={{textAlign: 'center', padding: 15}}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <MyButton to={{pathname: `${_match.url}/assign-course`, staffData: staffData}}
                                              direction={'center'} color={"default"}
                                              title={"Assign Course"}
                                              style={{backgroundColor: 'blue', color: '#fff', width: 200}}
                                              fullWidth={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <MyButton to={{pathname: `${_match.url}/assign-hod`, staffData: staffData, hod: true}}
                                              direction={'center'} color={"primary"}
                                              title={"Assign HOD"}
                                              fullWidth={true}
                                              hod={true}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <ActionButton color="default" fullWidth={true} onClick={terminateEmployment}
                                                  title={"Terminate Employment"}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <ActionButton color="primary" fullWidth={true} onClick={openResetPassword}
                                                  title={"Reset Password"}/>
                                </Grid>
                            </Card>
                    }

                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                    <Table
                        title="Courses"
                        columns={header}
                        data={courses}
                        options={{
                            exportButton: true, filtering: false,
                            grouping: false, sorting: true,
                            debounceInterval: 1000,
                            selection: false, showTitle: true,
                            pageSize: 5, search: false, actionsColumnIndex: -1
                        }}
                        actions={[
                            {
                                icon: () => <Button
                                    color="primary"
                                    variant="contained"
                                    style={{textTransform: 'none'}}
                                    size="small"
                                >
                                    Unassign
                                </Button>,
                                tooltip: 'Unassign Course',
                                onClick: (event, rowData) => unAssignCourse(event, rowData)
                            }
                        ]}
                    />
                </Grid>
                {
                    (employmentDetails.length === 0) ?
                        null
                        :
                        <Grid item xs={12} sm={12} md={9}>
                            <Table
                                title="Employment History"
                                columns={employmentHeader}
                                data={employmentDetails}
                                options={{
                                    exportButton: false, filtering: false,
                                    grouping: false, sorting: false,
                                    debounceInterval: 1000,
                                    selection: false, showTitle: true,
                                    pageSize: 5, search: false
                                }}
                            />
                        </Grid>
                }

            </Grid>
            {
                displayDialog ? <ResetStaffPassword open={displayDialog} onClose={onClose} handleChange={handleChange}
                                                    credentials={credentials}
                                                    resetStaffPassword={resetStaffPassword}/> : null
            }
            {
                (openPicture) ?
                    <UploadStaffPicture
                        staffId={staffData._id.$oid}
                        open={openPicture}
                        onClose={handleClosePicture}
                        updataPic={loadStaffImage}
                        reloadImage={loadStaffImage}
                    /> : null
            }
        </div>
    );
};

export default StaffDetails;
