import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import {Card, Grid} from '@material-ui/core';
import Utils from "../../utils/utils";
import LearningCenterService from "../../services/learningCenterService";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
});


class Infrastructure extends React.Component {
    state = {
        infrastructureData: {
            num_computers: '',
            stud_comp_ratio: '',
            num_libraries: '',
            lib_capacity: '',
            avail_audio_visual_aids: '',
            num_male_toilets: '',
            male_stud_toilet_ratio: '',
            num_female_toilets: '',
            female_stud_toilet_ratio: '',
            number_lab_computers: ''
        }
    };

    // Method to handle updating form field values
    handleChange = (name, value) => {
        let infrastructure = {...this.state.infrastructureData};
        if ((name === 'num_computers' || name === 'num_libraries' || name === 'avail_audio_visual_aids'|| name === 'number_lab_computers'
            || name === 'num_male_toilets' || name === 'lib_capacity' || name === 'num_female_toilets') && Utils.isValid(value)) {
            Utils.displayMessage("warning", "", "Only numbers allowed");
            return;
        }
        infrastructure[name] = value;
        this.setState({
            infrastructureData: infrastructure
        });
    };

    /*
    * Method to set focus on the text fields with invalid data
    * Parameters:
    * + field_id: The id of the field to set the focus on
    * */
    setFocus = (field_id) => {
        Utils.displayMessage('error', 'Error', 'Please specify ' + field_id).then(r => r);
        document.getElementById(field_id).focus();
    };

    // Method to send and save data to the server
    saveData = () => {
        let data = {...this.state.infrastructureData};

        LearningCenterService.updateLC(data, result => {
            if (result.error) {
                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
            } else {
                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                this.props.getSettingsData(result.inst_details);
                this.setState({stud_comp_ratio: result.inst_details.stud_comp_ratio, student_lib_ratio: result.inst_details.student_lib_ratio,
                                male_stud_toilet_ratio: result.inst_details.male_stud_toilet_ratio, female_stud_toilet_ratio: result.inst_details.female_stud_toilet_ratio});
            }
        });
    };

    // Method to initialize the form field values
    updateFields = () => {
        let _infrastructure = {...this.state.infrastructureData};
        let _data = this.props.details;

        _infrastructure._id = _data._id;
        _infrastructure.num_computers = _data.num_computers;
        _infrastructure.stud_comp_ratio = _data.stud_comp_ratio;
        _infrastructure.num_libraries = _data.num_libraries;
        _infrastructure.lib_capacity = _data.lib_capacity;
        _infrastructure.avail_audio_visual_aids = _data.avail_audio_visual_aids;
        _infrastructure.num_male_toilets = _data.num_male_toilets;
        _infrastructure.male_stud_toilet_ratio = _data.male_stud_toilet_ratio;
        _infrastructure.num_female_toilets = _data.num_female_toilets;
        _infrastructure.female_stud_toilet_ratio = _data.female_stud_toilet_ratio;
        _infrastructure.number_lab_computers = _data.number_lab_computers;

        this.setState({
            infrastructureData: _infrastructure
        })
    };

    componentDidMount() {
        this.updateFields();
    };

    render() {
        const {classes} = this.props;
        const infrastructure = {...this.state.infrastructureData};
        return (
            <div>
                <Card>
                    <div style={{marginLeft: 150}}>
                        <form className={classes.root} autoComplete="on">
                            <h2>Infrastructure Information</h2>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextField
                                        required
                                        id="num_computers"
                                        name="num_computers"
                                        label="No. of Computers"
                                        variant="outlined"
                                        value={infrastructure.num_computers}
                                        onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                    />
                                    <TextField
                                        required
                                        id="stud_comp_ratio"
                                        name="stud_comp_ratio"
                                        label="Student Computer Ratio"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        value={infrastructure.stud_comp_ratio}
                                        onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="num_libraries"
                                        name="num_libraries"
                                        label="No. of Libraries"
                                        variant="outlined"
                                        value={infrastructure.num_libraries}
                                        onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                    />
                                    <TextField
                                        required
                                        id="lib_capacity"
                                        name="lib_capacity"
                                        label="Library Capacity"
                                        variant="outlined"
                                        value={infrastructure.lib_capacity}
                                        onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={6}>
                                    <TextField
                                        required
                                        id="num_male_toilets"
                                        name="num_male_toilets"
                                        label="Male Toilets Count"
                                        variant="outlined"
                                        value={infrastructure.num_male_toilets}
                                        onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                    />
                                    <TextField
                                        id="male_stud_toilet_ratio"
                                        name="male_stud_toilet_ratio"
                                        label="Male Students Toilets Ratio"
                                        variant="outlined"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                        value={infrastructure.male_stud_toilet_ratio}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        id="num_female_toilets"
                                        name="num_female_toilets"
                                        label="Female Toilets Count"
                                        variant="outlined"
                                        value={infrastructure.num_female_toilets}
                                        onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                    />
                                    <TextField
                                        id="female_stud_toilet_ratio"
                                        name="female_stud_toilet_ratio"
                                        label="Female Students Toilets Ratio"
                                        variant="outlined"
                                        value={infrastructure.female_stud_toilet_ratio}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        required
                                        id="avail_audio_visual_aids"
                                        name="avail_audio_visual_aids"
                                        label="Available Video-Audio Aids"
                                        variant="outlined"
                                        value={infrastructure.avail_audio_visual_aids}
                                        onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                    />
                                    <TextField
                                        required
                                        id="number_lab_computers"
                                        name="number_lab_computers"
                                        label="Num of Library Computers"
                                        variant="outlined"
                                        value={infrastructure.number_lab_computers}
                                        onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    <div style={{padding: 5}}>
                        <Button variant="contained" color="primary" onClick={this.saveData} style={{margin: 5}}>
                            Save
                        </Button>
                    </div>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(Infrastructure);


