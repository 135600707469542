import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Card, CardContent, CardHeader, Grid, Typography} from '@material-ui/core';
import Utils from "../../utils/utils";
import StudentService from "../../services/studentService";
import Table from "material-table";
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddStudentAssessment from "./AddStudentAssessment";
import ProgressBar from "../ProgressBar/ProgressBar";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    btn: {
        margin: 9
    }
});

const EnrollmentDetails = (props) => {
    const _props = props.location;

    const [displayCloseForm, setDisplayCloseForm] = useState(false);
    const [openHomeWork, seOpenHomeWork] = useState(false);
    const [closed_date, setClosedDate] = useState(Utils.getToday());
    const [courses, setCourses] = useState([]);
    const [enrollment, setEnrollment] = useState(null);
    const [termResultData, setTermResultData] = useState(null);
    const [displayLoader, setDisplayLoader] = useState(false);

    useEffect(() => {
        setDisplayLoader(true);
    }, []);

    useEffect(() => {
        if (_props && _props.student_id) {
            localStorage.setItem('student_id', _props.student_id);
            localStorage.setItem('enroll_id', _props.enroll_id);
            localStorage.setItem('enrollData', JSON.stringify(_props.enrollData));
        }
    }, []);

    useEffect(() => {
        const enrollment = (_props.enrollData) ? _props.enrollData : JSON.parse(localStorage.getItem('enrollData'));
        setEnrollment(enrollment);
        loadStudentCourses();
    }, []);

    // Method to load the courses of an enrollment for a student
    const loadStudentCourses = () => {
        const student_id = localStorage.getItem('student_id') ? localStorage.getItem('student_id') : _props.student_id;
        const enroll_id = localStorage.getItem('enroll_id') ? localStorage.getItem('enroll_id') : _props.enroll_id;

        StudentService.getCourses(student_id, enroll_id, (result) => {
            setCourses(result);
            setDisplayLoader(false);
        });
    };

    const classes = useStyles();

    // Method to update the form field values of a new programme
    const handleChange = e => {
        setClosedDate(e.target.value);
    };

    // Method to display form to close an enrollment
    const editEnrollmentForm = () => {
        return (
            <Dialog open={displayCloseForm} onClose={hideEdit} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Close Enrollment</DialogTitle>
                <DialogContent>
                    <TextField
                        id="closed_date"
                        name="closed_date"
                        label="Close Date"
                        type="date"
                        value={closed_date}
                        onChange={e => handleChange(e)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={hideEdit} variant="contained">
                        Cancel
                    </Button>
                    <Button onClick={submitCloseData} color="primary" variant="contained">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        );
    };

    // Method to close an enrollment
    const submitCloseData = () => {
        const student_id = localStorage.getItem('student_id') ? localStorage.getItem('student_id') : _props.student_id;
        const enroll_id = localStorage.getItem('enroll_id') ? localStorage.getItem('enroll_id') : _props.enroll_id;

        let data = {student_id: student_id, enroll_id: enroll_id, closed_date: closed_date};
        StudentService.closeEnrollment(data, result => {
            if (result.error) {
                Utils.displayMessage('error', 'Failed', result.errors[0]);
            } else {
                Utils.displayMessage('success', 'Successful', result.success);
                hideEdit();
            }
        });
    };

    /*
    * Method to delete an enrollment
    * Parameters:
    * rowData: The enrollment to be deleted
    *
    * */
    const deleteEnrollment = () => {
        const student_id = localStorage.getItem('student_id') ? localStorage.getItem('student_id') : _props.student_id;
        const enroll_id = localStorage.getItem('enroll_id') ? localStorage.getItem('enroll_id') : _props.enroll_id;

        let data = {student_id: student_id, enroll_id: enroll_id};
        let text = 'Enrollment will be deleted, do you want to continue?';
        Utils.confirmDeleteMessage(text)
            .then((willDelete) => {
                if (willDelete) {
                    StudentService.deleteEnrollment(data, (result) => {
                        if (result.error) {
                            Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
                        } else {
                            Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                            props.history.goBack();
                        }
                    });
                }
            });
    };

    /*
    * Method to delete a course from an enrollment
    * Parameters:
    * rowData: The course to be deleted
    *
    * */
    const deleteCourse = (event, rowData) => {
        let text = 'Course will be deleted, do you want to continue?';
        Utils.confirmDeleteMessage(text)
            .then((willDelete) => {
                if (willDelete) {
                    StudentService.deleteTermResult(rowData._id.$oid, (result) => {
                        if (result.error) {
                            Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
                        } else {
                            Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                            loadStudentCourses();
                        }
                    });
                }
            });
    };

    // Method to show the form to chose enrollment closing date
    const showDateForm = () => {
        let _status = (enrollment.status === 'inactive') ? 'open' : 'close';
        let text = `Do you want to ${_status} this enrollment?`;
        Utils.confirmDeleteMessage(text)
            .then((willDelete) => {
                if (willDelete) {
                    setDisplayCloseForm(true);
                }
            });
    };

    // Method to hide the form to chose enrollment closing date
    const hideEdit = () => {
        setDisplayCloseForm(false);
    };

    // Method to close the add assignment view to a student's enrollment
    const handleCloseHomeWork = () => {
        seOpenHomeWork(false);
    };

    /*
    * Method to display field data of the student
    * Parameters:
    * + data: The data field of the student
    * */
    const displayField = (data) => (
        <Typography className={classes.title} gutterBottom variant="h5">
            {data}
        </Typography>
    );

    // Method to return user to the previous view
    const goBack = () => {
        props.history.goBack();
    };

    return enrollment && (
        <div>
            {
                displayLoader ? <div style={{marginBottom: 15}}><ProgressBar/></div> : null
            }
            <div style={{marginBottom: 10}}>
                {(enrollment.status === 'inactive') ?
                    null :
                    <Button variant="contained"
                            color="primary"
                            onClick={showDateForm}
                    >
                        Close
                    </Button>
                }
                <Button variant="contained"
                        color="default"
                        onClick={deleteEnrollment}
                >
                    Delete
                </Button>
                <Button variant="contained"
                        color="secondary"
                        onClick={goBack}
                        style={{float: 'right'}}
                >
                    Back
                </Button>
            </div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                    <Card className={classes.root}>
                        <CardHeader color="primary" title="Enrollment Details"/>
                        <CardContent style={{textAlign: 'left'}}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Term/Semester")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(enrollment.term)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Enrolled On")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(Utils.dateFormat(enrollment.enrolled_on))}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Start Date")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(Utils.dateFormat(enrollment.start_of_term))}                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("End Date")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(Utils.dateFormat(enrollment.end_of_term))}                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Status")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(enrollment.status)}
                                </Grid>
                            </Grid>
                            <br/>
                        </CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12}>
                                <div>
                                    <Table
                                        title="Courses"
                                        columns={[
                                            {title: 'Course', field: 'course_name'},
                                            {title: 'Instructor', field: 'instructor_name'},
                                            {title: 'Class Set', field: 'class_set'},
                                            {title: 'Term', field: 'term_name'},
                                            {title: 'Score', field: 'score'},
                                            {
                                                title: 'Grade',
                                                field: 'grade',
                                                render: rowData => rowData.grade === null ? 'N/A' : rowData.grade
                                            }
                                        ]}
                                        data={courses}
                                        options={{
                                            exportButton: false, filtering: false,
                                            grouping: false, sorting: false,
                                            debounceInterval: 1000,
                                            selection: false, showTitle: true,
                                            actionsColumnIndex: -1, pageSize: 5, search: false,
                                        }}
                                        localization={{
                                            header: {
                                                actions: ''
                                            }
                                        }}
                                        actions={[
                                            {
                                                icon: () => (
                                                    <Button
                                                        color="secondary"
                                                        variant="contained"
                                                        style={{textTransform: 'none'}}
                                                        size="small"
                                                    >
                                                        Delete
                                                    </Button>
                                                ),
                                                tooltip: 'Delete course',
                                                onClick: (event, rowData) => deleteCourse(event, rowData)
                                            }
                                        ]}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
            {editEnrollmentForm()}
            {
                (openHomeWork && termResultData) ?
                    <AddStudentAssessment termResultData={termResultData} open={openHomeWork}
                                          onClose={handleCloseHomeWork}/> : null
            }
        </div>
    );
};

export default EnrollmentDetails;
