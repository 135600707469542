import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Card, CardContent, CardHeader, Grid, Typography} from '@material-ui/core';
import MyButton from "../Buttons/Button";
import ScholarshipService from "../../services/scholarshipService";
import Utils from "../../utils/utils";
import Table from "material-table";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import MenuItem from "@material-ui/core/MenuItem";
import ProgressBar from "../ProgressBar/ProgressBar";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const AwardingBodyDetails = (props) => {

    const tableRef = React.createRef();
    const _match = props.match;
    const [scholarshipData, setScholarshipData] = useState(null);
    const [updateDta, setUpdateData] = useState({
        name: ''
    });
    const [terminateData, setTerminateData] = useState({
        terminate_reason: '',
        terminated_date: Utils.getToday(),
        scholarship_id: ''
    });
    const [open, setOpen] = useState(false);
    const [openTerminate, setOpenTerminate] = useState(false);
    const [displayLoader, setDisplayLoader] = useState(false);
    const terminate_reason = [
        {label: "New Scholarship", value: "New Scholarship"},
        {label: "Poor Performance", value: "Poor Performance"},
        {label: "MoBSE", value: "MoBSE"},
        {label: "Others", value: "Others"}];

    useEffect(() => {
        setDisplayLoader(true);
    }, []);

    useEffect(() => {
        ScholarshipService.loadScholarshipId(_match.params.awarding_body_id, getScholarshipData);
    }, [_match.params.awarding_body_id]);

    // Method to load scholarship awarding body details
    const refreshData = () => {
        ScholarshipService.loadScholarshipId(_match.params.awarding_body_id, getScholarshipData);
    };

    // Method to update state data
    const getScholarshipData = (data) => {
        setScholarshipData(data);
        setUpdateData({...data});
        setDisplayLoader(false);
    };

    const classes = useStyles();

    // Method to update a scholarship awarding body
    const updateAwardingBody = () => {
        ScholarshipService.updateAwardingBody(updateDta, result => {
            if (result.error) {
                Utils.displayMessage('error', 'Failed', result.errors[0]);
            } else {
                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                ScholarshipService.loadScholarshipId(_match.params.awarding_body_id, getScholarshipData);
                closeDialog();
            }
        });
    };

    // Method to terminate a scholarship awarding body
    const terminateScholarship = () => {
        let data = {...terminateData};
        data.terminated_date = Utils.formatDate(data.terminated_date);

        ScholarshipService.terminateScholarship(data, (result) => {
            if (result.error) {
                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
            } else {
                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                refreshTable();
                refreshData();
                closeTerminateDialog();
            }
        });
    };

    // Method to update form fields data
    const handleChange = (e) => {
        let _data = {...updateDta};
        _data[e.target.name] = e.target.value.trim();
        setUpdateData(_data);
    };

    const handleChangeTerminate = (e) => {
        let _terminateData = {...terminateData};
        _terminateData[e.target.name] = e.target.value.trim();
        setTerminateData(_terminateData);
    };

    // Method to open dialog display
    const openDialog = () => {
        setOpen(true);
    };

    // Method to close dialog display
    const closeDialog = () => {
        setOpen(false);
    };

    /*
    * Method to terminate or activate a student's scholarship
    * Parameters:
    * +  rowData: The data of the scholarship to terminate or activate
    *
    *  */
    const openTerminateDialog = (event, rowData) => {
        let _type = rowData.status === 'Active' ? "terminate" : 'activate';
        let text = `Do you want to ${_type} this scholarship?`;
        Utils.confirmDeleteMessage(text)
            .then((willDelete) => {
                if (willDelete) {
                    if (rowData.status === 'terminated' || rowData.status === 'Terminated') {
                        rowData.scholarship_id = rowData._id.$oid;
                        rowData.student_id = rowData.student_id.$oid;
                        ScholarshipService.activateScholarship(rowData, result => {
                            if (result.error) {
                                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
                            } else {
                                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                                refreshTable();
                            }
                        })
                    } else {
                        let _terminateData = {...terminateData};
                        _terminateData.scholarship_id = rowData._id.$oid;
                        _terminateData.student_id = rowData.student_id.$oid;
                        setTerminateData(_terminateData);
                        setOpenTerminate(true);
                    }
                }
            });
    };

    const refreshTable = () => {
        tableRef && tableRef.current.onQueryChange();
    };

    const closeTerminateDialog = () => {
        setOpenTerminate(false);
    };

    const displayField = (data) => (
        <Typography className={classes.title} gutterBottom variant="h5">
            {data}
        </Typography>
    );

    return scholarshipData && (
        <div>
            {
                displayLoader ? <div style={{marginBottom: 15}}><ProgressBar/></div> : null
            }
            <div style={{marginBottom: 10}}>
                <MyButton
                    to={{pathname: `${_match.url}/new-scholarship`, awarding_body_id: _match.params.awarding_body_id}}
                    direction={'left'} color={"primary"}
                    title={"Add Student"}
                />
                <Button variant="contained"
                        color="default"
                        onClick={openDialog}
                >
                    Edit
                </Button>
                <MyButton to={"/scholarships"} direction={'right'} color={"secondary"} title={"Back"}/>
            </div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                    <Card className={classes.root}>
                        <CardHeader color="primary" title="Awarding Body Details"/>
                        <CardContent style={{textAlign: 'left'}}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Awarding Body")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(scholarshipData.name)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                     {displayField("Total Active Scholarships")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(scholarshipData.total_active_students)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Total Inactive Scholarships")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(scholarshipData.total_inactive_students)}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                    <Table
                        tableRef={tableRef}
                        title={scholarshipData.name+" Scholarship Students List"}
                        columns={[
                            {
                                title: 'Student Name',
                                field: 'student_name',
                            },
                            {title: 'Student ID', field: 'student_number'},
                            {
                                title: 'Awarded Date',
                                field: 'awarded_date',
                                render: rowData => Utils.dateFormat(rowData.awarded_date)
                            },
                            {title: 'Awarded For', field: 'awarded_for'},
                            {title: 'Type', field: 'type'},
                            {title: 'Status', field: 'status'},
                        ]}
                        data={query => new Promise((resolve, reject) => {
                            ScholarshipService.loadScholarships(query, _match.params.awarding_body_id, resolve, reject);
                        })}
                        options={{
                            exportButton: true, filtering: false,
                            grouping: false, sorting: false,
                            debounceInterval: 1000,
                            selection: false, showTitle: true,
                            pageSize: 10, actionsColumnIndex: -1
                        }}
                        localization={{
                            header: {
                                actions: ""
                            }
                        }}
                        actions={[
                            rowData => ({
                                icon: () => (
                                    <Button
                                        color={(rowData.status === 'Active') ? "secondary" : "default"}
                                        variant="contained"
                                        style={{textTransform: 'none'}}
                                        size="small"
                                    >
                                        {(rowData.status === 'Active') ? "Terminate" : "Activate"}
                                    </Button>
                                ),
                                tooltip: 'Terminate/Activate scholarship',
                                onClick: (event, rowData) => openTerminateDialog(event, rowData),
                            }),
                            {
                                icon: 'refresh', tooltip: 'Refresh', isFreeAction: true,
                                onClick: () => refreshTable(),
                            }
                        ]}
                    />
                </Grid>
            </Grid>
            <Dialog open={open} onClose={closeDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Update Awarding Body</DialogTitle>
                <DialogContent>
                    <TextField
                        id="name"
                        name="name"
                        label="Awarding Body"
                        variant="outlined"
                        value={updateDta.name}
                        onChange={handleChange}
                        margin="dense"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={closeDialog} variant="contained">
                        Cancel
                    </Button>
                    <Button onClick={updateAwardingBody} color="primary" variant="contained">
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openTerminate} onClose={closeTerminateDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Terminate Scholarship</DialogTitle>
                <DialogContent>
                    <TextField
                        id="terminate_reason"
                        name="terminate_reason"
                        select
                        label="Reason to Terminate"
                        value={terminateData.terminate_reason}
                        onChange={handleChangeTerminate}
                        variant="outlined"
                        style={{
                            border: 0,
                            outline: 0
                        }}
                        margin="dense"
                        fullWidth
                    >
                        {terminate_reason.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}

                    </TextField>
                    <TextField
                        id="terminated_date"
                        name="terminated_date"
                        label="Terminated Date"
                        type="date"
                        value={terminateData.terminated_date}
                        onChange={handleChangeTerminate}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                    />

                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={closeTerminateDialog} variant="contained">
                        Cancel
                    </Button>
                    <Button onClick={terminateScholarship} color="primary" variant="contained">
                        Terminate
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AwardingBodyDetails;
