import React, {Component} from "react";
import CanvasJSReact from './canvasjs.react';

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

class DoughnutChart extends Component {
    render() {

        const options = {
            animationEnabled: true,
            title: {
                text: this.props.title,
                fontSize: 17
            },
            subtitle: [{
                text: (this.props.dataPoints.qualifications) ? "Total " + (this.props.dataPoints.qualifications[0].y + this.props.dataPoints.qualifications[1].y + this.props.dataPoints.qualifications[2].y) : "",
                verticalAlign: "center",
                fontSize: 24,
                dockInsidePlotArea: true
            }],
            data: [{
                type: "doughnut",
                showInLegend: true,
                indexLabel: "{name}: {y}",
                yValueFormatString: "#,###",
                dataPoints: this.props.dataPoints
            }]
        };

        return (
            <div>
                <CanvasJSChart options={options}
                    /* onRef={ref => this.chart = ref} */
                />
                {/*You can get reference to the chart instance as shown above using onRef. This allows you to access all chart properties and methods*/}
            </div>
        );
    }
}

export default DoughnutChart;
