import React from 'react';
import MyButton from "../Buttons/Button";
import Table from 'material-table';
import ScholarshipService from "../../services/scholarshipService";
import Button from "@material-ui/core/Button";
import Utils from "../../utils/utils";
import ProgressBar from "../ProgressBar/ProgressBar";

class Scholarships extends React.Component {

    constructor(props) {
        super(props);

        this.tableRef = React.createRef();
        this.state = {
            header: [
                {title: 'Awarding Body', field: 'name'},
                {title: 'Opened scholarships', field: 'total_active_students'},
                {title: 'Closed/Terminated scholarships', field: 'total_inactive_students'}
            ],
            scholarships: [],
            displayLoader: false
        };
    };

    /*
    * Method to delete a scholarship awarding body
    * Parameters:
    * + rowData: The scholarship awarding body to suspend or delete
    * */
    deleteScholarship = (event, rowData) => {
        let text = `Awarding body will be deleted, do you want to continue?`;
        Utils.confirmDeleteMessage(text)
            .then((willDelete) => {
                if (willDelete) {
                    ScholarshipService.awardingBody(rowData._id.$oid, result => {
                        if (result.error) {
                            Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
                        } else {
                            Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                            this.refreshTable();
                        }
                    })
                }
            });
    };

    /*
    * Method to view the details of a scholarship awarding body
    * Parameters:
    * + rowData: The scholarship awarding body to view details for
    * */
    rowSelect = (event, rowData) => {
        this.props.history.push({
            pathname: `${this.props.match.path}/${rowData._id.$oid}`,
            awarding_body: rowData.name
        });
    };

    // Method to refresh data of the table
    refreshTable = () => {
        this.tableRef && this.tableRef.current.onQueryChange();
    };

    componentDidMount() {
        this.setState({
            displayLoader: true
        });
        localStorage.removeItem('studentId');
        localStorage.removeItem('awarding_body');
        this.refreshTable();
    };

    render() {
        return (
            <div>
                {
                    this.state.displayLoader ? <div style={{marginBottom: 15}}><ProgressBar/></div> : null
                }
                <div style={{marginBottom: 10}}>
                    <MyButton to={"/awarding-body"} color={"primary"} title={"Add Scholarship"}/>
                </div>
                <div>
                    <Table
                        tableRef={this.tableRef}
                        title="Scholarships"
                        columns={this.state.header}
                        data={query => new Promise((resolve, reject) => {
                            ScholarshipService.loadData(query, resolve, reject, () => {
                                this.setState({
                                    displayLoader: false
                                });
                            });
                        })}
                        onRowClick={this.rowSelect}
                        options={{
                            exportButton: true, filtering: true,
                            grouping: true, sorting: true,
                            debounceInterval: 1000,
                            selection: false, showTitle: true,
                            pageSize: 10, actionsColumnIndex: -1
                        }}
                        localization={{
                            header: {
                                actions: ""
                            }
                        }}
                        actions={[
                            {
                                icon: 'refresh', tooltip: 'Refresh', isFreeAction: true,
                                onClick: () => this.refreshTable(),
                            },
                            {
                                icon: () => (
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        style={{textTransform: 'none'}}
                                        size="small"
                                    >
                                        DELETE
                                    </Button>
                                ),
                                tooltip: 'Delete Scholarship Body',
                                onClick: (event, rowData) => this.deleteScholarship(event, rowData)
                            }
                        ]}
                    />
                </div>
            </div>
        );
    }
}

export default Scholarships;


