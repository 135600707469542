import React from 'react';
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import withStyles from "@material-ui/core/styles/withStyles";
import Utils from "../../utils/utils";
import TermService from "../../services/termService";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
});

class Settings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            number_of_terms: 1,
            terms: {},
            showTermsForm: false,
        };

    };

    // Method to update value for the number of term definitions
    handleChangeTerms = (e) => {
        let val = e.target.value === '' ? 0 : parseInt(e.target.value);
        if(val > 4){
            Utils.displayMessage('error', 'Failed', 'You can not have more than 4 term definitions');
        }else{
            this.setState({
                [e.target.name]: val
            });
        }
    };

    // Method to update form field values
    handleChange = (e) => {
        let _terms = {...this.state.terms};
        _terms[e.target.name] = e.target.value;
        this.setState({
            terms: _terms
        });
    };

    // Method to clear data for the form fields
    clearForm = () => {
        let _terms = {...this.state.terms};
        _terms.term1 = '';
        _terms.term2 = '';
        _terms.term3 = '';
        _terms.term4 = '';

        this.setState({
            terms: _terms
        });
    };

    // Method to save school term definition to the server
    saveData = () => {
        let availTerms = (this.props.location.terms) ? this.props.location.terms : JSON.parse(localStorage.getItem('availTerms'));

        if (availTerms.length > 0) {
            Utils.displayMessage('error', 'Failed', 'You have already defined terms for your learning center');
        } else {
            let data = {...this.state.terms};
            TermService.createTerms(data, result => {
                if (result.error) {
                    Utils.displayMessage('error', 'Failed', result.errors[0]);
                } else {
                    Utils.displayMessage('success', 'Successful', result.success);
                    this.goBack();
                }
            });
        }

    };

    // Method to return user to the previous view
    goBack = () => {
        this.props.history.goBack();
    };

    componentDidMount() {
        if (this.props.location.terms) {
            localStorage.setItem('availTerms', JSON.stringify(this.props.location.terms));
        }
    };

    render() {
        const {classes} = this.props;
        const nTerms = parseInt(this.state.number_of_terms);
        return (
            <div>
                <Card>
                    <div>
                        <div style={{marginLeft: 100}}>
                            <form className={classes.root} autoComplete="on">
                                <h4>Terms/Semesters</h4>
                                <p>Please specify the number of terms you have in the year. You only need to do this once.<br/>
                                   This will be used to help you manage terms each year.</p>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <div>
                                            <TextField
                                                required
                                                id="number_of_terms"
                                                name="number_of_terms"
                                                label="Number of Terms"
                                                variant="outlined"
                                                helperText="Please specify your number of terms (semesters)"
                                                value={this.state.number_of_terms}
                                                onChange={e => this.handleChangeTerms(e)}
                                            />
                                        </div>
                                        <div>
                                            <TextField
                                                required
                                                id={"term1"}
                                                name={"term1"}
                                                label="Term 1"
                                                variant="outlined"
                                                value={this.state.terms["term1"]}
                                                onChange={e => this.handleChange(e)}
                                            />
                                            {
                                                (nTerms > 1) ?
                                                    <TextField
                                                        required
                                                        id={"term2"}
                                                        name={"term2"}
                                                        label="Term 2"
                                                        variant="outlined"
                                                        value={this.state.terms["term2"]}
                                                        onChange={e => this.handleChange(e)}
                                                    /> : null
                                            }
                                            {
                                                (nTerms > 2) ?
                                                    <TextField
                                                        required
                                                        id={"term3"}
                                                        name={"term3"}
                                                        label="Term 3"
                                                        variant="outlined"
                                                        value={this.state.terms["term3"]}
                                                        onChange={e => this.handleChange(e)}
                                                    /> : null
                                            }
                                            {
                                                (nTerms > 3) ?
                                                    <TextField
                                                        required
                                                        id={"term4"}
                                                        name={"term4"}
                                                        label="Term 4"
                                                        variant="outlined"
                                                        value={this.state.terms["term4"]}
                                                        onChange={e => this.handleChange(e)}
                                                    /> : null
                                            }
                                        </div>
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                        <Button variant="contained" color="secondary" onClick={this.goBack} style={{margin: 5}}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={this.saveData} style={{margin: 5}}>
                            Save
                        </Button>
                    </div>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(Settings);
