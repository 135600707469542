import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Utils from "../../utils/utils";
import StudentService from "../../services/studentService";
import MenuItem from "@material-ui/core/MenuItem";
import CoursesService from "../../services/coursesService";

const AddStudentAssessment = (props) => {
    const [course, setCourse] = useState({
        assessment_id: '',
        enrollment_id: '',
        course_id: '',
        student_id: '',
        term_result_id: '',
    });

    const [assessments, setAssessments] = useState([]);

    useEffect(() => {
        let _course = {...course};
        let term_result = {...props.termResultData};
        _course.term_result_id = term_result._id.$oid;
        _course.enrollment_id = term_result.enrollment_id.$oid;
        _course.course_id = term_result.course_id.$oid;
        _course.student_id = term_result.student_id.$oid;

        setCourse(_course);
    }, []);

    useEffect(() => {
        CoursesService.assessmentDefinitions(course.course_id, result => {
            let temp = [];
            let assessments = result.assessments;

            assessments.forEach(asst => {
                temp.push({label: asst.assignment_name, value: asst._id.$oid});
            });
            setAssessments(temp);
        });
    }, [course.course_id]);

    // Method to update form field values
    const handleChange = (e) => {
        let _course = {...course};
        _course[e.target.name] = e.target.value;
        setCourse(_course);
    };

    // Method to save assessment data
    const saveAssessment = () => {
        let _data = {...course};
        if (_data.assessment_id === ''){
            Utils.displayMessage("error", "No Assessment", "Please choose an assessment ").then(r => r);
            return;
        }
        StudentService.newAssessment(_data, (result) => {
            if (result.error) {
                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
            } else {
                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                props.onClose();
            }
        });
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title" >
            <DialogTitle id="form-dialog-title">Add Assessment</DialogTitle>
            <DialogContent style={{width: 350}}>
                <TextField
                    id="assessment_id"
                    name="assessment_id"
                    select
                    label="Select Assessment"
                    value={course.assessment_id}
                    onChange={e => handleChange(e)}
                    variant="outlined"
                    style={{
                        border: 0,
                        outline: 0,
                        width: 300
                    }}
                    margin="dense"
                    fullWidth
                >
                    {assessments.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}

                </TextField>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={props.onClose} variant="contained">
                    Cancel
                </Button>
                <Button onClick={saveAssessment} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddStudentAssessment;
