import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import {Grid} from '@material-ui/core';
import clsx from "clsx";
import OutlinedInput from "@material-ui/core/OutlinedInput/OutlinedInput";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import Utils from "../../utils/utils";
import * as cookies from "react-cookies";
import StaffService from "../../services/staffService";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
});

class ResetPassword extends React.Component {
    state = {
        credentials: {
            email: '',
            new_password: '',
            confirm_password: '',

        },
        showPassword: false,
        showConfirmPassword: false,
    };

    // Method to update the form field values for the currentUser
    handleChange = e => {
        let val = e.target.value.trim();
        let _credentials = {...this.state.credentials};
        if (e.target.name === 'phone_number' && Utils.isValid(val)) {
            Utils.displayMessage("warning", "", "Only numbers allowed");
            return;
        }
        _credentials[e.target.name] = val;
        this.setState({
            credentials: _credentials
        });
    };

    // Method to set the password field visible
    handleClickShowPassword = () => {
        this.setState(prevState => ({
            showPassword: !prevState.showPassword
        }));
    };

    // Method to set the confirm password field visible
    handleClickShowConfirmPassword = () => {
        this.setState(prevState => ({
            showConfirmPassword: !prevState.showConfirmPassword
        }));
    };

    // Method to prevent form from reloading the view when typing
    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // Method to return the user to the previous view/component
    goBack = () => {
        this.props.history.goBack();
    };

    // Method that send user data for updating the user's profile
    updateData = () => {
        let data = {...this.state.credentials};
        if (data.new_password !== data.confirm_password) {
            Utils.displayMessage('error', 'Error', 'Password mismatch!');
            document.getElementById('new_password').focus();
        } else {
            StaffService.updateCredentials(data, result => {
                if (result.error) {
                    Utils.displayMessage('error', 'Error', result.errors[0]);
                } else {
                    Utils.displayMessage('success', 'Successful', result.success);
                    this.loadUser();
                }
            });
        }
    };

    // Method to load the details of the current user
    loadUser = () => {
        StaffService.loadStaffId(cookies.load('currentUserId'), result => {
            this.setState({
                credentials: {...result.user}
            })
        });
    };

    componentDidMount() {
        this.loadUser();
    };

    render() {
        const {classes} = this.props;
        let _credentials = this.state.credentials;

        return (
            <div>
                <div style={{marginLeft: 150}}>
                    <form className={classes.root} autoComplete="on">
                        <h4>Reset Password</h4>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    required
                                    id="email"
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    value={_credentials.email}
                                    onChange={e => this.handleChange(e)}
                                />
                                <FormControl className={clsx(classes.margin, classes.textField)} required
                                             style={{marginRight: 7, marginTop: 8}}
                                             variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                                    <OutlinedInput
                                        id="new_password"
                                        type={this.state.showPassword ? 'text' : 'password'}
                                        value={_credentials.new_password}
                                        name="new_password"
                                        onChange={e => this.handleChange(e)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickShowPassword}
                                                    onMouseDown={e => this.handleMouseDownPassword(e)}
                                                    edge="end"
                                                >
                                                    {this.state.showPassword ? <Visibility/> : <VisibilityOff/>}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        labelWidth={70}
                                    />
                                </FormControl>
                                <FormControl className={clsx(classes.margin, classes.textField)} required
                                             style={{marginTop: 8}}
                                             variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Confirm
                                        Password</InputLabel>
                                    <OutlinedInput
                                        id="confirm_password"
                                        type={this.state.showConfirmPassword ? 'text' : 'password'}
                                        value={_credentials.confirm_password}
                                        name="confirm_password"
                                        onChange={e => this.handleChange(e)}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={this.handleClickShowConfirmPassword}
                                                    onMouseDown={e => this.handleMouseDownPassword(e)}
                                                    edge="end"
                                                >
                                                    {this.state.showConfirmPassword ? <Visibility/> :
                                                        <VisibilityOff/>}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        labelWidth={70}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <div style={{padding: 5}}>
                    <Button variant="contained" color="primary" onClick={this.updateData} style={{margin: 5}}>
                        Reset
                    </Button>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(ResetPassword);
