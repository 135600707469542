import React from 'react';
import MyButton from "../Buttons/Button";
import Table from 'material-table';
import DepartmentService from "../../services/departmentService";
import Grid from "@material-ui/core/Grid";
import Utils from "../../utils/utils";
import LearningCenterService from "../../services/learningCenterService";
import Button from "@material-ui/core/Button";
import ProgressBar from "../ProgressBar/ProgressBar";

class Departments extends React.Component {

    constructor(props) {
        super(props);

        this.tableRef = React.createRef();
        this.state = {
            header: [
                {title: 'Name', field: 'name'},
                {title: 'Location', field: 'location'}
            ],
            departments: [],
            displayLoader: false
        };
    };

    /*
    * Method to delete or suspend a department
    * Parameters:
    * + rowData: The department to suspend or delete
    * */
    deleteDepartment = (event, rowData) => {
        let _type = rowData.can_be_deleted ?
            "deleted" :
            (rowData.suspended) ? "activated" : "suspended";
        let text = `Department will be ${_type}, do you want to continue?`;
        Utils.confirmDeleteMessage(text)
            .then((willDelete) => {
                if (willDelete) {
                    if (rowData.can_be_deleted === true) {
                        DepartmentService.deleteDepartment(rowData._id.$oid, result => {
                            if (result.error) {
                                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
                            } else {
                                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                                this.refreshTable();
                            }
                        })
                    } else {
                        let _suspend = !rowData.suspended;
                        LearningCenterService.suspendItem(rowData._id.$oid, "Institution::Department", _suspend, result => {
                            if (result.error) {
                                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
                            } else {
                                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                                this.refreshTable();
                            }
                        })
                    }
                }
            });
    };

    /*
    * Method to view the details of a department
    * Parameters:
    * + rowData: The department to view details for
    * */
    rowSelect = (event, rowData) => {
        this.props.history.push(`${this.props.match.path}/${rowData._id.$oid}`);
    };

    // Method to refresh data of the table
    refreshTable = () => {
        this.tableRef && this.tableRef.current.onQueryChange();
    };

    /*
    * Method to edit a department
    * Parameters:
    * + rowData: The department to be edited
    * */
    editDepartment = (event, rowData) => {
        rowData.map(student => DepartmentService.editDepartment(student._id.$oid,));
    };

    componentDidMount() {
        this.setState({
            displayLoader: true
        });
    }

    render() {
        return (
            <div>
                {
                    this.state.displayLoader ? <div style={{marginTop: 30}}><ProgressBar/></div> : null
                }
                <div style={{marginTop: 30, marginBottom: 10}}>
                    <MyButton to={"/new-department"} color={"primary"} title={"Add Department"}/>
                </div>
                <div>
                    <Grid xs={12} sm={12} md={12}>
                        <Table
                            tableRef={this.tableRef}
                            title="Departments"
                            columns={this.state.header}
                            data={query => new Promise((resolve, reject) => {
                                DepartmentService.loadDepartmentsData(query, resolve, reject, () => {
                                    this.setState({
                                        displayLoader: false
                                    });
                                });
                            })}
                            onRowClick={this.rowSelect}
                            localization={{
                                header: {
                                    actions: ""
                                }
                            }}
                            options={{
                                exportButton: true, filtering: false,
                                grouping: false, sorting: true,
                                debounceInterval: 1000,
                                selection: false, showTitle: true,
                                pageSize: 10, search: true, actionsColumnIndex: -1,
                                rowStyle: rowData => ({
                                    backgroundColor: (rowData.suspended) ? '#DDD' : '#FFF',
                                })
                            }}

                            actions={[
                                rowData => ({
                                    icon: () => (
                                        <Button
                                            color={(rowData.can_be_deleted === true) ?
                                                "secondary" : (rowData.suspended === true) ? "primary" : "default"
                                            }
                                            variant="contained"
                                            style={{textTransform: 'none'}}
                                            size="small"
                                        >
                                            {(rowData.can_be_deleted === true) ?
                                                "DELETE" : (rowData.suspended === true) ? "ACTIVATE" : "SUSPEND"
                                            }
                                        </Button>
                                    ),
                                    tooltip: 'Delete/Suspend Department',
                                    onClick: (event, rowData) => this.deleteDepartment(event, rowData),
                                }),
                                {
                                    icon: 'refresh', tooltip: 'Refresh', isFreeAction: true,
                                    onClick: () => this.refreshTable()
                                }
                            ]}
                        />
                    </Grid>
                </div>
            </div>
        );
    }
}

export default Departments;


