import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import EmailIcon from '@material-ui/icons/Email';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import $ from "jquery";
import Utils from "../../utils/utils";
import {Api} from "../../config/api_config";


function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                EMIS
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(1, 0, 1),
    },
}));

const ResetPasswordEmail = (props) => {
    const classes = useStyles();
    const [credentials, setCredentials] = useState({email: ''});

    const handleChange = (e) => {
        let creds = {...credentials};
        creds[e.target.name] = e.target.value;
        setCredentials(creds);
    };

    const sendEmail = (e) => {
        e.preventDefault();
        const {email} = credentials;
        let r = Math.random().toString(36);
        $.ajax({
            method: "POST",
            url: Api.DEV_BASE_URL + "/users/send_pwd_reset_email",
            headers: {"UserKey": r},
            dataType: 'json',
            data: {email: email, influence: 'emis', r: r},
            success: function (result) {
                if (result.error) {
                    Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
                } else {
                    Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                }
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Email sending failed').then(r => r);
            }
        })
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <EmailIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Forgot Password
                </Typography>
                <form className={classes.form} onSubmit={e => sendEmail(e)}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={credentials.email}
                        onChange={handleChange}
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        type="submit"
                    >
                        Send Email
                    </Button>
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        className={classes.submit}
                        onClick={() => props.history.push('/login')}
                    >
                        Cancel
                    </Button>
                </form>
            </div>
            <Box mt={8}>
                <Copyright/>
            </Box>
        </Container>
    );
};

export default ResetPasswordEmail;
