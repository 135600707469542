import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Card, CardContent, CardHeader, Grid, Typography} from '@material-ui/core';
import Table from 'material-table';
import MyButton from "../Buttons/Button";
import ProgramService from "../../services/programService";
import CoursesService from "../../services/coursesService";
import Utils from "../../utils/utils";
import Button from "@material-ui/core/Button";
import ProgressBar from "../ProgressBar/ProgressBar";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const ProgramDetails = (props) => {
    const _match = props.match;
    const [programData, setProgramData] = useState(null);
    const [courseData, setCourseData] = useState([]);
    const [displayLoader, setDisplayLoader] = useState(false);

    useEffect(() => {
        setDisplayLoader(true);
    }, []);

    useEffect(() => {
        loadProgramDetails();
    }, [_match.params.programId]);

    // Method to load the programme's details and the courses in the programme
    const loadProgramDetails = () => {
        ProgramService.loadProgramId(_match.params.programId, setProgramData);
        ProgramService.loadCourses(_match.params.programId, setCourseData);
        setDisplayLoader(false);
    };

    const classes = useStyles();

    /*
    * Method to display field data of the programme
    * Parameters:
    * + data: The data field of the department
    * */
    const displayField = (data) => (
        <Typography className={classes.title} gutterBottom variant="h5">
            {data}
        </Typography>
    );

    /*
    * Method to delete an course for a programme
    * Parameters:
    * rowData: The course to be deleted
    *
    * */
    const deleteCourse = (event, rowData) => {
        let text = 'Course will be deleted, do you want to continue?';
        Utils.confirmDeleteMessage(text)
            .then((willDelete) => {
                if (willDelete) {
                    CoursesService.deleteCourse(rowData._id.$oid, result => {
                        if (result.error) {
                            Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
                        } else {
                            Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                            loadProgramDetails();
                        }
                    });
                }
            });
    };

    return programData && (
        <div>
            {
                displayLoader ? <div style={{marginBottom: 15}}><ProgressBar/></div> : null
            }
            <div>
                <MyButton to={{pathname: `${_match.url}/edit-program`, programData: programData}}
                          direction={'left'} color={"primary"}
                          title={"Edit"}
                />
                <MyButton to={{pathname: `${_match.url}/new-course`, programData: programData}} direction={'left'}
                          title={"Add Course"}/>
                <MyButton to={"/programs"} direction={'right'} color={"secondary"} title={"Back"}/>
            </div>
            <br/><br/>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                    <Card className={classes.root}>
                        <CardHeader color="primary" title="Program Details"/>
                        <CardContent style={{textAlign: 'left'}}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Name")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(programData.name)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("National Name")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(programData.national_name)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Award")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(programData.award)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Isced Level")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(programData.isced_level)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Duration")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(programData.duration)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Tuition")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(programData.tuition)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Entry Requiremetns")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(programData.entry_requirement)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Department")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(programData.department_name)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Field of Education")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(programData.education_field_name)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Accreditation Status")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(programData.accreditation_status)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Accreditation Number")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(programData.accreditation_number)}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <div>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                        <Table
                            title="Courses"
                            columns={[
                                {title: 'Name', field: 'name'},
                                {title: 'Code', field: 'course_code'},
                                {title: 'Duration (months)', field: 'duration'},
                                {title: 'Department', field: 'department_name'}
                            ]
                            }
                            data={courseData}
                            options={{
                                exportButton: true, filtering: false,
                                grouping: false, sorting: true,
                                debounceInterval: 1000,
                                selection: false, showTitle: true,
                                pageSize: 5, actionsColumnIndex: -1
                            }}
                            localization={{
                                header: {
                                    actions: ""
                                }
                            }}
                            actions={[
                                {
                                    icon: () => (
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            style={{textTransform: 'none'}}
                                            size="small"
                                        >
                                            Delete
                                        </Button>
                                    ),
                                    tooltip: 'Delete Course',
                                    onClick: (event, rowData) => deleteCourse(event, rowData)
                                }
                            ]}
                        />
                    </Grid>
                </Grid>
            </div>

        </div>
    );
};

export default ProgramDetails;
