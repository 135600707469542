import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import LearningCenterService from "../../services/learningCenterService";
import Utils from "../../utils/utils";
import Grid from "@material-ui/core/Grid";

const PictureDialog = (props) => {
    const [lcData, setLcData] = useState({
        lc_id: '',
        picture: ''
    });

    useEffect(() => {
        let _data = props.lcenterData;
        initialiseData(props.lcenterData);
    }, []);

    // Method to set the details of the learning center when uploading the logo
    const initialiseData = (l_centerData) => {
        let _lcData = {...l_centerData};
        _lcData.lc_id = l_centerData._id.$oid;
        setLcData(_lcData);
    };

    // Method to upload logo for a school/learning center
    const uploadImage = () =>{

        let data = {...lcData};
        const preview = document.getElementById("test");
        const file = document.querySelector('input[type=file]').files[0];
        const reader = new FileReader();
        if (file) {
            reader.readAsDataURL(file);
        }
        reader.addEventListener("load", function () {
            preview.src = reader.result;
            data.picture = reader.result;
            LearningCenterService.updatePicture(data, result => {
                if(result.error){
                    Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
                }else{
                    Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                    props.reloadImage();
                }
            });
        }, false);
        props.onClose();
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Upload Picture</DialogTitle>
            <DialogContent>

                <Grid item xs={12} sm={12} md={12}>
                    <input
                        accept="image/*"
                        id="raised-button-file"
                        multiple
                        type="file"
                        data-max-file-size="1MB"
                    />
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={props.onClose} variant="contained">
                    Cancel
                </Button>
                <Button onClick={uploadImage} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PictureDialog;
