import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles(theme => ({
    leftAlignDialogActions: {
        justifyContent: 'flex-start'
    },
    ConfirmPassword: {
        marginTop: 35
    }
}));

const ResetStaffPassword = (props) => {
    const [displayPassword, setDisplayPassword] = useState(false);
    const [displayConfirmPassword, setDisplayConfirmPassword] = useState(false);
    const classes = useStyles();

    // Method to update form fields
    const handleChange = (e) => {
        props.handleChange(e);
    };

    // Method to add the course to the student's enrollment
    const resetStaffPassword = () => {
        props.resetStaffPassword();
    };

    const handleClickShowPassword = () => {
        setDisplayPassword(!displayPassword);
    };

    const handleClickShowConfirmPassword = () => {
        setDisplayConfirmPassword(!displayConfirmPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Reset Staff Password</DialogTitle>
            <DialogContent>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel htmlFor="password">Password</InputLabel>
                    <OutlinedInput
                        id="password"
                        name="password"
                        type={displayPassword ? 'text' : 'password'}
                        value={props.credentials.password}
                        onChange={handleChange}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {displayPassword ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </InputAdornment>
                        }
                        labelWidth={70}
                    />
                </FormControl>
                <FormControl className={classes.confirmPassword} variant="outlined" fullWidth>
                    <InputLabel htmlFor="confirmPassword">Confirm Password</InputLabel>
                    <OutlinedInput
                        id="confirmPassword"
                        name="confirmPassword"
                        type={displayConfirmPassword ? 'text' : 'password'}
                        value={props.credentials.confirmPassword}
                        onChange={handleChange}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowConfirmPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {displayPassword ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </InputAdornment>
                        }
                        labelWidth={70}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions classes={{root: classes.leftAlignDialogActions}}>
                <Button color="secondary" onClick={props.onClose} variant="contained">
                    Cancel
                </Button>
                <Button onClick={resetStaffPassword} color="primary" variant="contained">
                    Reset
                </Button>
            </DialogActions>
        </Dialog>
    );
};


export default ResetStaffPassword;
