import DashboardIcon from '@material-ui/icons/Dashboard';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import Dashboard from "../components/Dashboard/Dashboard";
import TeacherCourses from "../components/Courses/TeacherCourses";

const dasboard = {
    path: "/",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard
};

const courses = {
    path: "/courses",
    name: "Courses",
    icon: ImportContactsIcon,
    component: TeacherCourses
};


const redirect = {redirect: true, path: "/", to: "/schools"};
const teacherRoutes = [dasboard, courses];


export default teacherRoutes;
