import React from 'react';
import Utils from "../../utils/utils";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import ProgramService from "../../services/programService";
import SettingsService from "../../services/settingsService";
import Table from "material-table";
import LearningCenterService from "../../services/learningCenterService";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
});

class Charges extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            depts: [],
            programs: [],
            charge_definition: {
                name: '',
                type: '',
                amount: '',
                currency: '',
                placement: '',
                award: '', //Which program it is applied and department
                departmentId: {},
                programId: {},
            },

            header: [
                {title: 'Name', field: 'name'},
                {title: 'Type', field: 'type'},
                {title: 'Award', field: 'award'},
                {title: 'Department', field: 'department_applied_to'},
                {title: 'Program', field: 'program_applied_to'},
                {title: 'Currency', field: 'currency'},
                {title: 'Amount', field: 'amount'},
            ],
            editingCharge: false,
            addData: false,
        };
    };

    // Method to update the form field values for charges
    handleChangeCharges = (e) => {
        let _charges = {...this.state.charge_definition};

        let val = e.target.value;
        if (e.target.name === 'amount' && Utils.isValid(val)) {
            Utils.displayMessage("warning", "", "Only numbers allowed");
            return;
        }
        _charges[e.target.name] = val;
        this.setState({
            charge_definition: _charges
        });
    };

    // Method to clear the values of the form fields
    clearChargesForm = () => {
        let _charges = {...this.state.charge_definition};
        _charges.name = '';
        _charges.amount = '';

        if(_charges.editing === true){
            _charges._id = null;
            _charges.editing = false;
        }

        this.setState({
            charge_definition: _charges,
            editingCharge: false
        });
    };

    // Method to fill in select field for department, to apply the charge to
    loadDepartments = (results) => {
        let _depts = [];
        if (results.depts && results.fields) {
            results.depts.map(dept => _depts.push({label: dept.name, value: dept._id.$oid}));
            _depts.push({label: 'All', value: 'All'});
            let _charge_definition = {...this.state.charge_definition};
            _charge_definition.departmentId = _depts[0].value;
            this.setState({
                depts: _depts,
                charge_definition: _charge_definition
            })
        }
        this.mapPrograms(results.programs);
    };

    // Method to data table
    refreshTable = () => {
        this.tableRef.current.onQueryChange();
    };

    // Method to fill in select field for programmes
    mapPrograms = (programs) => {
        let _programs = [];
        programs.map(program => _programs.push({label: program.name, value: program._id.$oid}));
        _programs.push({label: 'All', value: 'All'});
        let _charge_definition = {...this.state.charge_definition};
        if (_programs.length > 0) {
            _charge_definition.programId = _programs[0].value;
        }

        this.setState({
            programs: _programs,
            charge_definition: _charge_definition
        });
    };

    // Method to valid the programme fields before posting to the server
    validateFields = () => {
        const {name, type, amount} = this.state.charge_definition;

        if (name === '' || !Utils.validateText(name)) return 'name';
        if (type === '' || !Utils.validateText(type)) return 'type';
        if (amount === '' || !Utils.validateFloat(amount)) return 'amount';
    };

    // Method to initialize select fields
    initializeFields = () => {
        let _charge_def = {...this.state.charge_definition};
        _charge_def.currency = Utils.currencies[0].value;
        _charge_def.type = Utils.charge_types[0].value;
        _charge_def.award = Utils.awards[0].value;
        _charge_def.placement = Utils.placements[0].value;

        this.setState({
            charge_definition: _charge_def
        });
    };

    /*
    * Method to set focus on the text fields with invalid data
    * Parameters:
    * + field_id: The id of the field to set the focus on
    * */
    setFocus = (field_id) => {
        Utils.displayMessage('error', 'Error', 'Please specify a valid ' + field_id);
        document.getElementById(field_id).focus();
    };

    // Method to create new charge definition
    saveChargesData = () => {
        const d = this.state.charge_definition;
        let charges_data = {amount: d.amount, award: d.award, currency: d.currency, name: d.name, programId: d.programId, departmentId: d.departmentId,
                            placement: d.placement, type: d.type, school_term_id: (d.school_term_id) ? d.school_term_id.$oid : null, _id: d._id};
        let result = this.validateFields();
        if (result) {
            this.setFocus(result);
            return;
        }
        SettingsService.createChargeDefinition(charges_data, this.clearChargesForm);
    };

    /*
    * Method to delete or suspend a charge definition
    * Parameters:
    * + rowData: The charge definition to suspend or delete
    * */
    deleteChargeDefinition = (event, rowData) => {
        let _type = rowData.can_be_deleted ?
            "deleted" :
            (rowData.suspended) ? "activated" : "suspended";
        let text = `Charge will be ${_type}, do you want to continue?`;
        Utils.confirmDeleteMessage(text)
            .then((willDelete) => {
                if (willDelete) {
                    if (rowData.can_be_deleted === true) {
                        SettingsService.deleteCharge(rowData._id.$oid, result => {
                            if (result.error) {
                                Utils.displayMessage('error', 'Failed', result.errors[0]);
                            } else {
                                Utils.displayMessage('success', 'Successful', result.success);
                            }
                        })
                    } else {
                        let _suspend = !rowData.suspended;
                        LearningCenterService.suspendItem(rowData._id.$oid, "Institution::ChargeDefinition", _suspend, result => {
                            if (result.error) {
                                Utils.displayMessage('error', 'Failed', result.errors[0]);
                            } else {
                                Utils.displayMessage('success', 'Successful', result.success);
                            }
                        })
                    }
                }
            });
    };

    // Method to initialize data when editing a charge definition
    editChargeDefinition = (evt, rowData) => {
        rowData.programId = rowData.program_applied_to !== "All" ? rowData.program_id.$oid : "All";
        rowData.departmentId = rowData.department_applied_to !== "All" ? rowData.department_id.$oid : "All";
        rowData.editing = true;

        this.setState({
            charge_definition: {...rowData},
            editingCharge: true,
            addData: true,
        });
    };

    // Method to cancel adding or editing a charge definition
    cancel = () => {
        let charge_definition = {...this.state.charge_definition};

        if(charge_definition._id){
            charge_definition._id = null;
        }
        charge_definition.name = '';
        charge_definition.type = '';
        charge_definition.amount = '';
        charge_definition.currency = '';
        charge_definition.placement = '';
        charge_definition.award = '';
        charge_definition.editing = false;
        charge_definition.departmentId = {};
        charge_definition.programId = {};

        this.setState({
            charge_definition: charge_definition,
            editingCharge: false,
            addData: false
        });
    };

    // Method to check if fields are empty
    fieldsEmpty = () => {
        let _depts = this.state.depts;
        let _programs = this.state.programs;

        if(_depts.length===1 || _programs.length===1){
            //Utils.displayMessage('error', 'Failed', "You cannot add a charge to a student without defining charges, Please add  **Charges ** in the setting module to proceed").then(r => r);
            alert("Please enter the department and program information by going to the 'Department' or 'Program' module. If all departments and programs apply, choose 'All'.                                                                                                                                                                                                        Note: All is the only option a charge can belong to if you proceed")
        }else {
            return (this.state.depts.length === 0 || this.state.programs.length === 0);
        }
    };

    componentDidMount() {
        ProgramService.loadDeptsNationalNames(this.loadDepartments);
        this.initializeFields();
    }

    render() {
        const {classes} = this.props;
        const _charge_definition = this.state.charge_definition;
        const _depts = this.state.depts;
        const _programs = this.state.programs;

        return (
            <div>
                <Button variant="contained" color="primary" onClick={()=>{(this.fieldsEmpty()) ?
                    Utils.displayMessage('error', 'Failed', 'Departments/National names could not be loaded') : this.setState({addData: true});}}
                        style={{margin: 5}}
                >
                    {"Add Charge Type"}
                </Button>
                {(this.state.addData === true) ? <div style={{marginTop: 15}}>

                    <Card>
                        <div>
                            <div style={{marginLeft: 100}}>
                                <form className={classes.root} autoComplete="on">
                                    <h4>Define Charges</h4>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <TextField
                                                required
                                                id="name"
                                                name="name"
                                                label="Name"
                                                variant="outlined"
                                                helperText="Please specify charge definition name"
                                                value={_charge_definition.name}
                                                onChange={e => this.handleChangeCharges(e)}
                                            />
                                            <TextField
                                                required
                                                id="type"
                                                name="type"
                                                select
                                                label="Charge Type"
                                                value={_charge_definition.type}
                                                onChange={e => this.handleChangeCharges(e)}
                                                variant="outlined"
                                                style={{
                                                    border: 0,
                                                    outline: 0
                                                }}
                                            >
                                                {Utils.charge_types.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <TextField
                                                required
                                                id="award"
                                                name="award"
                                                select
                                                label="Award"
                                                value={_charge_definition.award}
                                                onChange={e => this.handleChangeCharges(e)}
                                                variant="outlined"
                                                style={{
                                                    border: 0,
                                                    outline: 0
                                                }}
                                            >
                                                {Utils.awards.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <TextField
                                                id="programId"
                                                name="programId"
                                                select
                                                label="Program"
                                                helperText="Please select program"
                                                value={_charge_definition.programId}
                                                onChange={e => this.handleChangeCharges(e)}
                                                variant="outlined"
                                                style={{
                                                    border: 0,
                                                    outline: 0
                                                }}
                                            >
                                                {_programs.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <TextField
                                                id="departmentId"
                                                name="departmentId"
                                                select
                                                label="Department"
                                                value={_charge_definition.departmentId}
                                                onChange={e => this.handleChangeCharges(e)}
                                                helperText="Please select department"
                                                variant="outlined"
                                                style={{
                                                    border: 0,
                                                    outline: 0
                                                }}
                                            >
                                                {_depts.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <TextField
                                                required
                                                id="amount"
                                                name="amount"
                                                label="Amount"
                                                variant="outlined"
                                                value={_charge_definition.amount}
                                                onChange={e => this.handleChangeCharges(e)}
                                            />
                                            <TextField
                                                required
                                                id="currency"
                                                name="currency"
                                                select
                                                label="Currency"
                                                variant="outlined"
                                                value={_charge_definition.currency}
                                                onChange={e => this.handleChangeCharges(e)}
                                                style={{
                                                    border: 0,
                                                    outline: 0
                                                }}
                                            >
                                                {Utils.currencies.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <TextField
                                                required
                                                id="placement"
                                                name="placement"
                                                select
                                                label="Placement"
                                                variant="outlined"
                                                value={_charge_definition.placement}
                                                onChange={e => this.handleChangeCharges(e)}
                                                style={{
                                                    border: 0,
                                                    outline: 0
                                                }}
                                            >
                                                {Utils.placements.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                            <Button variant="contained" color="secondary" onClick={this.cancel} style={{margin: 5}}>
                                Close
                            </Button>
                            <Button variant="contained" color="primary" onClick={this.saveChargesData}
                                    style={{margin: 5}}>
                                {this.state.editingCharge ? "Update" : "Save"}
                            </Button>
                        </div>
                    </Card>
                </div> :
                <div>
                    <Table
                        tableRef={this.tableRef}
                        title="Charge Definitions"
                        columns={this.state.header}
                        data={query => new Promise((resolve, reject) => {
                            SettingsService.loadChargeDefinitions(query, resolve, reject);
                        })}
                        onRowClick={this.rowSelect}
                        options={{
                            exportButton: true, filtering: false,
                            grouping: false, sorting: false,
                            debounceInterval: 1000, search: true,
                            selection: false, showTitle: true,
                            pageSize: 10, actionsColumnIndex: -1
                        }}

                        localization={{
                            header: {
                                actions: ""
                            }
                        }}

                        actions={[
                            {
                                icon: () => (
                                    <Button
                                        color="default"
                                        variant="contained"
                                        style={{textTransform: 'none'}}
                                        size="small"
                                    >
                                        EDIT
                                    </Button>
                                ),
                                tooltip: 'Edit Charge',
                                onClick: (event, rowData) => this.editChargeDefinition(event, rowData)
                            },
                            rowData => ({
                                icon: () => (
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        style={{textTransform: 'none'}}
                                        size="small"
                                    >
                                        {(rowData.can_be_deleted === true) ?
                                            "DELETE" : (rowData.suspended === true) ? "ACTIVATE" : "SUSPEND"
                                        }
                                    </Button>
                                ),
                                tooltip: 'Delete/Suspend Charge',
                                onClick: (event, rowData) => this.deleteChargeDefinition(event, rowData),
                            }),
                        ]}
                    />
                </div>}
            </div>
        );
    }
}

export default withStyles(styles)(Charges);
