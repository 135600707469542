import React from "react";
import Button from "@material-ui/core/Button";

const MyButton = (props) => {
    return(
        <Button onClick={props.onClick} variant="contained" color={props.color} fullWidth={props.fullWidth}>
            {props.title}
        </Button>
    );
};

export default MyButton;
