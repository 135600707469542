import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import BackButton from "../Buttons/BackButton";
import {Card, Grid} from '@material-ui/core';
import Utils from "../../utils/utils";
import ScholarshipService from "../../services/scholarshipService";
import MenuItem from "@material-ui/core/MenuItem";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
});

class AwardingBody extends React.Component {
    state = {
        awarding_body: {
            awarding_body: '',
            other_name: ''
        },
        showOtherName: false
    };

    // Method to update the form field values of a new scholarship awarding body
    handleChange = (name, value) => {
        let _awarding_body = {...this.state.awarding_body};
        _awarding_body[name] = value;
        if (value === "Other") {
            this.setState({
                showOtherName: value === "Other"
            });
        }
        this.setState({
            awarding_body: _awarding_body,
        });
    };

    // Method to clear the values of the form fields
    clearForm = () => {
        let _scholarshipData = this.state.newScholarship;
        _scholarshipData.awarding_body = '';
        _scholarshipData.awarded_date = Utils.getToday();
        _scholarshipData.awarded_for = '';
        _scholarshipData.studentId = {};
        _scholarshipData.status = '';
        _scholarshipData.type = '';

        this.setState({
            newScholarship: _scholarshipData
        });
        this.goBack();
    };

    // Method to valid the scholarship awarding body fields before posting to the server
    validateFields = () => {
        const {awarding_body, other_name} = this.state.awarding_body;
        if (awarding_body !== '' && !Utils.validateText(awarding_body)) return 'awarding_body';
        if (other_name !== '' && !Utils.validateText(awarding_body)) return 'other_name';
    };

    /*
    * Method to set focus on the text fields with invalid data
    * Parameters:
    * + field_id: The id of the field to set the focus on
    * */
    setFocus = (field_id) => {
        Utils.displayMessage('error', 'Error', 'Please specify ' + field_id);
        document.getElementById(field_id).focus();
    };

    // Method to send and save data to the server
    saveData = () => {

        let data = this.state.awarding_body;
        if (data.other_name !== '') {
            data.awarding_body = data.other_name;
        }
        let result = this.validateFields();
        if (result) {
            this.setFocus(result);
            return;
        }

        ScholarshipService.addAwardingBody(data, (result) => {
            if (result.error) {
                Utils.displayMessage('error', 'Failed', result.errors[0]);
            } else {
                Utils.displayMessage('success', 'Successful', result.success);
                this.goBack();
            }
        });
    };

    // Method to return the user to the previous view/component
    goBack = () => {
        localStorage.removeItem('studentId');
        localStorage.removeItem('scholarshipData');
        this.props.history.goBack();
    };

    render() {
        const {classes} = this.props;
        let awarding_body = this.state.awarding_body;

        return (
            <div>
                <Card>
                    <div style={{marginLeft: 150}}>
                        <form className={classes.root} autoComplete="on">
                            <h2>
                                {
                                    (this.props.location.scholarshipData === undefined &&
                                        JSON.parse(localStorage.getItem('scholarshipData')) === null) ?
                                        "Add Awarding Body" : "Edit Awarding Body"
                                }
                            </h2>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        required
                                        id="awarding_body"
                                        name="awarding_body"
                                        label="Awarding Body"
                                        value={awarding_body.awarding_body}
                                        onChange={(e) => this.handleChange("awarding_body", e.target.value)}
                                        select
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {Utils.awarding_types.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}

                                    </TextField>
                                    {
                                        this.state.showOtherName ?
                                            <TextField
                                                id="other_name"
                                                name="other_name"
                                                label="Name"
                                                value={awarding_body.other_name}
                                                onChange={e => this.handleChange(e.target.name, e.target.value)}
                                                variant="outlined"
                                                helperText="Specify scholarship awarding body name"
                                            />
                                            :
                                            null
                                    }
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    <BackButton goBack={this.goBack} color={"secondary"} title={"Cancel"}/>
                    <Button variant="contained" color="primary" onClick={this.saveData} style={{margin: 5}}>
                        Save
                    </Button>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(AwardingBody);


