import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import {Card, Grid} from '@material-ui/core';
import StudentService from "../../services/studentService";
import Utils from "../../utils/utils";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from '@material-ui/lab/Autocomplete';
import ScholarshipService from "../../services/scholarshipService";
import CoursesService from "../../services/coursesService";
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import {DatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
});

class AddStudent extends React.Component {
    state = {
        newStudent: {
            student_number: '',
            first_name: '',
            middle_name: '',
            last_name: '',
            gender: '',
            ethnicity: '',
            nationality: '',
            phone_number: '',
            dob: Utils.getDecade(),
            email: '',
            source_of_funding: '',
            awarded_for: (this.props.location.programData) ? '' : {},
            awarding_body: '',
            awarded_date: Utils.getToday(),
            awarded_year: '',
            status: '',
            type: '',
            lga:'',
            maxDate:Utils.getDecade(),
        },
        funding_types: [
            {label: "Scholarship", value: "Scholarship"},
            {label: "Self funding", value: "Self funding"}
        ],
        addScholarship: false,
        editing: false,
        hasScholarship: null,

        depts: [],
        programs: [],
        awarding_names: [],
        buttonToggle: false
    };
    countryList = Utils.countries.map((option) => option.label);

    // Method to update the form field values of a new programme
    handleChange = (name, value) => {
        let student = {...this.state.newStudent};
        if (name === 'phone_number' && Utils.isValid(value)) {
            Utils.displayMessage("warning", "", "Only numbers allowed");
            return;
        }
        if (value === 'Scholarship') {
            this.setState({
                addScholarship: true
            });
        }
        if (value === 'Self funding') {
            this.setState({
                addScholarship: false,
                hasScholarship: null
            });
        }
        student[name] = value;
        this.setState({
            newStudent: student
        });
    };

    // Method to update date of birth date field value
    handleDobChange = (n) => this.setState({
        newStudent: {...this.state.newStudent, dob: n}
    });

    // Method to update scholarship awarding date field value
    handleDateChange = (n) => this.setState({
        newStudent: {...this.state.newStudent, awarded_date: n}
    });

    // Method to clear form field values
    clearForm = () => {
        let _studentData = this.state.newStudent;
        _studentData.student_number = '';
        _studentData.first_name = '';
        _studentData.middle_name = '';
        _studentData.last_name = '';
        _studentData.gender = '';
        _studentData.ethnicity = '';
        _studentData.nationality = '';
        _studentData.phone_number = '';
        _studentData.dob ='';
        _studentData.email = '';
        _studentData.source_of_funding = '';
        _studentData.lga='';
        this.setState({
            newStudent: _studentData
        });
        this.goBack();
    };

    // Method to valid the programme fields before posting to the server
    validateFields = () => {
        const {
            first_name, middle_name, last_name, gender,
            ethnicity, nationality, phone_number, email
        } = this.state.newStudent;

        if (first_name === '' || !Utils.validateName(first_name)) return 'first_name';
        if (middle_name !== '' && !Utils.validateName(middle_name)) return 'middle_name';
        if (last_name === '' || !Utils.validateName(last_name)) return 'last_name';
        if (gender !== '' && !Utils.validateText(gender)) return 'gender';
        if (ethnicity !== '' && !Utils.validateText(ethnicity)) return 'ethnicity';
        if (nationality !== '' && !Utils.validateText(nationality)) return 'nationality';

        if (phone_number !== '' && !Utils.validatePhoneNumber(phone_number)) return 'phone_number';
        if (email !== '' && !Utils.validateEmail(email)) return 'email';
    };

    /*
    * Method to set focus on the text fields with invalid data
    * Parameters:
    * + field_id: The id of the field to set the focus on
    * */
    setFocus = (field_id) => {
        Utils.displayMessage('error', 'Error', 'Please specify ' + field_id.replaceAll('_', ' ')).then(r => r);
        document.getElementById(field_id).focus();
    };

    // Method to disable button to prevent user from doing multiple clicks
    toggleButton = () => {this.setState({buttonToggle: !this.state.buttonToggle})};

    // Method to send and save student data to the server
    saveData = () => {
        this.toggleButton();
        let data = {...this.state.newStudent};
        data.dob = Utils.formatDate(data.dob);
        if (data.source_of_funding !== 'Self funding') {
            data.awarded_date = Utils.formatDate(data.awarded_date);
            data.awarded_year = data.awarded_date.split('-')[0];
        }

        let result = this.validateFields();
        if (result) {
            this.setFocus(result);
            return;
        }

        if (data.source_of_funding === 'Self funding') {
            data.awarding_body = '';
            data.awarded_for = '';
            data.awarded_year = '';
            data.awarded_date = '';
            data.status = '';
            data.type = '';
        }
        data.editing = this.state.editing;

        StudentService.createStudent(data, result => {
            if (result.error) {
                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
                this.toggleButton();
            } else {
                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                this.clearForm();
                this.toggleButton();
            }
        });
    };

    // Method to initialize select fields for department and programmes
    deptsPrograms = (results) => {
        let _depts = [];

        let _programs = [];
        if (results.depts && results.programs) {
            results.depts.map(dept => _depts.push({label: dept.name, value: dept._id.$oid}));
            results.programs.map(program => _programs.push({label: program.name, value: program._id.$oid}));

            if (_depts.length === 0) {
                Utils.displayMessage('error', 'Error', 'You have to add at least one departments first before you can add a course');
                this.props.history.goBack();
            } else if (_programs.length === 0) {
                Utils.displayMessage('error', 'Error', 'You cannot add a student without adding Programs, Please add  Programs to proceed');
                this.props.history.goBack()
            } else {
                let course = {...this.state.newCourse};
                if (!this.props.location.courseData && JSON.parse(localStorage.getItem('courseData')) === null) {
                    course.awarded_for = _programs[0].value;
                }
                this.setState({
                    programs: _programs,
                })
            }
        }
    };

    // Method to return user to the previous view
    goBack = () => {
        this.props.history.goBack();
    };

    // Method to initialize the select fields of the form
    initialiseSelectFields = () => {
        let _student = {...this.state.newStudent};
        _student.gender = Utils.genders[0].value;
        _student.ethnicity = Utils.ethnics[0].value;
        _student.source_of_funding = this.state.funding_types[1].value;
        _student.lga=Utils.lga[0].value;
        this.setState({
            newStudent: _student
        })
    };

    // Initialize fields when editing a student
    getDetails = (result) => {
        let _hasScholarship = result.scholarship ? result.scholarship : null;
        let _newStudent = {...result.student};
        if (_hasScholarship) {
            _newStudent.awarded_for = _hasScholarship.programme_id;
            _newStudent.awarding_body = _hasScholarship.scholarship_awarding_body_id.$oid;
            _newStudent.status = _hasScholarship.status;
            _newStudent.awarded_year = _hasScholarship.awarded_year;
            _newStudent.awarded_date = _hasScholarship.awarded_date;
            _newStudent.type = _hasScholarship.type;
            _newStudent.scholarship_id = _hasScholarship._id.$oid;
        }

        this.setState({
            newStudent: {..._newStudent},
            editing: true,
            hasScholarship: _hasScholarship
        });
    };

    // Method to initialize scholarship awarding body
    updateSelectFields = (results) => {
        let awarding_names = [];

        if (results.awarding_names) {
            results.awarding_names.map(dept => awarding_names.push({label: dept.name, value: dept._id.$oid}));
            let _student = {...this.state.newStudent};
            if (this.props.location.studentData === undefined && localStorage.getItem('studentId') === null) {
                if(awarding_names.length > 0){
                    _student.awarding_body = awarding_names[0].value;
                }
            }
            this.setState({
                awarding_names: awarding_names,
                newStudent: _student
            })
        }
    };


    componentDidMount() {
        if (this.props.location.studentData !== undefined) {
            localStorage.setItem('scholarship', JSON.stringify(this.props.location.studentData.scholarship));
            localStorage.setItem('studentId', this.props.location.studentData.details._id.$oid);
        }
        if (this.props.location.studentData) {
            let _hasScholarship = JSON.parse(localStorage.getItem('scholarship'));
            let _newStudent = {...this.props.location.studentData.details};
            if (_hasScholarship) {
                _newStudent.awarded_for = _hasScholarship.programme_id;
                _newStudent.awarding_body = _hasScholarship.scholarship_awarding_body_id.$oid;
                _newStudent.status = _hasScholarship.status;
                _newStudent.awarded_year = _hasScholarship.awarded_year;
                _newStudent.awarded_date = _hasScholarship.awarded_date;
                _newStudent.type = _hasScholarship.type;
                _newStudent.scholarship_id = _hasScholarship._id.$oid;
            }
            this.setState({
                newStudent: _newStudent,
                editing: true,
                hasScholarship: _hasScholarship
            })
        } else if (this.props.location.studentData === undefined && localStorage.getItem('studentId')) {
            StudentService.loadStudentId(localStorage.getItem('studentId'), this.getDetails);
            this.setState({
                editing: true
            });
        } else {
            this.initialiseSelectFields();
        }
        ScholarshipService.loadAwardingNames(this.updateSelectFields);
        CoursesService.loadDeptsProgs(this.deptsPrograms);

    }

    render() {
        const {classes} = this.props;
        let student = this.state.newStudent;
        const _progs = this.state.programs;

        return (
            <div>
                <Card>
                    <div style={{marginLeft: 150}}>
                        <form className={classes.root} autoComplete="on">
                            <h2>{(this.props.location.studentData || localStorage.getItem('studentId')) ? "Edit Student" : "Add Student"}</h2>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        required
                                        id="student_number"
                                        name="student_number"
                                        label="Student Number"
                                        variant="outlined"
                                        value={student.student_number}
                                        onChange={e => this.handleChange(e.target.name, e.target.value)}
                                    />
                                    <TextField
                                        required
                                        id="first_name"
                                        name="first_name"
                                        label="First Name"
                                        variant="outlined"
                                        value={student.first_name}
                                        onChange={e => this.handleChange(e.target.name, e.target.value)}
                                    />
                                    <TextField
                                        id="middle_name"
                                        name="middle_name"
                                        label="Middle Name"
                                        variant="outlined"
                                        value={student.middle_name}
                                        onChange={e => this.handleChange(e.target.name, e.target.value)}
                                    />
                                    <TextField
                                        required
                                        id="last_name"
                                        name="last_name"
                                        label="Last Name"
                                        variant="outlined"
                                        value={student.last_name}
                                        onChange={e => this.handleChange(e.target.name, e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        required
                                        id="gender"
                                        name="gender"
                                        select
                                        label="Gender"
                                        value={student.gender}
                                        onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {Utils.genders.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        required
                                        id="ethnicity"
                                        name="ethnicity"
                                        select
                                        label="Ethnicity"
                                        value={student.ethnicity}
                                        onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {Utils.ethnics.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        required
                                        id="phone"
                                        name="phone_number"
                                        label="Phone Number"
                                        variant="outlined"
                                        value={student.phone_number}
                                        onChange={e => this.handleChange(e.target.name, e.target.value)}
                                    />
                                    <Autocomplete
                                        id="nationality"
                                        name="nationality"
                                        disableClearable
                                        options={this.countryList.sort()}
                                        value={student.nationality}
                                        style={{display: 'inline'}}
                                        onChange={(event, value) => this.handleChange("nationality", value)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                value={student.nationality}
                                                label="Nationality"
                                                variant="outlined"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                    value: student.nationality,
                                                    id: "nationality"
                                                }}
                                            />
                                        )}

                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="email"
                                        name="email"
                                        label="Email"
                                        variant="outlined"
                                        value={student.email}
                                        onChange={e => this.handleChange(e.target.name, e.target.value)}
                                    />
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            id="dob"
                                            name="dob"
                                            value={student.dob}
                                            onChange={this.handleDobChange}
                                            label="Date of Birth"
                                            variant="inline"
                                            inputVariant="outlined"
                                            format="dd/MM/yyyy"
                                            defaultDate="dd/MM/yyyy"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            maxDate={Utils.getDecade()}
                                        />
                                    </MuiPickersUtilsProvider>


                                    <TextField
                                        required
                                        id="source_of_funding"
                                        name="source_of_funding"
                                        select
                                        label="Source of Funding"
                                        value={student.source_of_funding}
                                        onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {this.state.funding_types.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        required
                                        id="lga"
                                        name="lga"
                                        select
                                        label="Local Government Area"
                                        value={student.lga}
                                        onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {Utils.lga.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    {
                                        (this.state.addScholarship || this.state.hasScholarship) ?
                                            <TextField
                                                id="awarding_body"
                                                name="awarding_body"
                                                select
                                                label="Awarding Body"
                                                variant="outlined"
                                                value={student.awarding_body}
                                                onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                                style={{
                                                    border: 0,
                                                    outline: 0
                                                }}
                                            >
                                                {this.state.awarding_names.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField> : null
                                    }
                                </Grid>
                            </Grid>
                            {(this.state.addScholarship || this.state.hasScholarship) ?
                                <>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    id="awarded_date"
                                                    name="awarded_date"
                                                    value={student.awarded_date}
                                                    onChange={this.handleDateChange}
                                                    label="Awarded Date"
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    format="dd/MM/yyyy"
                                                    valueDefault='20/01/2005'
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>

                                            <TextField
                                                id="awarded_for"
                                                name="awarded_for"
                                                select
                                                label="Awarded For"
                                                value={student.awarded_for}
                                                onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                                variant="outlined"
                                            >
                                                {_progs.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <TextField
                                                required
                                                id="status"
                                                name="status"
                                                select
                                                label="Status"
                                                variant="outlined"
                                                value={student.status}
                                                onChange={e => this.handleChange(e.target.name, e.target.value)}
                                                style={{
                                                    border: 0,
                                                    outline: 0
                                                }}
                                            >
                                                {Utils.status.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <TextField
                                                required
                                                id="type"
                                                name="type"
                                                select
                                                label="Type"
                                                variant="outlined"
                                                value={student.type}
                                                onChange={(e) => this.handleChange(e.target.name, e.target.value)}
                                                style={{
                                                    border: 0,
                                                    outline: 0
                                                }}
                                            >
                                                {Utils.type.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    </Grid>
                                </>
                                : null
                            }
                        </form>
                    </div>
                    <div style={{padding: 5}}>
                        <Button variant="contained" color="secondary" onClick={this.goBack} style={{margin: 5}}>
                            Cancel
                        </Button>
                        <Button disabled={this.state.buttonToggle} variant="contained" color="primary" onClick={this.saveData} style={{margin: 5}}>
                            Save
                        </Button>
                    </div>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(AddStudent);


