import $ from "jquery";
import Utils from "../utils/utils";

import {Api} from "../config/api_config";
import * as cookies from "react-cookies";

export default class ScholarshipService {

    /*
    * Method to create/update a programme
    * Parameters:
    * + data: The data of the programme to created or updated
    * + callback: Method to handle the data returned from the server
    * */
    static createScholarship(data, callback){
        const id = (data._id) ? data._id.$oid : '';
        const _method = (id) ? "PUT" : "POST";
        const text = (id) ? "Scholarship successfully updated" : "Scholarship successfully added";

        $.ajax({
            method: _method,
            url: Api.DEV_BASE_URL+"/v1/scholarships/"+id,
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"), "UserKey":cookies.load('User-Key')},
            dataType: "json",
            data: data,
            success: function (result) {
                callback(result);
            },
            error:function (e) {
                Utils.displayMessage('error','Failed', 'Scholarship could not be saved');
            }
        })
    }

    /*
    * Method to load all students with scholarships into a data table
    * Parameters:
    * + query: The query sent by the data table
    * + resolve: callback method to process the received list of courses
    * + callback: The method to handle the response coming from the server
    * */
    static loadScholarships = (query,id,resolve,reject) => {
        const filter = ":scholarship_awarding_body_id => '"+id+"'";
        let url = Utils.compileQuery(query, (Api.DEV_BASE_URL+"/v1/scholarships"),
            ["id","awarding_body","student_number","student_name","awarded_date","awarded_year","awarded_for",
                "first_name","student_id","middle_name","last_name","studentId","status", "type","dirty"], [{"by":"desc","attr":"awarding_body"}],
            "Students::Scholarship",null,filter);
        fetch(url, {
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"), "UserKey":cookies.load('User-Key')}
        })
            .then(response => {return response.json()})
            .then(result => {
                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total
                })
            });
    };

    /*
    * Method to load all the scholarship awarding bodies into a data table
    * Parameters:
    * + query: The query sent by the data table
    * + resolve: callback method to process the received list of courses
    * + callback: The method to handle the response coming from the server
    * */
    static loadData = (query,resolve,reject, callback) => {
        let url = Utils.compileQuery(query, (Api.DEV_BASE_URL+"/v1/awarding_body"),
            ["id","name","total_active_students","total_inactive_students", "dirty"], [{"by":"desc","attr":"awarding_body"}],
            "Students::ScholarshipAwardingBody",null,null);
        fetch(url, {
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"), "UserKey":cookies.load('User-Key')}
        })
            .then(response => {return response.json()})
            .then(result => {
                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total
                });
                callback();
            });
    };

    /*
    * Method to get the details of a scholarship awarding body
    * Parameters:
    * + id: The id of the scholarship awarding body to get details for
    * + callback: Method to handle response from the server
    *
    * */
    static loadScholarshipId = (id,callback) =>{
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL+"/v1/awarding_body/"+id,
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"), "UserKey":cookies.load('User-Key')},
            success: function(result) {
                callback(result.awarding_body);
            },
            error: function(e){
                Utils.displayMessage('error','Failed', 'Scholarship could not be loaded')
            }
        });
    };

    /*
    * Method to delete a scholarship awarding body
    * Parameters:
    * + id: The id of the scholarship awarding body to delete
    * + callback: Method to handle response from the server
    *
    * */
    static awardingBody = (id, callback) =>{
        $.ajax({
            method: "DELETE",
            url: Api.DEV_BASE_URL+"/v1/awarding_body/"+id,
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"), "UserKey":cookies.load('User-Key')},
            success: function(result) {
                callback(result);
            },
            error: function(e){
                Utils.displayMessage('error','Failed', 'Scholarship could not be deleted');
            }
        });
    };

    /*
    * Method to terminate a scholarship awarded to student
    * Parameters:
    * + data: The data of the scholarship to be terminated
    * + callback: Method to handle response from the server
    *
    * */
    static terminateScholarship = (data, callback) =>{
        $.ajax({
            method: "PUT",
            url: Api.DEV_BASE_URL+"/v1/scholarships/terminate/",
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"), "UserKey":cookies.load('User-Key')},
            data: data,
            success: function(result) {
                callback(result);
            },
            error: function(e){
                Utils.displayMessage('error','Failed', 'Scholarship could not be terminated');
            }
        });
    };

    /*
    * Method to activate a terminated scholarship awarded to student
    * Parameters:
    * + data: The data of the terminated scholarship to be activated
    * + callback: Method to handle response from the server
    *
    * */
    static activateScholarship = (data, callback) =>{
        $.ajax({
            method: "PUT",
            url: Api.DEV_BASE_URL+"/v1/scholarships/activate_scholarship",
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"), "UserKey":cookies.load('User-Key')},
            data: data,
            success: function(result) {
                callback(result);
            },
            error: function(e){
                Utils.displayMessage('error','Failed', 'Scholarship could not be activated');
            }
        });
    };

    /*
    * Method to create a scholarship awarding body
    * Parameters:
    * + data: The data of the scholarship awarding body to be created
    * + callback: Method to handle response from the server
    *
    * */
    static addAwardingBody = (data, callback) =>{
        $.ajax({
            method: "POST",
            url: Api.DEV_BASE_URL+"/v1/awarding_body",
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"), "UserKey":cookies.load('User-Key')},
            data: data,
            success: function(result) {
                callback(result);
            },
            error: function(e){
                Utils.displayMessage('error','Failed', 'Scholarship could not be terminated');
            }
        });
    };

    /*
    * Method to load awarding body names when adding a scholarship for a student
    * Parameters:
    * + callback: Method to handle response from the server
    *
    * */
    static loadAwardingNames = (callback) => {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/v1/awarding_body/get_awarding_body_names",
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"), "UserKey":cookies.load('User-Key')},
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Awarding body names could not be loaded. Please add them under settings.')
            }
        });
    };

    /*
    * Method to update a scholarship awarding body
    * Parameters:
    * + data: The data of the scholarship awarding body to be updated
    * + callback: Method to handle response from the server
    *
    * */
    static updateAwardingBody = (data, callback) => {
        let id = data._id.$oid;
        $.ajax({
            method: "PUT",
            url: Api.DEV_BASE_URL + "/v1/awarding_body/"+id,
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"), "UserKey":cookies.load('User-Key')},
            data: data,
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Awarding body names could not be loaded')
            }
        });
    };
}
