import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import {Grid} from '@material-ui/core';
import Utils from "../../utils/utils";
import * as cookies from "react-cookies";
import StaffService from "../../services/staffService";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
});

class ViewProfile extends React.Component {
    state = {
        currentUser: {
            first_name: '',
            middle_name: '',
            last_name: '',
            phone_number: '',
            email: '',
        }
    };

    // Method to update the form field values for the currentUser
    handleChange = e => {
        let val = e.target.value.trim();
        let _currentUser = {...this.state.currentUser};
        if (e.target.name === 'phone_number' && Utils.isValid(val)) {
            Utils.displayMessage("warning", "", "Only numbers allowed");
            return;
        }
        _currentUser[e.target.name] = val;
        this.setState({
            currentUser: _currentUser
        });
    };

    // Method to return the user to the previous view/component
    goBack = () => {
        this.props.history.goBack();
    };

    // Method that send user data for updating the user's profile
    updateData = () => {
        StaffService.updateProfile(this.state.currentUser, result => {
            if (result.error) {
                Utils.displayMessage('error', 'Error', result.errors[0]);
            } else {
                Utils.displayMessage('success', 'Successful', result.success);
                this.loadUser();
            }
        });
    };

    // Method to load the details of the current user
    loadUser = () => {
        StaffService.loadStaffId(cookies.load('currentUserId'), result => {
            this.setState({
                currentUser: {...result.user}
            })
        });
    };

    componentDidMount() {
        this.loadUser();
    };

    render() {
        const {classes} = this.props;
        let current_user = this.state.currentUser;
        return (
            <div>
                <div style={{marginLeft: 150}}>
                    <form className={classes.root} autoComplete="on">
                        <h4>Update Details</h4>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    required
                                    id="first_name"
                                    name="first_name"
                                    label="First Name"
                                    variant="outlined"
                                    value={current_user.first_name}
                                    onChange={e => this.handleChange(e)}
                                />
                                <TextField
                                    required
                                    id="middle_name"
                                    name="middle_name"
                                    label="Middle Name"
                                    variant="outlined"
                                    value={current_user.middle_name}
                                    onChange={e => this.handleChange(e)}
                                />
                                <TextField
                                    required
                                    id="last_name"
                                    name="last_name"
                                    label="Last Name"
                                    variant="outlined"
                                    value={current_user.last_name}
                                    onChange={e => this.handleChange(e)}
                                />
                                <TextField
                                    required
                                    id="phone_number"
                                    name="phone_number"
                                    label="Phone"
                                    variant="outlined"
                                    value={current_user.phone_number}
                                    onChange={e => this.handleChange(e)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    required
                                    id="email"
                                    name="email"
                                    label="Email"
                                    variant="outlined"
                                    value={current_user.email}
                                    onChange={e => this.handleChange(e)}
                                />
                            </Grid>
                        </Grid>
                    </form>
                </div>
                <div style={{padding: 5}}>
                    <Button variant="contained" color="primary" onClick={this.updateData} style={{margin: 5}}>
                        Update
                    </Button>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(ViewProfile);
