import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import {Card, Grid} from '@material-ui/core';
import StaffService from "../../services/staffService";
import Utils from "../../utils/utils";
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";
import ProgramService from "../../services/programService";
import MenuItem from "@material-ui/core/MenuItem";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import utils from "../../utils/utils";


const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        width: '25ch',
    },
});

class AddStaff extends React.Component {
    state = {
        newStaff: {
            user_id: '',
            first_name: '',
            middle_name: '',
            last_name: '',
            departmentId: {},
            headed_by: '',
            gender: '',
            ethnicity: '',
            nationality: '',
            certificated_in: '',
            license: '',
            phone_number: '',
            dob: utils.getDecade(),
            email: '',
            password: '',
            dup_password: '',
            job_title: '',
            user_type: {},
            teaching_field: {},
            teaching_level: {},
            rank: {},
            role: {},
            emp_date: Utils.getToday(),
            qualification: {},
            job_entry_req: {},
            emp_type: {},
        },
        showPassword: false,
        showConfirmPassword: false,
        depts: [],
        roles: [
            {label: "Academic", value: "Academic"},
            {label: "Administrative", value: "Administrative"},
            {label: "Other", value: "Other"}
        ],
        teaching_levels: [
            {label: "Foundation", value: "Foundation"},
            {label: "Level 1", value: "Level 1"},
            {label: "Level 2", value: "Level 2"},
            {label: "Level 3", value: "Level 3"},
            {label: "Level 4", value: "Level 4"},
            {label: "Others", value: "Others"},
            {label: "Certificate", value: "Certificate"},
            {label: "Ordinary Diploma", value: "Ordinary Diploma"},
            {label: "Advanced Diploma", value: "Advanced Diploma"},
            {label: "Higher Diploma", value: "Higher Diploma"},
            {label: "Bachelor", value: "Bachelor"},
            {label: "Masters", value: "Masters"},
            {label: "PhD", value: "PhD"},
            {label: "Post Graduate Certificate", value: "Post Graduate Certificate"},
            {label: "Post Graduate Diploma", value: "Post Graduate Diploma"},
            {label: "None", value: "None"}
        ],
        emp_types: [
            {label: "Full Time", value: "Full Time"},
            {label: "Part Time", value: "Part Time"},
            {label: "Temporary", value: "Temporary"},
            {label: "Permanent", value: "Permanent"}
        ],
        fields: [],
        ranks: [
            {label: "Lecturer", value: "Lecturer"},
            {label: "Professor", value: "Professor"},
            {label: "Dean", value: "Dean"},
            {label: "Senior Lecturer", value: "Senior Lecturer"},
            {label: "Assistant Lecturer", value: "Assistant Lecturer"},
            {label: "Assistant Professor", value: "Assistant Professor"},
            {label: "Associate Professor", value: "Associate Professor"},
            {label: "Teaching Assistant", value: "Teaching Assistant"},
            {label: "Adjunct", value: "Adjunct"},
            {label: "Visiting Scholar", value: "Visiting Scholar"},
            {label: "CAO", value: "CAO"},
            {label: "Director", value: "Director"},
            {label: "Admin Assistant", value: "Admin Assistant"},
            {label: "Admission Officer", value: "Admission Officer"},
            {label: "Communications Officer", value: "Communications Officer"},
            {label: "Executive Secretary", value: "Executive Secretary"},
            {label: "Financial Assistant", value: "Financial Assistant"},
            {label: "Video Cordinator", value: "Video Cordinator"},
            {label: "Receptionist", value: "Receptionist"},
            {label: "Registrar", value: "Registrar"},
            {label: "Provost", value: "Provost"},
            {label: "Deputy Provost", value: "Deputy Provost"},
            {label: "Academic Affairs", value: "Academic Affairs"},
        ],
        qualifications: [
            {label: "Masters", value: "Masters"},
            {label: "Doctorate", value: "Doctorate"},
            {label: "Bachelors", value: "Bachelors"},
            {label: "MCSA", value: "MCSA"},
            {label: "Diploma Lecturer", value: "Diploma Lecturer"},
            {label: "Ordinary Diploma", value: "Ordinary Diploma"},
            {label: "Higher Diploma", value: "Higher Diploma"},
            {label: "WASSCE", value: "WASSCE"},
            {label: "None", value: "None"}
        ],
        entry_levels: [
            {label: "Pre Entry", value: "Pre Entry"},
            {label: "O Level", value: "O Level"},
            {label: "A Level", value: "A Level"},
            {label: "Basic Literacy", value: "Basic Literacy"},
            {label: "GABECE", value: "GABECE"},
            {label: "Bachelors", value: "Bachelors"},
            {label: "Masters", value: "Masters"},
            {label: "None", value: "None"}
        ]
    };

    // Method to update the form field values of a new staff
    handleChange = (name,value) => {
        let staff = {...this.state.newStaff};
        if (name === 'phone_number' && Utils.isValid(value)) {
            Utils.displayMessage("warning", "", "Only numbers allowed").then(r => r);
            return;
        }

        staff[name] = value;
        this.setState({
            newStaff: staff
        });
    };

    // Method to update the form data field
    handleDobChange = (n) => this.setState({
        newStaff: {...this.state.newStaff, dob: n}
    });

    // Method to update the form field for employment history
    handleEmpChange = (n) => this.setState({
        newStaff: {...this.state.newStaff, emp_date: n}
    });

    // Method to clear the values of the form fields
    clearForm = () => {
        let _staffData = this.state.newStaff;
        _staffData.user_id = '';
        _staffData.first_name = '';
        _staffData.middle_name = '';
        _staffData.last_name = '';
        _staffData.gender = '';
        _staffData.ethnicity = '';
        _staffData.nationality = '';
        _staffData.phone_number = '';
        _staffData.dob = Utils.getToday();
        _staffData.email = '';
        _staffData.password = '';
        _staffData.license = '';
        _staffData.certificated_in = '';
        _staffData.dup_password = '';
        _staffData.showConfirmPassword = '';
        _staffData.departmentId = {};
        this.setState({
            newStaff: _staffData
        });
    };

    // Method to valid the staff data fields before posting to the server
    validateFields = () => {
        const {
            first_name, middle_name, last_name, gender,
            ethnicity, nationality, phone_number, email, user_id
        } = this.state.newStaff;

        if (user_id === '' || !Utils.validateText(user_id)) return 'user_id';
        if (first_name === '' || !Utils.validateName(first_name)) return 'first_name';
        if (middle_name !== '' && !Utils.validateName(middle_name)) return 'middle_name';
        if (last_name === '' || !Utils.validateName(last_name)) return 'last_name';
        if (gender === '' || !Utils.validateText(gender)) return 'gender';
        if (nationality === '' || !Utils.validateText(nationality)) return 'nationality';
        if (phone_number !== '' && !Utils.validatePhoneNumber(phone_number)) return 'phone_number';
        if (email !== '' && (email !== '' && !Utils.validateEmail(email))) return 'email';
    };

    /*
    * Method to set focus on the text fields with invalid data
    * Parameters:
    * + field_id: The id of the field to set the focus on
    * */
    setFocus = (field_id) => {
        if (field_id === 'user_id') {
            field_id = 'Staff ID';
            Utils.displayMessage('error', 'Error', 'Please specify ' + field_id);
        } else {
            Utils.displayMessage('error', 'Error', 'Please specify ' + field_id);
        }
        document.getElementById(field_id).focus();
    };

    // Method to send and save data to the server
    saveData = () => {

        let data = this.state.newStaff;
        data.dob = (data.dob) ? Utils.formatDate(data.dob) : Utils.getToday();
        if (data.user_type && data.user_type.label) {
            data.user_type = data.user_type.value;
        }
        if (data.rank && data.rank.label) {
            data.rank = data.rank.value;
        }
        if (data.qualification && data.qualification.label) {
            data.qualification = data.qualification.value;
        }
        if (data.job_entry_req && data.qualification.job_entry_req) {
            data.job_entry_req = data.job_entry_req.value;
        }
        if (data.departmentId && data.departmentId.label) {
            data.departmentId = data.departmentId.value;
        }
        if (data.teaching_field && data.teaching_field.label) {
            data.teaching_field = data.teaching_field.value;
        }
        if (data.teaching_level && data.teaching_level.label) {
            data.teaching_level = data.teaching_level.value;
        }
        if (data.emp_type && data.emp_type.label) {
            data.emp_type = data.emp_type.value;
        }

        let result = this.validateFields();
        if (result) {
            this.setFocus(result);
            return;
        }
        if (localStorage.getItem("staff_id") == null) {
            if (data.password !== data.dup_password) {
                Utils.displayMessage('error', 'Error', 'Password mismatch!').then(r => r);
                document.getElementById('password').focus();
                return;
            }
        }

        StaffService.createStaff(data, result => {
            if (result.error) {
                Utils.displayMessage('error', 'Error', result.errors[0]);
            } else {
                Utils.displayMessage('success', 'Successful', result.success);
                this.goBack();
            }
        });
    };

    // Method to display password field data
    handleClickShowPassword = () => {
        this.setState(prevState => ({
            showPassword: !prevState.showPassword
        }));
    };

    // Method to display confirm password field data
    handleClickShowConfirmPassword = () => {
        this.setState(prevState => ({
            showConfirmPassword: !prevState.showConfirmPassword
        }));
    };

    // Method to prevent reloading when typing the passwords
    handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // Method to load the departments
    loadDepartments = (results) => {
        let _depts = [];
        let _fields = [];

        if (results.depts && results.fields) {
            results.depts.map(dept => _depts.push({label: dept.name, value: dept._id.$oid}));
            results.fields.map(field => _fields.push({label: field.name, value: field._id.$oid}));
            let staff = {...this.state.newStaff};
            _depts.push({label: "None", value: "None"});
            _fields.push({label: "None", value: "None"});
            this.setState({
                depts: _depts,
                fields: _fields,
                newStaff: staff
            })
        }
    };

    // Method to initialize field data when editing a staff
    setData = (result) => {
        let _user = {...result.user};
        let _emp_history = {...result.emp_history};

        _user.user_type = _emp_history.role;
        _user.qualification = _emp_history.highest_qualification;
        _user.rank = _emp_history.rank;
        _user.teaching_field = _emp_history.main_teaching_field_id;
        if(_user.department_id && _user.department_id !== "None"){
            _user.departmentId = _user.department_id.$oid;
        }else{
            _user.departmentId = _user.department_id;
        }
        _user.role = _emp_history.role;
        this.setState({newStaff: _user});
    };

    // Method to return to the previous view
    goBack = () => {
        this.props.history.goBack();
        this.clearForm();
    };

    countryList = Utils.countries.map((option) => option.label);

    componentDidMount() {
        if (this.props.history.location.fromStaff) {
            localStorage.clear();
        }
        if (this.props.location.staffData) {
            let _emp = {...this.props.location.empHistoryData};
            let _data = {...this.props.location.staffData};
            let _staff_profile = {...this.props.location.staffProfile};

            if(_data.department_id && _data.department_id !== "None"){
                _data.departmentId = _data.department_id.$oid;
            }else{
                _data.departmentId = _data.department_id;
            }
            _data.user_type = _emp.role;
            _data.teaching_field = _emp.main_teaching_field_id;
            _data.teaching_level = _emp.teaching_level;
            _data.rank = _emp.rank;
            _data.role = _emp.role;
            _data.qualification = _emp.highest_qualification;
            _data.certificated_in = _emp.certificated_in;
            _data.emp_type = _emp.employment_type;
            _data.job_entry_req = _emp.job_entry_req;

            this.setState({
                newStaff: _data
            }, () => {
                localStorage.setItem("staff_id", _data._id.$oid);
            });
        } else if (this.props.location.staffData === undefined) {
            localStorage.getItem("staff_id") && StaffService.loadStaffId(localStorage.getItem("staff_id"), this.setData);
        }
        ProgramService.loadDeptsNationalNames(this.loadDepartments);
    };

    render() {
        const {classes} = this.props;
        let staff = this.state.newStaff;
        const _depts = this.state.depts;
        const _fields = this.state.fields;
        const _teaching_levels = this.state.teaching_levels;

        return (
            <div>
                <Card>
                    <div style={{marginLeft: 150}}>
                        <form className={classes.root} noValidate={false} autoComplete="on">
                            <h2>{ localStorage.getItem("staff_id") ? "Edit Staff" : "Add Staff"}</h2>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        required
                                        id="user_id"
                                        name="user_id"
                                        label="Staff ID"
                                        variant="outlined"
                                        value={staff.user_id}
                                        onChange={e => this.handleChange(e.target.name,e.target.value)}
                                    />
                                    <TextField
                                        required
                                        id="first_name"
                                        name="first_name"
                                        label="First Name"
                                        variant="outlined"
                                        value={staff.first_name}
                                        onChange={e => this.handleChange(e.target.name,e.target.value)}
                                    />
                                    <TextField
                                        id="middle_name"
                                        name="middle_name"
                                        label="Middle Name"
                                        variant="outlined"
                                        value={staff.middle_name}
                                        onChange={e => this.handleChange(e.target.name,e.target.value)}
                                    />
                                    <TextField
                                        required
                                        id="last_name"
                                        name="last_name"
                                        label="Last Name"
                                        variant="outlined"
                                        value={staff.last_name}
                                        onChange={e => this.handleChange(e.target.name,e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="license"
                                        name="license"
                                        label="License"
                                        variant="outlined"
                                        value={staff.license}
                                        onChange={e => this.handleChange(e.target.name,e.target.value)}
                                    />
                                    <TextField
                                        id="departmentId"
                                        name="departmentId"
                                        select
                                        label="Department"
                                        value={staff.departmentId}
                                        onChange={e => this.handleChange(e.target.name,e.target.value)}
                                        variant="outlined"
                                    >
                                        {_depts.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        required
                                        id="gender"
                                        name="gender"
                                        select
                                        label="Gender"
                                        value={staff.gender}
                                        onChange={e => this.handleChange(e.target.name,e.target.value)}
                                        variant="outlined"
                                    >
                                        {Utils.genders.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <Autocomplete
                                        id="nationality"
                                        name="nationality"
                                        disableClearable
                                        options={this.countryList.sort()}
                                        value={staff.nationality}
                                        onChange={(e,val) => this.handleChange("nationality",val)}
                                        style={{display: 'inline'}}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                value={staff.nationality}
                                                label="Nationality"
                                                variant="outlined"
                                                InputProps={{ ...params.InputProps, type: 'search',value: staff.nationality, id:"nationality"}}
                                                required
                                            />
                                        )}

                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="ethnicity"
                                        name="ethnicity"
                                        select
                                        label="Ethnicity"
                                        value={staff.ethnicity}
                                        onChange={e => this.handleChange(e.target.name,e.target.value)}
                                        variant="outlined"
                                    >
                                        {Utils.ethnics.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            id="dob"
                                            name="dob"
                                            value={staff.dob}
                                            onChange={this.handleDobChange}
                                            label="DOB"
                                            variant="inline"
                                            inputVariant="outlined"
                                            format="dd/MM/yyyy"
                                            valueDefault='20/01/2005'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            maxDate={utils.getDecade()}
                                        />
                                    </MuiPickersUtilsProvider>


                                    <TextField
                                        id="phone"
                                        name="phone_number"
                                        label="Phone Number"
                                        variant="outlined"
                                        value={staff.phone_number}
                                        onChange={e => this.handleChange(e.target.name,e.target.value)}
                                    />
                                    <TextField
                                        id="email"
                                        name="email"
                                        label="Email"
                                        variant="outlined"
                                        value={staff.email}
                                        onChange={e => this.handleChange(e.target.name,e.target.value)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        select
                                        id="user_type"
                                        name="user_type"
                                        label="User Role"
                                        value={staff.user_type}
                                        onChange={e => this.handleChange(e.target.name,e.target.value)}
                                        variant="outlined"
                                    >
                                        {this.state.roles.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            id="emp_date"
                                            name="emp_date"
                                            value={staff.emp_date}
                                            onChange={this.handleEmpChange}
                                            label="Employment Date"
                                            variant="inline"
                                            inputVariant="outlined"
                                            format="dd/MM/yyyy"
                                            valueDefault='20/01/2005'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <TextField
                                        select
                                        id="rank"
                                        name="rank"
                                        label="Rank"
                                        value={staff.rank}
                                        onChange={e => this.handleChange(e.target.name,e.target.value)}
                                        variant="outlined"
                                    >
                                        {this.state.ranks.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        select
                                        id="qualification"
                                        name="qualification"
                                        label="Staff Qualification"
                                        value={staff.qualification}
                                        onChange={e => this.handleChange(e.target.name,e.target.value)}
                                        variant="outlined"
                                    >
                                        {this.state.qualifications.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Grid container>

                                    <Grid item xs={12} sm={12} md={12}>
                                        <TextField
                                            id="certificated_in"
                                            name="certificated_in"
                                            label="Qualified Programme"
                                            variant="outlined"
                                            value={staff.certificated_in}
                                            onChange={e => this.handleChange(e.target.name,e.target.value)}
                                        />
                                        <TextField
                                            select
                                            id="job_entry_req"
                                            name="job_entry_req"
                                            label="Entry Requirement"
                                            value={staff.job_entry_req}
                                            onChange={e => this.handleChange(e.target.name,e.target.value)}
                                            variant="outlined"
                                        >
                                            {this.state.entry_levels.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <TextField
                                            select
                                            id="teaching_field"
                                            name="teaching_field"
                                            label="Main Teaching Field"
                                            value={staff.teaching_field}
                                            onChange={e => this.handleChange(e.target.name,e.target.value)}
                                            variant="outlined"
                                        >
                                            {_fields.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <TextField
                                            select
                                            id="teaching_level"
                                            name="teaching_level"
                                            label="Main Teaching Level"
                                            value={staff.teaching_level}
                                            onChange={e => this.handleChange(e.target.name,e.target.value)}
                                            variant="outlined"
                                        >
                                            {_teaching_levels.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>

                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="job_title"
                                        name="job_title"
                                        label="Job Title"
                                        value={staff.job_title}
                                        onChange={e => this.handleChange(e.target.name,e.target.value)}
                                        variant="outlined"
                                    />
                                    <TextField
                                        select
                                        id="emp_type"
                                        name="emp_type"
                                        label="Employment Type"
                                        value={staff.emp_type}
                                        onChange={e => this.handleChange(e.target.name,e.target.value)}
                                        variant="outlined"
                                    >
                                        {this.state.emp_types.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {(localStorage.getItem("staff_id")) ?
                                        null
                                        :
                                        <>
                                            <FormControl className={clsx(classes.margin, classes.textField)}
                                                         variant="outlined">
                                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                                <OutlinedInput
                                                    id="password"
                                                    type={this.state.showPassword ? 'text' : 'password'}
                                                    value={staff.password}
                                                    name="password"
                                                    onChange={e => this.handleChange(e.target.name,e.target.value)}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={this.handleClickShowPassword}
                                                                onMouseDown={e => this.handleMouseDownPassword(e)}
                                                                edge="end"
                                                            >
                                                                {this.state.showPassword ? <Visibility/> : <VisibilityOff/>}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    labelWidth={70}
                                                />
                                            </FormControl>
                                            <FormControl className={clsx(classes.margin, classes.textField)}
                                                         variant="outlined">
                                                <InputLabel htmlFor="outlined-adornment-password">Confirm
                                                    Password</InputLabel>
                                                <OutlinedInput
                                                    id="dup_password"
                                                    type={this.state.showConfirmPassword ? 'text' : 'password'}
                                                    value={staff.dup_password}
                                                    name="dup_password"
                                                    onChange={e => this.handleChange(e.target.name,e.target.value)}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={this.handleClickShowConfirmPassword}
                                                                onMouseDown={e => this.handleMouseDownPassword(e)}
                                                                edge="end"
                                                            >
                                                                {this.state.showConfirmPassword ? <Visibility/> :
                                                                    <VisibilityOff/>}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    labelWidth={70}
                                                />
                                            </FormControl>
                                        </>

                                    }
                                </Grid>




                            </Grid>
                        </form>
                    </div>
                    <Button variant="contained" color="secondary" onClick={this.goBack} style={{margin: 5}}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={this.saveData} style={{margin: 5}}>
                        Save
                    </Button>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(AddStaff);


