import React from 'react';
import MyButton from "../Buttons/Button";
import Table from 'material-table';
import StaffService from "../../services/staffService";
import Utils from "../../utils/utils";
import ProgressBar from "../ProgressBar/ProgressBar";

class Staff extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();

        this.state = {
            header: [
                {title: 'Staff ID', field: 'user_id'},
                { title: 'Picture', field: 'image', render: item => <img src={item.image} alt="" border="3" height="50" width="50" />},
                {title: 'First Name', field: 'first_name'},
                {title: 'Middle Name', field: 'middle_name'},
                {title: 'Last Name', field: 'last_name'},
                {title: 'Gender', field: 'gender'},
                {title: 'Nationality', field: 'nationality'},
                {title: 'Phone', field: 'phone_number'},
                {title: 'Email', field: 'email'}
            ],
            staff: [],
            displayLoader: false
        };
    }

    /*
    * Method to delete a staff
    * Parameters:
    * + rowData: The staff to delete
    * */
    deleteStaff = (event, rowData) => {
        let text = 'Staff will be deleted, do you want to continue?';
        Utils.confirmDeleteMessage(text)
            .then((willDelete) => {
                if (willDelete) {
                    StaffService.deleteStaff(rowData._id.$oid, response => {
                        if (response.error) {
                            Utils.displayMessage('error', 'Failed', response.errors[0]).then(r => r);
                        } else {
                            Utils.displayMessage('success', 'Successful', response.message).then(r => r);
                            this.refreshTable();
                        }
                    });
                }
            });
    };

    /*
    * Method to view the details of a staff
    * Parameters:
    * + rowData: The staff to view details for
    * */
    rowSelect = (event, rowData) => {
        // if (rowData.status === 'inactive') {
        //     Utils.displayMessage('error', 'Failed', "Staff's employment is terminated");
        // } else {
            this.props.history.push(`${this.props.match.path}/${rowData._id.$oid}`);
        // }
    };

    // Method to refresh data of the table
    refreshTable = () => {
        this.tableRef && this.tableRef.current.onQueryChange();
    };

    componentDidMount() {
        this.setState({
            displayLoader: true
        });
    };

    render() {
        return (
            <div>
                {
                    this.state.displayLoader ? <div style={{marginBottom: 15}}><ProgressBar/></div> : null
                }
                <div style={{marginBottom: 10}}>
                    <MyButton to={{pathname: "/new-staff", fromStaff: true}}
                              color={"primary"}
                              title={"Add Staff"}
                    />
                </div>
                <div>
                    <Table
                        tableRef={this.tableRef}
                        title="Staff"
                        columns={this.state.header}
                        data={query => new Promise((resolve, reject) => {
                            StaffService.loadData(query, resolve, reject, null,() =>{
                                this.setState({
                                    displayLoader: false
                                });
                            });
                        })}
                        onRowClick={this.rowSelect}
                        options={{
                            exportButton: true, filtering: true,
                            grouping: true, sorting: true,
                            debounceInterval: 1000,
                            selection: false, showTitle: true,
                            pageSize: 10, actionsColumnIndex: -1,
                            rowStyle: rowData => ({
                                backgroundColor: (rowData.status === 'inactive') ? '#DD0000' : '#FFF',
                                color: (rowData.status === 'inactive') ? '#FFF' : '#000',
                            })
                        }}
                        localization={{
                            header: {
                                actions: ""
                            }
                        }}
                        actions={[
                            {
                                icon: 'refresh', tooltip: 'Refresh', isFreeAction: true,
                                onClick: () => this.refreshTable(),
                            }
                        ]}
                    />
                </div>
            </div>
        );
    }
}

export default Staff;


