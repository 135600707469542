import $ from "jquery";
import Utils from "../utils/utils";

import {Api} from "../config/api_config";
import * as cookies from "react-cookies";

export default class StudentService {

    /*
  * Method to create/update a student record
  * Parameters:
  * + data: The data of the student to created or updated
  * + callback: Method to handle the data returned from the server
  * */
    static createStudent(data, callback) {
        const id = (data._id) ? data._id.$oid : '';
        const _method = (id) ? "PUT" : "POST";

        $.ajax({
            method: _method,
            url: Api.DEV_BASE_URL + "/v1/students/" + id,
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: data,
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Student could not be saved');
            }
        })
    }

    static updatePicture(data, callback) {
        $.ajax({
            method: "PUT",
            url: Api.DEV_BASE_URL + "/v1/students/upload_picture",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {student_id: data.studentId, picture: data.picture},
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Cannot save student picture').then(r => r);
            }
        })
    };

    static LoadImage(id, callback) {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/v1/students/get_image",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {student_id: id},
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'student image could not be loaded').then(r => r);
            }
        })
    };

    /*
    * Method to load students for select list
    * Parameters:
    * + callback: The method to handle the response coming from the server
    * */
    static loadStudents = (callback) => {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/v1/students",
            data: {
                "attrs": ["id", "first_name", "middle_name", "last_name", "email", "nationality", "ethnicity", "phone_number", "gender",
                    "dob", "global_id", "student_number", "status", "source_of_funding"],
                "order": [{"by": "desc", "attr": "name"}], "model": "Students::Student"
            },
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Students could not be loaded').then(r => r)
            }
        });
    };

    // Method to sync students
    static sync = () => {
        $.ajax({
            method: 'POST',
            url: Api.DEV_BASE_URL + '/v1/students/sync',
            dataTYpe: 'JSON',
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            success: (res) => {
                console.log('Successful sync', res);
            },
            error: function (error) {
                console.log('Error syncing', error);
            }
        })
    };

    /*
    * Method to load all students of a learning center into a data table
    * Parameters:
    * + query: The query sent by the data table
    * + resolve: callback method to process the received list of courses
    * + callback: The method to handle the response coming from the server
    * */
    static loadStudentsData = (query, resolve, reject, callback) => {
        let url = Utils.compileQuery(query, (Api.DEV_BASE_URL + "/v1/students"),
            ["id", "first_name", "middle_name", "global_id", "last_name", "email", "nationality", "ethnicity", "phone_number", "gender",
                "dob", "global_id","lga", "student_number", "status", "source_of_funding","image"], [{
                "by": "desc",
                "attr": "first_name"
            }],
            "Students::Student", null, null, null);
        fetch(url, {
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            }
        })
            .then(response => {
                return response.json()
            })
            .then(result => {
                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total
                });
                callback();
            });
    };

    /*
    * Method to get the details of a student
    * Parameters:
    * + id: The id of the student to be loaded
    * + callback: Method to handle the data returned from the server
    * */
    static loadStudentId = (id, callback) => {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/v1/students/" + id,
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Student could not be loaded').then(r => r)
            }
        });
    };

    /*
    * Method to delete a student
    * Parameters:
    * + id: The id of the student to be deleted
    * + callback: Method to handle the data returned from the server
    * */
    static deleteStudent = (id, callback) => {
        $.ajax({
            method: "DELETE",
            url: Api.DEV_BASE_URL + "/v1/students/" + id,
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            success: function (result) {
                callback(result)
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Student not be deleted').then(r => r);
            }
        });
    };

    /*
    * Method to close a student's admission
    * Parameters:
    * + data: The student and admission data for closing admission
    * + callback: Method to handle the data returned from the server
    * */
    static closeAdmission = (data, callback) =>{
        $.ajax({
            method: "PUT",
            url: Api.DEV_BASE_URL+"/v1/students/close_admission",
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"), "UserKey":cookies.load('User-Key')},
            data: data,
            success: function(result) {
                callback(result);
            },
            error: function(e){
                Utils.displayMessage('error', 'Failed', 'Student Admission could not be close').then(r => r);
            }
        });
    };

    /*
    * Method to add courses to a student's enrollment
    * Parameters:
    * + data: The courses to be added to the student's enrollment
    * + studentId: The id of the student
    * + callback: Method to handle the data returned from the server
    * */
    static enrollStudent = (data, studentId, callback) => {
        $.ajax({
            method: "POST",
            url: Api.DEV_BASE_URL + "/v1/students/add_course_to_enrollment",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {enrolled_course: data, studentId: studentId},
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Student could not be enrolled').then(r => r);
            }
        })
    };

    /*
    * Method enroll a student into a term/semester
    * Parameters:
    * + data: The enrollment data for the student
    * + callback: Method to handle the data returned from the server
    * */
    static newEnrollStudent = (data, callback) => {
        $.ajax({
            method: "POST",
            url: Api.DEV_BASE_URL + "/v1/students/enroll_student",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: data,
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Student could not be enrolled').then(r => r);
            }
        })
    };

    /*
    * Method to create an admission for a student
    * Parameters:
    * + data: The admission data and student id to create an admission for
    * + callback: Method to handle the data returned from the server
    * */
    static createAdmission = (data, callback) => {
        let method = (data._id) ? "PUT" : "POST";
        let id = (data._id) ? data._id.$oid : '';
        $.ajax({
            method: method,
            url: Api.DEV_BASE_URL + "/v1/admissions/" + id,
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: data,
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Student admission failed').then(r => r);
            }
        })
    };

    /*
    * Method to get the list of courses for a students enrollment
    * Parameters:
    * + student_id: The id of the student
    * + enrollment_id: The id of the enrollment
    * + callback: Method to handle the data returned from the server
    * */
    static getCourses = (student_id, enrollment_id, callback) => {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/v1/students/get_courses",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {student_id: student_id, enrollment_id: enrollment_id},
            success: function (result) {
                callback(result.courses);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Student courses could not be loaded')
            }
        });
    };

    /*
    * Method to apply a charge to a student
    * Parameters:
    * + data: The charge data and student id to create a bill for the student
    * + callback: Method to handle the data returned from the server
    * */
    static applyCharge = (data, callback) => {
        $.ajax({
            method: "POST",
            url: Api.DEV_BASE_URL + "/v1/students/apply_charge",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: data,
            success: function (result) {
                Utils.displayMessage('success', 'Successful', "Charge successfully applied for student");
                callback();
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Charges could not be saved for the student')
            }
        });
    };

    /*
    * Method to get the details of a charge definition
    * Parameters:
    * + studentId: The id of the student
    * + programId: The id of the student's programme
    * + callback: Method to handle the data returned from the server
    * */
    static getChargesDetails = (studentId, programId, callback) => {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/v1/charge_definition/get_charge_definitions",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {student_id: studentId, program_id: programId},
            success: function (result) {
                if(result.errors)
                    Utils.displayMessage('error', 'Failed', result.errors[0])
                else
                    callback(result.data);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Student courses could not be loaded')
            }
        });
    };

    /*
    * Method to get all the charges applied to a student
    * Parameters:
    * + studentId: The id of the student to load charges for
    * + callback: Method to handle the data returned from the server
    * */
    static getBills = (studentId, callback) => {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/v1/students/get_charges",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {student_id: studentId},
            success: function (result) {
                callback(result.data);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Student bills could not be loaded')
            }
        });
    };

    /*
    * Method to apply a payment for a student
    * Parameters:
    * + data: The payment data for the student
    * + callback: Method to handle the data returned from the server
    * */
    static applyPayment = (data, callback) => {
        $.ajax({
            method: "POST",
            url: Api.DEV_BASE_URL + "/v1/students/apply_payment",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: data,
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Payment could not be saved for the student')
            }
        });
    };

    /*
    * Method to get the list of payments made by a student
    * Parameters:
    * + student_id: The id of the student
    * + charge_id: The id of the charge to get payments for
    * + callback: Method to handle the data returned from the server
    * */
    static getPayments = (student_id, charge_id, callback) => {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/v1/students/get_payments",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {student_id: student_id, charge_id: charge_id},
            success: function (result) {
                callback(result.payments);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Student payments could not be loaded')
            }
        });
    };

    /*
    * Method to delete a charge applied for a student
    * Parameters:
    * + charge_id: The id of the charge to be deleted
    * + callback: Method to handle the data returned from the server
    * */
    static deleteCharge = (charge_id, callback) => {
        $.ajax({
            method: "DELETE",
            url: Api.DEV_BASE_URL + "/v1/students/delete_charge",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {charge_id: charge_id},
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Charge could not be deleted for the student')
            }
        });
    };

    /*
    * Method to delete a payment applied for a student
    * Parameters:
    * + payment_id: The id of the payment to be deleted
    * + callback: Method to handle the data returned from the server
    * */
    static deletePayment = (payment_id, callback) => {
        $.ajax({
            method: "DELETE",
            url: Api.DEV_BASE_URL + "/v1/students/delete_payment",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {payment_id: payment_id},
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Charge could not be deleted for the student')
            }
        });
    };

    /*
    * Method to delete an admission for a student
    * Parameters:
    * + id: The id of the admission to be deleted
    * + callback: Method to handle the data returned from the server
    * */
    static deleteAdmission = (id, callback) => {
        $.ajax({
            method: "DELETE",
            url: Api.DEV_BASE_URL + "/v1/admissions/" + id,
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Charge could not be deleted for the student')
            }
        });
    };

    /*
    * Method to get all the admissions for a student
    * Parameters:
    * + studentId: The id of the student
    * + callback: Method to handle the data returned from the server
    * */
    static getAllAdmissions = (studentId, callback) => {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/v1/students/get_admissions",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {student_id: studentId},
            success: function (result) {
                callback(result.data);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Student admissions could not be loaded')
            }
        });
    };

    /*
    * Method to delete a student's enrollment
    * Parameters:
    * + data: student's and enrollment's id
    * + callback: Method to handle the data returned from the server
    * */
    static deleteEnrollment = (data, callback) => {
        $.ajax({
            method: "DELETE",
            url: Api.DEV_BASE_URL + "/v1/students/delete_enrollment",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {
                student_id: data.student_id,
                enroll_id: data.enroll_id,
            },
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Enrollment could not be deleted')
            }
        });
    };

    /*
    * Method to close a student's enrollment
    * Parameters:
    * + data: student's and enrollment's id
    * + callback: Method to handle the data returned from the server
    * */
    static closeEnrollment = (data, callback) => {
        $.ajax({
            method: "PUT",
            url: Api.DEV_BASE_URL + "/v1/students/close_enrollment",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {
                student_id: data.student_id,
                enroll_id: data.enroll_id,
                close_data: data.close_data,
            },
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Enrollment could not be closed')
            }
        });
    };

    /*
    * Method to delete a student's term result
    * Parameters:
    * + resultId: The id of the term result
    * + callback: Method to handle the data returned from the server
    * */
    static deleteTermResult = (resultId, callback) => {
        $.ajax({
            method: "DELETE",
            url: Api.DEV_BASE_URL + "/v1/students/delete_term_result",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {result_id: resultId},
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', "Student's course could not be deleted").then(r => r)
            }
        });
    };

    /*
    * Method to add an assessment for a student
    * Parameters:
    * + data: The data of the new assessment to be added for the student
    * + callback: Method to handle the data returned from the server
    * */
    static newAssessment = (data, callback) => {
        $.ajax({
            method: "POST",
            url: Api.DEV_BASE_URL + "/v1/students/add_assessment_for_student",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: data,
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', "Assessment could not be added for the student").then(r => r);
            }
        });
    };

}
