import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import {Card, Grid} from '@material-ui/core';
import Utils from "../../utils/utils";
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import clsx from "clsx";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import MenuItem from "@material-ui/core/MenuItem";
import {GoogleReCaptcha, GoogleReCaptchaProvider} from "react-google-recaptcha-v3";


const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        width: '25ch',
    },
});

const recaptchaRef = React.createRef();

class AddStaff extends React.Component {
    state = {
        newStaff: {
            user_id: '',
            first_name: '',
            middle_name: '',
            last_name: '',
            phone_number: '',
            email: '',
            password: '',
            dup_password: '',
            user_type: {},
            role: {},
            recapterValues: ''
        },
        recapterValue: '',
        showPassword: false,
        showConfirmPassword: false,
        depts: [],
        fields: [],
        isEnabled: false

    };

    countryList = Utils.countries.map((option) => option.label);

    // Method to update the values of the text fields
    handleChange = (name, value) => {
        if (name === 'phone_number' && Utils.isValid(value)) {
            Utils.displayMessage("warning", "", "Only numbers allowed");
            return;
        }
        this.props.updateData(name, value);
    };

    // Method to clear values of the text fields
    clearForm = () => {
        let _staffData = this.state.newStaff;
        _staffData.user_id = '';
        _staffData.first_name = '';
        _staffData.middle_name = '';
        _staffData.last_name = '';
        _staffData.gender = '';
        _staffData.ethnicity = '';
        _staffData.nationality = '';
        _staffData.phone_number = '';
        _staffData.dob = Utils.getToday();
        _staffData.email = '';
        _staffData.password = '';
        _staffData.dup_password = '';
        _staffData.showConfirmPassword = '';
        _staffData.recapterValue = '';
        _staffData.departmentId = {};
        this.setState({
            newStaff: _staffData
        });
    };

    // Method validate form fields before sending the data to the server
    validateFields = () => {
        const {
            user_id, first_name, middle_name, last_name, gender,
            ethnicity, nationality, phone_number, email, password, recapterValue
        } = this.state.newStaff;
        if (recapterValue === '') return 'recapterValue';
        if (user_id === '') return 'user_id';
        if (first_name === '' || !Utils.validateText(first_name)) return 'first_name';
        if (middle_name !== '' && !Utils.validateText(middle_name)) return 'middle_name';
        if (last_name === '' || !Utils.validateText(last_name)) return 'last_name';
        if (gender !== '' && !Utils.validateText(gender)) return 'gender';
        if (ethnicity !== '' && !Utils.validateText(ethnicity)) return 'ethnicity';
        if (nationality !== '' && !Utils.validateText(nationality)) return 'nationality';
        if (phone_number !== '' && !Utils.validatePhoneNumber(phone_number)) return 'phone_number';
        if (email === '' || (email !== '' && !Utils.validateEmail(email))) return 'email';
        if (password === '') return 'password';
    };

    /*
    * Method to set focus on the text fields with invalid data
    * Parameters:
    * + field_id: The id of the field to set the focus on
    * */
    setFocus = (field_id) => {
        Utils.displayMessage('error', 'Error', 'Please specify ' + field_id).then(r => r);
        document.getElementById(field_id).focus();
    };

    // Initializes the select field values of the form
    initialiseSelectFields = () => {
        let user = {...this.state.newStaff};
        user.gender = Utils.genders[0].value;
        user.ethnicity = Utils.ethnics[0].value;
        user.emp_type = Utils.emp_types[0].value;
        user.user_type = Utils.roles[1].value;
        user.recapterValues = this.state.recapterValue;
        this.setState({
            newStaff: user
        })
    };

    // Method to update the value of the recaptcha
    onChange = (value) => {
        this.props.updateRecaptcha(value);
    };

    componentDidMount() {
        this.initialiseSelectFields();
    };

    render() {
        const {classes} = this.props;
        let user = this.props.adminData;

        return (
            <div>
                <Card>
                    <div style={{textAlign: 'center', marginLeft: 150}}>
                        <form className={classes.root} noValidate={false} autoComplete='off'>
                            <h2>Create Admin</h2>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        required
                                        id="user_id"
                                        name="user_id"
                                        label="Staff ID"
                                        variant="outlined"
                                        value={user.user_id}
                                        onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                    />
                                    <TextField
                                        required
                                        id="first_name"
                                        name="first_name"
                                        label="First Name"
                                        variant="outlined"
                                        value={user.first_name}
                                        onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                    />
                                    <TextField
                                        id="middle_name"
                                        name="middle_name"
                                        label="Middle Name"
                                        variant="outlined"
                                        value={user.middle_name}
                                        onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                    />
                                    <TextField
                                        required
                                        id="last_name"
                                        name="last_name"
                                        label="Last Name"
                                        variant="outlined"
                                        value={user.last_name}
                                        onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        required
                                        id="gender"
                                        name="gender"
                                        select
                                        label="Gender"
                                        value={user.gender}
                                        onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {Utils.genders.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        id="phone"
                                        name="phone_number"
                                        label="Phone Number"
                                        variant="outlined"
                                        value={user.phone_number}
                                        onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                    />
                                    <TextField
                                        required
                                        id="email"
                                        name="email"
                                        label="Email"
                                        variant="outlined"
                                        value={user.email}
                                        onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                    />
                                    <Autocomplete
                                        id="nationality"
                                        name="nationality"
                                        disableClearable
                                        options={this.countryList.sort()}
                                        value={user.nationality}
                                        onChange={(event, value) => this.handleChange("nationality", value)}
                                        style={{display: 'inline'}}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                value={user.nationality}
                                                label="Nationality"
                                                variant="outlined"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                    value: user.nationality,
                                                    id: "nationality"
                                                }}
                                            />
                                        )}

                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <FormControl className={clsx(classes.margin, classes.textField)} required
                                                 variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                        <OutlinedInput
                                            id="password"
                                            type={'password'}
                                            value={user.password}
                                            name="password"
                                            onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                            labelWidth={70}
                                        />
                                    </FormControl>
                                    <FormControl className={clsx(classes.margin, classes.textField)} required
                                                 variant="outlined">
                                        <InputLabel htmlFor="outlined-adornment-password">Confirm
                                            Password</InputLabel>
                                        <OutlinedInput
                                            id="dup_password"
                                            type={'password'}
                                            value={user.dup_password}
                                            name="dup_password"
                                            onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                            labelWidth={130}
                                        />
                                    </FormControl>
                                    <div>

                                        <GoogleReCaptchaProvider
                                            reCaptchaKey="6Ldj9VgaAAAAAAZykan5R_cXJaukHDuT-FNL7Nbs">
                                            <GoogleReCaptcha
                                                id="recapterValue"
                                                name='recapterValue'
                                                ref={recaptchaRef}
                                                onVerify={this.onChange}/>
                                        </GoogleReCaptchaProvider>
                                    </div>

                                </Grid>
                            </Grid>
                        </form>
                    </div>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(AddStaff);


