import React, {useEffect} from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Header/Header';

import adminRoutes from '../../../routes/admin';
import useStyles from './appLayoutStyles';
import Container from "@material-ui/core/Container";
import {Route, Switch} from "react-router-dom";
import AddStudent from "../../Students/AddStudent";
import StudentDetails from "../../Students/StudentDetails";
import AddProgram from "../../Programs/AddProgram";
import ProgramDetails from "../../Programs/ProgramDetails";
import AddDepartment from "../../Departments/AddDepartment";
import AddScholarship from "../../Scholarships/AddScholarship";
import AddStaff from "../../Staff/AddStaff";
import StaffDetails from "../../Staff/StaffDetails";
import AddCourse from "../../Courses/AddCourse";
import AddTerm from "../../School_Term/AddTerm";
import AddAdmission from "../../Students/AddAdmission";
import AssignCourse from "../../Staff/AssignCourse";
import AssignHOD from "../../Staff/AssignHOD";
import TeacherCourseDetails from "../../Courses/TeacherCourseDetails";
import InstitutionDetails from "../../Settings/Institution_Details";
import AddTerms from "../../Settings/AddTerms";
import Grading from "../../Settings/Grading";
import InstitutionCourseDetails from "../../Courses/InstitutionCourseDetails";
import AssignTeacher from "../../Courses/AssignTeacher";
import AddCourseForStudent from "../../Courses/AddCourseForStudent";
import AddAssessment from "../../Courses/AddAssessment";
import EnterScores from "../../Courses/EnterScores";
import teacherRoutes from "../../../routes/teacher";
import * as cookies from "react-cookies";
import TakeAttendance from "../../Courses/TakeAttendance";
import AddCharge from "../../Students/AddCharge";
import ReportView from "../../Reports/ReportView";
import Reports from "../../Reports/Reports";
import EnrollmentDetails from "../../Students/EnrollmentDetails";
import DepartmentDetails from "../../Departments/Departmentdetails";
import HomeProfile from '../../ViewProfile/Home';
import AwardingBody from "../../Scholarships/AwardingBody";
import AwardingBodyDetails from "../../Scholarships/AwardingBodyDetails";
import ChargeDetails from "../../Students/ChargeDetails";
import AddCourseToEnrollment from "../../Students/AddCourseToEnrollment";

const AppLayout = (props) => {
    const classes = useStyles();

    const [open, setOpen] = React.useState(true);
    const [user_type, setUserType] = React.useState('');

    useEffect(() => {
        if (!cookies.load('user_type') || !cookies.load('token')) {
            props.history.push('/login');
        }
    }, []);

    useEffect(() => {
        if (props.history.location.user) {
            setUserType(props.location.user.user_type);
        } else if (props.location.user === undefined) {
            setUserType(cookies.load('user_type'));
        }

    }, []);

    const determineRoute = (user_type) => {
        if (cookies.load('user_type') === "Administrative") return adminRoutes;
        else if (cookies.load('user_type') === "Academic") return teacherRoutes;
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const isLoggedIn = () => {
        if (cookies.load('user_type') === "Administrative" || cookies.load('user_type') === "Academic") {
            return true;
        }
        return false;
    };

    return (
        <div className={classes.root}>

            {
                isLoggedIn() ?
                    <>

                        <CssBaseline/>

                        <Header handleDrawerOpen={handleDrawerOpen} open={open}/>

                        <Sidebar handleDrawerClose={handleDrawerClose} open={open} routes={determineRoute()}/>

                        <main className={classes.content}>
                            <div className={classes.appBarSpacer}/>
                            <Container maxWidth="lg" className={classes.container}>
                                <ReportView/>
                                <Switch>
                                    {
                                        determineRoute().map((route, key) => {
                                            return <Route exact path={route.path} component={route.component}
                                                          key={key}/>;
                                        })
                                    }
                                    <Route exact path="/new-student" component={AddStudent}/>
                                    <Route exact path="/students/:studentId/edit-student"
                                           render={(props) => <AddStudent {...props} />}/>
                                    <Route exact path="/students/:studentId/new-scholarship"
                                           render={(props) => <AddScholarship {...props} />}/>
                                    <Route exact path="/students/:studentId/add-course"
                                           render={(props) => <AddCourseToEnrollment {...props} />}/>

                                    <Route exact path="/students/:studentId/edit-admission"
                                           render={(props) => <AddAdmission {...props} />}/>
                                    <Route exact path="/students/:studentId/admission"
                                           render={(props) => <AddAdmission {...props} />}/>
                                    <Route exact path="/students/:studentId/apply-charge"
                                           render={(props) => <AddCharge {...props} />}/>
                                    <Route exact path="/students/:studentId/view-enrollment"
                                           render={(props) => <EnrollmentDetails {...props} />}/>
                                    <Route exact path="/students/:studentId/charge-payments"
                                           render={(props) => <ChargeDetails {...props} />}/>

                                    <Route exact path="/students/:studentId" component={StudentDetails}/>
                                    <Route exact path="/students/:studentId/reports" component={Reports}/>
                                    <Route exact path="/new-program" component={AddProgram}/>
                                    <Route exact path="/programs/:programId" component={ProgramDetails}/>
                                    <Route exact path="/programs/:programId/edit-program"
                                           render={(props) => <AddProgram {...props} />}/>
                                    <Route exact path="/programs/:programId/new-course"
                                           render={(props) => <AddCourse {...props} />}/>
                                    <Route exact path="/new-department" component={AddDepartment}/>
                                    <Route exact path="/departments/:departmentID" component={DepartmentDetails}/>
                                    <Route exact path="/departments/:departmentID/edit-department"
                                           render={(props) => <AddDepartment {...props} />}/>

                                    {/*<Route exact path="/new-scholarship" component={AddScholarship}/>*/}
                                    <Route exact path="/awarding-body" component={AwardingBody}/>
                                    <Route exact path="/scholarships/:awarding_body_id"
                                           component={AwardingBodyDetails}/>
                                    <Route exact path="/scholarships/:scholarshipId/new-scholarship"
                                           component={AddScholarship}/>
                                    <Route exact path="/scholarships/:scholarshipId/edit-scholarship"
                                           render={(props) => <AddScholarship {...props} />}/>
                                    <Route exact path="/new-staff" component={AddStaff}/>
                                    <Route exact path="/staff/:staffId" component={StaffDetails}/>
                                    <Route exact path="/staff/:staffId/edit-staff"
                                           render={(props) => <AddStaff {...props} />}/>
                                    <Route exact path="/staff/:staffId/assign-course"
                                           render={(props) => <AssignCourse {...props} />}/>
                                    <Route exact path="/staff/:staffId/assign-hod"
                                           render={(props) => <AssignHOD {...props} />}/>

                                    <Route exact path="/terms/open-term" component={AddTerm}/>
                                    <Route exact path="/terms/new-terms" render={(props) => <AddTerms {...props} />}/>

                                    <Route exact path="/courses/:courseId/:setName"
                                           render={(props) => <TeacherCourseDetails {...props} />}/>
                                    <Route exact path="/courses/:courseId/add-assessment"
                                           render={(props) => <AddAssessment {...props} />}/>
                                    <Route exact path="/courses/:courseId/:setName/add-scores"
                                           render={(props) => <EnterScores {...props} />}/>
                                    <Route exact path="/courses/:courseId/:setName/take-attendance"
                                           render={(props) => <TakeAttendance {...props} />}/>
                                    <Route exact path="/all-courses/:courseId/assign-teacher"
                                           render={(props) => <AssignTeacher {...props} />}/>
                                    <Route exact path="/all-courses/:courseId/add-students"
                                           render={(props) => <AddCourseForStudent {...props} />}/>
                                    <Route exact path="/all-courses/new-course" component={AddCourse}/>
                                    <Route exact path="/new-course" component={AddCourse}/>

                                    <Route exact path="/all-courses/:courseId/edit-course"
                                           render={(props) => <AddCourse {...props} />}/>

                                    <Route exact path="/all-courses/:courseId"
                                           render={(props) => <InstitutionCourseDetails {...props} />}/>
                                    <Route exact path="/all-courses/:courseId/add-assessment"
                                           render={(props) => <AddAssessment {...props} />}/>
                                    <Route exact path="/settings/details"
                                           render={(props) => <InstitutionDetails {...props} />}/>
                                    <Route exact path="/settings/gradings" render={(props) => <Grading {...props} />}/>

                                    <Route exact path="/profile" component={HomeProfile}/>

                                </Switch>
                            </Container>
                        </main>
                    </>
                    : props.history.push('/login')
            }
        </div>
    );
};

export default AppLayout;
