import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AccessibilityIcon from '@material-ui/icons/Accessibility';

const useStyles = makeStyles({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 140,
    },
});

export default function MediaCard(props) {
    const classes = useStyles();
    const IcontType = props.icon;

    return (
        <Card className={classes.root}>
            <CardActionArea>
                <IcontType color={props.color} fontSize='large' />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {`${props.title} ${props.count}`}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
