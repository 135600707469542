import React from 'react';
import Utils from "../../utils/utils";
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import ProgramService from "../../services/programService";
import SettingsService from "../../services/settingsService";
import Table from "material-table";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
});

class SchoolLab extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();
        this.state = {
            lab: {
                name: '',
                status: '',
                internet_band_width: '',
                departmentId: {},
            },
            header: [
                {title: 'Name', field: 'name'},
                {title: 'Status', field: 'status'},
                {title: 'Department', field: 'department_name'},
                {title: 'Internet Bandwidth', field: 'internet_band_width'},
            ],
            editLabData: false,
            departments: [],
            addData: false
        };
    };

    // Method to update the form field values of a new school laboratory
    handleChangeSchoolLab = (e) => {
        let _lab = {...this.state.lab};

        let val = e.target.value;
        if (e.target.name === 'number_of_equipment' && Utils.isValid(val)) {
            Utils.displayMessage("warning", "", "Only numbers allowed");
            return;
        }
        _lab[e.target.name] = val;
        this.setState({lab: _lab});
    };

    // Method to clear the values of the form fields
    clearSchoolLabForm = () => {
        let _lab = {...this.state.lab};
        _lab.name = '';
        _lab.status = '';
        _lab.internet_band_width = '';
        _lab.departmentId = '';

        if (_lab.editing === true) {
            _lab._id = null;
            _lab.editing = false;
        }

        this.setState({lab: _lab, editLabData: false});
    };

    // Methodt to refresh the data table
    refreshTable = () => {this.tableRef.current.onQueryChange()};

    // Method to valid the school lab fields before posting to the server
    validateFields = () => {
        const {name} = this.state.lab;
        if (name === '' || !Utils.validateText(name)) return 'name';
    };

    // Method to initialize the select fields of the form
    initializeFields = () => {
        let _lab = {...this.state.lab};
        _lab.status = Utils.lab_status[0].value;
        this.setState({lab: _lab});
    };

    /*
    * Method to set focus on the text fields with invalid data
    * Parameters:
    * + field_id: The id of the field to set the focus on
    * */
    setFocus = (field_id) => {
        Utils.displayMessage('error', 'Error', 'Please specify a valid ' + field_id).then(r => r);
        document.getElementById(field_id).focus();
    };

    // Method to send and save school lab data to the server
    saveSchoolLabData = () => {
        let result = this.validateFields();
        if (result) {
            this.setFocus(result);
            return;
        }
        let l = this.state.lab;
        let lab = {_id: l._id, department_id: l.departmentId, status: l.status, name: l.name, internet_band_width: l.internet_band_width};
        SettingsService.createLaboratory(lab, result => {
            if (result.error) {
                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
            } else {
                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                this.clearSchoolLabForm();
            }
        });
    };

    /*
    * Method to delete a school lab
    * Parameters:
    * + rowData: The school lab to delete
    * */
    deleteLab = (event, rowData) => {
        let text = `Laboratory will be deleted, do you want to continue?`;
        Utils.confirmDeleteMessage(text)
            .then((willDelete) => {
                if (willDelete) {
                    SettingsService.deleteLab(rowData._id.$oid, result => {
                        if (result.error) {
                            Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
                        } else {
                            Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                            this.refreshTable();
                        }
                    });
                }
            });
    };

    /*
    * Method to open form to edit a school lab
    * Parameters:
    * + rowData: The school lab to edited
    * */
    editSchoolLab = (evt, rowData) => {
        rowData.editing = true;
        if(rowData.department_id){
            rowData.departmentId =  (rowData.department_id.$oid) ?  rowData.department_id.$oid :  rowData.department_id;
        }else{
            rowData.departmentId = rowData.department_name;
        }
        this.setState({
            lab: {...rowData},
            editLabData: true,
            addData: true
        });
    };

    // Method to cancel adding or editing a school lab.
    cancel = () => {
        let lab = {...this.state.lab};

        if (lab._id) {
            lab._id = null;
        }
        lab.name = '';
        lab.status = '';
        lab.internet_band_width = '';
        lab.editing = false;
        lab.departmentId = {};

        this.setState({
            lab: lab,
            editLabData: false,
            addData: false
        });
    };

    // Method to update select fields for the department
    loadDepartments = (results) => {
        let _departments = [];
        if (results.depts && results.fields) {
            results.depts.map(dept => _departments.push({label: dept.name, value: dept._id.$oid}));
            _departments.push({label: 'All', value: 'All'});
            let _lab = {...this.state.lab};
            _lab.departmentId = _departments[0].value;
            this.setState({
                departments: _departments,
                lab: _lab
            })
        }
    };

    componentDidMount() {
        ProgramService.loadDeptsNationalNames(this.loadDepartments);
        this.initializeFields();
    }

    render() {
        const {classes} = this.props;
        const _lab = this.state.lab;
        const departments = this.state.departments;
        return (
            <div>
                {(this.state.addData === true) ?
                <div style={{marginTop: 15}}>
                    <Card>
                        <div>
                            <div style={{marginLeft: 100}}>
                                <form className={classes.root} autoComplete="on">
                                    <h4>New Laboratory</h4>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <TextField
                                                required
                                                id="name"
                                                name="name"
                                                label="Name"
                                                variant="outlined"
                                                helperText="Please specify school lab name"
                                                value={_lab.name}
                                                onChange={e => this.handleChangeSchoolLab(e)}
                                            />
                                            <TextField
                                                required
                                                id="status"
                                                name="status"
                                                select
                                                label="Current Status"
                                                value={_lab.status}
                                                onChange={e => this.handleChangeSchoolLab(e)}
                                                variant="outlined"
                                                style={{
                                                    border: 0,
                                                    outline: 0
                                                }}
                                            >
                                                {Utils.lab_status.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <TextField
                                                id="departmentId"
                                                name="departmentId"
                                                select
                                                label="Department"
                                                value={_lab.departmentId}
                                                onChange={e => this.handleChangeSchoolLab(e)}
                                                helperText="Please select department"
                                                variant="outlined"
                                                style={{
                                                    border: 0,
                                                    outline: 0
                                                }}
                                            >
                                                {departments.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <TextField
                                                required
                                                id="internet_band_width"
                                                name="internet_band_width"
                                                label="Internet Bandwidth(Mbps)"
                                                variant="outlined"
                                                helperText="Please specify internet bandwidth"
                                                value={_lab.internet_band_width}
                                                onChange={e => this.handleChangeSchoolLab(e)}
                                            />
                                        </Grid>
                                    </Grid>
                                </form>
                            </div>
                            <Button variant="contained" color="secondary" onClick={this.cancel} style={{margin: 5}}>
                                Close
                            </Button>
                            <Button variant="contained" color="primary" onClick={this.saveSchoolLabData}
                                    style={{margin: 5}}>
                                {this.state.editLabData ? "Update" : "Save"}
                            </Button>
                        </div>
                    </Card>
                </div> :
                <div>
                    <Button variant="contained" color="primary" onClick={() => {(this.state.departments.length === 1) ?
                        Utils.displayMessage('error', 'Failed', "No Departments found. Please add at least one department") : this.setState({addData: true})}}
                            style={{margin: 5}}>
                        {'Add Lab'}
                    </Button>

                    <Table
                        tableRef={this.tableRef}
                        title="School Labs"
                        columns={this.state.header}
                        data={query => new Promise((resolve, reject) => {
                            SettingsService.loadLabs(query, resolve, reject);
                        })}
                        onRowClick={this.rowSelect}
                        options={{
                            exportButton: true, filtering: false,
                            grouping: false, sorting: false,
                            debounceInterval: 1000, search: true,
                            selection: false, showTitle: true,
                            pageSize: 10, actionsColumnIndex: -1
                        }}

                        localization={{
                            header: {
                                actions: ""
                            }
                        }}

                        actions={[
                            {
                                icon: () => (
                                    <Button
                                        color="default"
                                        variant="contained"
                                        style={{textTransform: 'none'}}
                                        size="small"
                                    >
                                        EDIT
                                    </Button>
                                ),
                                tooltip: 'Edit Laboratory',
                                onClick: (event, rowData) => this.editSchoolLab(event, rowData)
                            },
                            rowData => ({
                                icon: () => (
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        style={{textTransform: 'none'}}
                                        size="small"
                                    >
                                        DELETE
                                    </Button>
                                ),
                                tooltip: 'Delete Lab',
                                onClick: (event, rowData) => this.deleteLab(event, rowData),
                            }),
                        ]}
                    />
                </div>}
            </div>
        );
    }
}

export default withStyles(styles)(SchoolLab);
