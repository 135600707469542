import React from 'react';
import BasicGrid from "../CustomComponents/BasicGrid";
import SyncService from "../../services/syncService";

class SyncIndex extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();

        this.state = {
            LearningCenter: {},
            Program: {},
            Student: {},
            User: {}
        };
    };

    // Method to load data models that have changed or been newly added for syncing
    loadChanges = () => {
        SyncService.loadAllChanges()
            .then((data) => {
                this.setState({...data})
            })
            .catch(error => console.log('Sync Data Error', error))
    };

    componentDidMount() {
        this.loadChanges();
    };

    render() {
        return (

            <div>
                <BasicGrid data={this.state} refreshData={this.loadChanges}/>
            </div>
        );
    }
}

export default SyncIndex;


