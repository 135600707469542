import React from 'react';
import Table from "material-table";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Utils from "../../utils/utils";
import * as cookies from "react-cookies";
import {Api} from "../../config/api_config";
import BackButton from "../Buttons/BackButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from '@material-ui/core/DialogActions';
import Button from "@material-ui/core/Button";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';

function sleep(delay = 0) {
    return new Promise((resolve) => {
        setTimeout(resolve, delay);
    });
}

class Reports extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            studentReports: [
                {name: "Attendance Report by Course", report: 'students_individual_attendance_report_by_course',params: ['course','start_year','end_year']},
                {name: 'Report Card by Points', report: 'Report_emis_points'},
                {name: 'Report Card by Average', report: 'student_report_by_average'},
                {name: 'Transcript by Average', report: 'student_transcript_by_average'},
                {name: 'Transcript by Points', report: 'student_tanscript_by_points'},
            ],
            globalReports: [
                {name: 'Students by Programme', report: 'students_by_programme'},
                {name: "Attendance Per Course", report: 'students_attendance_report', params: ['course','start_year','end_year']},
                {name: "Students on Scholarship",report:"student_scholarship_reports", params: ['awarding_body','start_year','end_year']},
                {name: "Revenue Per Year",report:"financial_report", params:['year']},
                {name: "Student Scores Per Course",report:"student_scores_per_course", params: ['course','year']},
                {name: "Teacher Performance",report:"teacher_performance_report",params: ['term_name','start_year','end_year']},
                {name: "Course Master File",report:"course_masterfile_report",params: ['course','term_name','start_year','end_year']}
            ],
            showModal: false,
            forGlobal: false,
            reportData: {},
            dialogTrue: false,
            yearOptions: [],
            termOptions: [],
            awardingBodyOptions: [],
            yearOpen: false,
            courseOptions: [],
            courseOpen: false,
            termOpen: false,
            awardingBodyOpen: false,
        }
    };

    getAdditionalReport = (data, params) => {
        localStorage.setItem('showReport', 'true');
        let dateParams = null;

        let inject = {images: [{name: 'image', attr: 'image', id: cookies.load('orgId'), model: 'LearningCenter'}]};
        this.showReport(data.name, Api.DEV_BASE_URL+'/v1/reports/1?url=/&profile='+cookies.load('profile_id'),
            {params: params, sub: '&report=' + data.report + '&dynamic=true', inject: inject}, dateParams)

    };

    showReport = (name, baseUrl, parts, params) => {
        Utils.showReportUtility(name, baseUrl, parts, cookies, params);
    };

    displayReport = (event, data) => {
        if(data.params && data.params.length > 0){
            this.setState({reportData: data, dialogTrue: true});
        }else{
            let params = {
                lc_id: cookies.load('orgId').$oid,
                student_id: this.props.match.params.studentId,
                term_id: this.props.match.params.termId,
                start_year: this.props.start_year,
                end_year: this.props.end_year
            };
            this.getAdditionalReport(data,params);
        }
    };

    goBack = () => {
        this.props.history.goBack();
    };

    getReportParams = (params) => {
        if(params === 'course'){
            return <div>{this.loadOptions(this.state.courseOptions,'courseOpen',params, 'Course')}</div>
        }else if(params === 'year'){
            return <div>{this.loadOptions(this.state.yearOptions,'yearOpen',params, 'Year')}</div>
        }else if(params === 'start_year'){
            return <div>{this.loadOptions(this.state.yearOptions,'yearOpen',params, 'Start Year')}</div>
        }else if(params === 'end_year'){
            return <div>{this.loadOptions(this.state.yearOptions,'yearOpen',params, 'End Year')}</div>
        }else if(params === 'term_name'){
            return <div>{this.loadOptions(this.state.termOptions,'termOpen',params, 'Term/Semester')}</div>
        }else if(params === 'awarding_body'){
            return <div>{this.loadOptions(this.state.awardingBodyOptions,'awardingBodyOpen',params, 'Issuer')}</div>
        }
    };

    loadOptions = (openOptions,optOpen,params,title)=>{
        return <Autocomplete
            id="asynchronous-courses"
            style={{ width: 300 }}
            open={this.state[optOpen]}
            onOpen={() => {
                let x = {};
                x[optOpen] = true
                this.setState(x);
            }}
            onClose={() => {
                let x = {};
                x[optOpen] = false;
                this.setState(x);
            }}
            onChange={(e,newValue)=>{
                this.setParams(params, newValue.value);
            }}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            options={openOptions}
            loading={this.state[optOpen] && openOptions.length === 0}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={title}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {this.state[optOpen] && openOptions.length === 0 ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />;
    };

    setParams = (att, val) => {
        let d = this.state.reportData;
        if(d.add_params === undefined)
            d.add_params = {};
        d.add_params[att] = val;
        this.setState({reportData:d});
    };

    updateParams = () => {
        let params = {
            lc_id: cookies.load('orgId').$oid,
            student_id: this.props.match.params.studentId,
        };
        let ps = this.state.reportData.add_params;
        if(ps === undefined || this.state.reportData.params.length !== Object.keys(ps).length){
            Utils.displayMessage('error', 'Failed', "You must choose valid options");
            return;
        }
        Object.keys(ps).forEach(v => {
           if(ps[v]==null){
               Utils.displayMessage('error', 'Failed', "You must choose a "+v);
               return;
           }

           params[v] = ps[v];
        });
        this.getAdditionalReport(this.state.reportData,params);
    };

    hideDialog = () => {
        this.setState({dialogTrue: false})
    };

    componentDidMount() {
        if(this.props.location.studentName !== undefined){
            localStorage.setItem('studentName', this.props.location.studentName);
        }
        this.setState({forGlobal: (!this.props.match.params.studentId)});
    };

    asyncLoad = (param, cback,valCback) => {
            let active = true;

            if (valCback()) {
                return undefined;
            }

            (async () => {
                const response = await fetch(Api.DEV_BASE_URL+'/v1/reports/1/load_rep_params?lc_id='+cookies.load('orgId').$oid+'&'+param+'=true');
                await sleep(1e3); // For demo purposes.
                const options = await response.json();

                if(options && options.error){
                    Utils.displayMessage('error', 'Failed', options.error);
                    return;
                }
                if (active) {
                    cback(options);
                }
            })();

            return () => {
                active = false;
            };

    };

    render() {
        if(this.state.courseOpen && this.state.courseOptions.length === 0){
            this.asyncLoad('courses',(opts)=>{this.setState({courseOptions: opts.map(opt=>{return {name: opt.name, value: opt._id.$oid}})})}, ()=>{return !this.state.courseOpen || this.state.courseOptions.length > 0});
        }
        if(this.state.yearOpen && this.state.yearOptions.length === 0){
            this.asyncLoad('year',(opts)=>{this.setState({yearOptions: opts.map(opt=>{return {name: opt.name, value: opt.name}})})}, ()=>{return !this.state.yearOpen || this.state.yearOptions.length > 0});
        }
        if(this.state.termOpen && this.state.termOptions.length === 0){
            this.asyncLoad('terms',(opts)=>{this.setState({termOptions: opts.map(opt=>{return {name: opt.name, value: opt.name}})})}, ()=>{return !this.state.termOpen || this.state.termOptions.length > 0});
        }
        if(this.state.awardingBodyOpen && this.state.awardingBodyOptions.length === 0){
            this.asyncLoad('awarding_body',(opts)=>{
                let temp = [];
                opts.map(opt=> temp.push({name: opt.name, value: opt.name}));
                temp.unshift({name:"All", value: "All"});
                this.setState({awardingBodyOptions: temp})
                },
                ()=>{return !this.state.awardingBodyOpen || this.state.awardingBodyOptions.length > 0});
        }
        return (
            <div>
                <Grid container>
                    <Grid item xs={12} sm={12} md={12}>
                        <BackButton goBack={this.goBack} color={"secondary"} direction={"right"} title={"Back"} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sm={12} md={6}>
                        <Card>
                            <div style={{textAlign: 'center', padding: '1px'}}>
                                <h4>{(this.state.forGlobal) ? 'Reports': localStorage.getItem('studentName')}</h4>
                            </div>
                            <div>
                                <Table
                                    columns={[
                                        {
                                            title: '',
                                            field: 'name',
                                            cellStyle: {padding: '4px'},
                                            headerStyle: {padding: '4px'}
                                        },
                                        {title: '', field: 'report', hidden: true},
                                    ]}
                                    data={(this.state.forGlobal) ? this.state.globalReports : this.state.studentReports}
                                    onRowClick={this.displayReport}
                                    options={{
                                        columnsButton: false,
                                        sorting: false,
                                        showTitle: false,
                                        toolbar: false,
                                        search: false,
                                        paging: false,
                                    }}
                                />
                            </div>
                        </Card>
                    </Grid>
                </Grid>
                {(this.state.dialogTrue) ? <Dialog open={this.state.dialogTrue} onClose={this.hideDialog} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">
                        <h5>Choose Options</h5>
                    </DialogTitle>
                    <DialogContent>
                        {this.state.reportData.params.map(rep => {return this.getReportParams(rep)})}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.hideDialog}
                                  title={"Close"}
                                  color="secondary"
                                  style={{backgroundColor: '#C51162', color: '#fff', width: 200}}
                                  fullWidth={true}
                        >Close</Button>
                        <Button onClick={this.updateParams}
                                  direction={'center'} color={"default"}
                                  title={"Load"}
                                  style={{backgroundColor: '#3F5135', color: '#fff', width: 200}}
                                  fullWidth={true}
                        >Load</Button>
                    </DialogActions>
                </Dialog> : null}
            </div>
        );
    }
}

export default Reports;
