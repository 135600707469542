import React from 'react';
import MyButton from "../Buttons/Button";
import Table from 'material-table';
import ProgramService from "../../services/programService";
import Utils from "../../utils/utils";
import LearningCenterService from "../../services/learningCenterService";
import Button from "@material-ui/core/Button";
import ProgressBar from "../ProgressBar/ProgressBar";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import Tooltip from "@material-ui/core/Tooltip";

class Programs extends React.Component {

    constructor(props) {
        super(props);

        this.tableRef = React.createRef();
        this.state = {
            header: [
                {title: 'Name', field: 'name'},
                {title: 'Award', field: 'award'},
                {title: (<Tooltip title={"ISCED: International Standard Classification of Education"}>
                        <span>
                            Isced Level
                            <i className="material-icons">
                                help_outline
                            </i>
                        </span>
                </Tooltip>), field: 'isced_level'},
                {title: 'Tuition (GMD)', field: 'tuition'},
                {title: 'Department', field: 'department_name'},
                {title: 'Accreditation', field: 'accreditation_status'},
            ],
            programs: [],
            displayLoader: false
        };
    };

    /*
    * Method to delete or suspend a programme
    * Parameters:
    * + rowData: The programme to suspend or delete
    * */
    deleteProgramme = (event, rowData) => {
        let _type = rowData.can_be_deleted ?
            "deleted" :
            (rowData.suspended) ? "activated" : "suspended";
        let text = `Programme will be ${_type}, do you want to continue?`;
        Utils.confirmDeleteMessage(text)
            .then((willDelete) => {
                if (willDelete) {
                    if (rowData.can_be_deleted === true) {
                        ProgramService.deleteProgram(rowData._id.$oid, result => {
                            if (result.error) {
                                Utils.displayMessage('error', 'Failed', result.errors[0]);
                            } else {
                                Utils.displayMessage('success', 'Successful', result.success);
                                this.refreshTable();
                            }
                        })
                    } else {
                        let _suspend = !rowData.suspended;
                        LearningCenterService.suspendItem(rowData._id.$oid, "Academics::Program", _suspend, result => {
                            if (result.error) {
                                Utils.displayMessage('error', 'Failed', result.errors[0]);
                            } else {
                                Utils.displayMessage('success', 'Successful', result.success);
                                this.refreshTable();
                            }
                        })
                    }
                }
            });
    };

    /*
    * Method to view the details of a programme
    * Parameters:
    * + rowData: The department to view details for
    * */
    rowSelect = (event, rowData) => {
        this.props.history.push(`${this.props.match.path}/${rowData._id.$oid}`);
    };

    // Method to refresh data of the table
    refreshTable = () => {
        this.tableRef && this.tableRef.current.onQueryChange();
    };

    /*
    * Method to edit a programme
    * Parameters:
    * + rowData: The programme to be edited
    * */
    editProgram = (event, rowData) => {
        rowData.map(student => ProgramService.editProgram(student._id.$oid,));
        this.refreshTable();
    };

    componentDidMount() {
        this.setState({
            displayLoader: true
        });
    };

    render() {
        return (
            <div>
                {
                    this.state.displayLoader ? <div style={{marginBottom: 15}}><ProgressBar/></div> : null
                }
                <div style={{marginBottom: 10}}>
                    <MyButton to={"/new-program"} color={"primary"} title={"Add Program"}/>
                </div>
                <div>
                    <Table
                        tableRef={this.tableRef}
                        title="Programs"
                        columns={this.state.header}
                        data={query => new Promise((resolve, reject) => {
                            ProgramService.loadData(query, resolve, reject, () => {
                                this.setState({
                                    displayLoader: false
                                });
                            });
                        })}
                        onRowClick={this.rowSelect}
                        options={{
                            exportButton: true, filtering: true,
                            grouping: true, sorting: true,
                            debounceInterval: 1000,
                            selection: false, showTitle: true,
                            pageSize: 10, search: true, actionsColumnIndex: -1,
                            rowStyle: rowData => ({
                                backgroundColor: (rowData.suspended) ? '#DDD' : '#FFF',
                            })
                        }}
                        localization={{
                            header: {
                                actions: "",

                            }
                        }}
                        actions={[
                            rowData => ({
                                icon: () => (
                                    <Button
                                        color={(rowData.can_be_deleted === true) ?
                                            "secondary" : (rowData.suspended === true) ? "primary" : "default"
                                        }
                                        variant="contained"
                                        style={{textTransform: 'none'}}
                                        size="small"
                                    >
                                        {(rowData.can_be_deleted === true) ?
                                            "DELETE" : (rowData.suspended === true) ? "ACTIVATE" : "SUSPEND"
                                        }
                                    </Button>
                                ),
                                tooltip: 'Delete/Suspend Program',
                                onClick: (event, rowData) => this.deleteProgramme(event, rowData),
                            }),
                            {
                                icon: 'refresh', tooltip: 'Refresh', isFreeAction: true,
                                onClick: () => this.refreshTable(),

                            }
                        ]}
                    />
                </div>
            </div>
        );
    }
}

export default Programs;


