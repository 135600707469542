import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LearningCenter from "./LearningCenter";
import User from "./User";
import recapterValue from "./User";
import Utils, {displayError} from "../../utils/utils";
import LearningCenterService from "../../services/learningCenterService";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const Register = (props) => {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [enableButton, setEnableButton] = React.useState(true);
    const [centerData, setCenterData] = useState({
        name: '',
        address: '',
        website: '',
        phone: '',
        email: '',
        pobox: '',
        lga: '',
        district: '',
        number_of_staff: '',
        classification: '',
        lecture_room_count: '',
        lecture_room_capacity: '',
        enrollment_capacity: '',
        ownership: '',
        financial_source: '',
        region: '',
        school_code: '',
        registering: true
    });
    const [adminData, setAdminData] = useState({
        user_id: '',
        first_name: '',
        middle_name: '',
        last_name: '',
        phone_number: '',
        nationality: '',
        email: '',
        password: '',
        dup_password: '',
        user_type: 'Administrative',
        role: 'Administrator',
        influence: 'emis'
    });

    const [recaptchaValue, setRecaptchaValue] = useState('');

    useEffect(() => {
        initialiseCenterFields();
        initialiseAdminFields();
    }, []);

    // Method to hide finish button
    const enableFinishButton = () => {setEnableButton(false);};

    // Method to update the recaptcha value
    const updateRecaptcha = (val) => {setRecaptchaValue(val);};

    // Method to validate the values of the learning center's data fields
    const checkCenterFields = () => {
        const {name, email, address, phone} = centerData;

        if (name === '' || !Utils.validateText(name)) return 'Name';
        if (email === '' || (email !== '' && !Utils.validateEmail(email))) return 'Email';
        if (address === '' || !Utils.validateText(address)) return 'Address';
        if ((phone === '') || (phone !== '' && !Utils.validatePhoneNumber(phone))) return 'Phone';
        return false;
    };

    // Method to validate the values of the user's data fields
    const checkAdminFields = () => {
        const {
            user_id, first_name, middle_name, last_name, gender, phone_number, email, password, dup_password, nationality
        } = adminData;
        if (user_id === '' || !Utils.validateText(user_id)) return 'Staff ID';
        if (first_name === '' || !Utils.validateText(first_name)) return 'First Name';
        if (middle_name !== '' && !Utils.validateText(middle_name)) return 'Middle Name';
        if (last_name === '' || !Utils.validateText(last_name)) return 'Last Name';
        if (gender !== '' && !Utils.validateText(gender)) return 'Gender';
        if (phone_number !== '' && !Utils.validatePhoneNumber(phone_number)) return 'Phone Number';
        if (email === '' || (email !== '' && !Utils.validateEmail(email))) return 'Email';
        if (nationality === '' || !Utils.validateText(nationality)) return 'Nationality';
        if (password === '') return 'Password';
        if (dup_password === '') return 'Confirm Password';
        if (recaptchaValue === '') return 'recaptchaValue';
        return false;
    };

    /*
        Method to navigate user to the next step of the registration or
        to send registration data to the server to be save
    */
    const handleNext = () => {
        if (activeStep < 1) {
            let res = checkCenterFields();
            if (res === false) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            } else {
                Utils.displayMessage('error', 'Failed', `Please specify ${res}`).then(r => r);
            }
        } else {
            let res = checkAdminFields();
                if (res === false) {
                    saveData();
                } else {
                    Utils.displayMessage('error', 'Failed', `Please specify ${res}`).then(r => r);
                }

        }
    };

    // Method to initialize learning center form select fields
    const initialiseCenterFields = () => {
        let _lc = {...centerData};
        _lc.ownership = Utils.sector[0].value;
        _lc.lga = Utils.lga[0].value;
        _lc.region = Utils.regions[0].value;
        _lc.classification = Utils.classifications[0].value;
        _lc.financial_source = Utils.financial_source[0].value;
        setCenterData(_lc);
    };

    // Method to initialize admin/user form select fields
    const initialiseAdminFields = () => {
        let user = {...adminData};
        user.gender = Utils.genders[0].value;
        setAdminData(user);
    };

    // Method to save data to the server
    const saveData = () => {
        let data = {learningCenter: centerData, admin: adminData};
        LearningCenterService.createLearningCenter(data, result => {

            if (result.error) {
                Utils.displayMessage('error', 'Error', result.errors[0]).then(r => r);
            }else{
                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        });
    };

    // Method to navigate you back to the previous component/view
    const handleBack = () => {
        if(activeStep === 1){
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }else{
            props.history.push('/login');
        }
    };

    // Method to get the next view to be display in the registration wizard
    const getSteps = () => {
        return ['Learning Center Information', 'Administrator Information'];
    };

    // Method to update the user's data fields
    const updateAdminState = (name, val) => {
        let _data = {...adminData};
        _data[name] = val;

        setAdminData(_data);
    };

    // Method to update the learning center's data fields
    const updateCenterState = (name, val) => {
        let _data = {...centerData};
        _data[name] = val;

        setCenterData(_data);
    };

    // Method that navigates the user to the login component after registering
    const directToLogin = () => {
        props.history.push('/login');
    };

    // Method to return the component to be displayed based on the step you are on
    const getStepContent = (stepIndex) => {
        switch (stepIndex) {
            case 0:
                return <LearningCenter lcData={centerData} updateData={updateCenterState}/>;
            case 1:
                return <User enableFinishButton={enableFinishButton} adminData={adminData}
                             updateData={updateAdminState} updateRecaptcha={updateRecaptcha}/>;
            default:
                return 'Unknown stepIndex';
        }
    };

    const steps = getSteps();

    return (
        <div className={classes.root}>
            <h4 style={{textAlign: 'center'}}>Registration Page</h4>
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                {activeStep === steps.length ?
                    directToLogin()
                    : (
                        <div>
                            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
                            <div style={{textAlign: 'center'}}>
                                <Button
                                    onClick={handleBack}
                                    className={classes.button}
                                    color="secondary"
                                    variant="contained"
                                    style={{marginRight: 5}}
                                >
                                    Cancel
                                </Button>
                                <Button variant="contained" color="primary" onClick={handleNext}>
                                    {activeStep === steps.length - 1 ? 'Save' : 'Next'}
                                </Button>
                            </div>
                        </div>
                    )}
            </div>
        </div>
    );
};

export default Register;
