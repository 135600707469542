import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import Table from 'material-table';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Utils from "../../utils/utils";
import ProgramService from "../../services/programService";
import StudentService from "../../services/studentService";
import TextField from "@material-ui/core/TextField/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ChooseSet from "./ChooseSet";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    formControl: {
        width: 500,
        marginTop: 12,
        marginLeft: 70
    },
    PaperProps: {
        style: {
            width: 250,
        },
    },
});

class AddCourseToEnrollment extends React.Component {

    state = {
        columns: [
            {title: 'Course', field: 'name', editable: 'never'},
            {title: 'Code', field: 'course_code', editable: 'never'},
            {title: 'Department', field: 'department_name', editable: 'never'},
            {title: 'Programme', field: 'program_name', editable: 'never'},
        ],
        courses: [],
        options: [],
        studentId: '',
        open: false,
        courseData: {}
    };

    /*
    * Method to add a course for a student
    * Parameters:
    * rowData: The course to be added for the student
    *
    * */
    selectCourse = (event, rowData) => {
        let text = 'Course will be added for student, do you want to continue?';
        Utils.confirmDeleteMessage(text,"Confirm!")
            .then((willDelete) => {
                if (willDelete) {
                    let temp = [];
                    rowData.class_sets.map(_set => temp.push({label: _set.name, value: _set._id.$oid}));
                    this.setState({
                        open: true,
                        options: temp,
                        courseData: rowData
                    });
                }
            });
    };

    sendData = (class_set) => {
        let data = {...this.state.courseData};
        data.class = class_set;

        StudentService.enrollStudent(data, localStorage.getItem('studentId'), (result) => {
            if (result.error) {
                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
            } else {
                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                this.onClose();
            }
        });
    };

    onClose = () => {
        this.setState({open: false});
    };

    // Method to update the courses' list
    loadCourses = (courses) => {
        if(courses.length === 0){
            alert("You cannot add a student to courses without adding the courses you offer, Please add  **Courses ** to proceed")
            this.goBack()
        }else{
            courses.forEach(course => {
                course.course_id = course._id.$oid;
            });
            this.setState({courses: courses})
        }
    };

    // Method to return user to the previous view
    goBack = () => {
        this.props.history.goBack();
    };

    componentDidMount() {
        if (this.props.location.studentData) {
            let id = this.props.location.studentData.details._id.$oid;
            localStorage.setItem('studentId', id);
            this.setState({
                studentId: id
            });
        }
        ProgramService.loadAllCourses(this.loadCourses);
    };

    render() {
        return (
            <div>
                <Button variant="contained" color="secondary" onClick={this.goBack} style={{float: 'right'}}>
                    Back
                </Button>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={12}>
                        <Table
                            title="Choose Course To Add For Student"
                            columns={this.state.columns}
                            data={this.state.courses}
                            options={{
                                exportButton: false, filtering: false,
                                grouping: false, sorting: true,
                                debounceInterval: 1000,
                                selection: false, showTitle: true,
                                pageSize: 10, search: true,
                                actionsColumnIndex: -1
                            }}
                            localization={{
                                header: {
                                    actions: ""
                                }
                            }}
                            actions={[
                                {
                                    icon: () => (
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            style={{textTransform: 'none'}}
                                            size="small"
                                        >
                                            Add
                                        </Button>
                                    ),
                                    tooltip: 'Add Course for a student',
                                    onClick: (event, rowData) => this.selectCourse(event, rowData)
                                }
                            ]}
                        />
                    </Grid>
                </Grid>
                {
                    this.state.open ? <ChooseSet options={this.state.options} open={this.state.open} onClose={this.onClose} sendData={this.sendData} /> : null
                }
            </div>
        );
    }
}

export default withStyles(styles)(AddCourseToEnrollment);


