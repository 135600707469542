export const ADD_STUDENT = 'ADD_STUDENT';
export const LOAD_STUDENT = 'LOAD_STUDENT'
export const LOAD_STUDENTS = 'LOAD_STUDENTS'
export const VIEW_STUDENT = 'VIEW_STUDENT'

export const load_students = () => {
    return {
        type: LOAD_STUDENTS
    }
}
