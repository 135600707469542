import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import BackButton from "../Buttons/BackButton";
import {Card, Grid} from '@material-ui/core';
import Utils from "../../utils/utils";
import ScholarshipService from "../../services/scholarshipService";
import StudentService from "../../services/studentService";
import MenuItem from "@material-ui/core/MenuItem";
import CoursesService from "../../services/coursesService";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
});

class AddScholarship extends React.Component {
    state = {
        newScholarship: {
            awarding_body: '',
            awarded_year: '',
            awarded_date: Utils.getToday(),
            awarded_for: '',
            award_end_year: '',
            status: '',
            type: '',
            studentId: {},
        },
        students: [],
        programs: [],
        awarding_names: []
    };

    // Method to update the form field values of a new scholarship
    handleChange = (name, value) => {
        let scholarship = {...this.state.newScholarship};
        scholarship[name] = value;

        this.setState({
            newScholarship: scholarship
        });
    };

    // Method to handle the data value change of the scholarship form
    handleAwardChange = (n) => this.setState({
        newScholarship: {...this.state.newScholarship, awarded_date: n}
    });

    // Method to clear the values of the form fields
    clearForm = () => {
        let _scholarshipData = this.state.newScholarship;
        _scholarshipData.awarding_body = '';
        _scholarshipData.awarded_date = Utils.getToday();
        _scholarshipData.awarded_for = '';
        _scholarshipData.studentId = {};
        _scholarshipData.status = '';
        _scholarshipData.type = '';

        this.setState({
            newScholarship: _scholarshipData
        });
        this.goBack();
    };

    // Method to valid the scholarship fields before posting to the server
    validateFields = () => {
        const {awarding_body, awarded_for, status, type} = this.state.newScholarship;

        if (awarding_body === '' || !Utils.validateText(awarding_body)) return 'awarding_body';
        if (awarded_for === '' || !Utils.validateText(awarding_body)) return 'awarded_for';
        if (status === '' || !Utils.validateText(status)) return 'status';
        if (type === '' || !Utils.validateText(type)) return 'type';
    };

    /*
    * Method to set focus on the text fields with invalid data
    * Parameters:
    * + field_id: The id of the field to set the focus on
    * */
    setFocus = (field_id) => {
        Utils.displayMessage('error', 'Error', 'Please specify ' + field_id).then(r => r);
        document.getElementById(field_id).focus();
    };

    // Method to send and save data to the server
    saveData = () => {

        let data = this.state.newScholarship;

        data.awarded_year = data.awarded_date.split('-')[0];
        data.awarded_date = Utils.formatDate(data.awarded_date);

        if (localStorage.getItem('studentId')) {
            data.studentId = localStorage.getItem('studentId');
        }

        if (data.studentId.label) {
            data.studentId = data.studentId.$oid;
        }
        let result = this.validateFields();
        if (result) {
            this.setFocus(result);
            return;
        }
        ScholarshipService.createScholarship(data, (result) => {
            if (result.error) {
                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
            } else {
                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                this.goBack();
            }
        });
    };

    // Method to load the department names and programmes names
    deptsPrograms = (results) => {
        let _depts = [];

        let _programs = [];
        if (results.depts && results.programs) {
            results.depts.map(dept => _depts.push({label: dept.name, value: dept._id.$oid}));
            results.programs.map(program => _programs.push({label: program.name, value: program._id.$oid}));

            if (_depts.length === 0){
                Utils.displayMessage('error', 'Error', 'You have to add at least one departments first before you can add a course').then(r => r);
                this.props.history.goBack();
            } else if(_programs.length === 0){
                Utils.displayMessage('error', 'Error', 'You have to add at least one program first before you can add a course').then(r => r);
                this.props.history.goBack()
            }else {
                let _newScholarship = {...this.state.newScholarship};
                if (this.props.location.scholarshipData === undefined && localStorage.getItem('scholarshipData') === null) {
                    _newScholarship.awarded_for = _programs[0].value;
                    _newScholarship.status = Utils.status[0].value;
                    _newScholarship.type = Utils.type[0].value;
                }
                this.setState({
                    programs: _programs,
                    newScholarship: _newScholarship
                })
            }
        }
    };

    // Method to update the select field values of the form
    updateSelectFields = (results) => {
        let awarding_names = [];

        if (results.awarding_names && results.awarding_names.length > 0) {
            results.awarding_names.map(dept => awarding_names.push({label: dept.name, value: dept._id.$oid}));
            let _newScholarship = {...this.state.newScholarship};
            if (this.props.location.awarding_body_id === undefined && localStorage.getItem('awarding_body_id') === null) {
                _newScholarship.awarding_body = awarding_names[0].value;
            }
            this.setState({
                awarding_names: awarding_names,
                newScholarship: _newScholarship
            })
        }else{
            //go back if there are no awarding bodies.
            alert('You cannot add a scholarship for a student without adding Awarding Bodies, Please add  **Scholarships** to proceed')
            this.goBack();
        }
    };

    // Method to update studnet select list
    mapStudents = (students) => {
        let _students = [];
        students.map(student => _students.push({label: Utils.getFullName(student), value: student._id.$oid}));
        let _scholarship = {...this.state.newScholarship};

        _scholarship.studentId = _students[0];
        this.setState({
            students: _students,
            newScholarship: _scholarship
        });
    };

    // Method to load students for the select list
    loadStudents = () => {
        StudentService.loadStudents(this.mapStudents);
    };

    componentDidMount() {

        let _props_location = this.props.location;
        if(_props_location.awarding_body_id !== undefined){
            localStorage.setItem('awarding_body_id', _props_location.awarding_body_id);
        }
        if(_props_location.studentData){
            localStorage.setItem('studentId', _props_location.studentData.details._id.$oid);
        }
        if(localStorage.getItem('awarding_body_id') !== null || localStorage.getItem('studentId') !== null){
            let _newScholarship = {...this.state.newScholarship};
            _newScholarship.awarding_body = localStorage.getItem('awarding_body_id');
            _newScholarship.studentId = localStorage.getItem('studentId');
            this.setState({
                newScholarship: _newScholarship
            });
        }
        ScholarshipService.loadAwardingNames(this.updateSelectFields);
        CoursesService.loadDeptsProgs(this.deptsPrograms)
        this.loadStudents();
    };

    // Method to return the user to the previous view/component
    goBack = () => {
        localStorage.removeItem('studentId');
        localStorage.removeItem('scholarshipData');
        localStorage.removeItem('awarding_body_id');
        this.props.history.goBack();
    };

    render() {
        const {classes} = this.props;
        let scholarship = this.state.newScholarship;
        const _students = this.state.students;
        const _progs = this.state.programs;

        return (
            <div>
                <Card>
                    <div style={{marginLeft: 150}}>
                        <form className={classes.root} autoComplete="on">
                            <h2>
                                {
                                    (this.props.location.scholarshipData === undefined &&
                                        JSON.parse(localStorage.getItem('scholarshipData')) === null) ?
                                        "Add Scholarship" : "Edit Scholarship"
                                }
                            </h2>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    {
                                        localStorage.getItem('awarding_body_id') !== null ?
                                            null :
                                            <TextField
                                                required
                                                id="awarding_body"
                                                name="awarding_body"
                                                label="Awarding Body"
                                                value={scholarship.awarding_body}
                                                onChange={(e) => this.handleChange("awarding_body", e.target.value.trim())}
                                                select
                                                variant="outlined"
                                                style={{
                                                    border: 0,
                                                    outline: 0
                                                }}
                                            >
                                                {this.state.awarding_names.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}

                                            </TextField>
                                    }

                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            id="awarded_date"
                                            name="awarded_date"
                                            value={scholarship.awarded_date}
                                            onChange={this.handleAwardChange}
                                            label="Awarded Date"
                                            variant="inline"
                                            inputVariant="outlined"
                                            format="dd/MM/yyyy"
                                            valueDefault='20/01/2005'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                    <TextField
                                        required
                                        id="award_end_year"
                                        name="award_end_year"
                                        label="Awarded End Year"
                                        variant="outlined"
                                        value={scholarship.award_end_year}
                                        onChange={e => this.handleChange(e.target.name,e.target.value)}
                                    />
                                    {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>*/}
                                    {/*    <DatePicker*/}
                                    {/*        id="award_end_year"*/}
                                    {/*        name="award_end_year"*/}
                                    {/*        views={['year']}*/}
                                    {/*        inputVariant="outlined"*/}
                                    {/*        label="Awarded End Year"*/}
                                    {/*        value={scholarship.award_end_year}*/}
                                    {/*        onChange={this.handleDateChange}*/}
                                    {/*        renderInput={(params) => <TextField {...params} helperText={null} />}*/}
                                    {/*        InputLabelProps={{*/}
                                    {/*            shrink: true,*/}
                                    {/*        }}*/}
                                    {/*    />*/}
                                    {/*</MuiPickersUtilsProvider>*/}

                                    {/*<TextField
                                        id="awarded_date"
                                        name="awarded_date"
                                        label="Awarded Date"
                                        type="date"
                                        value={scholarship.awarded_date}
                                        onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="outlined"
                                    />*/}
                                    <TextField
                                        id="awarded_for"
                                        name="awarded_for"
                                        select
                                        label="Awarded For"
                                        value={scholarship.awarded_for}
                                        onChange={(e) => this.handleChange(e.target.name, e.target.value.trim())}
                                        variant="outlined"
                                    >
                                        {_progs.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {
                                        ((this.props.location.scholarshipData !== undefined || JSON.parse(localStorage.getItem('scholarshipData')) !== null)
                                            || localStorage.getItem('studentId') !== null) ?
                                            null
                                            :
                                            <TextField
                                                required
                                                id="studentId"
                                                name="studentId"
                                                select
                                                label="Student"
                                                value={scholarship.studentId}
                                                onChange={(e) => this.handleChange(e.target.name, e.target.value.trim())}
                                                variant="outlined"
                                            >
                                                {_students.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                    }
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        required
                                        id="status"
                                        name="status"
                                        select
                                        label="Status"
                                        variant="outlined"
                                        value={scholarship.status}
                                        onChange={(e) => this.handleChange(e.target.name,e.target.value.trim())}
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {Utils.status.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        required
                                        id="type"
                                        name="type"
                                        select
                                        label="Type"
                                        variant="outlined"
                                        value={scholarship.type}
                                        onChange={(e) => this.handleChange(e.target.name,e.target.value.trim())}
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {Utils.type.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    <BackButton goBack={this.goBack} color={"secondary"} title={"Cancel"}/>
                    <Button variant="contained" color="primary" onClick={this.saveData} style={{margin: 5}}>
                        Save
                    </Button>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(AddScholarship);


