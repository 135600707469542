import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import {Card, Grid} from '@material-ui/core';
import MenuItem from "@material-ui/core/MenuItem";
import CoursesService from '../../services/coursesService';
import Utils from "../../utils/utils";
import StaffService from "../../services/staffService";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        width: '25ch',
    },
    button: {
        marginLeft: 5,
        marginTop: 20
    }
});

class AddAssessment extends React.Component {
    state = {
        newAssessment: {
            assessment_type: '',
            assessment_name: '',
            maximum_score: '',
            weight: '',
            class_set: '',
            course_id: '',
            teacher_id: ''
        },
        assessment_types: [
            {label: "Assignment", value: "Assignment"},
            {label: "Exams", value: "Exams"}
        ],
        teachers: []
    };

    // Method validate form fields before sending the data to the server
    validateFields = () => {
        const {assessment_name, maximum_score, weight} = this.state.newAssessment;
        if (assessment_name == '' || !Utils.validateText(assessment_name)) return 'assessment_name';
        if (maximum_score === '' || !Utils.validatePhoneNumber(maximum_score)) return 'maximum_score';

        if (weight == '' || !Utils.validatePhoneNumber(weight)) return 'weight';
    };

    /*
    * Method to set focus on the text fields with invalid data
    * Parameters:
    * + field_id: The id of the field to set the focus on
    * */
    setFocus = (field_id) => {
        Utils.displayMessage('error', 'Error', 'Please specify ' + field_id);
        document.getElementById(field_id).focus();
        return;
    };

    // Method to update the values of the text fields
    handleChange = e => {
        let val = e.target.value;
        let name = e.target.name;
        let _newAssessment = {...this.state.newAssessment};
        if ((name === 'maximum_score' || name === 'weight') && Utils.isValid(val)) {
            Utils.displayMessage("warning", "", "Only numbers allowed");
            return;
        }
        _newAssessment[name] = val;

        this.setState({
            newAssessment: _newAssessment
        });
    };

    // Method to clear values of the text fields
    clearForm = () => {
        let _newAssessment = {...this.state.newAssessment};
        _newAssessment.assessment_type = '';
        _newAssessment.assessment_name = '';
        _newAssessment.maximum_score = '';
        _newAssessment.weight = '';

        this.setState({
            newAssessment: _newAssessment
        });
    };

    //Consider the weight not the max_score
    getTotalMaxWeight = () => {
        let max_score = 0;
        let data = this.props.location.assessments ?
            this.props.location.assessments :
            JSON.parse(localStorage.getItem('assessments'));
        data.forEach(assessment => {
            max_score += parseInt(assessment.weight);
        });
        return 100 - max_score;
    };

    // Method to send and save data to the server
    saveData = () => {
        let max_score = parseInt(this.state.newAssessment.weight);
        let result = this.validateFields();
        if (result) {
            this.setFocus(result);
            return;
        }
        let msg = (this.getTotalMaxWeight() === 0) ? 'Total weight score for assessments can not be more than 100' : 'Weight score entered must be less than or equal to ' + this.getTotalMaxWeight();
        if (max_score > this.getTotalMaxWeight()) {
            Utils.displayMessage('error', 'Failed', msg);
        } else {
            CoursesService.addAssessment(this.state.newAssessment, this.goBack);
        }
    };

    // Method to go back to the previous component
    goBack = () => {
        localStorage.removeItem('courseId');
        this.props.history.goBack();
    };

    // Methods to initialise the select fields of the assessment form
    initialiseSelectField = () => {
        let _state = {...this.state.newAssessment};
        _state.assessment_type = this.state.assessment_types[0].label;
        if (this.props.location.teacherId || localStorage.getItem('teacherId')) {
            _state.teacher_id = this.props.location.teacherId ? this.props.location.teacherId : localStorage.getItem('teacherId');
        }
        if (this.props.location.courseData || localStorage.getItem('courseId')) {
            _state.course_id = this.props.location.courseData ? this.props.location.courseData._id.$oid : localStorage.getItem('courseId');
        }
        _state.class_set = Utils.sets[0].value;
        this.setState({
            newAssessment: _state
        });
    };

    // Get the list of teachers
    getTeachers = (data) => {
        let _data = [];
        data.map(teacher => _data.push({label: Utils.getFullName(teacher), value: teacher._id.$oid}));
        this.setState({
            teachers: _data
        });
    };

    componentDidMount() {
        if (this.props.location.courseData !== undefined) {
            localStorage.setItem('courseId', this.props.location.courseData._id.$oid);
        }
        if (this.props.location.assessments !== undefined) {
            localStorage.setItem('assessments', JSON.stringify(this.props.location.assessments));
        }
        if (this.props.location.teacherId) {
            localStorage.setItem('teacherId', this.props.location.teacherId);
        }
        StaffService.loadAllTeachers(this.getTeachers);
        this.initialiseSelectField();
    };

    componentWillUnmount() {
        localStorage.clear();
    }

    render() {
        const {classes} = this.props;
        const _data = {...this.state.newAssessment};

        return (
            <div>
                <Card>
                    <div style={{marginLeft: 150}}>
                        <h3>Add Assessment</h3>
                        <form className={classes.root} autoComplete="on">
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    {
                                        (localStorage.getItem('teacherId')) ?
                                            null :
                                            <TextField
                                                required
                                                id="class_set"
                                                name="class_set"
                                                select
                                                label="Class Set"
                                                value={_data.class_set}
                                                onChange={(e) => this.handleChange(e)}
                                                variant="outlined"
                                                style={{
                                                    border: 0,
                                                    outline: 0
                                                }}
                                            >
                                                {Utils.sets.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                    }
                                    <TextField
                                        required
                                        id="assessment_type"
                                        name="assessment_type"
                                        select
                                        label="Assessment Type"
                                        value={_data.assessment_type}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {this.state.assessment_types.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        required
                                        id="assessment_name"
                                        name="assessment_name"
                                        label="Assessment Name"
                                        variant="outlined"
                                        value={_data.assessment_name}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        required
                                        id="maximum_score"
                                        name="maximum_score"
                                        label="Maximum Score"
                                        variant="outlined"
                                        value={_data.maximum_score}
                                        onChange={e => this.handleChange(e)}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="weight"
                                        name="weight"
                                        label="Weight"
                                        variant="outlined"
                                        value={_data.weight}
                                        onChange={e => this.handleChange(e)}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    <div style={{padding: 5}}>
                        <Button variant="contained" color="secondary" onClick={this.goBack} style={{margin: 5}}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={this.saveData}
                                style={{margin: 5}}>
                            Add
                        </Button>
                    </div>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(AddAssessment);




