import $ from "jquery";
import Utils from "../utils/utils";

import {Api} from "../config/api_config";
import * as cookies from "react-cookies";

export default class DepartmentService {

    /*
    * Method to create/update a department
    * Parameters:
    * + data: The data of the department to created or updated
    * + callback: Method to handle the data returned from the server
    * */
    static createDepartment(data, callback){
        const id = (data._id) ? data._id.$oid : '';
        const _method = (id) ? "PUT" : "POST";
        const text = (id) ? "Department successfully updated" : "Department successfully added";

        $.ajax({
            method: _method,
            url: Api.DEV_BASE_URL+"/v1/departments/"+id,
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"), "UserKey":cookies.load('User-Key')},
            data: data,
            success: function (result) {
                Utils.displayMessage('success', 'Successful', text).then(r => r);
                callback(result);

            },
            error:function (e) {
                Utils.displayMessage('error', 'Failed', 'Department could not be saved').then(r => r);
            }
        })
    }

    /*
    * Method to load all departments of a learning center into a data table
    * Parameters:
    * + query: The query sent by the data table
    * + resolve: callback method to process the received list of courses
    * + callback: The method to handle the response coming from the server
    * */
    static loadDepartmentsData = (query,resolve,reject, callback) => {
        let url = Utils.compileQuery(query, (Api.DEV_BASE_URL+"/v1/departments"),
            ["id","name","head","location", "suspended", "can_be_deleted"], [{"by":"desc","attr":"name"}],
            "Institution::Department",null, null,null);
        fetch(url, {
            dataType: "json",
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"), "UserKey":cookies.load('User-Key')}
        })
            .then(response => {return response.json()})
            .then(result => {
                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total
                });
                callback();
            });

    };

    /*
    * Method to delete a department
    * Parameters:
    * + id: The id of the department to be deleted
    * + callback: Method to handle the data returned from the server
    * */
    static deleteDepartment = (id, callback) =>{
        $.ajax({
            method: "DELETE",
            url: Api.DEV_BASE_URL+"/v1/departments/"+id,
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"), "UserKey":cookies.load('User-Key')},
            dataType: "json",
            success: function(result) {
                callback(result);
            },
            error: function(e){
                Utils.displayMessage('error', 'Failed', 'Department could not be deleted').then(r => r);
            }
        });

    };

    /*
    * Method to get the details of a department
    * Parameters:
    * + id: The id of the department to be loaded
    * + callback: Method to handle the data returned from the server
    * */
    static loadDepartment = (id, callback) =>{
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL+"/v1/departments/"+id,
            headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"), "UserKey":cookies.load('User-Key')},
            dataType: "json",
            success: function(result) {
                callback(result.data);
            },
            error: function(e){
                Utils.displayMessage('error', 'Failed', 'Department could not be deleted').then(r => r);
            }
        });
    }

}
