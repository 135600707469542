import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import SyncService from "../../services/syncService";
import LinearProgress from '@material-ui/core/LinearProgress';
import Utils from "../../utils/utils";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

export default function BasicCard(props) {
    const classes = useStyles();
    const [showProgress, setShowProgress] = useState(false);

    const hideProgressbar = () => {
        setShowProgress(false);
    };

    // Method to send the sync request
    const syncRecord = () => {
        setShowProgress(true);
        SyncService.sync(props.data.data)
            .then(res => {
                hideProgressbar();
                if (res.error) {
                    Utils.displayMessage('error', 'Failed', res.message).then(r => r);
                } else {
                    Utils.displayMessage('success', 'Successful', "Data successfully sync").then(r => r);
                    props.refreshData();
                }
            })
            .catch(error => {
                hideProgressbar();
            })
    };

    return (
        <Card className={classes.root} variant="outlined">
            <CardContent>
                <Typography variant="h5" component="h2">
                    {props.data.name} ({props.data.count})
                </Typography>
                <Typography variant="body2" component="p">
                    You have <b><span
                    style={{color: 'red'}}>{props.data.count > 0 ? props.data.count : 0}</span></b> {props.data.count === 1 ? 'change' : 'changes'} that
                    have not been uploaded to the Global EMIS.
                </Typography>
            </CardContent>
            <CardActions>
                <Button color={'secondary'} size="small" onClick={syncRecord}>Sync</Button>
            </CardActions>
            {
                (showProgress) ?
                    <div>
                        <LinearProgress/>
                    </div>
                    :
                    null
            }
        </Card>
    );
}
