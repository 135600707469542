import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import {Card, Grid} from '@material-ui/core';
import Utils from "../../utils/utils";
import LearningCenterService from "../../services/learningCenterService";
import MenuItem from "@material-ui/core/MenuItem";
import UploadPicture from "../UploadPicture/UploadPicture";
import * as cookies from "react-cookies";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
});

class Inst_Details extends React.Component {
    state = {
        Institution: {
            name: '',
            address: '',
            lecture_room_capacity: '',
            enrollment_capacity: '',
            lga: '',
            number_of_staff: '',
            ownership: '',
            region: '',
            classification: '',
            email: '',
            accreditation_status: '',
            accredited_by: '',
            accreditation_number: ''
        },
        regions: [
            {label: "Region 1", value: "Region 1"},
            {label: "Region 2", value: "Region 2"},
            {label: "Region 3", value: "Region 3"},
            {label: "Region 4", value: "Region 4"},
            {label: "Region 5", value: "Region 5"},
            {label: "Region 6", value: "Region 6"}
        ],
        openPicture: false,
        instData: null,
        picture: ""
    };

    // Method to update the form field values
    handleChange = e => {
        let val = e.target.value;
        let institution = {...this.state.Institution};
        if ((e.target.name === 'lecture_room_capacity' ||
            e.target.name === 'number_of_staff' ||
            e.target.name === 'lecture_room_count' ||
            e.target.name === 'enrollment_capacity') && Utils.isValid(val)) {
            Utils.displayMessage("warning", "", "Only numbers allowed");
            return;
        }
        institution[e.target.name] = val;

        this.setState({
            Institution: institution
        });
    };

    // Method to clear the values of the form fields
    clearForm = () => {
        let _institution = this.state.Institution;
        _institution.name = '';
        _institution.address = '';
        _institution.lecture_room_capacity = '';
        _institution.enrollment_capacity = '';
        _institution.lga = '';
        _institution.ownership = {};
        _institution.region = {};
        _institution.number_of_staff = '';
        _institution.email = '';
        _institution.accreditation_status = '';
        _institution.accredited_by = '';
        _institution.accreditation_number = ''

        this.setState({
            Institution: _institution
        });
    };

    // Method to valid form fields before posting to the server
    validateFields = () => {
        const {name, address, lga, number_of_staff, lecture_room_capacity, ownership, region, classification} = this.state.Institution;

        if (name === '' || !Utils.validateText(name)) return 'name';
        if (address === '' || !Utils.validateText(address)) return 'address';
        if (lga === '' || !Utils.validateText(lga)) return 'lga';
        if (ownership === '' || !Utils.validateText(ownership)) return 'ownership';
        if (region === '' || !Utils.validateText(region)) return 'region';
        if (classification === '' || !Utils.validateText(classification)) return 'classification';
        if (lecture_room_capacity === '' || lecture_room_capacity === null) return 'lecture_room_capacity';
        if (number_of_staff === '' || number_of_staff === null) return 'number_of_staff';
    };

    /*
    * Method to set focus on the text fields with invalid data
    * Parameters:
    * + field_id: The id of the field to set the focus on
    * */
    setFocus = (field_id) => {
        Utils.displayMessage('error', 'Error', 'Please specify ' + field_id);
        document.getElementById(field_id).focus();
    };

    // Method to send and save data to the server
    saveData = () => {
        let data = this.state.Institution;

        data.name = data.name.trim();
        data.address = data.address.trim();


        let result = this.validateFields();
        if (result) {
            this.setFocus(result);
            return;
        }
        data.details = true;
        LearningCenterService.updateLC(data, result => {
            if (result.error) {
                Utils.displayMessage('error', 'Failed', result.errors[0]);
            } else {
                Utils.displayMessage('success', 'Successful', result.success);
                this.props.getSettingsData(result.inst_details);
            }
        });
    };

    // Method to initialize the select fields of the form
    initialiseSelectFields = () => {
        let _institution = {...this.state.Institution};
        let _data = this.props.details;

        _institution._id = _data._id;
        _institution.lga = _data.lga;
        _institution.school_code = _data.school_code;
        _institution.region = _data.region;
        _institution.global_id = _data.global_id;
        _institution.address = _data.address;
        _institution.classification = _data.classification;
        _institution.district = _data.district;
        _institution.email = _data.email;
        _institution.name = _data.name;
        _institution.pobox = _data.pobox;
        _institution.ownership = _data.ownership[0].toUpperCase() + _data.ownership.substring(1);
        _institution.enrollment_capacity = _data.enrollment_capacity;
        _institution.financial_source = _data.financial_source;
        _institution.lecture_room_count = _data.lecture_room_count;
        _institution.lecture_room_capacity = _data.lecture_room_capacity;
        _institution.phone = _data.phone;
        _institution.website = _data.website;
        _institution.number_of_staff = _data.number_of_staff;
        _institution.accreditation_number = _data.accreditation_number;
        _institution.accredited_by = _data.accredited_by;
        _institution.accreditation_status = _data.accreditation_status;

        this.setState({
            Institution: _institution
        })
    };

    // Method to open view for adding picture/logo
    openUploadPicture = () => {
        this.setState({
            instData: {...this.state.Institution},
            openPicture: true,
        });
    };

    // Method to close view for adding picture/logo
    handleClosePicture = () => {
        this.setState({
            instData: null,
            openPicture: false
        });
    };

    // Method to load the logo of the school
    loadLcImage = () => {
        LearningCenterService.LoadImage(cookies.load('orgId'), result => {
            if (result && result.image) {
                const preview = document.getElementById("test");
                this.setState({
                    picture: result.image
                });
            }
        });
    };

    componentDidMount() {
        this.initialiseSelectFields();
        this.loadLcImage();
    };

    render() {
        const {classes} = this.props;
        let institution = this.state.Institution;
        return (
            <div>
                <Card>
                    <div style={{marginLeft: 150}}>
                        <form className={classes.root} autoComplete="on">
                            <h4>Institution Details</h4>
                            <Grid container>
                                <Grid item xs={4} sm={4} md={3}>
                                    {/*<img width="90%" height="90%" id="test" src={"https://designshack.net/wp-content/uploads/free-logo-templates.png"}/>*/}
                                    <img width="90%" height="90%" id="test" src={this.state.picture}/>
                                </Grid>
                                <Grid item xs={4} sm={4} md={3}>
                                    <Button variant="contained" color="default" onClick={this.openUploadPicture}
                                            style={{margin: 5}}>
                                        UPLOAD
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        required
                                        id="name"
                                        name="name"
                                        label="Name"
                                        variant="outlined"
                                        value={institution.name}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        required
                                        id="address"
                                        name="address"
                                        label="Address"
                                        variant="outlined"
                                        value={institution.address}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        id="email"
                                        name="email"
                                        label="Email"
                                        variant="outlined"
                                        value={institution.email}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        required
                                        id="lga"
                                        name="lga"
                                        select
                                        label="Local Government Area"
                                        value={institution.lga}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {Utils.lga.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <TextField
                                    id="number_of_staff"
                                    name="number_of_staff"
                                    label="Number of staff"
                                    variant="outlined"
                                    value={institution.number_of_staff}
                                    onChange={e => this.handleChange(e)}
                                />
                                <TextField
                                    id="lecture_room_count"
                                    name="lecture_room_count"
                                    label="Number of Lecture Rooms"
                                    variant="outlined"
                                    value={institution.lecture_room_count}
                                    onChange={e => this.handleChange(e)}
                                />
                                <TextField
                                    id="lecture_room_capacity"
                                    name="lecture_room_capacity"
                                    label="Lecture Room Capacity"
                                    variant="outlined"
                                    value={institution.lecture_room_capacity}
                                    onChange={e => this.handleChange(e)}
                                />
                                <TextField
                                    id="enrollment_capacity"
                                    name="enrollment_capacity"
                                    label="Enrollment Capacity"
                                    variant="outlined"
                                    value={institution.enrollment_capacity}
                                    onChange={e => this.handleChange(e)}
                                />
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        required
                                        id="ownership"
                                        name="ownership"
                                        select
                                        label="Sector"
                                        value={institution.ownership}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {Utils.sector.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        required
                                        id="region"
                                        name="region"
                                        select
                                        label="Region"
                                        value={institution.region}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {this.state.regions.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </TextField>
                                    <TextField
                                        id="classification"
                                        name="classification"
                                        select
                                        label="Classification"
                                        value={institution.classification}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {Utils.classifications.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        id="accreditation_status"
                                        name="accreditation_status"
                                        select
                                        label="Accreditation"
                                        value={institution.accreditation_status}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                    >
                                        {Utils.accreditation.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="accredited_by"
                                        name="accredited_by"
                                        label="Accredited By"
                                        variant="outlined"
                                        value={institution.accredited_by}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        id="accreditation_number"
                                        name="accreditation_number"
                                        label="Accreditation Number"
                                        value={institution.accreditation_number}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                    />
                                    <TextField
                                        id="school_code"
                                        name="school_code"
                                        label="School Code"
                                        value={institution.school_code}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                    />
                                    <TextField
                                        id="pobox"
                                        name="pobox"
                                        label="P.O.Box"
                                        value={institution.pobox}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    <div style={{padding: 5}}>
                        <Button variant="contained" color="primary" onClick={this.saveData} style={{margin: 5}}>
                            Save
                        </Button>
                    </div>
                </Card>
                {
                    (this.state.openPicture && this.state.instData) ?
                        <UploadPicture lcenterData={this.state.instData} open={this.state.openPicture}
                                       updataPic={this.loadLcImage} onClose={this.handleClosePicture}
                                       reloadImage={this.loadLcImage}/> : null
                }
            </div>
        );
    }
}

export default withStyles(styles)(Inst_Details);


