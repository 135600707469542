import React, {useEffect, useLayoutEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Card, CardContent, CardHeader, Grid, Typography} from '@material-ui/core';
import Table from "material-table";
import StudentService from "../../services/studentService";
import MyButton from "../Buttons/Button";
import Utils from "../../utils/utils";
import Button from "@material-ui/core/Button";
import PaymentIcon from '@material-ui/icons/Payment';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PayBill from './Payment';
import LearningCenterService from "../../services/learningCenterService";
import * as cookies from "react-cookies";
import ActionButton from "../Buttons/ActionButton";
import TextField from "@material-ui/core/TextField/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import ProgressBar from "../ProgressBar/ProgressBar";
import UploadStudentPicture from "../UploadPicture/UploadStudentPicture";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    btn: {
        margin: 9
    }
});

const StudentDetails = (props) => {
    const _match = props.match;
    const [studentData, setStudentData] = useState({
        details: null,
        admission: null,
        scholarship: null,
        enrollments: null
    });
    const [bills, setBills] = useState([]);
    const [allAdmissions, setAllAdmissions] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [charge, setCharge] = React.useState(null);
    const [newEnrollment, setNewEnrollment] = React.useState({
        date: Utils.getToday(),
        studentId: '',
        current_semester_name: '',
        current_semester_id: ''
    });
    const [displayLoader, setDisplayLoader] = useState(false);

    /*
    * Method to open charge payment form
    * Parameters:
    * rowData: The charge to make a payment for
    *
    * */
    const handleClickOpen = (evt, rowData) => {
        if (rowData.balance === 0) {
            Utils.displayMessage('error', 'Failed', 'This charge has already been paid').then(r => r);
        } else {
            setCharge(rowData);
            setOpen(true);
        }
    };

    // Method to hide charge payment form
    const handleClose = () => {
        setOpen(false);
    };

    // Method to initialize field values
    const getDetails = (result) => {
        let _student = {...studentData};
        _student.details = result.student;
        _student.enrollments = (result.student.enrollments) ? result.student.enrollments : [];
        _student.admission = result.admission;
        _student.scholarship = result.scholarship;

        let _studentName = _student.details.middle_name === "" ?
            _student.details.first_name + " " + _student.details.last_name :
            _student.details.first_name + " " + _student.details.middle_name + " " + _student.details.last_name;

        setStudentData(_student);
        setStudentName(_studentName);
        setStudentPicture(result.student.image);

    };

    // Method to reload charges's table data
    const refreshCharges = () => {
        StudentService.getBills(localStorage.getItem('studentId'), setBills);
    };

    // Method to close dialog for terminating an admission
    const closeTerminateDialog = () => {
        setOpenCloseAdmission(false);
    };

    const reasons = [
        {label: "Graduated", value: "Graduated"},
        {label: "Dropped out", value: "Dropped out"},
        {label: "Transferred", value: "Transferred"},
        {label: "Expelled", value: "Expelled"}];
    const [closedAdmissionData, setClosedAdmissionData] = useState({
        reason: '',
        closed_date: Utils.getToday(),
        actual_end_date: Utils.getToday(),
        student_id: ''
    });
    const [openCloseAdmission, setOpenCloseAdmission] = useState(false);
    const [openActualDate, setOpenActualDate] = useState(false);
    const [studentName, setStudentName] = useState('');

    // Method to update close admission form field values
    const handleChangeClose = (val, name) => {
        let _terminateData = {...closedAdmissionData};
        (val === "Graduated" || name === "actual_end_date") ? setOpenActualDate(true) : setOpenActualDate(false)
        _terminateData[name] = val;
        setClosedAdmissionData(_terminateData);
    };

    // Method to clear form field values
    const clearCloseForm = () => {
        let _data = {...closedAdmissionData};
        _data.reason = '';
        _data.student_id = '';
        _data.closed_date = Utils.getToday();
        _data.actual_end_date = Utils.getToday();
        setClosedAdmissionData(_data);
    };

    /*
    * Method to delete charge
    * Parameters:
    * rowData: The charge to be deleted
    *
    * */
    const deleteCharge = (event, rowData) => {
        let text = 'Charge will be deleted, do you want to continue?';
        Utils.confirmDeleteMessage(text)
            .then((willDelete) => {
                if (willDelete) {
                    StudentService.deleteCharge(rowData._id.$oid, (result) => {
                        if (result.error) {
                            Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
                        } else {
                            Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                            reloadData();
                        }
                    });
                }
            });
    };

    /*
    * Method to delete an admission
    * Parameters:
    * rowData: The admission to be deleted
    *
    * */
    const deleteAdmission = (event, rowData) => {
        let text = 'Admission will be deleted, do you want to continue?';
        Utils.confirmDeleteMessage(text, "Confirm!")
            .then((willDelete) => {
                if (willDelete) {
                    if (studentData.enrollments.length === 0) {
                        StudentService.deleteAdmission(rowData._id.$oid, (result) => {
                            if (result.error) {
                                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
                            } else {
                                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                                reloadData();
                            }
                        });
                    } else {
                        Utils.displayMessage('error', 'Failed', "You cannot delete admission, please delete enrollment first").then(r => r);
                    }
                }
            });
    };

    /*
    * Method to edit an admission
    * Parameters:
    * rowData: The admission to be edited
    *
    * */
    const editAdmission = (event, rowData) => {
        props.history.push({
            pathname: `${props.match.path}/edit-admission`,
            admissionData: rowData, editing: true,
            studentData: studentData
        });
    };

    useEffect(() => {
        setDisplayLoader(true);
    }, []);

    useEffect(() => {
        localStorage.setItem('studentId', _match.params.studentId);
    }, [_match.params.studentId]);

    useEffect(() => {
        reloadData();
        localStorage.removeItem('admissionData');
        localStorage.removeItem('student_id');
    }, []);

    useEffect(() => {
        let _enrollData = {...newEnrollment};
        LearningCenterService.getCurrentTerm(cookies.load('orgId'), response => {
            if (response.data) {
                _enrollData.current_semester_id = response.data._id.$oid;
                _enrollData.current_semester_name = response.data.name;
            }
        });
        _enrollData.studentId = localStorage.getItem('studentId') !== null ? localStorage.getItem('studentId') : _match.params.studentId;
        setNewEnrollment(_enrollData);
    }, []);

    const classes = useStyles();

    // Method to reload data
    const reloadData = () => {
        const studentId = localStorage.getItem('studentId') !== null ? localStorage.getItem('studentId') : _match.params.studentId;
        StudentService.loadStudentId(studentId, getDetails);
        StudentService.getBills(studentId, setBills);
        StudentService.getAllAdmissions(studentId, setAllAdmissions);

        setDisplayLoader(false);
    };

    /*
    * Method to display field data of the student
    * Parameters:
    * + data: The data field of the student
    * */
    const displayField = (data) => (
        <Typography className={classes.title} gutterBottom variant="h5">
            {data}
        </Typography>
    );

    // Method to show confirmation message for enrolling a student
    const showEnroll = () => {
        let student = studentData.details;
        let student_name = student.first_name + " " + student.middle_name + " " + student.last_name;
        if (newEnrollment.current_semester_name === '') {
            Utils.displayMessage('error', 'Failed', "This student cannot be enrolled because you do not have an open term. Please open a term to continue.");
            return;
        }
        const text = `${student_name} will be enrolled in ${newEnrollment.current_semester_name}, do you want to continue?`;
        return (

            Utils.confirmDeleteMessage(text, "Confirm!")
                .then((willDelete) => {
                    if (willDelete) {
                        StudentService.newEnrollStudent(newEnrollment, result => {
                            if (result.error) {
                                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
                            } else {
                                reloadData();
                                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                            }
                        });
                    }
                })
        );
    };

    // Method display new admission view for a student
    const newAdmission = () => {
        if (studentData.admission) {
            if (studentData.admission.status == 'Active') {
                Utils.displayMessage('warning', 'Failed', 'You already have an active admission for this student.').then(r => r)
            } else {
                props.history.push({
                    pathname: `${_match.url}/admission`,
                    studentData: studentData,
                    fromUrl: `${_match.url}`
                });
            }
        } else {
            props.history.push({
                pathname: `${_match.url}/admission`,
                studentData: studentData,
                fromUrl: `${_match.url}`
            });
        }
    };

    // Method display add course view for a student
    const newAddCourseFor = () => {
        let enrolls = studentData.enrollments.filter(enroll => enroll.status === 'active');

        if (enrolls.length > 0) {
            props.history.push({
                pathname: `${_match.url}/add-course`,
                addCourse: true,
                studentData: studentData,
                fromUrl: `${_match.url}`
            });
        } else {
            Utils.displayMessage('warning', 'Failed', 'Student has to be enrolled first before you can add course.').then(r => r);
        }
    };

    /*
    * Method to view the details of an enrollment
    * Parameters:
    * rowData: The enrollment data to be viewed
    *
    *  */
    const viewEnrollment = (event, rowData) => {
        const student_id = localStorage.getItem('studentId') ? localStorage.getItem('studentId') : _match.params.studentId;
        props.history.push({
            pathname: `${props.match.url}/view-enrollment`,
            student_id: student_id,
            enroll_id: rowData._id.$oid,
            enrollData: rowData,
        })
    };

    /*
    * Method to view the details of a charge
    * Parameters:
    * rowData: The charge data to be viewed
    *
    *  */
    const viewCharge = (event, rowData) => {
        const student_id = localStorage.getItem('studentId') ? localStorage.getItem('studentId') : _match.params.studentId;
        props.history.push({
            pathname: `${props.match.url}/charge-payments`,
            student_id: student_id,
            charge_id: rowData._id.$oid,
            chargeData: rowData,
        })
    };

    // Method to close the admission of a student
    const closeAdmission = () => {
        let data = {...closedAdmissionData};
        data.closed_date = Utils.formatDate(data.closed_date);
        data.actual_end_date = Utils.formatDate(data.actual_end_date);
        data.student_id = localStorage.getItem('studentId');

        StudentService.closeAdmission(data, (result) => {
            if (result.error) {
                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
            } else {
                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                clearCloseForm();
                closeTerminateDialog();
            }
        });
    };

    // Method to open dialog to show confirmation message when closing an admission
    const openCloseDialog = () => {
        let text = "Do you want to close this Admission?";
        Utils.confirmDeleteMessage(text, "Confirm!")
            .then((willDelete) => {
                if (willDelete) {
                    setOpenCloseAdmission(true);
                }
            });
    };

    const [openPicture, setOpenPicture] = useState(false)
    const [studentPicture, setStudentPicture] = useState('')

    const openUploadPicture = () => {
        setOpenPicture(true)
    }

    const handleClosePicture = () => {
        setOpenPicture(false)
    };


    const loadStudentImage = () => {
        StudentService.LoadImage(studentData.details._id.$oid, result => {
            if (result && result.image) {
                const preview = document.getElementById("test");
                setStudentPicture(result.image)
            }
        });
    };

    return studentData.details && (
        <div>
            {
                displayLoader ? <div style={{marginBottom: 15}}><ProgressBar/></div> : null
            }
            <div style={{marginBottom: 10}}>
                <MyButton to={{pathname: `${_match.url}/edit-student`, studentData: studentData, editing: true}}
                          direction={'left'} color={"primary"}
                          title={"Edit"}
                />
                <Button variant="contained"
                        color="default"
                        onClick={newAdmission}
                >
                    Add Admission
                </Button>
                <MyButton
                    to={{pathname: `${_match.url}/new-scholarship`, studentData: studentData, fromUrl: `${_match.url}`}}
                    color={"primary"}
                    title={"Add Scholarship"}
                />
                <Button variant="contained"
                        color="default"
                        onClick={showEnroll}
                >
                    Enroll Student
                </Button>
                <Button variant="contained"
                        color="primary"
                        onClick={newAddCourseFor}
                >
                    Add Course
                </Button>
                <MyButton to={"/students"} direction={'right'} color={"secondary"} title={"Back"}/>
            </div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={7}>
                    <Card className={classes.root}>
                        <CardHeader color="primary" title="Student Details"/>
                        <CardContent style={{textAlign: 'left'}}>
                            <Grid container>
                                <Grid item xs={4} sm={4} md={3}>
                                    {/*<img width="90%" height="90%" id="test" src={"https://designshack.net/wp-content/uploads/free-logo-templates.png"}/>*/}
                                    <img width="90%" height="90%" id="test" src={studentPicture}/>
                                </Grid>
                                <Grid item xs={4} sm={4} md={3}>
                                    <Button variant="contained" color="default" onClick={openUploadPicture}
                                            style={{margin: 5}}>
                                        UPLOAD
                                    </Button>
                                </Grid>

                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Global ID")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(studentData.details.global_id)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Student Number")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(studentData.details.student_number)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("First Name")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(studentData.details.first_name)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Middle Name")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(studentData.details.middle_name)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Last Name")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(studentData.details.last_name)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Gender")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(studentData.details.gender)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("DOB")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(Utils.dateFormat(studentData.details.dob))}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Phone Number")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(studentData.details.phone_number)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Email")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(studentData.details.email)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("LGA")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(studentData.details.lga)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Source of Funding")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(studentData.details.source_of_funding)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Admission Year")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {(studentData.admission) ? displayField(Utils.dateFormat(studentData.admission.start_date)) : ''}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Admission Status")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {(studentData.admission) ? displayField(studentData.admission.status) : ''}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Programme")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {(studentData.admission) ? displayField(studentData.admission.program_name) : ''}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                    {(newEnrollment.current_semester_id !== '') ? <Card style={{textAlign: 'center', padding: 15}}>
                        <Grid item xs={12} sm={12} md={12}>
                            <MyButton to={{
                                pathname: `${_match.url}/reports`,
                                noGlobal: true,
                                studentName: studentName
                            }}
                                      direction={'center'} color={"primary"}
                                      title={"Reports"}
                                      style={{backgroundColor: 'blue', color: '#fff', width: 200}}
                                      fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <MyButton to={{pathname: `${_match.url}/apply-charge`, studentData: studentData}}
                                      direction={'center'} color={"default"}
                                      title={"Apply Charge"}
                                      style={{backgroundColor: 'blue', color: '#fff', width: 200}}
                                      fullWidth={true}
                            />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12}>
                            <ActionButton color="primary" fullWidth={true} onClick={openCloseDialog}
                                          title={"Close Admission"}/>
                        </Grid>
                    </Card> : <Card><h5>Please activate term to see reports</h5></Card>}
                </Grid>
            </Grid>
            <br/>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                    <Card>
                        <div>
                            <Table
                                title="Enrollments"
                                columns={[
                                    {title: 'Term', field: 'term'},
                                    {
                                        title: 'Enrolled On',
                                        field: 'enrolled_on',
                                        render: rowData => Utils.dateFormat(rowData.enrolled_on.slice(0, 12))
                                    },
                                    {
                                        title: 'Start Date',
                                        field: 'start_of_term',
                                        render: rowData => Utils.dateFormat(rowData.start_of_term)
                                    },
                                    {
                                        title: 'End Date',
                                        field: 'end_of_term',
                                        render: rowData => Utils.dateFormat(rowData.end_of_term)
                                    },
                                    {title: 'Status', field: 'status'},
                                ]}
                                data={studentData.enrollments}
                                onRowClick={(event, rowData) => viewEnrollment(event, rowData)}
                                options={{
                                    exportButton: true, filtering: false,
                                    grouping: false, sorting: false,
                                    debounceInterval: 1000,
                                    selection: false, showTitle: true,
                                    pageSize: 5, search: false
                                }}
                            />
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Card>
                        <div>
                            <Table
                                title="Scholarships"
                                columns={[
                                    {title: 'Awarded By', field: 'awarding_body'},
                                    {
                                        title: 'Awarded On',
                                        field: 'awarded_date',
                                        render: rowData => Utils.dateFormat(rowData.awarded_date.slice(0, 12))
                                    },
                                    {
                                        title: 'End Year',
                                        field: 'awarded_year'
                                    },
                                    {
                                        title: 'Type',
                                        field: 'type'
                                    },
                                    {title: 'Status', field: 'status'},
                                ]}
                                data={studentData.scholarship}
                                options={{
                                    exportButton: true, filtering: false,
                                    grouping: false, sorting: false,
                                    debounceInterval: 1000,
                                    selection: false, showTitle: true,
                                    pageSize: 5, search: false
                                }}
                            />
                        </div>
                    </Card>
                </Grid>
            </Grid>
            <br/>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={5}>
                    <Card>
                        <div>
                            <Table
                                title="Charges"
                                columns={[
                                    {title: 'Charge For', field: 'charge_name'},
                                    {
                                        title: 'Due Date',
                                        field: 'due_date',
                                        render: rowData => Utils.dateFormat(rowData.due_date)
                                    },
                                    {title: 'Amount', field: 'amount'},
                                    {title: 'Balance', field: 'balance'},
                                ]}
                                data={bills}
                                onRowClick={(event, rowData) => viewCharge(event, rowData)}
                                options={{
                                    exportButton: true, filtering: false,
                                    grouping: false, sorting: false,
                                    debounceInterval: 1000,
                                    selection: false, showTitle: true,
                                    pageSize: 5, search: false,
                                    actionsColumnIndex: -1,
                                    rowStyle: rowData => ({
                                        backgroundColor: (rowData.balance === 0) ? '#CCC' : '#FFF',
                                    })
                                }}
                                localization={{
                                    header: {
                                        actions: ''
                                    }
                                }}
                                actions={[
                                    {
                                        icon: () => <IconButton color="primary"
                                                                aria-label="Apply Payment"><PaymentIcon/></IconButton>,
                                        tooltip: 'Apply Payment',
                                        onClick: (event, rowData) => handleClickOpen(event, rowData)
                                    },
                                    {
                                        icon: () => <IconButton color="secondary"
                                                                aria-label="Delete charge"><DeleteIcon/></IconButton>,
                                        tooltip: 'Delete charge',
                                        onClick: (event, rowData) => deleteCharge(event, rowData)
                                    }
                                ]}
                            />
                        </div>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={7}>
                    <Card>
                        <div>
                            <Table
                                title="Admissions"
                                columns={[
                                    {title: 'Programme', field: 'program_name'},
                                    {title: 'Award', field: 'award'},
                                    {title: 'Qualification', field: 'qualification_entry'},
                                    {
                                        title: 'Start Date',
                                        field: 'start_date',
                                        render: rowData => Utils.dateFormat(rowData.start_date)
                                    },
                                    {
                                        title: 'Expected End Date',
                                        field: 'expected_end_date',
                                        render: rowData => Utils.dateFormat(rowData.expected_end_date)
                                    },
                                    {title: 'ISCED Level', field: 'isced_level'},
                                    {title: 'Status', field: 'status'},
                                ]}
                                data={allAdmissions}
                                options={{
                                    exportButton: true, filtering: false,
                                    grouping: false, sorting: false,
                                    debounceInterval: 1000,
                                    selection: false, showTitle: true,
                                    pageSize: 5, search: false,
                                    actionsColumnIndex: -1
                                }}
                                localization={{
                                    header: {
                                        actions: ''
                                    }
                                }}
                                actions={[
                                    {
                                        icon: () => <IconButton color="primary" aria-label="Edit Admission"><EditIcon/></IconButton>,
                                        tooltip: 'Edit Admission',
                                        onClick: (event, rowData) => editAdmission(event, rowData)
                                    },
                                    {
                                        icon: () => <IconButton color="secondary"
                                                                aria-label="Delete Admission"><DeleteIcon/></IconButton>,
                                        tooltip: 'Delete Admission',
                                        onClick: (event, rowData) => deleteAdmission(event, rowData)
                                    }
                                ]}
                            />
                        </div>
                    </Card>
                </Grid>
            </Grid>
            {
                (charge !== null && open) ?
                    <PayBill charge={charge} open={open} onClose={handleClose} refreshCharges={refreshCharges}/> : null
            }
            <Dialog open={openCloseAdmission} onClose={closeTerminateDialog} aria-labelledby="form-dialog-title">
                <DialogContent>
                    <TextField
                        id="closed_date"
                        name="closed_date"
                        label="Closing Date"
                        type="date"
                        value={closedAdmissionData.closed_date}
                        onChange={e => handleChangeClose(e.target.value, e.target.name)}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        variant="outlined"
                        margin="dense"
                        fullWidth
                    />
                    <TextField
                        id="reason"
                        name="reason"
                        select
                        label="Reason for Closing"
                        value={closedAdmissionData.reason}
                        onChange={e => handleChangeClose(e.target.value, e.target.name)}
                        variant="outlined"
                        style={{
                            border: 0,
                            outline: 0
                        }}
                        margin="dense"
                        fullWidth
                    >
                        {reasons.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    {
                        openActualDate ?
                            <TextField
                                id="actual_end_date"
                                name="actual_end_date"
                                label="Graduation Date"
                                type="date"
                                value={closedAdmissionData.actual_end_date}
                                onChange={e => handleChangeClose(e.target.value, e.target.name)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="outlined"
                                margin="dense"
                                fullWidth
                            /> : null
                    }


                </DialogContent>
                <DialogActions>
                    <Button color="secondary" onClick={closeTerminateDialog} variant="contained">
                        Cancel
                    </Button>
                    <Button onClick={closeAdmission} color="primary" variant="contained">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>

            {
                (openPicture) ?
                    <UploadStudentPicture
                        studentId={studentData.details._id.$oid}
                        open={openPicture}
                        onClose={handleClosePicture}
                        updataPic={loadStudentImage}
                        reloadImage={loadStudentImage}
                    /> : null
            }
        </div>
    );
};

export default StudentDetails;
