import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Utils from "../../utils/utils";
import StudentService from "../../services/studentService";

const PaymentFormDialog = (props) => {
    const [payment, setPayment] = useState({
        payment_date: Utils.getToday(),
        charge_amount: '',
        currency: '',
        amount_paid: '',
        student_id: '',
        payment_for: '',
        charge_id: '',
        student_name: '',
    });

    useEffect(() => {
        initialiseData(props.charge);
    }, []);

    // Method to initialize from fields data
    const initialiseData = (charge) => {
        let _payment = {...payment};
        _payment.charge_amount = (charge.amount === charge.balance) ? charge.amount : charge.balance;
        _payment.currency = charge.currency;
        _payment.payment_for = charge.charge_for;
        _payment.student_name = charge.student_name;
        _payment.charge_id = charge._id.$oid;
        _payment.student_id = charge.student_id.$oid;
        setPayment(_payment);
    };

    // Method to update form fields
    const handleChange = (e) => {
        let _payment = {...payment};
        let val = e.target.value.trim();
        if (e.target.name === 'amount_paid' && Utils.isValid(val)) {
            Utils.displayMessage("warning", "", "Only numbers allowed");
            return;
        }
        _payment[e.target.name] = val;
        setPayment(_payment);
    };

    // Method to make a payment for a student
    const applyPayment = () => {
        let _data = {...payment};
        if (parseFloat(_data.amount_paid) > parseFloat(_data.charge_amount)) {
            Utils.displayMessage("warning", "", `Amount to pay must be less than or equal to the charge amount ${_data.charge_amount}`);
            return;
        }
        StudentService.applyPayment(_data, (result) => {
            if (result.error) {
                Utils.displayMessage('error', 'Failed', result.errors[0]);
            } else {
                Utils.displayMessage('success', 'Successful', result.success);
                props.refreshCharges();
                props.onClose();
            }
        });
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Add Payment</DialogTitle>
            <DialogContent>
                <TextField
                    id="payment_for"
                    name="payment_for"
                    label="Charge Name"
                    variant="outlined"
                    value={payment.payment_for}
                    margin="dense"
                    fullWidth
                />
                <TextField
                    id="charge_amount"
                    name="charge_amount"
                    label={"Charge Amount(" + payment.currency + ")"}
                    variant="outlined"
                    value={payment.charge_amount}
                    margin="dense"
                    fullWidth
                />
                <TextField
                    id="payment_date"
                    name="payment_date"
                    label="Date"
                    type="date"
                    variant="outlined"
                    onChange={handleChange}
                    value={payment.payment_date}
                    margin="dense"
                    fullWidth
                />
                <TextField
                    id="amount_paid"
                    name="amount_paid"
                    label="Amount"
                    variant="outlined"
                    onChange={handleChange}
                    value={payment.amount_paid}
                    margin="dense"
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={props.onClose} variant="contained">
                    Cancel
                </Button>
                <Button onClick={applyPayment} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PaymentFormDialog;
