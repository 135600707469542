import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import {Card, Grid} from '@material-ui/core';
import Table from 'material-table';
import SearchIcon from '@material-ui/icons/Search';
import CoursesService from "../../services/coursesService";
import Utils from "../../utils/utils";


const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        width: '25ch',
    },
    button: {
        marginLeft: 5,
        marginTop: 20
    }
});

class AddCourseForStudent extends React.Component {
    state = {
        addStudents: {
            course_id: '',
            selectedStudents: [],
        },
        number_search: ''
    };

    // Method to update the form fields
    handleChange = e => {
        this.setState({number_search: e.target.value});
    };

    // Method to clear the form fields
    clearForm = () => {
        let _assign_courseData = {...this.state.assignTeacher};
        this.setState({
            assignTeacher: _assign_courseData
        });
    };

    getStudentData = (student) => {
        let sec = this.props.location.classSets ? Object.keys(this.props.location.classSets)[0] : Object.keys(JSON.parse(localStorage.getItem('class_sets')))[0];
        if (student) {
            let temp = {...this.state.addStudents};
            let _arr = [...temp.selectedStudents];
            student.class_set = sec;
            _arr.push(student);
            temp.selectedStudents = _arr;
            this.setState({addStudents: temp});
        } else {
            Utils.displayMessage('error', 'No record found', 'Student record does not exist').then(r => r);
        }
    };

    // Method that performs a student search
    searchStudent = () => {
        let _number_search = this.state.number_search.trim();
        if (this.state.number_search === "") {
            Utils.displayMessage('error', 'ID Missing', "Please enter student's Id to search!").then(r => r);
        } else {
            CoursesService.searchStudent(_number_search, this.getStudentData);
            this.setState({number_search: ''});
        }
    };

    // Method to delete a student from the list of students to add a course for
    deleteStudent = (evt, rowData) => {
        let temp = {...this.state.addStudents};
        let _arr = [...temp.selectedStudents];
        _arr.splice(rowData.tableData.id, 1);
        temp.selectedStudents = _arr;
        this.setState({
            addStudents: temp
        })
    };

    // Method to scrub the list of students to remove unnecessary data
    scrubStudentsData = (data) => {
        let _data = [];
        data.map(student => _data.push({student_id: student._id.$oid, class: student.class_set}));
        return _data;
    };

    // Method to send data for saving
    saveData = () => {
        let _data = {};
        _data.course_id = this.props.location.courseData ? this.props.location.courseData._id.$oid : localStorage.getItem('course_id');
        _data.selectedStudents = this.scrubStudentsData(this.state.addStudents.selectedStudents);
        CoursesService.addStudentToCourse(_data, result => {
            if (result.error) {
                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
            } else {
                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                this.props.history.goBack();
            }
        });
    };

    // Method to return user to the previous view
    goBack = () => {
        if (this.state.addStudents.selectedStudents.length > 0) {
            let text = 'Do you want to cancel adding students to this course?';
            Utils.confirmDeleteMessage(text, "Confirm!")
                .then((willDelete) => {
                    if (willDelete) {
                        this.props.history.goBack();
                    }
                });

        } else {
            this.props.history.goBack();
        }
    };

    getHeader = () => {
        let header = [
            {title: 'First Name', field: 'first_name', editable: 'never'},
            {title: 'Middle Name', field: 'middle_name', editable: 'never'},
            {title: 'Last Name', field: 'last_name', editable: 'never'},
            {title: 'Student Number', field: 'student_number', editable: 'never'},
        ];

        let _set = this.props.location.classSets ? this.props.location.classSets : JSON.parse(localStorage.getItem('class_sets'));
        header.splice(header.length, 1, {
            title: 'Class Set',
            field: 'class_set',
            lookup: _set
        });

        return header;
    };

    componentDidMount() {
        if (this.props.location.courseData) {
            localStorage.setItem('course_id', this.props.location.courseData._id.$oid);
            localStorage.setItem('class_sets', JSON.stringify(this.props.location.classSets));
        }
    }

    render() {
        const {classes} = this.props;
        const _data = {...this.state.addStudents};
        let header = this.getHeader();

        return (
            <div style={{marginLeft: 150, marginRight: 150}}>
                <Card>
                    <h2 style={{marginLeft: 15}}>Add Students</h2>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12}>
                            <div style={{margin: 10, padding: 10}}>
                                <TextField
                                    id="number_search"
                                    name="number_search"
                                    label="Search by Number"
                                    type="search"
                                    value={this.state.number_search}
                                    onChange={e => this.handleChange(e)}
                                    style={{top: 10}}
                                />
                                <Button
                                    variant="contained"
                                    className={classes.button}
                                    startIcon={<SearchIcon/>}
                                    onClick={this.searchStudent}
                                >
                                    Search
                                </Button>
                            </div>
                        </Grid>
                    </Grid>
                    {
                        (_data.selectedStudents.length === 0) ?
                            <h4 style={{marginLeft: 10}}>Please enter student number to search and add to this
                                course!</h4>
                            :
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Table
                                        title="Selected Students"
                                        columns={header}
                                        data={_data.selectedStudents}
                                        options={{
                                            exportButton: false, filtering: false,
                                            grouping: false, sorting: false,
                                            debounceInterval: 1000,
                                            selection: false, showTitle: true,
                                            pageSize: 5, search: false, toolbar: true,
                                            actionsColumnIndex: -1
                                        }}
                                        localization={{
                                            header: {
                                                actions: ""
                                            }
                                        }}
                                        actions={[
                                            {
                                                icon: 'delete',
                                                tooltip: 'Delete Student',
                                                onClick: (event, rowData) => this.deleteStudent(event, rowData)
                                            }
                                        ]}
                                        cellEditable={{
                                            onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                                                return new Promise((resolve, reject) => {
                                                    let tempState = {...this.state.addStudents};
                                                    let stateData = [...tempState.selectedStudents];
                                                    let index = rowData.tableData.id;
                                                    stateData[index] = {...stateData[index], class_set: newValue};
                                                    tempState.selectedStudents = stateData;

                                                    this.setState({
                                                        addStudents: tempState
                                                    });
                                                    setTimeout(resolve, 1000);
                                                });
                                            }
                                        }}
                                    />
                                </Grid>
                            </Grid>
                    }
                    <Button variant="contained" color="secondary" onClick={this.goBack} style={{margin: 5}}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={this.saveData} style={{margin: 5}}>
                        Add
                    </Button>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(AddCourseForStudent);


