import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Card, CardContent, CardHeader, Grid, Typography} from '@material-ui/core';
import MyButton from "../Buttons/Button";
import CoursesService from "../../services/coursesService";
import Table from "material-table";
import Utils from "../../utils/utils";
import StaffService from "../../services/staffService";
import Button from "@material-ui/core/Button";
import ProgressBar from "../ProgressBar/ProgressBar";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const InstitutionCourseDetails = (props) => {
    const _match = props.match;
    const [courseData, setCourseData] = useState(null);
    const [assignedTeachers, setAssignedTeachers] = useState([]);
    const [currentStudents, setCurrentStudents] = useState([]);
    const [currentTerm, setCurrentTerm] = useState(null);
    const [displayLoader, setDisplayLoader] = useState(false);
    const [classSets, setClassSets] = useState({});

    useEffect(() => {
        setDisplayLoader(true);
    }, []);

    useEffect(() => {
        CoursesService.loadCourse(_match.params.courseId, getCourse);
        CoursesService.getClassSets(_match.params.courseId, loadClassSets);
        CoursesService.loadAssignedTeachers(_match.params.courseId, setAssignedTeachers);
        CoursesService.getCurrentStudents(_match.params.courseId, updateStudents);
    }, [_match.params.courseId]);

    useEffect(() => {
        localStorage.removeItem('course_id');
        localStorage.removeItem('class_sets');
    }, []);

    /*
    * Method to get the details of a courses
    * Parameters:
    * + result: THe course details
    * */
    const getCourse = (result) => {
        if (result.error) {
            Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
        } else {
            setCourseData(result.course);
        }
    };

    const classes = useStyles();

    /*
    * Method to update data list for the tables
    * Parameters
    * + result: The data that contains the list of students enrolled in the course and the current_terms
    * */
    const updateStudents = (result) => {
        setCurrentStudents(result.students);
        setCurrentTerm(result.current_term);
        setDisplayLoader(false);
    };

    const displayField = (data) => (
        <Typography className={classes.title} gutterBottom variant="h5">
            {data}
        </Typography>
    );

    // Method to course's data
    const reloadData = () => {
        CoursesService.loadCourse(_match.params.courseId, getCourse);
        CoursesService.loadAssignedTeachers(_match.params.courseId, setAssignedTeachers);

    };

    /*
    * Method to unassign a course assigned to a teacher
    * Parameters:
    * + The teacher course assignment record to be deleted/removed
    * */
    const unAssignTeacher = (event, rowData) => {
        let text = 'Teacher will be unassigned from course, do you want to continue?';
        Utils.confirmDeleteMessage(text)
            .then((willDelete) => {
                if (willDelete) {
                    StaffService.unAssignCourse(rowData._id.$oid, result => {
                        if (result.error) {
                            Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
                        } else {
                            Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                            reloadData();
                        }
                    });
                }
            });
    };

    /*
    * Method to the get the year portion of a school term
    * Parameters
    * + term: The term record to extract the year from
    * */
    const getYear = (term) => {
        let start_date = term.start_date.split('-')[0];
        let end_date = term.end_date.split('-')[0];

        return (end_date === start_date) ? start_date : `${start_date}-${end_date}`
    };

    const loadClassSets = (data) => {
        let _sets = {...classSets};
        if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                _sets[data[i]._id.$oid] = data[i].name
            }
        } else {
            _sets["N/A"] = "N/A";
        }
        setClassSets(_sets);
    };

    return courseData && (
        <div>
            {
                displayLoader ? <div style={{marginBottom: 15}}><ProgressBar/></div> : null
            }
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>

                    <MyButton to={{pathname: `${_match.url}/edit-course`, courseData: courseData, classSets: classSets}}
                              direction={'left'} color={"primary"}
                              title={"Edit"}
                    />
                    <MyButton
                        color={"default"}
                        to={{
                            pathname: `${_match.url}/assign-teacher`,
                            courseData: courseData,

                        }}
                        direction={'left'}
                        title={"Assign Teacher"}
                    />
                    <MyButton
                        to={{pathname: `${_match.url}/add-students`, courseData: courseData, classSets: classSets}}
                        direction={'left'}
                        title={"Add Students"}
                        color={"primary"}
                    />

                    <MyButton to={"/all-courses"} direction={'right'} color={"secondary"} title={"Back"}/>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                    <Card className={classes.root}>
                        <CardHeader color="primary" title="Course Details"/>
                        <CardContent style={{textAlign: 'left'}}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Course Name")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(courseData.name)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Course Code")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(courseData.course_code)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Duration")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(courseData.duration + ' week(s)')}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Contact Hours")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(courseData.contact_hours ? courseData.contact_hours + ' hour(s)' : 0 + ' hour(s)')}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Department")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(courseData.department_name)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Programme")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(courseData.program_name)}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Table
                        title="Assigned Teachers"
                        columns={[
                            {title: 'Teacher', field: 'teacher_name'},
                            {title: 'Set', field: 'class_set'},
                        ]}
                        data={assignedTeachers}
                        options={{
                            exportButton: true, filtering: false,
                            grouping: false, sorting: false,
                            debounceInterval: 1000,
                            selection: false, showTitle: true,
                            pageSize: 5, search: false, toolbar: true,
                            actionsColumnIndex: -1
                        }}
                        localization={{
                            header: {
                                actions: ""
                            }
                        }}
                        actions={[
                            {
                                icon: () => (
                                    <Button
                                        color="default"
                                        variant="contained"
                                        style={{textTransform: 'none'}}
                                        size="small"
                                    >
                                        Unassign
                                    </Button>
                                ),
                                tooltip: 'Unassign teacher from course',
                                onClick: (event, rowData) => unAssignTeacher(event, rowData)
                            }
                        ]}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <Table
                        title={(currentTerm) ? `${currentTerm.name}:  ` + getYear(currentTerm) : ''}
                        columns={[
                            {title: 'Student', field: 'student_name'},
                            {title: 'Score', field: 'score'},
                            {title: 'Points', field: 'points'},
                            {title: 'Class Set', field: 'class_set'},
                        ]}
                        data={currentStudents}
                        options={{
                            exportButton: true, filtering: false,
                            grouping: false, sorting: false,
                            debounceInterval: 1000,
                            selection: false, showTitle: true,
                            pageSize: 5, search: false, toolbar: true,
                            actionsColumnIndex: -1
                        }}
                        localization={{
                            header: {
                                actions: ""
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </div>
    );
};

export default InstitutionCourseDetails;
