import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import {Card, Grid} from '@material-ui/core';
import Utils from "../../utils/utils";
import LearningCenterService from "../../services/learningCenterService";
import MenuItem from "@material-ui/core/MenuItem";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    }
});


class Curricula extends React.Component {
    state = {
        infrastructureData: {
            num_inst_qlty_pols: '',
            num_curr_developed: '',
            num_curr_accr: '',
            num_curr_reviewed: '',
            num_func_qlty_ass_units: '',
            time_ration_theory_pract: '',
            presence_uni_industry: '',
            presence_alum_units: '',
            presence_career_guid: '',
            presence_intern_placement: '',
            presence_prod_unit: '',
            presence_func_qa_unit: '',
            r: ''
        },
        presence_options: [
            {label: "Yes", value: "Yes"},
            {label: "No", value: "No"}
        ]
    };

    // Method to update the form field values for curricula
    handleChange = (name, value) => {
        let infrastructure = {...this.state.infrastructureData};
        if ((name === 'num_func_qlty_ass_units' || name === 'num_curr_developed' || name === 'num_curr_reviewed'
            || name === 'num_inst_qlty_pols' || name === 'num_curr_accr') && Utils.isValid(value)) {
            Utils.displayMessage("warning", "", "Only numbers allowed").then(r => r);
            return;
        }
        infrastructure[name] = value;
        this.setState({
            infrastructureData: infrastructure
        });
    };

    /*
  * Method to set focus on the text fields with invalid data
  * Parameters:
  * + field_id: The id of the field to set the focus on
  * */
    setFocus = (field_id) => {
        Utils.displayMessage('error', 'Error', 'Please specify ' + field_id).then(r => r);
        document.getElementById(field_id).focus();
    };

    // Method to save curricula information of the school
    saveData = () => {
        let data = {...this.state.infrastructureData};

        LearningCenterService.updateLC(data, result => {
            if (result.error) {
                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
            } else {
                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                this.props.getSettingsData(result.inst_details);
            }
        });
    };

    // Method to update the fields when editing
    updateFields = () => {
        let _infrastructure = {...this.state.infrastructureData};
        let _data = this.props.details;

        _infrastructure._id = _data._id;
        _infrastructure.num_inst_qlty_pols = _data.num_inst_qlty_pols;
        _infrastructure.num_curr_developed = _data.num_curr_developed;
        _infrastructure.num_curr_accr = _data.num_curr_accr;
        _infrastructure.num_curr_reviewed = _data.num_curr_reviewed;
        _infrastructure.num_func_qlty_ass_units = _data.num_func_qlty_ass_units;
        _infrastructure.time_ration_theory_pract = _data.time_ration_theory_pract;
        _infrastructure.num_sub_journals = _data.num_sub_journals;
        _infrastructure.presence_uni_industry = _data.presence_uni_industry;
        _infrastructure.presence_alum_units = _data.presence_alum_units;
        _infrastructure.presence_career_guid = _data.presence_career_guid;
        _infrastructure.presence_intern_placement = _data.presence_intern_placement;
        _infrastructure.presence_prod_unit = _data.presence_prod_unit;
        _infrastructure.presence_func_qa_unit = _data.presence_func_qa_unit;

        this.setState({
            infrastructureData: _infrastructure
        })
    };

    componentDidMount() {
        this.updateFields();
    };

    render() {
        const {classes} = this.props;
        const infrastructure = {...this.state.infrastructureData};
        return (
            <div>
                <Card>
                    <div style={{marginLeft: 150}}>
                        <form className={classes.root} autoComplete="on">
                            <h2>Curricula Information</h2>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="num_inst_qlty_pols"
                                        name="num_inst_qlty_pols"
                                        label="No. of Quality Assurance Policies"
                                        variant="outlined"
                                        value={infrastructure.num_inst_qlty_pols}
                                        onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                        style ={{width: '43%'}}

                                        fullWidth={true}
                                    />
                                    <TextField
                                        id="num_curr_developed"
                                        name="num_curr_developed"
                                        label="No. of Curricula Developed."
                                        variant="outlined"
                                        value={infrastructure.num_curr_developed}
                                        onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                        style ={{width: '43%'}}
                                        fullWidth={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="num_curr_accr"
                                        name="num_curr_accr"
                                        label="No. of Curricula Accredited."
                                        variant="outlined"
                                        value={infrastructure.num_curr_accr}
                                        onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                        style ={{width: '43%'}}
                                        fullWidth={true}
                                    />
                                    <TextField
                                        id="num_curr_reviewed"
                                        name="num_curr_reviewed"
                                        label="No. of Curricula Reviewed"
                                        variant="outlined"
                                        value={infrastructure.num_curr_reviewed}
                                        onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                        style ={{width: '43%'}}
                                        fullWidth={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="num_func_qlty_ass_units"
                                        name="num_func_qlty_ass_units"
                                        label="No. of Functional Quality Assurance Units"
                                        variant="outlined"
                                        value={infrastructure.num_func_qlty_ass_units}
                                        onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                        style ={{width: '43%'}}
                                        fullWidth={true}
                                    />
                                    <TextField
                                        id="time_ration_theory_pract"
                                        name="time_ration_theory_pract"
                                        label="Theory-Pract. Time Ratio"
                                        variant="outlined"
                                        value={infrastructure.time_ration_theory_pract}
                                        onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                        style ={{width: '43%'}}
                                        fullWidth={true}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="num_sub_journals"
                                        name="num_sub_journals"
                                        label="Num Subscribed Journals"
                                        variant="outlined"
                                        value={infrastructure.num_sub_journals}
                                        onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                        style ={{width: '43%'}}
                                        fullWidth={true}
                                    />
                                    <TextField
                                        id="presence_uni_industry"
                                        name="presence_uni_industry"
                                        select
                                        label="Presence of Uni-Industry Linkage"
                                        value={infrastructure.presence_uni_industry}
                                        onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0,
                                            width: '43%'
                                        }}
                                    >
                                        {this.state.presence_options.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>

                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="presence_alum_units"
                                        name="presence_alum_units"
                                        select
                                        label="Presence of Alumni Units"
                                        value={infrastructure.presence_alum_units}
                                        onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0,
                                            width: '43%'
                                        }}
                                    >
                                        {this.state.presence_options.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        id="presence_career_guid"
                                        name="presence_career_guid"
                                        select
                                        label="Presence of Career, Guidance Units"
                                        value={infrastructure.presence_career_guid}
                                        onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0,
                                            width: '43%'
                                        }}
                                    >
                                        {this.state.presence_options.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="presence_intern_placement"
                                        name="presence_intern_placement"
                                        select
                                        label="Internship-Placement Units"
                                        value={infrastructure.presence_intern_placement}
                                        onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0,
                                            width: '43%'
                                        }}
                                    >
                                        {this.state.presence_options.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        id="presence_prod_unit"
                                        name="presence_prod_unit"
                                        select
                                        label="Presence of Production Units"
                                        value={infrastructure.presence_prod_unit}
                                        onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0,
                                            width: '43%'
                                        }}
                                    >
                                        {this.state.presence_options.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="presence_func_qa_unit"
                                        name="presence_func_qa_unit"
                                        select
                                        label="Presence of Functional QA Unit"
                                        value={infrastructure.presence_func_qa_unit}
                                        onChange={e => this.handleChange(e.target.name, e.target.value.trim())}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0,
                                            width: '43%'
                                        }}
                                    >
                                        {this.state.presence_options.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    <div style={{padding: 5}}>
                        <Button variant="contained" color="primary" onClick={this.saveData} style={{margin: 5}}>
                            Save
                        </Button>
                    </div>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(Curricula);


