import React, {useState} from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import {LoginApi} from "../../config/login_config";
import $ from "jquery";
import Utils from "../../utils/utils";
import cookies from 'react-cookies';
import clsx from "clsx";
import {Visibility, VisibilityOff} from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://material-ui.com/">
                EMIS
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(2, 0, 1),
    },
}));

const SignIn = (props) => {
    const classes = useStyles();
    const [credentials, setCredentials] = useState({
        email: '',
        password: ''
    });
    const [displayPassword, setDisplayPassword] = useState(false);

    // Method to update values of the text fields
    const handleChange = (e) => {
        let creds = {...credentials};
        creds[e.target.name] = e.target.value;
        setCredentials(creds);
    };

    // Method to redirect user to the registration component
    const register = () => {
        props.history.push('/register');
    };

    // Method to send in login credentials for authentication
    const login = (e) => {
        e.preventDefault();
        const {email, password} = credentials;
        let r = Math.random().toString(36);
        cookies.save('User-Key', r);
        $.ajax({
            method: "POST",
            url: LoginApi.DEV_BASE_URL + "/authenticate",
            headers: {"UserKey": r},
            data: {"email": email, "password": password, influence: 'emis', r: r},
            dataType: "json",
            success: function (result) {
                let expires = new Date();
                expires = new Date(expires.getTime() + ((result.age - 30) * 1000));
                // expires = new Date(expires.getTime() + (3000));
                cookies.save('token', result.auth_token, {path: '/', expires: expires});
                cookies.save('profile_id', result.profile_id, {path: '/', expires: expires});
                cookies.save('profile', result.profile, {path: '/', expires: expires});
                cookies.save('orgId', result.org, {path: '/', expires: expires});
                cookies.save('user_type', result.user_type);
                cookies.save('userId', result.user_id);
                cookies.save('currentUser',result.user);
                cookies.save('currentUserId',result.user_id);

                if(props.closeDialog){
                    props.closeDialog();
                }else{
                    props.history.push({
                        pathname: '/',
                        user: {username: email, user_type: result.user_type}
                    });
                }
            },
            error: function (jqXHR, textStatus, errorThrown) {
                Utils.displayMessage('error', 'Failed', 'Wrong username or password').then(r => r);
            }
        });
    };

    const handleClickShowPassword = () => {
        setDisplayPassword(!displayPassword);
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline/>
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon/>
                </Avatar>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <form className={classes.form} onSubmit={e => login(e)}>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={credentials.email}
                        onChange={handleChange}
                    />
                    <FormControl className={clsx(classes.margin, classes.textField)} variant="outlined"
                                 fullWidth>
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <OutlinedInput
                            id="password"
                            name="password"
                            type={displayPassword ? 'text' : 'password'}
                            value={credentials.password}
                            onChange={handleChange}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {displayPassword ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            }
                            labelWidth={70}
                        />
                    </FormControl>
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary"/>}
                        label="Remember me"
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        type="submit"
                    >
                        Sign In
                    </Button>
                    OR
                    <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        className={classes.submit}
                        onClick={register}
                    >
                        Register
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="/forgot-password" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                    </Grid>
                    <br/>
                </form>
            </div>
            <Box mt={8}>
                <Copyright/>
            </Box>
        </Container>
    );
};

export default SignIn;
