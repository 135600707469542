import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Card, CardContent, CardHeader, Grid, Typography} from '@material-ui/core';
import MyButton from "../Buttons/Button";
import DepartmentService from "../../services/departmentService";
import ProgressBar from "../ProgressBar/ProgressBar";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    btn: {
        margin: 9
    }
});

const DepartmentDetails = (props) => {
    const _match = props.match;
    const [DepartmentData, setDepartmentData] = useState(null);
    const [displayLoader, setDisplayLoader] = useState(false);

    useEffect(() => {
        setDisplayLoader(true);
    }, []);

    useEffect(() => {
        DepartmentService.loadDepartment(_match.params.departmentID, setDepartmentData);
        setDisplayLoader(false);
    }, [_match.params.departmentID]);

    const classes = useStyles();

    /*
    * Method to display field data of the department
    * Parameters:
    * + data: The data field of the department
    * */
    const displayField = (data) => (
        <Typography className={classes.title} gutterBottom variant="h5">
            {data}
        </Typography>
    );

    return DepartmentData && (
        <div>
            {
                displayLoader ? <div style={{marginBottom: 15}}><ProgressBar/></div> : null
            }
            <div style={{marginBottom: 10}}>
                <MyButton to={{pathname: `${_match.url}/edit-department`, departmentData: DepartmentData}}
                          direction={'left'} color={"primary"}
                          title={"Edit"}
                />
                <MyButton to={"/departments"} direction={'right'} color={"secondary"} title={"Back"}/>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={7}>
                        <Card className={classes.root}>
                            <CardHeader color="primary" title="Department Details"/>
                            <CardContent style={{textAlign: 'left'}}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={4}>
                                        {displayField("Name")}
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={4}>
                                        {displayField(DepartmentData.name)}
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={4}>
                                            {displayField("Location")}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            {displayField(DepartmentData.location)}
                                        </Grid>
                                    </Grid>
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={4}>
                                            {displayField("Head Of Department")}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={4}>
                                            {displayField(DepartmentData.head)}
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};
export default DepartmentDetails;
