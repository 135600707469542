import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from "material-table";
import Card from "@material-ui/core/Card";
import MyButton from "../Buttons/Button";
import CoursesService from "../../services/coursesService";
import Grid from "@material-ui/core/Grid";
import Utils from "../../utils/utils";
import Button from "@material-ui/core/Button";
import LearningCenterService from "../../services/learningCenterService";
import ProgressBar from "../ProgressBar/ProgressBar";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});


const InstitutionCourses = (props) => {

    const header = [
        {title: 'Course', field: 'name'},
        {title: 'Code', field: 'course_code'},
        {title: 'Department', field: 'department_name'},
        {title: 'Duration (Weeks)', field: 'duration'},
        {title: 'Contact Hours', field: 'contact_hours'},
    ];
    const [displayLoader, setDisplayLoader] = useState(false);
    const tableRef = React.createRef();

    const classes = useStyles();

    useEffect(() => {
        setDisplayLoader(true);
    }, []);

    useEffect(() => {
        localStorage.removeItem('courseData');
        localStorage.removeItem('programId');
    });

    /*
    * Method to send delete request for a course
    * Parameters:
    * + rowData: data of the course to delete
    * */
    const deleteCourse = (event, rowData) => {
        let _type = rowData.can_be_deleted ?
            "deleted" :
            (rowData.suspended) ? "activated" : "suspended";
        let text = `Course will be ${_type}, do you want to continue?`;
        Utils.confirmDeleteMessage(text)
            .then((willDelete) => {
                if (willDelete) {
                    if (rowData.can_be_deleted === true) {
                        CoursesService.deleteCourse(rowData._id.$oid, result => {
                            if (result.error) {
                                Utils.displayMessage('error', 'Failed', result.errors[0]);
                            } else {
                                Utils.displayMessage('success', 'Successful', result.success);
                                refreshTable();
                            }
                        })
                    } else {
                        let _suspend = !rowData.suspended;
                        LearningCenterService.suspendItem(rowData._id.$oid, "Academics::Course", _suspend, result => {
                            if (result.error) {
                                Utils.displayMessage('error', 'Failed', result.errors[0]);
                            } else {
                                Utils.displayMessage('success', 'Successful', result.success);
                                refreshTable();
                            }
                        })
                    }
                }
            });
    };

    // Method to refresh table's data list
    const refreshTable = () => {
        tableRef && tableRef.current.onQueryChange();
    };

    /*
    * Method to navigate user to view the details of a course
    * Parameters:
    * + rowData: data of the course to view details for
    * */
    const rowSelect = (event, rowData) => {
        props.history.push(`${props.match.path}/${rowData._id.$oid}`);
    };

    return (
        <div>
            {
                displayLoader ? <div style={{marginBottom: 15}}><ProgressBar/></div> : null
            }
            <Grid container style={{marginBottom: 10}}>
                <MyButton to={`${props.match.url}/new-course`} color={"primary"} title={"Add Course"}/>
            </Grid>
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <Card className={classes.root}>
                        <div>
                            <Table
                                tableRef={tableRef}
                                title="Courses"
                                columns={header}
                                data={query => new Promise((resolve, reject) => {
                                    CoursesService.loadCoursesData(query, resolve, reject,() => {
                                        setDisplayLoader(false);
                                    });
                                })}
                                onRowClick={rowSelect}
                                options={{
                                    exportButton: true, filtering: true,
                                    grouping: true, sorting: true,
                                    debounceInterval: 1000,
                                    selection: false, showTitle: true,
                                    pageSize: 10, search: true, actionsColumnIndex: -1,
                                    rowStyle: rowData => ({
                                        backgroundColor: (rowData.suspended) ? '#DDD' : '#FFF',
                                    })
                                }}
                                localization={{
                                    header: {
                                        actions: ""
                                    }
                                }}
                                actions={[
                                    rowData => ({
                                        icon: () => (
                                            <Button
                                                color={(rowData.can_be_deleted === true) ?
                                                    "secondary" : (rowData.suspended === true) ? "primary" : "default"
                                                }
                                                variant="contained"
                                                style={{textTransform: 'none'}}
                                                size="small"
                                            >
                                                {(rowData.can_be_deleted === true) ?
                                                    "DELETE" : (rowData.suspended === true) ? "ACTIVATE" : "SUSPEND"
                                                }
                                            </Button>
                                        ),
                                        tooltip: 'Delete/Suspend Course',
                                        onClick: (event, rowData) => deleteCourse(event, rowData),
                                    }),
                                    {
                                        icon: 'refresh', tooltip: 'Refresh', isFreeAction: true,
                                        onClick: () => refreshTable(),
                                    }
                                ]}
                            />
                        </div>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default InstitutionCourses;

