import React, {useEffect, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import DoughnutChart from "../../assets/charts/doughnutChart";
import BarChart from "../../assets/charts/barChart";
import CombinedBarChart from "../../assets/charts/combinedBarChart";
import DashboardService from "../../services/dashboardService";
import Tile from '../Tiles';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import Divider from "@material-ui/core/Divider";
import ProgressBar from "../ProgressBar/ProgressBar";
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const Dashboard = () => {

    const [dashboardStudents, setStudentData] = useState({
        students: [0,0,0],
        studs_nationality: [],
        students_fields: [],
        passRates: [],
        student_levels: []
    });

    const [dashboardTeachers, setTeacherData] = useState({
        teachers: [0,0,0],
        qualifications: [],
        staff_nationality: [],
    });
    const [displayLoader, setDisplayLoader] = useState(false);

    /*
    * Method to update students' data
    * Parameters:
    * + studentsData: students data
    * */
    const getStudentData = (studentsData) => {
        let count_data = studentsData.students_count;
        let nats_data = studentsData.nationalities;

        let _dataPointsStudents = {...dashboardStudents};
        _dataPointsStudents.students[0] = count_data.total;
        _dataPointsStudents.students[1] = count_data.female;
        _dataPointsStudents.students[2] = count_data.male;

        _dataPointsStudents.studs_nationality[0] = {name: "Gambian", y: nats_data.gambian};
        _dataPointsStudents.studs_nationality[1] =  {name: "Non Gambian", y: nats_data.non_gambian};

        getFields(studentsData.fields);
        getStudentsLevels(studentsData.levels);
        setStudentData(_dataPointsStudents);
    };

    const getFields = (fields) => {
        let _dataPointsStudents = {...dashboardStudents};

        for(let i = 0; i < fields.length; i++){
            _dataPointsStudents.students_fields[i] =  {name: fields[i].field, y: fields[i].count};
        }
        setStudentData(_dataPointsStudents);
    };

    /*
    * Method to update students' data
    * Parameters:
    * + studentsData: students data
    * */
    const getStudentsLevels = (levels) => {
        let _dataPointsStudents = {...dashboardStudents};

        for(let i = 0; i < levels.length; i++){
            // _dataPointsStudents.student_levels[i] =  {name: levels[i].name, y: levels[i].count}; //FOr bar pie chart
            _dataPointsStudents.student_levels[i] =  {label: levels[i].name, y: levels[i].count};
        }
        setStudentData(_dataPointsStudents);
    };

    /*
    * Method to update teachers' qualifications data
    * Parameters:
    * + qualifications: teachers' qualifications data
    * */
    const teacherQualifications = (qualifications) => {
        let _dataPointsTeachers = {...dashboardTeachers};

        for(let i = 0; i < qualifications.length; i++){
            _dataPointsTeachers.qualifications[i] =  {name: qualifications[i].qualification, y: qualifications[i].count};
        }
        setTeacherData(_dataPointsTeachers);
    };

    /*
    * Method to update teachers' data
    * Parameters:
    * + staffData: teachers data
    * */
    const getTeacherData = (staffData) => {
        let count_data = staffData.teachers_count;
        let nats_data = staffData.nationalities;

        let _dataPointsTeachers = {...dashboardTeachers};
        _dataPointsTeachers.teachers[0] = count_data.total;
        _dataPointsTeachers.teachers[1] = count_data.female;
        _dataPointsTeachers.teachers[2] = count_data.male;

        _dataPointsTeachers.staff_nationality[0] = {name: "Gambian", y: nats_data.gambian};
        _dataPointsTeachers.staff_nationality[1] =  {name: "Non Gambian", y: nats_data.non_gambian};

        teacherQualifications(staffData.qualifications);
        setTeacherData(_dataPointsTeachers);
        setDisplayLoader(false);
    };

    useEffect(() => {
        setDisplayLoader(true);
    }, []);

    useEffect(() => {
        DashboardService.students(getStudentData);
    }, []);

    useEffect(() => {
        DashboardService.teachers(getTeacherData);
    }, []);

    return (

        <div>
            {
                displayLoader ? <div style={{marginBottom: 15}}><ProgressBar/></div> : null
            }
            <Grid container>
                <Grid item xs={12} md={12} lg={4}>
                    <span title="Outlook on the distribution of students and teachers by gender"><HelpOutlineIcon /></span>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={4}>
                    <Tile color="primary" title="Total Students" count={dashboardStudents.students[0]}
                          icon={GroupIcon}/>
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                    <Tile color="secondary" title="Female" count={dashboardStudents.students[1]} icon={PersonIcon}/>
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                    <Tile color="action" title="Male" count={dashboardStudents.students[2]} icon={PersonIcon}/>
                </Grid>
            </Grid>
            <Divider/>
            <Grid container spacing={3}>
                <Grid item xs={12} md={12} lg={4}>
                    <Tile color="primary" title="Total Teachers" count={dashboardTeachers.teachers[0]}
                          icon={GroupIcon}/>
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                    <Tile color="secondary" title="Female" count={dashboardTeachers.teachers[1]} icon={PersonIcon}/>
                </Grid>
                <Grid item xs={12} md={12} lg={4}>
                    <Tile color="action" title="Male" count={dashboardTeachers.teachers[2]} icon={PersonIcon}/>
                </Grid>
            </Grid>
            <br/>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4} lg={6}>
                    <DoughnutChart title={"Number of Teachers by Nationality"} dataPoints={dashboardTeachers.staff_nationality} />
                </Grid>
                <Grid item xs={12} md={8} lg={6}>
                    <DoughnutChart title={"Teachers by Qualification"} dataPoints={dashboardTeachers.qualifications}/>
                    {/*<CombinedBarChart />*/}
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                    <DoughnutChart title={"Number of Students by Nationality"} dataPoints={dashboardStudents.studs_nationality} />
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <DoughnutChart title={"Number of Students by Field"} dataPoints={dashboardStudents.students_fields} />
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                    {/*<DoughnutChart title={"Number of Students by Level"} dataPoints={dashboardStudents.student_levels} />*/}
                    <BarChart title={"Number of Students by Level"} dataPoints={dashboardStudents.student_levels}/>
                </Grid>
            </Grid>
        </div>
    );
};

export default Dashboard;
