import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import {Card, Grid} from '@material-ui/core';
import TermService from "../../services/termService";
import Utils from "../../utils/utils";
import * as cookies from "react-cookies";
import LearningCenterService from "../../services/learningCenterService";
import MenuItem from "@material-ui/core/MenuItem";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
});

class AddTerm extends React.Component {
    state = {
        newTerm: {
            term_name: '',
            start_date: Utils.getToday(),
            end_date: Utils.getToday(),
        },
        school_terms: []
    };

    // Method to update the form field values of a new tern definition
    handleChange = e => {
        let val = e.target.value;
        let term = {...this.state.newTerm};

        term[e.target.name] = val;
        this.setState({
            newTerm: term

        });
    };

    // Method to clear the values of the form fields
    clearForm = () => {
        let _termData = this.state.newTerm;
        _termData.term_name = '';
        _termData.start_date = Utils.getToday();
        _termData.end_date = Utils.getToday();
        this.setState({
            newTerm: _termData
        });
        this.goBack()
    };

    // Method to valid the term definition fields before posting to the server
    validateFields = () => {
        const {term_name} = this.state.newTerm;
        if (term_name === '' || !Utils.validateText(term_name)) return 'term_name';
    };

    /*
    * Method to set focus on the text fields with invalid data
    * Parameters:
    * + field_id: The id of the field to set the focus on
    * */
    setFocus = (field_id) => {
        Utils.displayMessage('error', 'Error', 'Please specify ' + field_id);
        document.getElementById(field_id).focus();
    };

    // Method to send and save data to the server
    saveData = () => {
        let data = {...this.state.newTerm};
        if (data.start_date >= data.end_date) {
            Utils.displayMessage('error', 'Failed', 'Start date can not be greater than or equal to the end date!').then(r => r);
        } else {
            data.start_date = Utils.formatDate(data.start_date);
            data.end_date = Utils.formatDate(data.end_date);

            let result = this.validateFields();
            if (result) {
                this.setFocus(result);
                return;
            }
            TermService.createNewTerm(data, result => {
                if (result.error) {
                    Utils.displayMessage('error', 'Failed', result.errors[0]);
                } else {
                    Utils.displayMessage('success', 'Successful', result.success);
                    this.goBack();
                }
            })
        }
    };

    // Method to return the user to the previous view
    goBack = () => {
        this.props.history.goBack();
    };

    // Method to load term definitions
    loadTermDefinitions = () => {
        LearningCenterService.getTerms(cookies.load('orgId'), (result) => {
            if (result && result.length > 0) {
                let temp = [];
                result.map(term => temp.push({label: term.name, value: term._id.$oid}));
                let _newTerm = {...this.state.newTerm};
                _newTerm.term_name = temp[0].value;
                this.setState({
                    school_terms: temp,
                    newTerm: _newTerm
                })
            }
        });
    };

    componentDidMount() {
        if (this.props.location.termData) {
            localStorage.setItem('termData', JSON.stringify(this.props.location.termData));
        }
        if (this.props.location.termData !== undefined || JSON.parse(localStorage.getItem('termData')) !== null) {
            const _termData = this.props.location.termData ? this.props.location.termData : JSON.parse(localStorage.getItem('termData'));
            this.setState({
                newTerm: _termData
            })
        }
        this.loadTermDefinitions();
    };

    render() {
        const {classes} = this.props;
        let term = this.state.newTerm;

        return (
            <div>
                <div>
                    <Card>
                        <div style={{marginLeft: 150}}>
                            <form className={classes.root} autoComplete="on">
                                <h2>{(this.props.location.termData !== undefined || JSON.parse(localStorage.getItem('termData')) !== null) ? "Edit Term" : "New Term"}</h2>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12}>
                                        {
                                            (this.props.location.termData !== undefined || JSON.parse(localStorage.getItem('termData')) !== null) ?
                                                null
                                                :
                                                <TextField
                                                    required
                                                    id="term_name"
                                                    name="term_name"
                                                    select
                                                    label="Term/Semester"
                                                    value={term.term_name}
                                                    onChange={(e) => this.handleChange(e)}
                                                    variant="outlined"
                                                    style={{
                                                        border: 0,
                                                        outline: 0
                                                    }}
                                                >
                                                    {this.state.school_terms.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>

                                        }
                                        <TextField
                                            id="start_date"
                                            name="start_date"
                                            label="Start Date"
                                            type="date"
                                            value={term.start_date}
                                            onChange={e => this.handleChange(e)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                        <TextField
                                            id="end_date"
                                            name="end_date"
                                            label="End Date"
                                            type="date"
                                            value={term.end_date}
                                            onChange={e => this.handleChange(e)}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                        <Button variant="contained" color="secondary" onClick={this.clearForm} style={{margin: 5}}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={this.saveData} style={{margin: 5}}>
                            Save
                        </Button>
                    </Card>
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(AddTerm);


