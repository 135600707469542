import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Table from "material-table";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import {Grid} from "@material-ui/core";
import CoursesService from "../../services/coursesService";
import Utils from "../../utils/utils";
import ProgressBar from "../ProgressBar/ProgressBar";
import TextField from "@material-ui/core/TextField/TextField";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const EnterScores = (props) => {
    const _match = props.match;

    const filter = createFilterOptions();
    const [studentGrades, setStudentGrades] = useState([]);
    const [titles, setTitles] = useState([{title: 'Student', field: 'name', editable: 'never'},{title: 'Number', field:'number', editable: 'never'},{title: 'Score', field: 'score',editable:'always'}]);
    const [tableTitle, setTableTitle] = useState("Enter Scores");
    const [termResults, setTermResults] = useState([]);
    const [displayLoader, setDisplayLoader] = useState(false);
    const [assessmentTypes, setAssessmentTypes] = useState([
        {label: "Assignment", value: "Assignment"},
        {label: "Exams", value: "Exams"}
    ]);
    const [assessments, setAssessments] = useState([]);
    const [assessment, setAssessment] = useState({max_score: 0, weight: 0, name: '', type:'Assignment'})
    useEffect(() => {
        setDisplayLoader(true);
    }, []);

    useEffect(() => {
        CoursesService.getEnterScoreData(_match.params.courseId, _match.params.setName, updateAssessments);
    }, [_match.params.courseId, _match.params.setName]);

    /*
    * Method to display the names of the assessments in the select field for assessments
    * + terms_results: Array of term results that has the different assessment names
    *
    * */
    const updateAssessments = (term_results) => {
        if (term_results.length > 0) {
            setTermResults(term_results);
            let uniques = {};
            term_results.forEach(res => {
                if(res.term_score){
                    res.term_score.forEach(score => {
                        if(uniques[score.assignment_name] === undefined) {
                            uniques[score.assignment_name] =
                                {title: score.assignment_name, name: score.assignment_name, type:score.assignment_type, max_score: score.max_score, weight: score.weight};
                        }
                    });

                }
            });
            setAssessments(Object.values(uniques));
        }
        setDisplayLoader(false);
    };

    /*
    * Method to display the scores table data
    * + assignment: Assignment object to enter score for
    *
    * */
    const setTableData = (assignment) => {
        const name = assignment.name;
        let _data = [];
        if (termResults) {
            termResults.forEach(result => {
                let score = 0;
                let id = null;
               if(result.term_score){

                   for(let i =0; i < result.term_score.length; i++){
                        if(result.term_score[i].assignment_name === name){
                            score = result.term_score[i].score;
                            id = result.term_score[i]._id.$oid;
                            break;
                        }
                   }
               }
                _data.push({name: result.student_name, number: result.student_number, score: score, id: id, student_id: result.student_id, result_id: result._id.$oid});
            });
            setStudentGrades(_data);
        }
    };

    /*
    * Method to update the field values
    * + attr: The field name to update a value for
    * + value: The data to put in the field name
    * */
    const handleChange = (attr, value) => {
        let ass = Object.assign({}, assessment);
        ass[attr] = value;
        setAssessment(ass);

    };

    /*
    * Method to update the an assessment
    * + assignment: Assignment object
    *
    * */
    const loadMeta = (assignment) => {
        if(assignment.max_score && assignment.weight){
            setAssessment(assignment);
        }
    };


    const setValue = (assignment) => {
        handleChange('name', assignment.name);
        loadMeta(assignment);
        setTableData(assignment);
    };

    // Method to return the user to the previous component
    const goBack = () => {
        props.history.goBack();
    };

    // Method to save scores for a course.
    const saveScores = () => {
        CoursesService.enterStudentsScores(studentGrades, assessment, goBack);
    };

    const classes = useStyles();
    return (
        <div>
            {
                displayLoader ? <div style={{marginBottom: 15}}><ProgressBar/></div> : null
            }
            <Grid container>
                <Grid item xs={12} sm={12} md={12}>
                    <Card className={classes.root}>
                        <div>
                            <Card>
                                <div>
                                    <form className={classes.root} autoComplete="on">
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <Autocomplete
                                                    value={assessment.name}
                                                    onChange={(event, newValue) => {
                                                        if (typeof newValue === 'string') {
                                                            setValue({
                                                                name: newValue, title: newValue
                                                            });
                                                        } else if (newValue && newValue.inputValue) {
                                                            // Create a new value from the user input
                                                            setValue({
                                                                name: newValue.inputValue, title: newValue.inputValue,
                                                            });
                                                        } else {
                                                            setValue(newValue);
                                                        }
                                                    }}
                                                    filterOptions={(options, params) => {
                                                        const filtered = filter(options, params);

                                                        // Suggest the creation of a new value
                                                        if (params.inputValue !== '') {
                                                            filtered.push({
                                                                name: params.inputValue,
                                                                title: `Add "${params.inputValue}"`,
                                                            });
                                                        }

                                                        return filtered;
                                                    }}
                                                    selectOnFocus
                                                    clearOnBlur
                                                    handleHomeEndKeys
                                                    id="assessmentName"
                                                    options={assessments}
                                                    getOptionLabel={(option) => {
                                                        // Value selected with enter, right from the input
                                                        if (typeof option === 'string') {
                                                            return option;
                                                        }
                                                        // Add "xxx" option created dynamically
                                                        if (option.name) {
                                                            return option.name;
                                                        }
                                                        // Regular option
                                                        return option.title;
                                                    }}
                                                    renderOption={(option) => option.title}
                                                    style={{ width: 300 }}
                                                    freeSolo
                                                    renderInput={(params) => (
                                                        <TextField {...params} label="Choose or create new assignment" variant="outlined" />
                                                    )}
                                                />
                                                <Grid container style={{marginTop: 10}}>
                                                    <Grid xs={12} md={4}>
                                                        <TextField
                                                            required
                                                            id="assessment_type"
                                                            name="assessment_type"
                                                            select
                                                            label="Assessment Type"
                                                            value={assessment.type}
                                                            onChange={(e) => handleChange('type',e.target.value)}
                                                            variant="outlined"
                                                            style={{
                                                                border: 0,
                                                                outline: 0
                                                            }}
                                                        >
                                                            {assessmentTypes.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                </Grid>
                                                    <Grid xs={12} md={4}>
                                                        <TextField
                                                            required
                                                            id="maximum_score"
                                                            name="maximum_score"
                                                            label="Maximum Score"
                                                            variant="outlined"
                                                            value={assessment.max_score}
                                                            onChange={e => handleChange('max_score',e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={4}>
                                                        <TextField
                                                            id="weight"
                                                            name="weight"
                                                            label="Weight"
                                                            variant="outlined"
                                                            value={assessment.weight}
                                                            onChange={e => handleChange('weight', e.target.value)}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </div>
                            </Card>
                        </div>
                        <div>
                            <Table
                                title={tableTitle}
                                columns={titles}
                                data={studentGrades}
                                options={{
                                    exportButton: false, filtering: false,
                                    grouping: false, sorting: false,
                                    debounceInterval: 1000,
                                    selection: false, showTitle: true,
                                    paging: false, search: false
                                }}
                                localization={{
                                    header: {actions: ""}
                                }}
                                editable={{
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                const dataUpdate = [...studentGrades];
                                                const number = oldData.number;

                                                if(assessment.max_score == null || assessment === 0 || (assessment.max_score instanceof String && assessment.max_score.trim() === '')) {
                                                    setStudentGrades([...studentGrades]);
                                                    Utils.displayMessage('error', 'Failed', 'Please enter a valid maximum score');
                                                }else if(newData.score >= parseInt(assessment.max_score)){
                                                    setStudentGrades([...studentGrades]);
                                                    Utils.displayMessage('error', 'Failed', 'Score entered can not be greater than the max score');
                                                }else{
                                                    for(let i = 0; i < dataUpdate.length; i++){
                                                        if(number === dataUpdate[i].number){
                                                            dataUpdate[i] = newData;
                                                            break;
                                                        }
                                                    }
                                                    setStudentGrades([...dataUpdate]);
                                                }
                                                resolve();
                                            }, 1000)
                                        })
                                }}
                            />
                        </div>
                        <Button variant="contained" color="secondary" onClick={goBack} style={{margin: 5}}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={saveScores} style={{margin: 5}}>
                            Save
                        </Button>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default EnterScores;
