import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import {Card, Grid} from '@material-ui/core';
import Utils from "../../utils/utils";
import CoursesService from "../../services/coursesService";
import MenuItem from "@material-ui/core/MenuItem";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
});

class AddCourse extends React.Component {
    state = {
        newCourse: {
            name: '',
            course_code: '',
            duration: '',
            contact_hours: '',
            departmentId: {},
            programId: '',
            number_of_sets: 1
        },
        depts: [],
        programs: [],
    };

    // Method to update the values of the text fields
    handleChange = e => {
        let val = e.target.value;
        let program = {...this.state.newCourse};
        if ((e.target.name === 'duration' || e.target.name === 'contact_hours') && Utils.isValid(val)) {
            Utils.displayMessage("warning", "", "Only numbers allowed").then(r => r);
            return;
        }
        program[e.target.name] = val;
        this.setState({
            newCourse: program
        });
    };

    // Method to clear values of the text fields
    clearForm = () => {
        let _courseData = this.state.newCourse;
        _courseData.name = '';
        _courseData.course_code = '';
        _courseData.duration = '';
        _courseData.contact_hours = '';
        this.setState({
            newCourse: _courseData
        });
        this.props.history.goBack();
    };

    // Method to navigate back to the previous component/view
    goBack = () => {
        this.clearForm();
    };

    // Method validate form fields before sending the data to the server
    validateFields = () => {
        const {name, duration, course_code, departmentId, programId} = this.state.newCourse;
        if (name === '' || !Utils.validateText(name)) return 'name';
        if (course_code !== '' && !Utils.validateText(course_code)) return 'course_code';
        if (duration === '') return 'duration';
        if (departmentId === '' || !Utils.validateText(departmentId)) return 'departmentId';
        if (programId !== '' && !Utils.validateText(programId)) return 'programId';

    };

    /*
    * Method to set focus on the text fields with invalid data
    * Parameters:
    * + field_id: The id of the field to set the focus on
    * */
    setFocus = (field_id) => {
        Utils.displayMessage('error', 'Error', 'Please specify ' + field_id);
        document.getElementById(field_id).focus();
    };

    // Method to send and save data to the server
    saveData = () => {
        let data = this.state.newCourse;
        let result = this.validateFields();
        if (result) {
            this.setFocus(result);
            return
        }

        data.editing = this.state.editing;
        CoursesService.createCourse(data, result => {
            if (result.error) {
                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
            } else {
                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                this.clearForm();
            }
        });
    };

    /*
    * Method load the departments and programme names
    * Parameters:
    * + results: The list of departments to add the course to
    * */
    deptsPrograms = (results) => {
        let _depts = [];

        let _programs = [];
        if (this.props.location.programData) {
            if (results.depts) {
                results.depts.map(dept => _depts.push({label: dept.name, value: dept._id.$oid}));
                let course = {...this.state.newCourse};
                course.departmentId = _depts[0].value;
                _depts.push({label: "None", value: "None"});
                this.setState({
                    depts: _depts,
                    newCourse: course
                })

            }
        } else {
            if (results.depts && results.programs) {
                results.depts.map(dept => _depts.push({label: dept.name, value: dept._id.$oid}));
                results.programs.map(program => _programs.push({label: program.name, value: program._id.$oid}));
                _programs.push({label: "None", value: "None"});
                _depts.push({label: "None", value: "None"});

                if (_programs.length === 0) {
                    Utils.displayMessage('error', 'Error', 'You have to add atleast one program first before you can add a course');
                    this.props.history.goBack()
                } else {
                    let course = {...this.state.newCourse};
                    if (!this.props.location.courseData && JSON.parse(localStorage.getItem('courseData')) === null) {
                        course.departmentId = _depts[0].value;
                        course.programId = _programs[0].value;
                    }
                    this.setState({
                        depts: _depts,
                        programs: _programs,
                        newCourse: course
                    })
                }

            }
        }

    };

    // Method to update the course values with the details of the course received from the query
    getDetails = (result) => {
        this.setState({
            newCourse: {...result.course},
            editing: true
        });
    };

    componentDidMount() {
        if (this.props.location.courseData) {
            localStorage.setItem('courseData', JSON.stringify(this.props.location.courseData));
        }
        if (this.props.location.programData) {
            localStorage.setItem('programId', this.props.location.programData._id.$oid);
        }
        if (this.props.location.programData !== undefined || localStorage.getItem('programId') !== null) {
            let course = {...this.state.newCourse};
            course.programId = (this.props.location.programData) ? this.props.location.programData._id.$oid : localStorage.getItem('programId');
            this.setState({
                newCourse: course
            });
        }
        if (this.props.location.courseData || JSON.parse(localStorage.getItem('courseData'))) {
            let temp = (this.props.location.courseData) ? {...this.props.location.courseData} : {...JSON.parse(localStorage.getItem('courseData'))};
            if (temp.department_id) {
                temp.departmentId = temp.department_id.$oid;
            } else {
                temp.departmentId = temp.department_name;
            }
            temp.programId = temp.program_id.$oid;

            this.setState({
                newCourse: temp,
                editing: true
            });
        } else if (this.props.location.courseData === undefined && localStorage.getItem('courseId')) {
            CoursesService.loadCourse(localStorage.getItem('courseId'), this.getDetails);
            this.setState({
                editing: true
            });
        }
        CoursesService.loadDeptsProgs(this.deptsPrograms);

    };

    render() {
        const {classes} = this.props;
        let course = this.state.newCourse;
        const _depts = this.state.depts;
        const _progs = this.state.programs;

        return (
            <div>
                <Card>
                    <div style={{marginLeft: 150}}>
                        <form className={classes.root} autoComplete="on">
                            {
                                (this.props.location.courseData) ? <h2>Edit Course</h2> : <h2>Add Course</h2>
                            }
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        required
                                        id="name"
                                        name="name"
                                        label="Name"
                                        variant="outlined"
                                        value={course.name}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        id="course_code"
                                        name="course_code"
                                        label="Code"
                                        variant="outlined"
                                        value={course.course_code}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        required
                                        id="duration"
                                        name="duration"
                                        label="Duration (Weeks)"
                                        variant="outlined"
                                        value={course.duration}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        id="contact_hours"
                                        name="contact_hours"
                                        label="Contact Hours"
                                        variant="outlined"
                                        value={course.contact_hours}
                                        onChange={e => this.handleChange(e)}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="departmentId"
                                        name="departmentId"
                                        select
                                        label="Department"
                                        value={course.departmentId}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                    >
                                        {_depts.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {
                                        localStorage.getItem('programId') ?
                                            null
                                            :
                                            <TextField
                                                id="programId"
                                                name="programId"
                                                select
                                                label="Program"
                                                value={course.programId}
                                                onChange={(e) => this.handleChange(e)}
                                                variant="outlined"
                                            >
                                                {_progs.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                    }
                                    <TextField
                                        id="number_of_sets"
                                        name="number_of_sets"
                                        label="Number of Class Sets"
                                        variant="outlined"
                                        value={(this.props.location.classSets) ? Object.keys(this.props.location.classSets).length : course.number_of_sets}
                                        onChange={e => this.handleChange(e)}
                                        type="number"
                                        InputLabelProps={{shrink: true}}
                                        InputProps={{inputProps: {min: 1}}}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    <div style={{padding: 5}}>
                        <Button variant="contained" color="secondary" onClick={this.goBack}
                                style={{margin: 5}}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={this.saveData}
                                style={{margin: 5}}>
                            Save
                        </Button>
                    </div>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(AddCourse);


