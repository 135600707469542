import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Card, CardContent, CardHeader, Grid, Typography} from '@material-ui/core';
import Utils from "../../utils/utils";
import StudentService from "../../services/studentService";
import Table from "material-table";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from '@material-ui/icons/Delete';
import ProgressBar from "../ProgressBar/ProgressBar";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    btn: {
        margin: 9
    }
});

const ChargeDetails = (props) => {
    const _props = props.location;

    const [payments, setPayments] = useState([]);
    const [charge, setCharge] = useState(null);
    const [displayLoader, setDisplayLoader] = useState(false);

    useEffect(() => {
        setDisplayLoader(true);
    }, []);

    useEffect(() => {
        if (_props && _props.student_id) {
            localStorage.setItem('student_id', _props.student_id);
            localStorage.setItem('charge_id', _props.charge_id);
            localStorage.setItem('chargeData', JSON.stringify(_props.chargeData));
        }
    }, []);

    useEffect(() => {
        loadChargeDetails();
        loadStudentPayments();
    }, []);

    // Method to load the charge details
    const loadChargeDetails = () => {
        const charge = (_props.chargeData) ? _props.chargeData : JSON.parse(localStorage.getItem('chargeData'));
        setCharge(charge);
    };

    // Method to load the payments made by a student
    const loadStudentPayments = () => {
        const student_id = localStorage.getItem('student_id') ? localStorage.getItem('student_id') : _props.student_id;
        const charge_id = localStorage.getItem('charge_id') ? localStorage.getItem('charge_id') : _props.charge_id;

        StudentService.getPayments(student_id, charge_id, (result) => {
            setPayments(result);
            setDisplayLoader(false);
        });
    };

    // Method to refresh a charge's total balance after deleting a payment
    const refreshChargeData = (amount) => {
        let _charge = {...charge};
        _charge.balance += amount;
        setCharge(_charge);
    };

    const classes = useStyles();

    /*
    * Method to delete a payment for a charge
    * Parameters:
    * rowData: The payment to be deleted
    *
    * */
    const removePayment = (event, rowData) => {
        let text = 'Payment will be deleted, do you want to continue?';
        let amount_paid = rowData.amount_paid;
        Utils.confirmDeleteMessage(text)
            .then((willDelete) => {
                if (willDelete) {
                    StudentService.deletePayment(rowData._id.$oid, (result) => {
                        if (result.error) {
                            Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r );
                        } else {
                            Utils.displayMessage('success', 'Successful', result.success).then(r => r );
                            refreshChargeData(amount_paid);
                            loadStudentPayments();
                        }
                    });
                }
            });
    };

    /*
    * Method to display field data of the student
    * Parameters:
    * + data: The data field of the student
    * */
    const displayField = (data) => (
        <Typography className={classes.title} gutterBottom variant="h5">
            {data}
        </Typography>
    );

    // Method to return user to the previous view
    const goBack = () => {
        props.history.goBack();
    };

    return charge && (
        <div>
            {
                displayLoader ? <div style={{marginBottom: 15}}><ProgressBar/></div> : null
            }
            <div style={{marginBottom: 10}}>
                <Button variant="contained" color="secondary" onClick={goBack} style={{float: 'right'}}>
                    Back
                </Button>
            </div>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6}>
                    <Card className={classes.root}>
                        <CardHeader color="primary" title="Charge Details"/>
                        <CardContent style={{textAlign: 'left'}}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Charge For")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(charge.charge_name)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(`Amount (${charge.currency})`)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(charge.amount)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(`Balance (${charge.currency})`)}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(charge.balance)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Due Date")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(Utils.dateFormat(charge.due_date))}                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <div>
                        <Table
                            title="Payments"
                            columns={[
                                {
                                    title: 'Amount',
                                    field: 'amount_paid',
                                    render: rowData => `${rowData.currency} ${rowData.amount_paid}`
                                },
                                {
                                    title: 'Balance',
                                    field: 'balance',
                                    render: rowData => `${rowData.currency} ${rowData.balance}`
                                },
                                {
                                    title: 'Date Paid',
                                    field: 'payment_date',
                                    render: rowData => Utils.dateFormat(rowData.payment_date)
                                }
                            ]}
                            data={payments}
                            options={{
                                exportButton: false, filtering: false,
                                grouping: false, sorting: false,
                                debounceInterval: 1000,
                                selection: false, showTitle: true,
                                actionsColumnIndex: -1, pageSize: 5, search: false,
                            }}
                            localization={{
                                header: {
                                    actions: ''
                                }
                            }}
                            actions={[
                                {
                                    icon: () => <IconButton color="secondary"
                                                            aria-label="Delete charge"><DeleteIcon/></IconButton>,
                                    tooltip: 'Delete payment',
                                    onClick: (event, rowData) => removePayment(event, rowData)
                                }
                            ]}
                        />
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default ChargeDetails;
