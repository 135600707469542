import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import MyButton from "../Buttons/Button";
import {Card, Grid} from '@material-ui/core';
import Utils from "../../utils/utils";
import ProgramService from "../../services/programService";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
});

const level_help = <div>
                        <p>ISCED 0: Early childhood education</p>
                        <p>ISCED 1: Primary Education</p>
                        <p>ISCED 2: Lower Secondary Education</p>
                        <p>ISCED 3: Upper Secondary Education</p>
                        <p>ISCED 4: Post-secondary non-tertiary education</p>
                        <p>ISCED 5: Short-cycle Tertiary Education</p>
                        <p>ISCED 6: Bachelors Degree</p>
                        <p>ISCED 7: Masters Degree</p>
                        <p>ISCED 8: Doctorate Degree</p>
                    </div>;

class AddProgram extends React.Component {
    state = {
        newProgram: {
            name: '',
            national_nameId: {},
            isced_level: '',
            duration: '',
            tuition: '',
            entry_requirement: '',
            departmentId: {},
            education_fieldId: {},
            award: '',
            classification: '',
            accreditation_status: '',
            accreditation_number: '',
            program_type: '',
        },
        national_names: [],
        depts: [],
        fields: [],
        editing: false
    };

    // Method to update the form field values of a new programme
    handleChange = e => {
        let val = e.target.value;
        let program = {...this.state.newProgram};
        if ((e.target.name === 'tuition' || e.target.name === 'duration') && Utils.isValid(val)) {
            Utils.displayMessage("warning", "", "Only numbers allowed");
            document.getElementById(e.target.name).focus();
            return;
        }
        program[e.target.name] = val;
        this.setState({
            newProgram: program
        });
    };

    // Method to clear the values of the form fields
    clearForm = () => {
        let _programData = this.state.newProgram;
        _programData.name = '';
        _programData.isced_level = '';
        _programData.duration = '';
        _programData.tuition = '';
        _programData.accreditation_number = '';
        _programData.accreditation_status = '';
        this.setState({
            newProgram: _programData
        });

        this.props.history.goBack();

    };

    // Method to valid the programme fields before posting to the server
    validateFields = () => {
        const {name, award, isced_level, duration, tuition} = this.state.newProgram;

        if (name === '' || !Utils.validateText(name)) return 'name';
        if (award !== '' && !Utils.validateText(award)) return 'award';
        if (isced_level === '') return 'isced_level';
        if (duration === '') return 'duration';
        if (tuition === '' || !Utils.validateText(tuition)) return 'tuition';
    };

    /*
    * Method to set focus on the text fields with invalid data
    * Parameters:
    * + field_id: The id of the field to set the focus on
    * */
    setFocus = (field_id) => {
        Utils.displayMessage('error', 'Error', 'Please specify ' + field_id);
        document.getElementById(field_id).focus();
    };

    // Method to send and save data to the server
    saveData = () => {
        let data = this.state.newProgram;
        let result = this.validateFields();
        if (result) {
            this.setFocus(result);
            return;
        }
        if(data.accreditation_status === "Accredited" && data.accreditation_number === ''){
            Utils.displayMessage('error', 'Error', 'Please specify the accreditation number').then(r => r);
            document.getElementById("accreditation_number").focus();
            return;
        }
        data.editing = this.state.editing;
        ProgramService.createProgram(data, this.clearForm);
    };

    // Method to load the department names, national names for programmes and fields of education
    deptsNationalNames = (results) => {
        let _names = [];
        let _depts = [];
        let _fields = [];
        if (results.names && results.depts && results.fields) {
            results.names.map(name => _names.push({label: name.national_name, value: name._id.$oid}));
            results.depts.map(dept => _depts.push({label: dept.name, value: dept._id.$oid}));
            results.fields.map(field => _fields.push({label: field.name, value: field._id.$oid}));

            _depts.push({label: "None", value: "None"});

            let program = {...this.state.newProgram};
            if (this.props.location.programData === undefined) {
                program.national_nameId = _names[1].value;
                program.departmentId = _depts[0].value;
                program.education_fieldId = _fields[1].value;
            }
            this.setState({
                national_names: _names,
                depts: _depts,
                fields: _fields,
                newProgram: program
            })
        }
    };

    // Method to initialize the select fields of the form
    initialiseFields = () => {
        let program = {...this.state.newProgram};
        program.classification = Utils.classifications[0].value;
        program.duration = Utils.durations[0].value;
        program.award = Utils.awards[0].value;
        program.isced_level = Utils.isced_levels[0].value;
        program.entry_requirement = Utils.requirements[1].value;
        program.accreditation_status = Utils.accreditation[0].value;
        program.program_type = Utils.program_types[0].value;

        this.setState({
            newProgram: program
        })
    };

    // Method to initialize form fields when updating a programme
    getDetails = (result) => {
        this.setState({
            newProgram: {...result.program},
            editing: true
        })
    };

    componentDidMount() {
        if (this.props.location.programData) {
            let temp = {...this.props.location.programData};
            temp.entry_requirement = temp.entry_requirement === 'Wassce' ? temp.entry_requirement.toUpperCase() : temp.entry_requirement;
            temp.education_fieldId = temp.education_field_id.$oid;
            temp.national_nameId = temp.national_name_id;
            if(temp.department_id){
                temp.departmentId = temp.department_id.$oid;
            }else {
                temp.departmentId = temp.department_name;
            }


            this.setState({
                newProgram: temp,
                editing: true
            })
        } else if (this.props.location.programData === undefined && localStorage.getItem('programId')) {
            ProgramService.loadProgramId(localStorage.getItem('programId'), this.getDetails);
            this.setState({
                editing: true

            });
        } else {
            this.initialiseFields();
        }
        ProgramService.loadDeptsNationalNames(this.deptsNationalNames);
    }

    render() {
        const {classes} = this.props;
        const _match = this.props.match;
        let program = this.state.newProgram;
        const _national_names = this.state.national_names;
        const _depts = this.state.depts;
        const _fields = this.state.fields;
        const back_to = (this.props.location.programData) ? Utils.getReturnUrl(_match.url) : '/programs';

        return (
            <div>

                <Card>
                    <div style={{marginLeft: 150}}>
                        <form className={classes.root} autoComplete="on">
                            {
                                (this.props.location.programData) ? <h2>Edit Program</h2> : <h2>Add Program</h2>
                            }
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        required
                                        id="name"
                                        name="name"
                                        label="Name"
                                        variant="outlined"
                                        value={program.name}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        id="national_nameId"
                                        name="national_nameId"
                                        select
                                        label="National Name"
                                        value={program.national_nameId}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                    >
                                        {_national_names.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        required
                                        id="award"
                                        name="award"
                                        select
                                        label="Award"
                                        value={program.award}
                                        onChange={e => this.handleChange(e)}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {Utils.awards.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        id="isced_level"
                                        name="isced_level"
                                        select
                                        label="Isced Level"
                                        value={program.isced_level}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {Utils.isced_levels.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <Tooltip title={level_help}>
                                        <span>
                                            <i className="material-icons">
                                                help_outline
                                            </i>
                                        </span>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        required
                                        id="duration"
                                        name="duration"
                                        label="Duration(Months)"
                                        variant="outlined"
                                        value={program.duration}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        required
                                        id="tuition"
                                        name="tuition"
                                        label="Tuition (GMD)"
                                        variant="outlined"
                                        value={program.tuition}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        required
                                        id="entry_requirement"
                                        name="entry_requirement"
                                        select
                                        label="Requirements"
                                        value={program.entry_requirement}
                                        onChange={e => this.handleChange(e)}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                        helperText="Entry Requirement"
                                    >
                                        {Utils.requirements.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        id="education_fieldId"
                                        name="education_fieldId"
                                        select
                                        label="Field of Education"
                                        value={program.education_fieldId}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                    >
                                        {_fields.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="departmentId"
                                        name="departmentId"
                                        select
                                        label="Department"
                                        value={program.departmentId}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                    >
                                        {_depts.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        id="classification"
                                        name="classification"
                                        select
                                        label="Classification"
                                        value={program.classification}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                    >
                                        {Utils.classifications.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        id="accreditation_status"
                                        name="accreditation_status"
                                        select
                                        label="Accreditation"
                                        value={program.accreditation_status}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                    >
                                        {Utils.accreditation.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        id="accreditation_number"
                                        name="accreditation_number"
                                        label="Accreditation Number"
                                        value={program.accreditation_number}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                        helperText="School Accreditation Number"
                                    />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="program_type"
                                        name="program_type"
                                        select
                                        label="Program Type"
                                        value={program.program_type}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                    >
                                        {Utils.program_types.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    <MyButton to={back_to} color={"secondary"} title={"Cancel"} style={{margin: 5}}/>
                    <Button variant="contained" color="primary" onClick={this.saveData} style={{margin: 5}}>
                        Save
                    </Button>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(AddProgram);


