import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import BackButton from "../Buttons/BackButton";
import {Card, Grid} from '@material-ui/core';
import Utils from "../../utils/utils";
import StudentService from "../../services/studentService";
import MenuItem from "@material-ui/core/MenuItem";
import settingsService from "../../services/settingsService";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
});

class AddCharge extends React.Component {
    state = {
        newCharge: {
            charge_name: '',
            due_date: Utils.getToday(),
            amount: 0,
            studentId: '',
            currency: '',
            studentNationality: '',
            program: '',
            charge_for: '',
            type: '',
            programId: '',
            charge_def_id: ''
        },
        fromUrl: null,
        programs: [],
        header: [
            {title: 'Name', field: 'name'},
            {title: 'Type', field: 'type'},
            {title: 'Award', field: 'award'},
            {title: 'Department', field: 'department_applied_to'},
            {title: 'Program', field: 'program_applied_to'},
            {title: 'Currency', field: 'currency'},
            {title: 'Amount', field: 'amount'},
        ],
        data: [],
        selectData: [],
    };

    // Method to update the form field values of a new programme
    handleChange = e => {
        let val = e.target.value;
        let charge = {...this.state.newCharge};
        charge[e.target.name] = val;

        this.setState({
            newCharge: charge
        });
    };

    // Method to update due date field value
    handleDueChange = (n) => this.setState({
        newCharge: {...this.state.newCharge, due_date: n}
    });

    // Method to update select field value
    handleChangeSelect = e => {
        let val = e.target.value;
        let charge = {...this.state.newCharge};
        charge[e.target.name] = val;

        this.setState({
            newCharge: charge
        });
        this.fillChargeForm(e.target.value);
    };

    // Method to clear form field values
    clearForm = () => {
        let _chargeData = this.state.newCharge;
        _chargeData.due_date = Utils.getToday();
        _chargeData.amount = 0;
        _chargeData.program = {};
        _chargeData.charge_for = {};
        _chargeData.charge_name = '';

        this.setState({
            newCharge: _chargeData
        });
    };

    // Method to send and save charge data to the server
    applyCharge = () => {
        let data = this.state.newCharge;
        data.due_date = Utils.formatDate(data.due_date);

        if (data.studentId === "") {
            data.studentId = localStorage.getItem('studentId');
        }
        if (data.studentNationality === "") {
            data.studentNationality = localStorage.getItem('studentNationality');
        }
        if (data.programId === "") {
            data.programId = localStorage.getItem('programId');
        }
        StudentService.applyCharge(data, this.goBack);
    };

    // Method to update charges list
    getCharges = (charges) => {
        let _selectData = [];
        charges.forEach(charge => {
            _selectData.push({label: charge.name, value: charge._id.$oid});
        });
        this.setState({
            data: [...charges],
            selectData: _selectData,
        });
    };

    // Method to fill select field for charges
    mapCharge = (charges) => {
        let _charges = charges.data.filter(charge => charge.suspended === false);
        if(_charges.length===0) {
            Utils.displayMessage('error', 'Failed', "You cannot add a charge to a student without defining charges, Please add  **Charges ** in the setting module to proceed").then(r => r);
            this.goBack();
        }
    };

    componentDidMount() {
       settingsService.loadCharges(this.mapCharge);

        if (this.props.location.studentData) {
            let _id = this.props.location.studentData.details._id.$oid;
            let _nationality = this.props.location.studentData.details.nationality;
            let charge = {...this.state.newCharge};
            if (this.props.location.studentData.admission == null) {
                Utils.displayMessage('error', 'No Admission', 'Create an Admission to proceed').then(r => r);
                this.props.history.goBack();
            } else {
                let _progId = this.props.location.studentData.admission.program_id.$oid;
                charge.studentId = _id;
                charge.studentNationality = _nationality;
                charge.programId = _progId;
                this.setState({
                    newCharge: charge
                });
                localStorage.setItem('studentData', this.props.location.studentData.details);
                localStorage.setItem('studentId', _id);
                localStorage.setItem('programId', _progId);
                StudentService.getChargesDetails(localStorage.getItem('studentId'), localStorage.getItem('programId'), this.getCharges);
            }
        } else {
            StudentService.getChargesDetails(localStorage.getItem('studentId'), localStorage.getItem('programId'), this.getCharges);
        }
    };

    // Method to initialize fields when you select charge type to apply for the student
    fillChargeForm = (id) => {
        let _charge = {...this.state.newCharge};
        let _data = this.state.data.filter(charge => charge._id.$oid === id)[0];

        _charge.charge_name = id;
        _charge.program = _data.program_applied_to;
        _charge.type = _data.type;
        _charge.charge_for = _data.award;
        _charge.amount = _data.amount;
        _charge.currency = _data.currency;
        _charge.programId = _data.program_id;
        _charge.charge_def_id = _data._id.$oid;
        this.setState({
            newCharge: _charge,
            disableButton: false
        });
    };

    // Method to return user to the previous view
    goBack = () => {
        this.props.history.goBack();
    };

    render() {
        const {classes} = this.props;
        let charge=this.state.newCharge;

        return (
            <div>
                <Card>
                    <div style={{marginLeft: 150}}>
                        <form className={classes.root} autoComplete="on">
                            <h3>Apply charge</h3>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        required
                                        id="charge_name"
                                        name="charge_name"
                                        select
                                        label="Charge Name"
                                        value={charge.charge_name}
                                        onChange={(e) => this.handleChangeSelect(e)}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {this.state.selectData.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    {
                                        (charge.charge_name !== '') ?
                                            <>
                                                <TextField
                                                    required
                                                    id="charge_for"
                                                    name="charge_for"
                                                    label="Charge For"
                                                    value={charge.charge_for}
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    required
                                                    id="program"
                                                    name="program"
                                                    label="Program"
                                                    value={charge.program}
                                                    variant="outlined"
                                                />
                                                <TextField
                                                    required
                                                    id="type"
                                                    name="type"
                                                    label="Charge Type"
                                                    value={charge.type}
                                                    variant="outlined"
                                                />
                                            </> : null
                                    }
                                </Grid>
                            </Grid>
                            {
                                (charge.charge_name !== '')?
                                    <Grid container>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                <DatePicker
                                                    id="due_date"
                                                    name="due_date"
                                                    value={charge.due_date}
                                                    onChange={this.handleDueChange}
                                                    label="Due Date"
                                                    variant="inline"
                                                    inputVariant="outlined"
                                                    format="dd/MM/yyyy"
                                                    defaultDate="dd/MM/yyyy"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            </MuiPickersUtilsProvider>
                                            <TextField
                                                required
                                                id="amount"
                                                name="amount"
                                                label={charge.currency === '' ? "Amount" : "Amount(" + charge.currency + ")"}
                                                variant="outlined"
                                                value={charge.amount}
                                            />
                                        </Grid>
                                    </Grid> : null
                            }
                        </form>
                    </div>
                    <div>
                        <BackButton goBack={this.goBack} color={"secondary"} title={"Cancel"}/>
                        <Button disabled={this.state.disableButton} variant="contained" color="primary"
                                onClick={this.applyCharge} style={{margin: 5}}>
                            Apply
                        </Button>
                    </div>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(AddCharge);


