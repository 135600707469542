import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import {Card, Grid} from '@material-ui/core';
import Utils from "../../utils/utils";
import Table from "material-table";
import SettingsService from "../../services/settingsService";
import ScholarshipService from "../../services/scholarshipService";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
});

class Grading extends React.Component {
    state = {
        placement: {
            start_range: '',
            end_range: '',
            grade: '',
            accreditation: '',
            level_or_points: '',
            gpa: '',
        },
        addData: false,
        placements: [],
        editing: false,
        rowIndex: null
    };

    // Method to update the form field values for grading scheme
    handleChange = e => {
        let val = e.target.value;
        let placement = {...this.state.placement};
        if (e.target.name === 'start_range' && Utils.isValid(val) || e.target.name === 'end_range' && Utils.isValid(val)) {
            Utils.displayMessage("warning", "", "Only numbers allowed");
            return;
        }
        placement[e.target.name] = val;
        this.setState({
            placement: placement
        });
    };

    // Method to clear the values of the form fields
    clearForm = () => {
        let _placement = this.state.placement;

        if (_placement._id) {
            _placement._id = null;
        }
        _placement.start_range = '';
        _placement.end_range = '';
        _placement.grade = '';
        _placement.level_or_points = '';
        _placement.gpa = '';
        _placement.accreditation = '';

        this.setState({
            placement: _placement,
            editing: false,
            rowIndex: null
        });
    };

    // Method to save grading scheme of a school
    saveData = () => {
        let d = this.state.placement;
        let data = {_id: d._id, accreditation: d.accreditation, end_range: d.end_range, gpa: d.gpa, grade: d.grade,
            learning_center_id: (d.learning_center_id) ? d.learning_center_id.$oid : null, level_or_points: d.level_or_points, start_range: d.start_range}
        SettingsService.savePlacements(data, (result) => {
            if (result.error) {
                Utils.displayMessage('error', 'Failed', result.errors[0]);
            } else {
                Utils.displayMessage('success', 'Successful', result.success);
                if (result.placement) {
                    let _data = [...this.state.placements];
                    let _result = result.placement;
                    _result.gpa = _result.gpa === '' ? 0 : _result.gpa;
                    _result.level_or_points = _result.level_or_points === '' ? 0 : _result.level_or_points;
                    this.state.editing ?
                        _data = _data.map(data => {
                            if (data._id.$oid == _result._id.$oid)
                                return _result
                            return data
                        })
                        : _data.push(_result)


                    this.setState({
                        placements: _data
                    });
                    this.clearEdit();
                }
            }
        });
    };

    // Cancel the editing process
    clearEdit = () => {
        this.setState({
            editing: false,
            rowIndex: null
        });
        this.clearForm();
    };

    // Method to display and initialize form when editing
    editPlacement = (event, rowData) => {
        this.setState({
            placement: {...rowData},
            editing: true,
            rowIndex: rowData.tableData.id,
            addData: true
        });
    };

    /*
    * Method to delete a placement
    * Parameters:
    * + rowData: The placement to delete
    * */
    deletePlacement = (event, rowData) => {
        let id = rowData._id.$oid;
        let text = `Placement will be deleted. Do you want to continue?`;
        Utils.confirmDeleteMessage(text)
            .then((willDelete) => {
                if (willDelete) {
                    SettingsService.deletePlacement(id, result => {
                        if (result.error) {
                            Utils.displayMessage('error', 'Failed', result.errors[0]);
                        } else {
                            Utils.displayMessage('success', 'Successful', result.success);
                            let _data = [...this.state.placements];
                            this.setState({
                                placements: _data.filter(placement => placement._id.$oid !== id)
                            });
                        }
                    })
                }
            });
    };

    //method to cancell adding a new placement rule
    cancel = () => {
        let _placement = {...this.state.placement};

        if (_placement._id) {
            _placement._id = null;
        }
        _placement.start_range = '';
        _placement.end_range = '';
        _placement.grade = '';
        _placement.level_or_points = '';
        _placement.gpa = '';
        _placement.accreditation = '';

        this.setState({
            placement: {..._placement},
            editing: false,
            rowIndex: null,
            addData: false
        });
    };

    componentDidMount() {
        if (this.props.gradings) {
            this.setState({
                placements: this.props.gradings
            })
        }
    };

    render() {
        const {classes} = this.props;
        let placement = this.state.placement;

        return (
            <div>
                <Button variant="contained" color="primary" onClick={() => {this.setState({addData: true})}}
                        style={{margin: 5}}
                >
                    {"Add Grading Scheme"}
                </Button>
                <Card>
                    {(this.state.addData === true) ? <div><div style={{marginLeft: 150}}>
                            <form className={classes.root} autoComplete="on">
                                <h3>Add Placement</h3>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <TextField
                                            required
                                            id="start_range"
                                            name="start_range"
                                            label="Start Range"
                                            variant="outlined"
                                            value={placement.start_range}
                                            onChange={e => this.handleChange(e)}
                                        />
                                        <TextField
                                            required
                                            id="end_range"
                                            name="end_range"
                                            label="End Range"
                                            variant="outlined"
                                            value={placement.end_range}
                                            onChange={e => this.handleChange(e)}
                                        />
                                        <TextField
                                            id="grade"
                                            name="grade"
                                            label="Grade"
                                            variant="outlined"
                                            value={placement.grade}
                                            onChange={e => this.handleChange(e)}
                                        />
                                        <TextField
                                            id="accreditation"
                                            name="accreditation"
                                            label="Accreditation"
                                            variant="outlined"
                                            value={placement.accreditation}
                                            onChange={e => this.handleChange(e)}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <TextField
                                            required
                                            id="level_or_points"
                                            name="level_or_points"
                                            label="Level or Point"
                                            variant="outlined"
                                            value={placement.level_or_points}
                                            onChange={e => this.handleChange(e)}
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                        <div style={{padding: 5}}>
                            <Button variant="contained" color="secondary" onClick={this.cancel} style={{margin: 5}}>
                                Close
                            </Button>
                            <Button variant="contained" color="primary" onClick={this.saveData}
                                    style={{margin: 5}}
                            >
                                {this.state.editing ? "Update" : "Add"}
                            </Button>
                        </div>
                        </div> :
                    <div>
                        <Table
                            tableRef={this.state.tableRef}
                            title="Gradings"
                            columns={[
                                {title: 'Start Range', field: 'start_range'},
                                {title: 'End Range', field: 'end_range'},
                                {title: 'Grade', field: 'grade'},
                                {title: 'Accreditation', field: 'accreditation'},
                                {title: 'Level or Point', field: 'level_or_points'},
                            ]}
                            data={this.state.placements}
                            localization={{
                                header: {
                                    actions: ""
                                }
                            }}
                            options={{
                                exportButton: true, filtering: false,
                                grouping: false, sorting: false,
                                debounceInterval: 1000,
                                selection: false, showTitle: true,
                                pageSize: 10,
                                actionsColumnIndex: -1
                            }}
                            actions={[
                                {
                                    icon: () => (
                                        <Button
                                            color="default"
                                            variant="contained"
                                            style={{textTransform: 'none'}}
                                            size="small"
                                        >
                                            EDIT
                                        </Button>
                                    ),
                                    tooltip: 'Edit Placement',
                                    onClick: (event, rowData) => this.editPlacement(event, rowData)
                                },
                                {
                                    icon: () => (
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            style={{textTransform: 'none'}}
                                            size="small"
                                        >
                                            DELETE
                                        </Button>
                                    ),
                                    tooltip: 'Delete Placement',
                                    onClick: (event, rowData) => this.deletePlacement(event, rowData)
                                }
                            ]}
                        />
                    </div>}
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(Grading);


