import React from 'react';
import TextField from "@material-ui/core/TextField/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import withStyles from "@material-ui/core/styles/withStyles";
import SettingsService from "../../services/settingsService";
import Home from "./Home";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
});

class Settings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            number_of_terms: 1,
            terms: {},
            showTermsForm: false,
        };

    };

    // Method to update the form field value for number of terms to be defined
    handleChangeTerms = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };

    // Method to update the form field values of term definitions
    handleChange = (e) => {
        let _terms = {...this.state.terms};
        _terms[e.target.name] = e.target.value;
        this.setState({
            terms: _terms
        });
    };

    // Method to clear the values of the form fields
    clearForm = () => {
        let _terms = {...this.state.terms};
        _terms.term1 = '';
        _terms.term2 = '';
        _terms.term3 = '';

        this.setState({
            terms: _terms
        });
    };

    // Method to display for to add school terms
    addTerms = () => {
        this.setState(prevState => ({
            showTermsForm: !prevState.showTermsForm
        }));
    };

    // Method to send and save data to the server
    saveData = () => {
        let data = this.state.terms;
        SettingsService.createSettings(data, this.clearForm);
    };

    render() {
        const {classes} = this.props;
        const nTerms = parseInt(this.state.number_of_terms);
        return (
            <div>
                <Home/>
                <Button variant="contained" color="primary" onClick={this.addTerms} style={{margin: 5}}>
                    Add Terms
                </Button>
                <Card>
                    {
                        (this.state.showTermsForm) ?
                            <div>
                                <div style={{marginLeft: 100}}>
                                    <form className={classes.root} autoComplete="on">
                                        <Grid container>
                                            <Grid item xs={12} sm={12} md={12}>
                                                <div>
                                                    <TextField
                                                        required
                                                        id="number_of_terms"
                                                        name="number_of_terms"
                                                        label="Number of Terms"
                                                        type="number"
                                                        variant="outlined"
                                                        helperText="Please specify your number of terms (semesters)"
                                                        value={this.state.number_of_terms}
                                                        onChange={e => this.handleChangeTerms(e)}
                                                    />
                                                </div>
                                                <div>
                                                    <TextField
                                                        required
                                                        id={"term1"}
                                                        name={"term1"}
                                                        label="Term 1"
                                                        variant="outlined"
                                                        value={this.state.terms["term1"]}
                                                        onChange={e => this.handleChange(e)}
                                                    />
                                                    {
                                                        (nTerms > 1) ?
                                                            <TextField
                                                                required
                                                                id={"term2"}
                                                                name={"term2"}
                                                                label="Term 2"
                                                                variant="outlined"
                                                                value={this.state.terms["term2"]}
                                                                onChange={e => this.handleChange(e)}
                                                            /> : null
                                                    }
                                                    {
                                                        (nTerms > 2) ?
                                                            <TextField
                                                                required
                                                                id={"term3"}
                                                                name={"term3"}
                                                                label="Term 3"
                                                                variant="outlined"
                                                                value={this.state.terms["term3"]}
                                                                onChange={e => this.handleChange(e)}
                                                            /> : null
                                                    }
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </div>
                                <Button variant="contained" color="secondary" onClick={this.addTerms}
                                        style={{margin: 5}}>
                                    Cancel
                                </Button>
                                <Button variant="contained" color="primary" onClick={this.saveData} style={{margin: 5}}>
                                    Save
                                </Button>
                            </div>
                            : null
                    }
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(Settings);
