import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import {Card, Grid} from '@material-ui/core';
import StaffService from "../../services/staffService";
import Utils from "../../utils/utils";
import CoursesService from "../../services/coursesService";
import MenuItem from "@material-ui/core/MenuItem";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        width: '25ch',
    },
});

class AssignTeacher extends React.Component {
    state = {
        assignTeacher: {
            teacher_id: {},
            course_id: '',
            course_set: {}
        },
        teachers: [],
        classSets: [],
    };

    // Method to update form value fields
    handleChange = e => {
        let val = e.target.value;
        let assign_teacher = {...this.state.assignTeacher};
        assign_teacher[e.target.name] = val;

        this.setState({
            assignTeacher: assign_teacher
        });
    };

    // Method to clear the form fields
    clearForm = () => {
        let _assign_courseData = {...this.state.assignTeacher};
        this.setState({
            assignTeacher: _assign_courseData
        });
    };

    // Method to send the data to the server
    saveData = () => {
        let data = this.state.assignTeacher;
        if (this.props.location.courseData) {
            data.course_id = this.props.location.courseData._id.$oid;
        }
        if (data.teacher_id.label) {
            data.teacher_id = data.teacher_id.value;
        }
        if (data.course_id === "") {
            data.course_id = localStorage.getItem('courseId')
        }
        CoursesService.assignTeacher(data, (result) => {
            if(result.error){
                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
            }else{
                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                this.goBack();
            }
        });
    };

    // Method update the list of teachers for the select field
    loadTeachers = (teachers) => {

        let _teacher = [];
        if (teachers && teachers.length > 0) {
            teachers.map(teacher => _teacher.push({
                label: `${teacher.first_name} ${teacher.middle_name} ${teacher.last_name}`,
                value: teacher._id.$oid
            }));

            let _assignTeacher = {...this.state.assignTeacher};
            _assignTeacher.teacher_id = _teacher[0].value;
            this.setState({
                teachers: _teacher,
                assignTeacher: _assignTeacher
            })
        }
    };

    updateClassSets = (sets) => {
        let _sets = [...this.state.classSets];
        sets.forEach(_set => _sets.push({label: _set.name, value: _set._id.$oid}));

        this.setState({classSets: _sets});
    };

    // Method to retrun to the previous view
    goBack = () => {
        this.props.history.goBack();
        localStorage.removeItem('courseId');
    };

    // Method to initialize the select fields of the form
    initialiseField = () => {
        let _assignTeacher = {...this.state.assignTeacher};
        _assignTeacher.course_set = Utils.sets[0].value;
        this.setState({
            assignTeacher: _assignTeacher
        });
    };

    componentDidMount() {
        if (this.props.location.courseData) {
            let _assignTeacher = {...this.state.assignTeacher};
            _assignTeacher.course_id = this.props.location.courseData._id.$oid;
            localStorage.setItem('courseId', this.props.location.courseData._id.$oid);
            this.setState({
                assignTeacher: _assignTeacher
            })
        }
        this.initialiseField();
        StaffService.loadAllTeachers(this.loadTeachers);
        CoursesService.getClassSets(localStorage.getItem('courseId') || this.props.location.courseData._id.$oid, this.updateClassSets);
    }

    render() {
        const {classes} = this.props;
        const _teachers = this.state.teachers;
        const _assignTeacher = {...this.state.assignTeacher};
        return (
            <div>
                <Card>
                    <div style={{marginLeft: 150}}>
                        <form className={classes.root} noValidate={false} autoComplete="on">
                            <h2>Assign Teacher</h2>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="teacher_id"
                                        name="teacher_id"
                                        select
                                        fullWidth
                                        label="Teachers"
                                        value={_assignTeacher.teacher_id}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                    >
                                        {_teachers.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        id="course_set"
                                        name="course_set"
                                        select
                                        fullWidth
                                        label="Set(Group)"
                                        value={_assignTeacher.course_set}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                    >
                                        {this.state.classSets.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    <Button variant="contained" color="secondary" onClick={this.goBack} style={{margin: 5}}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={this.saveData} style={{margin: 5}}>
                        Assign
                    </Button>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(AssignTeacher);


