import React from 'react';
import Table from 'material-table';
import TermService from "../../services/termService";
import Button from "@material-ui/core/Button";
import Utils from "../../utils/utils";
import LearningCenterService from "../../services/learningCenterService";
import * as cookies from "react-cookies";
import {Grid} from "@material-ui/core";
import TextField from "@material-ui/core/TextField/TextField";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from "@material-ui/core/DialogTitle";
import ProgressBar from "../ProgressBar/ProgressBar";

class Terms extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();

        this.state = {
            header: [
                {title: 'Term', field: 'name'},
                {title: 'Start Date', field: 'start_date', render: rowData => Utils.dateFormat(rowData.start_date)},
                {title: 'End Date', field: 'end_date', render: rowData => Utils.dateFormat(rowData.end_date)},
                {title: 'Status', field: 'status'}
            ],
            allTermsHeader: [
                {title: 'Name', field: 'name'}
            ],
            terms: [],
            currentTerms: [],
            previousTerms: [],
            termData: null,
            availableTerms: [],
            switch_terms: false,
            reRenderTable: true,
            showOfficialTerms: false,
            editGeneralTerm: false,
        };
    }

    /*
    * Method to open/close a school term for an academic year
    * Parameters:
    * + rowData: The school term to be opened or closed
    * */
    openOrCloseTerm = (event, rowData) => {
        let _newTerm = {...rowData};
        let question = (_newTerm.status === "open") ? "close" : "open";
        _newTerm.status = (_newTerm.status === "open") ? "closed" : "open";

        let text = `Do you want to ${question} this term?`;
        Utils.confirmDeleteMessage(text)
            .then((willDelete) => {
                if (willDelete) {
                    TermService.openOrCloseTerm(_newTerm, result => {
                        if (result.error) {
                            Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
                        } else {
                            this.refreshTable();
                            this.setState({
                                reRenderTable: true
                            });
                            Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                        }
                    });
                }
            });
    };

    /*
    * Method to open view to edit a school term for an academic year
    * Parameters:
    * + rowData: The school term to be edited
    * */
    editTerm = (event, rowData) => {
        this.props.history.push({
            pathname: `${this.props.match.url}/open-term`,
            termData: rowData
        });
    };

    /*
    * Method to delete a school term for an academic year
    * Parameters:
    * + rowData: The school term to be deleted
    * */
    deleteTerm = (event, rowData) => {
        if (rowData.status === "open") {
            Utils.displayMessage('error', 'Failed', 'Term is opened! Please close it before deleting.').then(r => r);
        } else {
            let text = `Do you want to delete this term?`;
            Utils.confirmDeleteMessage(text)
                .then((willDelete) => {
                    if (willDelete) {
                        TermService.deleteTerm(rowData._id.$oid, result => {
                            if (result.error) {
                                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
                            } else {
                                this.refreshTable();
                                this.setState({
                                    reRenderTable: true
                                });
                                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                            }
                        });
                    }
                });
        }
    };

    // Method to refresh table data
    refreshTable = () => {
        TermService.loadCurrentTerms(this.getCurrentTerms);
    };

    // Method add a new school term for a particular academic year.
    newTerm = () => {
        if (this.state.availableTerms.length === 0) {
            Utils.displayMessage('warning', 'Failed', 'Please add terms to define them before opening a new term.').then(r => r)
        } else {
            this.props.history.push({pathname: `${this.props.match.url}/open-term`});
        }
    };

    // Method to open form to add different term definitions
    termDefinitions = () => {
        this.props.history.push({
            pathname: `${this.props.match.url}/new-terms`,
            terms: this.state.availableTerms
        });
    };

    // Method to switch between current and previous terms
    switchTermsView = () => {
        this.setState({switch_terms: !this.state.switch_terms});
    };

    // Method to update previous terms table data
    getPrevTerms = (terms) => {
        this.setState({
            previousTerms: terms
        })
    };

    // Method to update current terms table data
    getCurrentTerms = (terms) => {
        this.setState({
            currentTerms: terms
        })
    };

    // Method to close form to edit term definition
    closeEditGenTerm = () => {
        this.setState({
            editGeneralTerm: false
        });
    };

    // Method to open form to edit term definition
    editGeneralTerm = (event, rowData) => {
        this.setState({
            termData: rowData,
            editGeneralTerm: true
        });
    };

    // Method to update the form field values
    handleChange = (e) => {
        let _data = {...this.state.termData};
        _data[e.target.name] = e.target.value;
        this.setState({
            termData: _data
        });
    };

    // Method to create school term definitions
    submitTermData = () => {
        TermService.createTerms(this.state.termData, result => {
            if (result.error) {
                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
            } else {
                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                this.loadTerms();
                this.closeEditGenTerm();
            }
        });
    };

    // Method to load the different school terms data
    loadTerms = () => {
        TermService.loadCurrentTerms(this.getCurrentTerms);
        TermService.loadPreviousTerms(this.getPrevTerms);
        LearningCenterService.getTerms(cookies.load('orgId'), (result) => {
            this.setState({availableTerms: result, displayLoader: false});
        });
    };

    componentDidMount() {
        this.setState({
            displayLoader: true
        });
        this.loadTerms();
        localStorage.removeItem('termData');
    };

    render() {
        return (
            <div>
                {
                    this.state.displayLoader ? <div style={{marginBottom: 15}}><ProgressBar/></div> : null
                }
                <div style={{marginBottom: 10}}>
                    <Button
                        color="primary"
                        variant="contained"
                        onClick={this.termDefinitions}
                        disabled={this.state.availableTerms.length > 0}
                    >
                        Setup Year
                    </Button>

                    <Button
                        variant="contained"
                        color="default"
                        onClick={this.newTerm}
                    >
                        Add Term
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.switchTermsView}
                    >
                        {!this.state.switch_terms ? "Previous Terms" : "Current Terms"}
                    </Button>
                </div>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={8} md={9}>
                        {
                            !this.state.switch_terms ?
                                <Table
                                    tableRef={this.state.tableRef}
                                    title="Current Terms"
                                    columns={this.state.header}
                                    data={this.state.currentTerms}
                                    options={{
                                        exportButton: false, filtering: false,
                                        grouping: false, sorting: true,
                                        debounceInterval: 1000,
                                        selection: false, showTitle: true,
                                        pageSize: 5, search: false,
                                        actionsColumnIndex: -1
                                    }}
                                    localization={{
                                        header: {
                                            actions: ""
                                        }
                                    }}
                                    actions={[
                                        rowData => ({
                                            icon: () => (
                                                <Button
                                                    color="primary"
                                                    variant="contained"
                                                    style={{textTransform: 'none'}}
                                                    size="small"
                                                >
                                                    {(rowData.status === "open") ? "CLOSE" : "OPEN"}
                                                </Button>
                                            ),
                                            tooltip: '',
                                            onClick: (event, rowData) => this.openOrCloseTerm(event, rowData),
                                        }),
                                        {
                                            icon: () => (
                                                <Button
                                                    color="default"
                                                    variant="contained"
                                                    style={{textTransform: 'none'}}
                                                    size="small"
                                                >
                                                    EDIT
                                                </Button>
                                            ),
                                            tooltip: '',
                                            onClick: (event, rowData) => this.editTerm(event, rowData)
                                        },
                                        {
                                            icon: () => (
                                                <Button
                                                    color="secondary"
                                                    variant="contained"
                                                    style={{textTransform: 'none'}}
                                                    size="small"
                                                >
                                                    DELETE
                                                </Button>
                                            ),
                                            tooltip: '',
                                            onClick: (event, rowData) => this.deleteTerm(event, rowData)
                                        }
                                    ]}
                                />
                                :
                                <Table
                                    tableRef={this.state.tableRef}
                                    title="Previous Terms"
                                    columns={this.state.header}
                                    data={this.state.previousTerms}
                                    options={{
                                        exportButton: false, filtering: false,
                                        grouping: false, sorting: true,
                                        debounceInterval: 1000,
                                        selection: false, showTitle: true,
                                        pageSize: 5, search: false,
                                        actionsColumnIndex: -1
                                    }}
                                />
                        }
                    </Grid>
                    <Grid item xs={12} sm={4} md={3}>
                        <Table
                            tableRef={this.state.tableRef}
                            title="Defined Terms"
                            columns={this.state.allTermsHeader}
                            data={this.state.availableTerms}
                            options={{
                                exportButton: false, filtering: false,
                                grouping: false, sorting: true,
                                debounceInterval: 1000,
                                selection: false, showTitle: true,
                                pageSize: 5, search: false,
                                actionsColumnIndex: -1
                            }}
                            localization={{
                                header: {
                                    actions: ""
                                }
                            }}
                            actions={[
                                {
                                    icon: () => (
                                        <Button
                                            color="default"
                                            variant="contained"
                                            style={{textTransform: 'none'}}
                                            size="small"
                                        >
                                            EDIT
                                        </Button>
                                    ),
                                    tooltip: '',
                                    onClick: (event, rowData) => this.editGeneralTerm(event, rowData)
                                },
                            ]}
                        />
                    </Grid>
                </Grid>
                <Dialog open={this.state.editGeneralTerm} onClose={this.closeEditGenTerm}
                        aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Edit Term</DialogTitle>
                    <DialogContent>
                        <TextField
                            id="name"
                            name="name"
                            label="Name"
                            variant="outlined"
                            value={(this.state.termData) ? this.state.termData.name : ''}
                            margin="dense"
                            onChange={e => this.handleChange(e)}
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button color="secondary" onClick={this.closeEditGenTerm} variant="contained">
                            Cancel
                        </Button>
                        <Button onClick={this.submitTermData} color="primary" variant="contained">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default Terms;


