import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from "@material-ui/core/MenuItem";

const SetDialog = (props) => {
    const [group, setGroup] = useState('');

    // Method to update form fields
    const handleChange = (e) => {
        setGroup(e.target.value);
    };

    // Method to add the course to the student's enrollment
    const sendData = () => {
        props.sendData(group);
        setGroup('')
    };

    return (
        <Dialog open={props.open} onClose={props.onClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Select Class Set</DialogTitle>
            <DialogContent>
                <TextField
                    id="course_set"
                    name="course_set"
                    select
                    fullWidth
                    label="Set(Group)"
                    value={group}
                    onChange={(e) => handleChange(e)}
                    variant="outlined"
                >
                    {props.options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextField>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={props.onClose} variant="contained">
                    Cancel
                </Button>
                <Button onClick={sendData} color="primary" variant="contained">
                    Add
                </Button>
            </DialogActions>
        </Dialog>
    );
};


export default SetDialog;
