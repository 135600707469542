import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import StaffService from "../../services/staffService";
import Table from "material-table";
import Card from "@material-ui/core/Card";
import Utils from "../../utils/utils";
import ProgressBar from "../ProgressBar/ProgressBar";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const TeacherCourses = (props) => {
    const tableRef = React.createRef();
    const [courses, setCourses] = useState([]);
    const [displayLoader, setDisplayLoader] = useState(false);

    const header = [
        {title: 'Course', field: 'course_name'},
        {title: 'Set', field: 'class_set'},
        {title: 'Contact Hours', field: 'contact_hours'},
        {title: 'Term', field: 'term'},
        {title: 'Start Date', field: 'academic_year', render: rowData => Utils.dateFormat(rowData.academic_year)},
    ];

    useEffect(() => {
        setDisplayLoader(true);
    }, []);

    useEffect(() => {
        StaffService.getCourses(result => setCourses(result.data));
        localStorage.removeItem('course_id');
        setDisplayLoader(false);
    }, []);

    const classes = useStyles();

    /*
    * Method to view the details of a course
    * parameters:
    * + rowData: The course data to view
    * */
    const rowSelect = (event, rowData) => {
        props.history.push(`${props.match.path}/${rowData.course_id}/${rowData.class_set}`);
    };

    // Method to refresh table data list
    const refreshTable = () => {
        tableRef && tableRef.current.onQueryChange();
    };

    return (
        <div>
            {
                displayLoader ? <div style={{marginBottom: 15}}><ProgressBar/></div> : null
            }
            <Card className={classes.root}>
                <div>
                    <Table
                        tableRef={tableRef}
                        title="My Courses"
                        columns={header}
                        data={courses}
                        onRowClick={rowSelect}
                        options={{
                            exportButton: true, filtering: true,
                            grouping: true, sorting: true,
                            debounceInterval: 1000,
                            selection: false, showTitle: true,
                            pageSize: 10
                        }}
                        actions={[
                            {
                                icon: 'refresh', tooltip: 'Refresh', isFreeAction: true,
                                onClick: () => refreshTable(),
                            }
                        ]}
                    />
                </div>
            </Card>
        </div>
    );
};

export default TeacherCourses;
