import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import InstitutionDetails from "./Institution_Details";
import Grading from "./Grading";
import Infrastructure from "./Infrastructure";
import SettingsService from "../../services/settingsService";
import Charges from "./Charges";
import * as cookies from "react-cookies";
import LearningCenterService from "../../services/learningCenterService";
import Curricula from "./Curricula";
import Monitoring from "./Monitoring";
import SchoolLab from "./SchoolLab";
import ProgressBar from "../ProgressBar/ProgressBar";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function Home(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [inst_details, setInstDetails] = useState({details: null, grading: null});
    const [gradings, setGradings] = useState({gradings: null});
    const [displayLoader, setDisplayLoader] = useState(false);

    const getSettingsData = (lc_details) => {
        const _settings = {...inst_details};
        _settings.details = {...lc_details};
        setInstDetails(_settings);
        setDisplayLoader(false);
    };

    useEffect(() => {
        setDisplayLoader(true);
    }, []);

    useEffect(() => {
        LearningCenterService.loadSettings(cookies.load('orgId'), getSettingsData);
    }, []);

    useEffect(() => {
        LearningCenterService.loadGrades(cookies.load('orgId'), (gradings) => {
            setGradings(gradings)
        });
    }, []);

    // Method to reload settings details
    const refreshSettings = () => {
        SettingsService.loadSettings(getSettingsData);
    };

    // Method to handle updating form field values
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return inst_details.details && (
        <div className={classes.root}>
            {
                displayLoader ? <div style={{marginBottom: 15}}><ProgressBar/></div> : null
            }
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                    <Tab label="Details" {...a11yProps(0)} />
                    <Tab label="Grading" {...a11yProps(1)} />
                    <Tab label="Charges" {...a11yProps(2)} />
                    <Tab label="Infrastructure" {...a11yProps(3)} />
                    <Tab label="Curricula" {...a11yProps(4)} />
                    <Tab label="Laboratories" {...a11yProps(5)} />
                    <Tab label="Monitoring" {...a11yProps(6)} />
                </Tabs>
            </AppBar>

            <TabPanel value={value} index={0}>
                <InstitutionDetails details={inst_details.details} getSettingsData={getSettingsData}
                                    callback={refreshSettings}/>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Grading gradings={gradings}/>
            </TabPanel>
            <TabPanel value={value} index={2}>
                <Charges/>
            </TabPanel>
            <TabPanel value={value} index={3}>
                <Infrastructure details={inst_details.details} getSettingsData={getSettingsData}
                                callback={refreshSettings}/>
            </TabPanel>
            <TabPanel value={value} index={4}>
                <Curricula details={inst_details.details} getSettingsData={getSettingsData} callback={refreshSettings}/>
            </TabPanel>
            <TabPanel value={value} index={5}>
                <SchoolLab/>
            </TabPanel>
            <TabPanel value={value} index={6}>
                <Monitoring details={inst_details.details} getSettingsData={getSettingsData}
                            callback={refreshSettings}/>
            </TabPanel>
        </div>
    );
}
