import React from 'react';
import userManual from "../../../user_manual.pdf";

const UserManual = () => {
    return (
        <iframe
            title="file"
                style={{ width: '70%', height: '100%', position:'fixed', top:70, left:300, bottom:0, right:0,
                    border: 'none', margin:0, padding:0, overflow: 'hidden', zIndex:999999
                }}
            src={userManual}
        />
    );
};

export default UserManual;
