import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {Route, Switch, BrowserRouter} from 'react-router-dom';
import store from './app/redux/store';
import {createBrowserHistory} from "history";
import indexRoutes from "./app/routes";
import {Provider} from "react-redux";

const _history = createBrowserHistory();

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter history={_history}>
            <Switch>
                {indexRoutes.map((prop, key) => {
                    return <Route path={prop.path} component={prop.component} key={key} />;
                })}
            </Switch>
        </BrowserRouter>
    </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
