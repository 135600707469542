import AppLayout from "../components/Layouts/AppLayout/AppLayout";
import SignIn from "../components/Auth/SignIn";
import Register from "../components/Auth/Register";
import ResetPasswordEmail from "../components/Auth/ResetPasswordEmail";
import ResetPasswordForm from "../components/Auth/ResetPasswordForm";


const indexRoutes = [
    { path: "/register", component: Register},
    { path: "/login", component: SignIn},
    { path: "/forgot-password", component: ResetPasswordEmail},
    { path: "/reset-password", component: ResetPasswordForm},
    { path: "/", component: AppLayout}
];

export default indexRoutes;
