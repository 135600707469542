import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import {Grid} from "@material-ui/core";
import CoursesService from "../../services/coursesService";
import Utils from "../../utils/utils";
import ProgressBar from "../ProgressBar/ProgressBar";
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const TakeAttendance = (props) => {
    const _match = props.match;
    const [studentAttendances, setStudentAttendances] = useState([]);
    const [courseId, setCourse] = useState(null);
    const [displayLoader, setDisplayLoader] = useState(false);
    const [useDate, setDate] = useState(new Date().toISOString().substring(0, 10));

    const updateAssessments = (_term_results) => {
        if (_term_results.length > 0) {

            let term_results = _term_results;
            let term_result_length = term_results.length;

            let _data = [];
            for (let i = 0; i < term_result_length; i++) {
                let temp = {};
                if (term_results[i].attendance !== null)
                    temp.id = term_results[i].attendance._id.$oid;
                temp.student_name = term_results[i].student_name;
                temp.course_name = term_results[i].course_name;
                temp.date = term_results[i].date;
                temp.enrollment_id = term_results[i].enrollment_id;
                temp.class_set = term_results[i].class_set;
                temp.status = term_results[i].attendance !== null ? getStatus(term_results[i].attendance) : 1;
                temp.student_id = term_results[i].student_id;
                _data.push(temp);
            }
            setStudentAttendances(_data);
            setDisplayLoader(false);
        }
    };

    const getStatus = (attendance)=>{
        let v = null;
        if(attendance.status === 'Present')
            v = 1
        else if(attendance.status === 'Late')
            v = 2
        else if (attendance.status === 'Absent')
            v = 3
        else
            v = 4
        return v;
    };

    useEffect(() => {
        setDisplayLoader(true);
    }, []);

    useEffect(() => {
        setCourse(_match.params.courseId);
        CoursesService.getEnterAttendance(_match.params.courseId, _match.params.setName, useDate, updateAssessments);
    }, [_match.params.courseId, _match.params.setName]);

    // Method to return the user to the previous view
    const goBack = () => {
        props.history.goBack();
    };

    // Method to update the fields' values
    const handleChange = (e) => {
        setDate(e.target.value);
        CoursesService.getEnterAttendance(_match.params.courseId, _match.params.setName, e.target.value, updateAssessments);
    };

    // Method to send the request to save attendance for a course
    const saveAttendance = () => {

        studentAttendances.forEach((student) => {
            student.date = useDate;
        });
        CoursesService.takeAttendance(studentAttendances, courseId, (result) =>{
             if(result.error){
                 Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
             }else{
                 Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                 props.history.goBack();
             }
        });
    };

    // Method to get the attendance status of the student
    const handleStatus = (att, e) => {
        let s = [];
        studentAttendances.forEach(student => {
            if(student.student_id === att.student_id) {
                let v = null;
                if(e.target.name === 'present')
                    v = 1;
                else if(e.target.name === 'late')
                    v = 2;
                else if (e.target.name === 'absent')
                    v = 3;
                else
                    v = 4;
                student.status = v;
            }
            s.push(student)
        });
        setStudentAttendances(s);
    };

    const classes = useStyles();
    return courseId && (
        <div>
            {
                displayLoader ? <div style={{marginBottom: 15}}><ProgressBar/></div> : null
            }
            <Grid container>
                <Grid xs={12}>
                    <FormControl className={classes.formControl}>
                        <TextField
                            id="date"
                            label="Date"
                            type="date"
                            value={useDate}
                            className={classes.textField}
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <FormHelperText>Choose the date</FormHelperText>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Card className={classes.root}>
                        <div>
                            <List dense={false}>
                                {studentAttendances.map((att) => {
                                    return <ListItem >
                                        <ListItemText style={{paddingTop: 50}}
                                            primary={att.student_name}
                                            secondary={null}
                                        />
                                        <ListItemSecondaryAction>
                                            <div>
                                                <FormControl component="fieldset" className={classes.formControl}>
                                                    <FormLabel component="legend">Attendance</FormLabel>
                                                    <FormGroup row>
                                                        <FormControlLabel
                                                            control={<Checkbox checked={att.status === 1} onChange={(e) => {handleStatus(att, e)}} name="present" />}
                                                            label="Present"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox checked={att.status === 2} onChange={(e) => {handleStatus(att, e)}} name="late" />}
                                                            label="Late"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox checked={att.status === 3} onChange={(e) => {handleStatus(att, e)}} name="absent" />}
                                                            label="Absent"
                                                        />
                                                        <FormControlLabel
                                                            control={<Checkbox checked={att.status === 4} onChange={(e) => {handleStatus(att, e)}} name="suspend" />}
                                                            label="Suspend"
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </div>
                                        </ListItemSecondaryAction>
                                    </ListItem>}
                                )}
                            </List>
                        </div>
                        <Button variant="contained" color="secondary" onClick={goBack} style={{margin: 5}}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={saveAttendance} style={{margin: 5}}>
                            Save
                        </Button>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default TakeAttendance;
