import $ from "jquery";
import Utils from "../utils/utils";

import {Api} from "../config/api_config";
import * as cookies from "react-cookies";

export default class SettingsService {

    static createSettings(data, callback) {
        const id = (data._id) ? data._id.$oid : '';
        const _method = (id) ? "PUT" : "POST";
        const text = (id) ? "Terms successfully updated" : "Terms successfully added";
        $.ajax({
            method: _method,
            url: Api.DEV_BASE_URL + "/v1/settings/" + id,
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            dataType: "json",
            data: {terms: data},
            success: function (result) {
                Utils.displayMessage('success', 'Successful', text).then(r => r);
                callback();
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Term could not be saved').then(r => r);
            }
        })
    };

    /*
    * Method to load the settings details for a school
    * Parameters:
    * + callback: Method to handle data received from the server
    * */
    static loadSettings = (callback) => {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/v1/settings",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            success: function (result) {
                callback(result.settings);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Settings could not be load').then(r => r)
            }
        });
    };

    /*
    * Method to to save a placement rule
    * Parameters:
    * + data: Placement rule data to be added
    * + callback: Method to handle data received from the server
    * */
    static savePlacements = (data, callback) => {
        let _method = data._id ? "PUT" : "POST";
        let _url = data._id ? "update_placement" : "institution_placements";
        $.ajax({
            method: _method,
            url: Api.DEV_BASE_URL + "/v1/settings/" + _url,
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {placement: data, orgId: cookies.load('orgId')},
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', e.statusText).then(r => r);
            }
        })
    };

    /*
    * Method to to update a placement rule
    * Parameters:
    * + data: Placement rule data to be update
    * + callback: Method to handle data received from the server
    * */
    static update_placement = (data, callback) => {
        $.ajax({
            method: "POST",
            url: Api.DEV_BASE_URL + "/v1/settings/update_placement",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {placement: data},
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', e.statusText).then(r => r);
            }
        })
    };

    /*
    * Method to to create/update a charge definition
    * Parameters:
    * + data: Charge definition to be created or updated
    * + callback: Method to handle data received from the server
    * */
    static createChargeDefinition = (data, callback) => {
        let _method = data._id ? "PUT" : "POST";
        let id = data._id ? data._id.$oid : '';
         $.ajax({
            method: _method,
            url: Api.DEV_BASE_URL + "/v1/charge_definition/"+id,
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: data,
            success: function (result) {
                Utils.displayMessage('success', 'Successful', result.message).then(r => r);
                callback();
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Charge definition could not be saved').then(r => r);
            }
        })
    };

    /*
    * Method to to delete a charge definition
    * Parameters:
    * + id: id of the charge definition to be deleted
    * + callback: Method to handle data received from the server
    * */
    static deleteCharge = (id, callback) => {
        $.ajax({
            method: "DELETE",
            url: Api.DEV_BASE_URL + "/v1/charge_definition/" + id,
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Charge definition could not be saved').then(r => r);
            }
        })
    };

    /*
    * Method to to delete a placement rule
    * Parameters:
    * + id: id of the placement rule to be deleted
    * + callback: Method to handle data received from the server
    * */
    static deletePlacement = (id, callback) => {
        $.ajax({
            method: "DELETE",
            url: Api.DEV_BASE_URL + "/v1/settings/delete_placement",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {placement_id: id},
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Placement could not be deleted').then(r => r);
            }
        })
    };

    /*
    * Method to to load charge definitions
    * Parameters:
    * + callback: Method to handle data received from the server
    * */
    static loadCharges = (callback) => {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/v1/charge_definition",
            data: {
                "attrs":["id", "name","suspended", "can_be_deleted", "currency", "type", "award", "placement", "amount", "currency", "program_applied_to", "department_applied_to"],
                "order": [{"by": "desc", "attr": "name"}],
                "model": "Institution::ChargeDefinition"
            },
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            dataType: "json",
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Departments/National names could not be loaded')
            }
        });
    };

    /*
    * Method to load all charge definitions of a learning center into a data table
    * Parameters:
    * + query: The query sent by the data table
    * + resolve: callback method to process the received list of courses
    * + callback: The method to handle the response coming from the server
    * */
    static loadChargeDefinitions = (query, resolve, reject) => {
        let url = Utils.compileQuery(query, (Api.DEV_BASE_URL + "/v1/charge_definition"),
            ["id", "name","suspended", "can_be_deleted", "currency", "type", "award", "placement", "amount", "currency", "program_applied_to", "department_applied_to"], [{
                "by": "desc",
                "attr": "name"
            }],
            "Institution::ChargeDefinition", null, null, null);
        fetch(url, {
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
        })
            .then(response => {
                return response.json()
            })
            .then(result => {
                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total
                })
            });
    };

    /*
    * Method to load all school laboratories of a learning center into a data table
    * Parameters:
    * + query: The query sent by the data table
    * + resolve: callback method to process the received list of courses
    * + callback: The method to handle the response coming from the server
    * */
    static loadLabs = (query, resolve, reject) => {
        let url = Utils.compileQuery(query, (Api.DEV_BASE_URL + "/v1/school_labs"),
            ["id", "name", "status","internet_band_width","department_id", "department_name"], [{"by": "desc", "attr": "name"}],
            "Institution::Laboratory", null, null, null);
        fetch(url, {
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
        })
            .then(response => {
                return response.json()
            })
            .then(result => {
                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total
                })
            });
    };

    /*
    * Method to to add/edit a school laboratory
    * Parameters:
    * + data: laboratory data to be aded/edited
    * + callback: Method to handle data received from the server
    * */
    static createLaboratory = (data, callback) => {
        let _method = data._id ? "PUT" : "POST";
        let id = data._id ? data._id.$oid : '';
        $.ajax({
            method: _method,
            url: Api.DEV_BASE_URL + "/v1/school_labs/"+id,
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: data,
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Laboratory could not be saved').then(r => r);
            }
        })
    };

    /*
    * Method to to delete a school laboratory
    * Parameters:
    * + id: id of the school lab to be deleted
    * + callback: Method to handle data received from the server
    * */
    static deleteLab = (id, callback) => {
        $.ajax({
            method: "DELETE",
            url: Api.DEV_BASE_URL + "/v1/school_labs/"+id,
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Laboratory could not be deleted').then(r => r);
            }
        })
    };

}
