import $ from "jquery";
import {Api} from "../config/api_config";
import * as cookies from "react-cookies";

export default class SyncService {

    // Method to load data that are eligible for syncing
    static loadAllChanges = () => {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'GET',
                url: Api.DEV_BASE_URL+'/v1/sync/dirty',
                dataType: 'JSON',
                data: {lc_id: cookies.load('orgId').$oid},
                headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"), "UserKey":cookies.load('User-Key')},
                success: (res) => {
                    resolve(res)
                },
                error: function (error) {
                    reject(error)
                }
            })
        })

    };

    /*
    * Method to sync data
    * Parameters:
    * + data: The data to be synced
    * */
    static sync = (data, callback) => {
        return new Promise((resolve, reject) => {
            $.ajax({
                method: 'POST',
                url: Api.DEV_BASE_URL+'/v1/sync/sync_single',
                dataTYpe: 'JSON',
                headers:{"Authorization":"token "+cookies.load("token"), "UserProfile":cookies.load("profile"), "UserKey":cookies.load('User-Key')},
                data: data,
                success: (res) => {
                    resolve(res);
                },
                error: function (error) {
                    reject(error);
                }
            })
        })

    };

}
