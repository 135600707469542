import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import {Card, Grid} from '@material-ui/core';
import Utils from "../../utils/utils";
import LearningCenterService from "../../services/learningCenterService";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
});

class Monitoring extends React.Component {
    state = {
        monitoring: {
            qualification_of_teaching_staff: '',
            num_quality_audit_visits: '',
            num_annual_sup_visits: '',
        }
    };

    // Method to update the form field values of term definitions
    handleChange = e => {
        let val = e.target.value;
        let _monitoring = {...this.state.monitoring};
        if ((e.target.name === 'num_quality_audit_visits' || e.target.name === 'num_annual_sup_visits') && Utils.isValid(val)) {
            Utils.displayMessage("warning", "", "Only numbers allowed").then(r => r);
        }
        _monitoring[e.target.name] = val;
        this.setState({
            monitoring: _monitoring
        });
    };

    // Method to send and save data to the server
    saveData = () => {
        let data = {...this.state.monitoring};

        LearningCenterService.updateLC(data, result => {
            if (result.error) {
                Utils.displayMessage('error', 'Failed', result.errors[0]);
            } else {
                Utils.displayMessage('success', 'Successful', result.success);
                this.props.getSettingsData(result.inst_details);
            }
        });
    };

    // Method to initialize the form field values
    updateFields = () => {
        let _monitoring = {...this.state.monitoring};
        let _data = this.props.details;

        _monitoring._id = _data._id;
        _monitoring.qualification_of_teaching_staff = _data.qualification_of_teaching_staff;
        _monitoring.num_quality_audit_visits = _data.num_quality_audit_visits;
        _monitoring.num_annual_sup_visits = _data.num_annual_sup_visits;

        this.setState({
            monitoring: _monitoring
        })
    };

    componentDidMount() {
        this.updateFields();
    };

    render() {
        const {classes} = this.props;
        let monitoring = this.state.monitoring;
        return (
            <div>
                <Card>
                    <div style={{marginLeft: 150}}>
                        <form className={classes.root} autoComplete="on">
                            <h4>Monitoring and Supervision / Personnel</h4>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="qualification_of_teaching_staff"
                                        name="qualification_of_teaching_staff"
                                        label="Qualifications of teaching staff"
                                        variant="outlined"
                                        value={monitoring.qualification_of_teaching_staff}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        id="num_quality_audit_visits"
                                        name="num_quality_audit_visits"
                                        label="No. of ann. quality audit visits"
                                        variant="outlined"
                                        value={monitoring.num_quality_audit_visits}
                                        onChange={e => this.handleChange(e)}
                                    />
                                    <TextField
                                        id="num_annual_sup_visits"
                                        name="num_annual_sup_visits"
                                        label="No. of ann. supervision visits"
                                        variant="outlined"
                                        value={monitoring.num_annual_sup_visits}
                                        onChange={e => this.handleChange(e)}
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    <div style={{padding: 5}}>
                        <Button variant="contained" color="primary" onClick={this.saveData} style={{margin: 5}}>
                            Save
                        </Button>
                    </div>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(Monitoring);
