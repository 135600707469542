 export const LoginApi = {
    BASE_URL: "api.emis",
    // DEV_BASE_URL: "https://auth0.lecket.gm",
     DEV_BASE_URL: "https://emis-api.moherstportal.gm",
    // DEV_BASE_URL: "http://192.168.2.41:3000",
    // DEV_BASE_URL: "http://localhost:3000",
   AUTH_URL: "",


  };
