import React, { Component} from "react";
import CanvasJSReact from './canvasjs.react'

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

class BarChart extends Component {
    componentDidMount() {
        CanvasJS.addColorSet("greenShades",
            [//colorSet Array

                "#2F4F4F",
                "#008080",
                "#2E8B57",
                "#3CB371",
                "#90EE90"
            ]);
    }

    render() {

        const options = {
            colorSet: "greenShades",
            title: {
                text:this.props.title,
                fontSize: 17
            },
            data: [
                {
                    type: "column",
                    // showInLegend: true,
                    dataPoints: this.props.dataPoints
                }
            ]
        };

        return (
            <div>
                <CanvasJSChart options = {options}/>
            </div>
        );
    }
}

export default BarChart;
