import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import MyButton from "../Buttons/Button";
import Table from "material-table";
import ProgramService from "../../services/programService";
import Card from "@material-ui/core/Card";
import {CardContent, CardHeader, Grid, Typography} from "@material-ui/core";
import CoursesService from "../../services/coursesService";
import * as cookies from "react-cookies";
import Utils from "../../utils/utils";
import ProgressBar from "../ProgressBar/ProgressBar";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListSubheader from '@material-ui/core/ListSubheader';
import List from "@material-ui/core/List/List";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
});

const TeacherCourseDetails = (props) => {
    const _match = props.match;

    const teacherId = cookies.load("userId");

    const [students, setStudents] = useState([]);
    const [assessments, setAssessments] = useState([]);
    const [termResults, setTermResults] = useState([]);
    const [courseData, setCourseData] = useState(null);
    const [displayLoader, setDisplayLoader] = useState(false);

    const header = [
        {title: 'Student', field: 'student_name', editable: 'never'},
        {title: 'Term', field: 'term_name', editable: 'never'},
        {title: 'Score', field: 'score', editable: 'onUpdate'},
        {title: 'Points', field: 'points'},
        // {title: 'GPA', field: 'gpa'}
    ];

    useEffect(() => {
        setDisplayLoader(true);
    }, []);

    useEffect(() => {
        if(_match.params.courseId && _match.params.setName){
            localStorage.setItem('course_id', _match.params.courseId);
            localStorage.setItem('set_name', _match.params.setName);
        }
    },[_match.params.courseId, _match.params.setName]);

    useEffect(() => {
        CoursesService.loadCourse(_match.params.courseId, setCourseData);
        CoursesService.getAssessmentDefinitions(_match.params.courseId, _match.params.setName, setAssessments);
        ProgramService.getStudents(_match.params.courseId, _match.params.setName, setStudents);

        setDisplayLoader(false);
    }, [_match.params.courseId,_match.params.setName]);

    useEffect(() => {
        ProgramService.getStudents(localStorage.getItem('course_id'), localStorage.getItem('set_name'), setStudents);
    }, []);

    const displayField = (data) => (
        <Typography className={classes.title} gutterBottom variant="h5">
            {data}
        </Typography>
    );

    const classes = useStyles();
    return courseData && (
        <div>
            {
                displayLoader ? <div style={{marginBottom: 15}}><ProgressBar/></div> : null
            }
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                    <MyButton color={"warning"} to={{
                        pathname: `${_match.url}/add-scores`,
                        courseData: courseData,
                        teacherId: teacherId,
                        termResults: termResults
                    }} direction={'left'} title={"Enter Scores"}/>
                    <MyButton color={"primary"} to={{
                        pathname: `${_match.url}/take-attendance`,
                        courseData: courseData,
                        teacherId: teacherId,
                        termResults: termResults
                    }} direction={'left'} title={"Attendance"}/>
                    <MyButton to={"/courses"} direction={'right'} color={"secondary"} title={"Back"}/>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={5}>
                    <Card className={classes.root}>
                        <CardHeader color="primary" title="Course Details"/>
                        <CardContent style={{textAlign: 'left'}}>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Course Name")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(courseData.course.name)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Course Code")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(courseData.course.course_code)}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Duration")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(courseData.course.duration+ ' month(s)')}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Contact Hours")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(courseData.course.contact_hours+ ' hour(s)')}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField("Department")}
                                </Grid>
                                <Grid item xs={12} sm={12} md={4}>
                                    {displayField(courseData.course.department_name)}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={12} sm={12} md={7}>

                    <List dense={true} subheader={<ListSubheader>Assessments Given</ListSubheader>} >
                        {assessments.map((ass) => {
                            return <ListItem >
                                <ListItemText
                                              primary={ass.name}
                                              secondary={'type: '+ass.type+', max score: '+ass.max_score}
                                />
                            </ListItem>}
                        )}
                    </List>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12}>
                    <Card className={classes.root}>
                        <div>
                            <Table
                                title="Enrolled Students"
                                columns={header}
                                data={students}
                                options={{
                                    exportButton: false, filtering: true,
                                    grouping: true, sorting: true,
                                    debounceInterval: 1000,
                                    selection: false, showTitle: true,
                                    pageSize: 10
                                }}
                                localization={{
                                    header: {actions: ""}
                                }}
                            />
                        </div>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default TeacherCourseDetails;
