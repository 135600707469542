import $ from "jquery";
import Utils from "../utils/utils";

import {Api} from "../config/api_config";
import * as cookies from "react-cookies";

export default class StaffService {

    /*
    * Method to create/update a staff
    * data: The data of the staff to be added or edited
    + callback: Method to handle the data returned from the server
    *
    * method: POST when creating a new staff
    * method: PUT when editing staff
    * method: PUT when editing staff
    *
    * */

    static createStaff(data, callback) {
        const id = (data._id) ? data._id.$oid : '';
        const _method = (id) ? "PUT" : "POST";

        $.ajax({
            method: _method,
            url: Api.DEV_BASE_URL + "/users/" + id,
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: data,
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Staff could not be saved').then(r => r);
            }
        })
    };

    static loadData = (query, resolve, reject, filter = null, callback) => {
        let url = Utils.compileQuery(query, (Api.DEV_BASE_URL + "/users"),
            ["id","image", "first_name", "middle_name", "last_name", "email", "nationality", "ethnicity", "phone_number", "gender",
                "dob", "rank", "certificated_in", "license", "role", "status", "user_id", "department_name", "password", "deparment"], [{
                "by": "desc",
                "attr": "first_name"
            }],
            "Base::User", null, filter, null);
        fetch(url, {
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            }
        })
            .then(response => {
                return response.json()
            })
            .then(result => {
                resolve({
                    data: result.data,
                    page: query.page,
                    totalCount: result.total
                })
                callback();
            });
    };

    /*
    * Method to get the information of a user
    * Parameters:
    * + id: The id of the staff to get the details of the staff
    * + callback: Method to handle the data returned from the server
    * */
    static loadStaffId = (id, callback) => {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/users/" + id,
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Staff could not be load').then(r => r)
            }
        });
    };

    /*
    * Method to get the courses assigned to a teacher
    * Parameters:
    * + callback: Method to handle the data returned from the server
    * */
    static getCourses = (callback) => {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/v1/teachers/get_courses",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {teacherId: cookies.load('userId')},
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', "Teacher's courses could not be loaded").then(r => r)
            }
        });
    };

    /*
    * Method to create/update a staff
    * id: The id of the staff to be deleted
    + callback: Method to handle the data returned from the server
    * */

    static deleteStaff = (id, callback) => {
        $.ajax({
            method: "DELETE",
            url: Api.DEV_BASE_URL + "/users/" + id,
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', e).then(r => r);
            }
        });
    };

    /*
    * Method to assign a course to a teacher
    * Parameters: The data of the course and the staff to assign the course to
    * + callback: Method to to handle data returned from the server
    *
    * */
    static assignCourse(data, callback) {
        $.ajax({
            method: "POST",
            url: Api.DEV_BASE_URL + "/v1/courses/assign_course",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: data,
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                let err = e.responseJSON.error;
                if (err === undefined)
                    err = "Course current students could not be loaded"
                Utils.displayMessage('error', 'Failed', err).then(r => r);
            }
        })
    };

    /*
    * Method to assign a staff as HOD
    * Parameters:
    * + data: The data of the department and the staff to assign as HOD of the department
    * + callback: Method to handle the data returned from the server
    * */
    static assignHOD(data, callback) {
        $.ajax({
            method: "POST",
            url: Api.DEV_BASE_URL + "/v1/departments/assign_hod",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: data,
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                let err = e.responseJSON.error;
                if (err === undefined)
                    err = 'staff could not be assigned as hod'
                Utils.displayMessage('error', 'Failed', err).then(r => r);
            }
        })
    };


    /*
    * Method to remove a staff as HOD
    * Parameters:
    * + id: The id of the staff to unassign as head of department
    * + callback: Method to handle the data returned from the server
    * */
    static unAssignHOD(id, callback) {
        $.ajax({
            method: "PUT",
            url: Api.DEV_BASE_URL + "/v1/departments/unassign_hod",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {teacher_id: id},
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'staff could not be unassigned as hod').then(r => r);
            }
        })
    };

    static updatePicture(data, callback) {
        $.ajax({
            method: "PUT",
            url: Api.DEV_BASE_URL + "/users/upload_picture",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {staff_id: data.staffId, picture: data.picture},
            success: function (result) {
                console.log("update picture result:", result)
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Could not upload staff picture').then(r => r);
            }
        })
    };

    static LoadImage(id, callback) {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/users/get_image",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {staff_id: id},
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'staff image could not be loaded').then(r => r);
            }
        })
    };


    /*
    * Method to get the employment history of a staff
    * Parameters:
    * + id: The id of the staff to get employment detais for
    * + callback: Method to handle the data returned from the server
    * */
    static getEmpHistory(id, callback) {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/v1/teachers/employment_history",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {user_id: id},
            success: function (result) {
                callback(result.history);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'staff employment history could not be loaded').then(r => r);
            }
        })
    };

    /*
    * Method to terminate the employment of a staff
    * Parameters:
    * + id: The id of the staff to be deleted
    * */
    static terminateEmployment(id) {
        $.ajax({
            method: "PUT",
            url: Api.DEV_BASE_URL + "/users/terminate_employment",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {user_id: id},
            success: function (result) {
                Utils.displayMessage('success', 'Successful', "Staff employment successfully terminated").then(r => r);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Staff employment history could not be terminated').then(r => r);
            }
        })
    };

    /*
    * Method to terminate the employment of a staff
    * Parameters:
    * + id: The id of the staff to be deleted
    * */
    static activateEmployment(id) {
        $.ajax({
            method: "PUT",
            url: Api.DEV_BASE_URL + "/users/activate_employment",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {user_id: id},
            success: function (result) {
                Utils.displayMessage('success', 'Successful', "Staff employment successfully activated").then(r => r);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Staff employment history could not be activated').then(r => r);
            }
        })
    };

    /*
    * Method to load all teachers for select fields
    * Parameters:
    * + callback: Method to handle the data returned from the server
    * */
    static loadAllTeachers = (callback) => {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/users/all_teachers",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            success: function (result) {
                callback(result.teachers);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Teachers could not be loaded').then(r => r);
            }
        })
    };

    /*
    * Method to unassign a course assigned to a teacher
    * Parameters
    * + id: The id of the course teacher assignment record to be deleted
    * + callback: Method to handle the response from the server
    *  */
    static unAssignCourse = (id, callback) => {
        $.ajax({
            method: "DELETE",
            url: Api.DEV_BASE_URL + "/v1/teachers/unassign_course",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {record_id: id},
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Course could not be unassigned from teacher').then(r => r);
            }
        })
    };

    /*
    * Method to update the profile of a staff
    * Parameters
    * + data: The information to update the staff details with
    * + callback: Method to handle the response from the server
    *  */
    static updateProfile = (data, callback) => {
        let id = data._id.$oid;
        $.ajax({
            method: "PUT",
            url: Api.DEV_BASE_URL + "/users/update_profile",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {user_id: id, user: data},
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'User profile could not be updated').then(r => r);
            }
        })
    };

    /*
    * Method to reset the password of a staff
    * Parameters:
    * data: The data of the staff to reset password for
    + callback: Method to handle the data returned from the server
    * */
    static updateCredentials = (data, callback) => {
        let id = data._id.$oid;
        $.ajax({
            method: "PUT",
            url: Api.DEV_BASE_URL + "/users/reset_password",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {user_id: id, user: data},
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Credentials could not be updated').then(r => r);
            }
        })
    };

    /*
    * Method to reset the password of a staff by Admin
    * Parameters:
    * data: The data of the staff to reset password for
    + callback: Method to handle the data returned from the server
    * */
    static adminResetPassword = (data, callback) => {
        $.ajax({
            method: "PUT",
            url: Api.DEV_BASE_URL + "/users/admin_reset_staff_password",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {new_password: data.password,confirm_password: data.confirmPassword, email: data.email},
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Staff password could not be updated').then(r => r);
            }
        })
    };

    /*
    * Method to get the profiles of a user
    * Parameters:
    * + id: The id of the staff to get the details of the staff
    * + callback: Method to handle the data returned from the server
    * */
    static isStaffProfileValid = (id, profile_type, callback) => {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + "/users/is_profile_valid",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {user_id: id, profile_type: profile_type},
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', "Staff's profile could not be loaded").then(r => r)
            }
        });
    };
}




