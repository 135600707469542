import React, { Component} from "react";
import CanvasJSReact from './canvasjs.react'

const CanvasJS = CanvasJSReact.CanvasJS;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

class BarChart extends Component {
    componentDidMount() {
        CanvasJS.addColorSet("greenShades",
            [//colorSet Array

                "#2F4F4F",
                "#008080",
                "#2E8B57",
                "#3CB371",
                "#90EE90"
            ]);
    }

    render() {

        const options = {
            title:{
                // text: "Attendance Rate Over Time",
                text: "Pass Rate Over Time (%)",
                fontSize: 17
            },

            data: [  //array of dataSeries
                { //dataSeries - first quarter
                    /*** Change type "column" to "bar", "area", "line" or "pie"***/
                    type: "column",
                    name: "Boys",
                    showInLegend: true,
                    dataPoints: [
                        { label: "2017", y: 58 },
                        { label: "2018", y: 69 },
                        { label: "2019", y: 80 },
                        { label: "2020", y: 74 },
                    ]
                },
                { //dataSeries - second quarter

                    type: "column",
                    name: "Girls",
                    showInLegend: true,
                    dataPoints: [
                        { label: "2017", y: 63 },
                        { label: "2018", y: 73 },
                        { label: "2019", y: 88 },
                        { label: "2020", y: 77 },
                    ]
                }
            ]
        };

        return (
            <div>
                <CanvasJSChart options = {options}/>
            </div>
        );
    }
}

export default BarChart;
