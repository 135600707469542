import React from 'react';
import MyButton from "../Buttons/Button";
import Table from 'material-table';
import StudentService from "../../services/studentService";
import Button from "@material-ui/core/Button";
import Utils from "../../utils/utils";
import ProgressBar from "../ProgressBar/ProgressBar";

class Students extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef();

        this.state = {
            header: [
                {title: 'Student ID', field: 'student_number'},
                { title: 'Picture', field: 'image', render: item => <img src={item.image} alt="" border="3" height="50" width="50" />},
                {title: 'First Name', field: 'first_name'},
                {title: 'Middle Name', field: 'middle_name'},
                {title: 'Last Name', field: 'last_name'},
                {title: 'Gender', field: 'gender'},
                {title: 'Status', field: 'status'},
            ],
            students: [],
            displayLoader: false
        };
    }

    /*
    * Method to delete a student
    * Parameters:
    * + rowData: The student record to be deleted
    * */
    deleteStudent = (event, rowData) => {
        let text = 'Student will be deleted, do you want to continue?';
        Utils.confirmDeleteMessage(text)
            .then((willDelete) => {
                if (willDelete) {
                    StudentService.deleteStudent(rowData._id.$oid, result => {
                        if (result.error) {
                            Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
                        } else {
                            Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                            this.refreshTable();
                        }
                    });
                }
            });
    };

    /*
    * Method to view the details of a student
    * Parameters:
    * + rowData: The student to view details for
    * */
    rowSelect = (event, rowData) => {
        this.props.history.push(`${this.props.match.path}/${rowData._id.$oid}`);
    };

    // Method to refresh data of the table
    refreshTable = () => {
        this.tableRef && this.tableRef.current.onQueryChange();
    };

    componentDidMount() {
        this.setState({
            displayLoader: true
        });
        localStorage.removeItem('studentId');
        localStorage.removeItem('editing');
        localStorage.removeItem('admissionData');
        localStorage.removeItem('awarding_body_id')
    }

    render() {
        return (
            <div>
                {
                    this.state.displayLoader ? <div style={{marginBottom: 15}}><ProgressBar/></div> : null
                }
                <div style={{marginBottom: 10}}>
                    <MyButton to={`/new-student`} color={"primary"} title={"Add Student"}/>
                </div>
                <div>
                    <Table
                        tableRef={this.tableRef}
                        title="Students"
                        columns={this.state.header}
                        data={query => new Promise((resolve, reject) => {
                            StudentService.loadStudentsData(query, resolve, reject, () => {
                                this.setState({
                                    displayLoader: false
                                });
                            });
                        })}
                        onRowClick={this.rowSelect}
                        options={{
                            exportButton: true, filtering: true,
                            grouping: true, sorting: true,
                            debounceInterval: 1000,
                            selection: false, showTitle: true,
                            pageSize: 10, search: true, actionsColumnIndex: -1
                        }}
                        localization={{
                            header: {
                                actions: ""
                            }
                        }}
                        actions={[
                            {
                                icon: () => (
                                    <Button
                                        color="secondary"
                                        variant="contained"
                                        style={{textTransform: 'none'}}
                                        size="small"
                                    >
                                        Delete
                                    </Button>
                                ),
                                tooltip: 'Delete Student',
                                onClick: (event, rowData) => this.deleteStudent(event, rowData)
                            },
                            {
                                icon: 'refresh', tooltip: 'Refresh', isFreeAction: true,
                                onClick: () => this.refreshTable(),
                            }
                        ]}
                    />
                </div>
            </div>
        );
    }
}

export default Students
