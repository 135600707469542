import $ from "jquery";
import Utils from "../utils/utils";

import {Api} from "../config/api_config";
import * as cookies from "react-cookies";

export default class TermService {

    /*
    * Method to create/edit a school term definition eg Semester 1, Semester 2
    * Parameters:
    * + data: The data of the school term to be added/edited
    * + callback: The method to handle the response coming from the server
    * method is POST if creating
    * method is PUT if editing
    * */
    static createTerms(data, callback) {
        let id = data._id ? data._id.$oid : '';
        let _method = data._id ? "PUT" : "POST";
        $.ajax({
            method: _method,
            url: Api.DEV_BASE_URL + "/v1/school_terms/" + id,
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {terms: data},
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Term could not be saved');
            }
        });
    };

    /*
    * Method to create/edit a school term eg Semester 1 2020-2021, Semester 1 2021-2022
    * Parameters:
    * + data: The data of the school term to be added/edited
    * + callback: The method to handle the response coming from the server
    * method is POST if creating
    * method is PUT if editing
    * */
    static createNewTerm(data, callback) {
        const id = (data._id) ? data._id.$oid : '';
        const _method = (id) ? "PUT" : "POST";
        $.ajax({
            method: _method,
            url: Api.DEV_BASE_URL + "/v1/open_closed_terms/" + id,
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: data,
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Term could not be saved').then(r => r);
            }
        })
    };

    /*
    * Method to open or close a school term
    * Parameters:
    * + data: The data of the school term to be opened/closed
    * + callback: The method to handle the response coming from the server
    * */
    static openOrCloseTerm(data, callback) {
        data.term_id = data._id.$oid;
        $.ajax({
            method: "PUT",
            url: Api.DEV_BASE_URL + "/v1/open_closed_terms/open_or_close_term",
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: data,
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Term could not be open/closed').then(r => r);
            }
        })
    };

    /*
    * Method to delete a shool term
    * Parameters:
    * + id: The id of the school term to be deleted
    * */
    static deleteTerm(id, callback) {
        $.ajax({
            method: "DELETE",
            url: Api.DEV_BASE_URL + "/v1/open_closed_terms/" + id,
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            success: function (result) {
                callback(result);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Term could not be saved').then(r => r);
            }
        })
    };

    /*
    * Method to previous school terms
    * Parameters:
    * + callback: The method to handle the response coming from the server
    * */
    static loadPreviousTerms = (callback) => {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + '/v1/open_closed_terms/get_previous_terms',
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {lcId: cookies.load('orgId')},
            success: function (result) {
                callback(result.previous_terms);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Term could not be saved').then(r => r);
            }
        })
    };

    /*
    * Method to load the current school term
    * Parameters:
    * + callback: The method to handle the response coming from the server
    * */
    static loadCurrentTerms = (callback) => {
        $.ajax({
            method: "GET",
            url: Api.DEV_BASE_URL + '/v1/open_closed_terms/get_current_terms',
            dataType: "json",
            headers: {
                "Authorization": "token " + cookies.load("token"),
                "UserProfile": cookies.load("profile"),
                "UserKey": cookies.load('User-Key')
            },
            data: {lcId: cookies.load('orgId')},
            success: function (result) {
                callback(result.current_terms);
            },
            error: function (e) {
                Utils.displayMessage('error', 'Failed', 'Term could not be saved').then(r => r);
            }
        })
    };
}
