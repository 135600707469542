import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import {Card, Grid} from '@material-ui/core';
import Utils from "../../utils/utils";
import departmentService from "../../services/departmentService";

const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
});

class AddDepartment extends React.Component {
    state = {
        newDepartment: {
            name: '',
            location: '',
        }
    };

    // Method to update the form field values of a new department
    handleChange = e => {
        let val = e.target.value;
        let department = {...this.state.newDepartment};
        department[e.target.name] = val;
        this.setState({
            newDepartment: department
        });
    };

    // Method to clear the values of the form fields
    clearForm = () => {
        let _departmentData = this.state.newDepartment;
        _departmentData.name = '';
        _departmentData.location = '';
        this.setState({
            newDepartment: _departmentData
        });
    };

    // Method to return the user to the previous view/component
    goBack = () => {
        this.clearForm();
        this.props.history.goBack();
    };

    // Method to valid the department fields before posting to the server
    validateFields = () => {
        const {name, location} = this.state.newDepartment;
        if (name === '' || Utils.hasNumber(name)) return 'name';
        if (location === '' || !Utils.validateText(location)) return 'location';
    };

    /*
    * Method to set focus on the text fields with invalid data
    * Parameters:
    * + field_id: The id of the field to set the focus on
    * */
    setFocus = (field_id) => {
        let end = field_id === 'name' ? '. Name must not be only numbers' : '';
        Utils.displayMessage('error', 'Error', 'Please specify a valid ' + field_id + end).then(r => r);
        document.getElementById(field_id).focus();
    };

    // Method to send and save data to the server
    saveData = () => {

        let data = this.state.newDepartment;

        let result = this.validateFields();
        if (result) {
            this.setFocus(result);
            return
        }

        data.editing = this.state.editing;
        departmentService.createDepartment(data, result => {
            if (result.error) {
                Utils.displayMessage('error', 'Error', result.error[0]).then(r => r);
            } else {
                this.goBack();
                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
            }

        });
    };

    componentDidMount() {
        if (this.props.location.departmentData) {
            this.setState({
                newDepartment: {...this.props.location.departmentData},
                editing: true
            });
        } else if (this.props.location.departmentData === undefined && localStorage.getItem('departmentID')) {
            departmentService.loadDepartmentID(localStorage.getItem('departmentID'), this.getDetails);
            this.setState({
                editing: true
            });
        } else {
            //this.initialiseSelectFields();
        }
    }

    render() {
        const {classes} = this.props;
        const _match = this.props.match;
        let department = this.state.newDepartment;
        const back_to = (this.props.location.departmentData) ? Utils.getReturnUrl(_match.url) : '/departments';

        return (
            <div>
                <Grid item xs={12} sm={12} md={6}>
                    <Card>
                        <div>
                            <form className={classes.root} autoComplete="on">
                                {
                                    (this.props.location.departmentData) ? <h2>Edit Department</h2> :
                                        <h2>Add Department</h2>
                                }
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextField
                                            required
                                            id="name"
                                            name="name"
                                            label="Name"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="outlined"
                                            value={department.name}
                                            onChange={e => this.handleChange(e)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={6}>
                                        <TextField
                                            required
                                            id="location"
                                            name="location"
                                            label="Location"
                                            variant="outlined"
                                            value={department.location}
                                            onChange={e => this.handleChange(e)}
                                        />
                                    </Grid>
                                </Grid>
                            </form>
                        </div>
                        <Button variant="contained" color="secondary" onClick={this.goBack} style={{margin: 5}}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="primary" onClick={this.saveData} style={{margin: 5}}>
                            Save
                        </Button>
                    </Card>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(AddDepartment);


