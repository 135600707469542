import DashboardIcon from '@material-ui/icons/Dashboard';
import SettingsIcon from '@material-ui/icons/Settings';
import LayersIcon from '@material-ui/icons/Layers';
import ReportIcon from '@material-ui/icons/Report';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import GroupIcon from '@material-ui/icons/Group';
import PersonIcon from '@material-ui/icons/Person';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import Students from '../components/Students/Students';
import Departments from '../components/Departments/Departments';
import Dashboard from "../components/Dashboard/Dashboard";
import Scholarships from "../components/Scholarships/Scholarships";
import Staff from "../components/Staff/Staff";
import Settings from "../components/Settings/Settings";
import Programs from "../components/Programs/Programs";
import Terms from "../components/School_Term/Terms";
import InstitutionCourses from "../components/Courses/InstitutionCourses";
import Home from "../components/Settings/Home";
import SyncIndex from "../components/Sync/SyncIndex";
import Reports from "../components/Reports/Reports"
import UserManual from "../components/CustomComponents/UserManual";

const dashboard = {
    path: "/",
    name: "Dashboard",
    icon: DashboardIcon,
    component: Dashboard
};

const term = {
    path: "/terms",
    name: "Terms",
    icon: AccessTimeIcon,
    component: Terms
};

const departments = {
    path: "/departments",
    name: "Departments",
    icon: AccountBalanceIcon,
    component: Departments
};

const programs = {
    path: "/programs",
    name: "Programs",
    icon: FolderOpenIcon,
    component: Programs
};

const inst_courses = {
    path: "/all-courses",
    name: "Courses",
    icon: ImportContactsIcon,
    component: InstitutionCourses
};

const scholarships = {
    path: "/scholarships",
    name: "Scholarships",
    icon: LayersIcon,
    component: Scholarships
};

const teachers = {
    path: "/staff",
    name: "Staff",
    icon: GroupIcon,
    component: Staff
};

const students = {
    path: "/students",
    name: "Students",
    icon: PersonIcon,
    component: Students
};

const reports = {
    path: "/reports",
    name: "Reports",
    icon: ReportIcon,
    component: Reports
};

const settings = {
    path: "/settings",
    name: "Settings",
    icon: SettingsIcon,
    component: Home
};

const sync = {
    path: "/sync",
    name: "Sync",
    icon: CloudUploadIcon,
    component: SyncIndex
};

const user_manual = {
    path: "/user-manual",
    name: "User Manual",
    icon: MenuBookIcon,
    component: UserManual
};

const redirect = {redirect: true, path: "/", to: "/schools"};
const adminRoutes = [dashboard,term,departments,programs,inst_courses,scholarships,teachers,students,settings,reports,sync,user_manual];

export default adminRoutes;
