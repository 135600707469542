import React, {useEffect, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import clsx from 'clsx';
import * as cookies from "react-cookies";
import useStyles from "./headerStyles";
import {withRouter} from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import SignIn from "../../Auth/SignIn";
import LearningCenterService from "../../../services/learningCenterService";
import Utils from "../../../utils/utils";
import StaffService from "../../../services/staffService";

const Header = (props) => {
    const classes = useStyles();

    const [anchorEl, setAnchorEl] = useState(null);
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
    const [showLogin, setShowLogin] = useState(false);

    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMenuClose = () => {
        props.history.push('/profile');
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const hideDialog = () => {
        setShowLogin(false);
    };

    const checkSession = () =>{
        setInterval(() => {
            if(!cookies.load('token')){
                setShowLogin(true);
            }
        }, 20000);
    };

    const loadGenericTerms = () => {
        LearningCenterService.getTerms(cookies.load('orgId'), (result) => {
            if(result.length === 0){
                Utils.displayMessage('warning', 'Warning', 'Please Setup Year and open a term before adding more data.', 7000).then(r => r)
            }
        });
    };

    const changeLayout = () => {
        props.history.push('/');
    };

    const switchProfile = (profileType) => {
        let type = profileType === "Administrative" ? "an Admin" : "a Teacher";
        if(cookies.load('user_type') === profileType){
            Utils.displayMessage('error', 'Failed', `Sorry, you are currently logged in as ${type}.`,4000).then(r => r);
        }else{
            StaffService.isStaffProfileValid(cookies.load('userId'), profileType, (result) => {
                if (result.error) {
                    Utils.displayMessage('error', 'Failed', `Sorry, you do not have a valid ${type} profile.`,4000).then(r => r);
                }else{
                    cookies.save('user_type', result.profile.user_type);
                    cookies.save('profile', profileType);
                    cookies.save('profile_id', result.profile._id.$oid);
                    changeLayout();
                }
            });
        }
        setAnchorEl(null);
        handleMobileMenuClose();
    };

    useEffect(() => {
        checkSession();
        loadGenericTerms();
    }, []);

    const logout = () => {
        window.location.href = '/login';
        localStorage.clear();
        cookies.remove('userId');
    };

    const menuId = 'primary-search-account-menu';
    const renderMenu = (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={menuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMenuOpen}
            onClose={handleMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={() =>switchProfile("Administrative")}>EMIS Admin</MenuItem>
            <MenuItem onClick={() => switchProfile("Academic")}>EMIS Teacher</MenuItem>
            <MenuItem onClick={logout}>Log out</MenuItem>
        </Menu>
    );

    const mobileMenuId = 'primary-search-account-menu-mobile';
    const renderMobileMenu = (
        <Menu
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={mobileMenuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            <MenuItem onClick={() =>switchProfile("Administrative")}>EMIS Admin</MenuItem>
            <MenuItem onClick={() => switchProfile("Academic")}>EMIS Teacher</MenuItem>
            <MenuItem onClick={logout}>Log out</MenuItem>
        </Menu>
    );

    return (
        <div>
            <AppBar position="absolute" className={clsx(classes.appBar, props.open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={props.handleDrawerOpen}
                        className={clsx(classes.menuButton, props.open && classes.menuButtonHidden)}
                    >
                        <MenuIcon/>
                    </IconButton>
                    <Typography className={classes.title} variant="h6" noWrap>
                        EMIS
                    </Typography>
                    <div className={classes.grow}/>
                    <div className={classes.sectionDesktop}>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={menuId}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <AccountCircle/>
                        </IconButton>
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={mobileMenuId}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon/>
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderMobileMenu}
            {renderMenu}
            <Dialog open={showLogin} onClose={hideDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">
                    Your session has expired, please login to continue
                </DialogTitle>
                <DialogContent>
                    <SignIn closeDialog={hideDialog}/>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default withRouter(Header);

