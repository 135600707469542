import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import BackButton from "../Buttons/BackButton";
import {Card, Grid} from '@material-ui/core';
import Utils from "../../utils/utils";
import StudentService from "../../services/studentService";
import ProgramService from "../../services/programService";
import MenuItem from "@material-ui/core/MenuItem";
import DateFnsUtils from "@date-io/date-fns";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";


const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
});

class AddAdmission extends React.Component {
    state = {
        newAdmission: {
            expected_end_date: Utils.getToday(),
            start_date: Utils.getToday(),
            status: '',
            qualification_entry: '',
            admission_type: '',
            award: '',
            isced_level: '',
            student_id: '',
            program_id: {},
        },

        fromUrl: null,
        students: [],
        programs: []
    };

    // Method to update the form field values of a new programme
    handleChange = e => {
        let val = e.target.value;
        let admission = {...this.state.newAdmission};
        admission[e.target.name] = val;

        this.setState({
            newAdmission: admission
        });
    };

    // Method to update start date field value
    handleStartChange = (n) => this.setState({
        newAdmission: {...this.state.newAdmission, start_date: n}
    });

    // Method to update end date field value
    handleEndChange = (n) => this.setState({
        newAdmission: {...this.state.newAdmission, expected_end_date: n}
    });

    // Method to clear form field values
    clearForm = () => {
        let _admissionData = this.state.newAdmission;
        _admissionData.expected_end_date = Utils.getToday();
        _admissionData.start_date = Utils.getToday();
        _admissionData.status = '';
        _admissionData.qualification_entry = '';
        _admissionData.award = '';
        _admissionData.isced_level = '';

        this.setState({
            newAdmission: _admissionData
        });
    };

    // Method to valid the programme fields before posting to the server
    validateFields = () => {
        const {program_id} = this.state.newAdmission;

        if (program_id === {}) return 'program';
    };

    /*
    * Method to set focus on the text fields with invalid data
    * Parameters:
    * + field_id: The id of the field to set the focus on
    * */
    setFocus = (field_id) => {
        Utils.displayMessage('error', 'Error', 'Please specify ' + field_id).then(r => r);
        document.getElementById(field_id).focus();
    };

    // Method to send and save data to the server
    saveData = () => {

        let data = this.state.newAdmission;
        if(data.start_date >= data.expected_end_date){
            Utils.displayMessage('error', 'Error', 'Start date can not be greater than or equal to expected end date').then(r => r);
            return;
        }
        if (data.student_id.label) {
            data.student_id = data.student_id.value
        }
        if (data.program_id.label) {
            data.program_id = data.program_id.value;
        }
        if (this.props.location.studentData) {
            data.student_id = this.props.location.studentData.details._id.$oid;
        } else {
            data.student_id = localStorage.getItem('student_id');
        }

        let result = this.validateFields();
        if (result) {
            this.setFocus(result);
            return;
        }
        StudentService.createAdmission(data, (result) => {
            if (result.error) {
                Utils.displayMessage('error', 'Failed', result.errors[0]).then(r => r);
            } else {
                Utils.displayMessage('success', 'Successful', result.success).then(r => r);
                this.goBack();
            }
        });
    };

    // Method to fill select field for students
    mapStudents = (students) => {
        let _students = [];
        students.map(student => _students.push({label: Utils.getFullName(student), value: student._id.$oid}));
        let _admission = {...this.state.newAdmission};

        _admission.student_id = _students[0];
        this.setState({
            students: _students,
            newAdmission: _admission
        });
    };

    // Method to fill select field for programmes
    mapPrograms = (programs) => {
        let _programs = [];
        programs.map(program => _programs.push({label: program.name, value: program._id.$oid}));
        let _admission = {...this.state.newAdmission};

        if (this.props.location.admissionData === undefined || localStorage.getItem('admissionData') === null){
            _admission.program_id = _programs[0].value;
        }
        this.setState({
            programs: _programs,
            newAdmission: _admission
        });
    };

    // Method to load students for select fields
    loadSelectData = () => {
        StudentService.loadStudents(this.mapStudents);
    };

    // Method to initialize the select fields of the form
    initialiseSelectFields = () => {
        let _admission = {...this.state.newAdmission};
        _admission.isced_level = Utils.isced_levels[0].value;
        _admission.award = Utils.awards[0].value;
        _admission.qualification_entry = Utils.requirements[1].value;
        _admission.status = Utils.status[0].value;
        _admission.admission_type = Utils.admission_types[0].value;
        this.setState({
            newAdmission: _admission
        })
    };

    componentDidMount() {
        ProgramService.loadPrograms(this.mapPrograms);
        let _props_location = this.props.location;
        if (_props_location.admissionData) {
            localStorage.setItem('admissionData', JSON.stringify(_props_location.admissionData));
        }

        if (_props_location.fromUrl) {
            localStorage.setItem('student_id', _props_location.studentData.details._id.$oid);
            let admission = {...this.state.newAdmission};
            admission.student_id = (_props_location.studentData.details._id.$oid);
            this.setState({
                newAdmission: admission,
                fromUrl: _props_location.fromUrl
            })
        }
        if (_props_location.admissionData || localStorage.getItem('admissionData') !== null) {
            let temp = (_props_location.admissionData) ? {..._props_location.admissionData} : {...JSON.parse(localStorage.getItem('admissionData'))};
            if (temp.program_id) {
                temp.program_id = temp.program_id.$oid;
                temp.student_id = temp.student_id.$oid;
                this.setState({
                    newAdmission: temp
                });
            }
        } else {
            this.initialiseSelectFields();
            this.loadSelectData();
        }
    };

    // Method to return user to the previous view
    goBack = () => {
        localStorage.removeItem('student_id');
        this.props.history.goBack();
    };

    render() {
        const {classes} = this.props;
        let admission = this.state.newAdmission;
        const _programs = this.state.programs;

        return (
            <div>
                <Card>
                    <div style={{marginLeft: 150}}>
                        <form className={classes.root} autoComplete="on">
                            <h2>{(localStorage.getItem('admissionData')) ? 'Edit Admission' : 'Add Admission'}</h2>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            id="start_date"
                                            name="start_date"
                                            value={admission.start_date}
                                            onChange={this.handleStartChange}
                                            label="Start Date"
                                            variant="inline"
                                            inputVariant="outlined"
                                            format="dd/MM/yyyy"
                                            valueDefault='20/01/2005'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>

                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                        <DatePicker
                                            id="expected_end_date"
                                            name="expected_end_date"
                                            value={admission.expected_end_date}
                                            onChange={this.handleEndChange}
                                            label="Expected End Date"
                                            variant="inline"
                                            inputVariant="outlined"
                                            format="dd/MM/yyyy"
                                            valueDefault='20/01/2005'
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>

                                    <TextField
                                        required
                                        id="qualification_entry"
                                        name="qualification_entry"
                                        select
                                        label="Qualification"
                                        value={admission.qualification_entry}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {Utils.requirements.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        required
                                        id="award"
                                        name="award"
                                        select
                                        label="Award"
                                        value={admission.award}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {Utils.awards.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="isced_level"
                                        name="isced_level"
                                        select
                                        label="Isced Level"
                                        value={admission.isced_level}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {Utils.isced_levels.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        required
                                        id="program_id"
                                        name="program_id"
                                        select
                                        label="Programme"
                                        value={admission.program_id}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {_programs.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        required
                                        id="status"
                                        name="status"
                                        select
                                        label="Status"
                                        variant="outlined"
                                        value={admission.status}
                                        onChange={e => this.handleChange(e)}
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {Utils.status.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        required
                                        id="admission_type"
                                        name="admission_type"
                                        select
                                        label="Admission Type"
                                        variant="outlined"
                                        value={admission.admission_type}
                                        onChange={e => this.handleChange(e)}
                                        style={{
                                            border: 0,
                                            outline: 0
                                        }}
                                    >
                                        {Utils.admission_types.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    <BackButton goBack={this.goBack} color={"secondary"} title={"Cancel"}/>
                    <Button variant="contained" color="primary" onClick={this.saveData} style={{margin: 5}}>
                        Save
                    </Button>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(AddAdmission);


