import React from 'react';
import TextField from '@material-ui/core/TextField';
import {withStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import MyButton from "../Buttons/Button";
import {Card, Grid} from '@material-ui/core';
import StaffService from "../../services/staffService";
import Utils from "../../utils/utils";
import ProgramService from "../../services/programService";
import MenuItem from "@material-ui/core/MenuItem";


const styles = (theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    margin: {
        margin: theme.spacing(1),
    },
    textField: {
        width: '25ch',
    },
});

class AssignHOD extends React.Component {
    state = {
        assignHOD: {
            department_id: {},
            teacher_id: ''
        },
        departments: []
    };

    // Method to update the form fields
    handleChange = e => {
        let val = e.target.value;
        let assign_hod = {...this.state.assignHOD};
        assign_hod[e.target.name] = val;
        this.setState({
            assignHOD: assign_hod
        });
    };

    // Method to clear form fields
    clearForm = () => {
        let _assign_hodData = {...this.state.assignHOD};
        this.setState({
            assignHOD: _assign_hodData
        });
    };

    // Method to return to the previous view
    goBack = () => {
        this.props.history.goBack();
    };

    // Method the send the department to be assigned to the staff
    saveData = () => {
        let data = this.state.assignHOD;
        if (data.department_id.label) {
            data.department_id = data.department_id.value;
        }
        if (this.props.location.staffData === undefined) {
            data.teacher_id = localStorage.getItem('teacherId');
        }
        StaffService.assignHOD(data, result => {
            if (result.error) {
                Utils.displayMessage('error', 'Error', result.errors[0]);
            } else {
                Utils.displayMessage('success', 'Successful', result.success);
                this.props.history.goBack();
                this.clearForm();
            }
        });
    };

    // Method to load departments for the select field
    loadDepartments = (departments) => {

        let _departments = [];
        if (departments) {
            departments.map(department => _departments.push({label: department.name, value: department._id.$oid}));
            let assign_hod = {...this.state.assignHOD};
            assign_hod.department_id = _departments[0].value;
            this.setState({
                departments: _departments,
                assignHOD: assign_hod
            })
        }
    };

    componentDidMount() {
        if (this.props.location.staffData) {
            let _assignHOD = {...this.state.assignHOD};
            localStorage.setItem('teacherId', this.props.location.staffData._id.$oid);
            _assignHOD.teacher_id = this.props.location.staffData._id.$oid;
            this.setState({
                assignHOD: _assignHOD
            })
        }
        ProgramService.loadDepartments(this.loadDepartments);
    }

    render() {
        const {classes} = this.props;
        const _match = this.props.match;
        let assign_hod = this.state.assignHOD;
        const back_to = (this.props.location.staffData) ? Utils.getReturnUrl(_match.url) : '/staff';
        const _departments = this.state.departments;

        return (
            <div>
                <div>
                    <MyButton to={back_to} direction={'right'} color={"secondary"} title={"Back"}/>
                </div>
                <br/><br/><br/>
                <Card>
                    <div style={{marginLeft: 150}}>
                        <form className={classes.root} noValidate={false} autoComplete="on">
                            <h2>Assign HOD</h2>
                            <Grid container>
                                <Grid item xs={12} sm={12} md={12}>
                                    <TextField
                                        id="department_id"
                                        name="department_id"
                                        select
                                        fullWidth
                                        label="Department"
                                        value={assign_hod.department_id}
                                        onChange={(e) => this.handleChange(e)}
                                        variant="outlined"
                                    >
                                        {_departments.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    <Button variant="contained" color="secondary" onClick={this.goBack} style={{margin: 5}}>
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={this.saveData} style={{margin: 5}}>
                        Save
                    </Button>
                </Card>
            </div>
        );
    }
}

export default withStyles(styles)(AssignHOD);


