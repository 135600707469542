import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import BasicCard from "./BasicCard";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        height: 140,
        width: 100,
    }
}));


const BasicGrid = (props) => {
    const classes = useStyles()
    const [data, setData] = useState([]);

    useEffect(() => {
        const d = props.data;
        const data = [];
        Object.keys(d).forEach((key) => {
            if (d[key]['data']) {
                data.push({name: key, count: d[key]['data'].length, key: Math.random(), data: d[key]});
            }
            setData(data);
        });
    }, [props.data]);

    return (
        <Grid container className={classes.root} spacing={4}>
            <Grid item xs={12}>
                <Grid container spacing={10}>
                    {data.map((value) => (
                        <Grid key={value.key} item>
                            <BasicCard data={value} refreshData={props.refreshData}/>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default BasicGrid;
